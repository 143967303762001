import * as signalR from '@microsoft/signalr';

import { Auth } from 'aws-amplify';

import config from '../../../commons/api/config';

export default function createWebSocketConnection() {
  const url = `${config.chatSocket}`;

  const RECONNECT_TIMEOUT = 2000;

  return new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: async () => {
        // TODO: review if socket can use select:getToken from auth module.
        const awsAuth = await Auth.currentAuthenticatedUser();

        if (awsAuth.signInUserSession) {
          return awsAuth.signInUserSession.accessToken.jwtToken || null;
        }

        return null;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => {
        return RECONNECT_TIMEOUT;
      },
    })
    .build();
}
