import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import lightBlue from '@material-ui/core/colors/lightBlue';
import clsx from 'clsx';
import { TextField, FormControl, Button, Box, InputAdornment } from '@material-ui/core';

import { textField } from './formFieldStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import CustomText from './customText';

const styles = (theme) => ({
  textField: {
    ...textField(theme),
  },
  transparentBackground: {
    background: 'transparent',
  },
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
  formControl: {
    color: 'white',
    fontSize: theme.fontSize.lg,
  },
  inputTextHighlight: {
    color: lightBlue[300],
  },
  input: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
    //backgroundColor: 'transparent',
  },
  adornment: {
    fontSize: theme.fontSize.md,
    height: '.4em',
    alignItems: 'start',
  },
  arrowButton: {
    minWidth: 0,
    padding: 0,
    borderRadius: 0,
    '&:first-child': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid',
    borderColor: theme.palette.divider,
    justifyContent: 'center',
  },

  box: {
    display: 'inline-flex',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    background: theme.palette.background.filled,
  },
});

const NumberFieldCustom = (props) => {
  const { classes, accessor, displayName, error, step, key } = props;

  const inputRef = useRef(null);

  const spinner = (incr) => (e) => {
    const increment = step ? step * incr : incr;
    //const baseValue = value ? value : props.value;
    const baseValue = props.value;
    const newValue = Math.round((Number(baseValue) + Number(increment)) * 100) / 100;
    //setValue(newValue);
    function setNativeValue(element, value) {
      const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
      const prototype = Object.getPrototypeOf(element);
      const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

      if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
      } else {
        valueSetter.call(element, value);
      }
    }
    const event = new Event('input', { bubbles: true });
    setNativeValue(inputRef.current, newValue);
    inputRef.current.dispatchEvent(event);
  };

  const adornment = props.adornment || ' ';

  return (
    <Box
      className={clsx(classes.textField, classes.box)}
      style={{
        width: props.width ? props.width : props.fullWidth ? '400px' : '250px',
      }}
    >
      <FormControl className={classes.formControl} fullWidth={props.fullWidth}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id={key}
            type="number"
            label={<CustomText value={accessor} />}
            variant="filled"
            size="small"
            value={props.value}
            onChange={props.handleChange(accessor)}
            error={props.error ? error.value : false}
            helperText={props.error ? error.message : ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
              className: clsx(
                classes.input,
                !props.dark && !props.warning && classes.transparentBackground,
                props.dark && classes.darkBackground,
                props.warning && classes.warningBackground,
              ),
              inputProps: {
                step: props.step ? props.step : 1,
                ref: inputRef,
              },

              endAdornment: (
                <InputAdornment position="end" classes={{ root: classes.adornment }}>
                  <CustomText value={adornment} />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.buttonGroup}>
            <Button className={classes.arrowButton}>
              <PlayArrowIcon
                style={{ transform: 'rotate(270deg)' }}
                onClick={spinner(1)}
              ></PlayArrowIcon>
            </Button>
            <Button className={classes.arrowButton}>
              <PlayArrowIcon
                style={{ transform: 'rotate(90deg)' }}
                onClick={spinner(-1)}
              ></PlayArrowIcon>
            </Button>
          </div>
        </div>
      </FormControl>
    </Box>
  );
};

NumberFieldCustom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberFieldCustom);
