import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Typography, Modal } from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import TotalsRow from '../../rfqs/components/TotalsRow';
import { CancelButton } from '../../../commons/components/buttons';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';

import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';
import TrafficLights from '../../../commons/components/trafficLights';
import { visibilities } from '../../../commons/models/constants';

import { status } from '../../../commons/models/constants';

import { UPDATE_RESPONSE_FORM } from '../../rfqs/constants';
//import {updateForm} from '../../rfqs/actions'
import { updateForm, updateOrders } from '../actions';
import { UPDATE_ORDERS } from '../constants';

import {
  getActiveUser,
  getUsersObject,
  getOrganisationsObject,
  getOrganisations,
  getById,
  getAllFieldValues,
} from '../selectors';

import { getKeyValuePairsFromOrder } from '../../contracts/utils/modelFunctions';
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '70vh',
    //maxHeight: '70vh',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.background.primary,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  trafficLights: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '8px',
  },
  orderRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 2px',
  },
  orderRowAlt: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class OrderDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (value) => {
    const { activeOrder } = this.state;

    switch (value) {
      case 'Amend':
        const payload = {
          action: 'amendOrder',
          order: activeOrder,
          open: true,
        };

        this.props.updateAmendOrderForm(payload);
        break;
      default:
        if (value) {
          const order = {
            orderId: activeOrder.id,
            status: String(value),
          };

          const payload = {
            items: [order],
          };

          this.props.updateOrders(payload);
        }
        break;
    }
  };

  cancel = () => {
    if (this.props.order.status !== status.CANCELLED) {
      const order = {
        orderId: this.props.order.id,
        status: status.CANCELLED,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  suspend = () => {
    if (
      this.props.order.status !== status.CANCELLED &&
      this.props.order.status !== status.SUSPENDED
    ) {
      const order = {
        orderId: this.props.order.id,
        status: status.SUSPENDED,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  activate = () => {
    if (this.props.order.status !== status.CANCELLED && this.props.order.status !== status.ACTIVE) {
      const order = {
        orderId: this.props.order.id,
        status: status.ACTIVE,
      };

      const payload = {
        items: [order],
      };

      this.props.updateOrders(payload);
    }
    this.props.onClose();
  };

  // TODO:Remove
  amendOrder = () => {
    const order = this.props.orderById;

    if (order.status !== status.CANCELLED) {
      const payload = {
        type: 'amend',
        action: 'openForm',
        rfq: null,
        orders: order,
        open: true,
      };

      this.props.updateAmendOrderForm(payload);
    }
    this.props.onClose();
  };

  getRecipients = (order, organisations, organisationId) => {
    const selectedOrganisationsIds =
      order !== null
        ? order.organisationId === organisationId
          ? order.selectedOrganisationsIds
          : []
        : [];
    const visibility =
      order !== null ? (order.organisationId === organisationId ? order.visibility : '') : '';
    let list = [];
    const orgs = [...organisations];

    if (visibility === visibilities.SELECTED_ORGANISATIONS) {
      selectedOrganisationsIds.forEach((r) => {
        let shortName = r.shortName || null;
        if (shortName === null) {
          shortName = getOrganisationFullNameFromId(r, orgs);
        }
        list.push(shortName);
      });
    } else {
      list = [visibility];
    }

    list.sort();

    return list;
  };

  render() {
    const { classes, users, organisations, user, orgs, allFieldValues } = this.props;

    const order = this.props.orderById;

    const isCompanyOrder = order.organisationId === user.organisationId;
    const isMyOrder = order.userId === user.id;

    const userData = {
      'User Name': `${users[order.userId].firstName} ${users[order.userId].lastName}`,
      'Organisation Name': organisations[order.organisationId].name,
      'Org Short Name': organisations[order.organisationId].shortName,
    };

    let orderRows = [];
    if (order.assets) {
      orderRows = getKeyValuePairsFromOrder(order);
    }
    const orderRow = orderRows[0] || [];

    const list = this.getRecipients(order, orgs, user.organisationId);
    const iWeightClass =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'weightClass') : 0;
    const iPrice =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'price') : 0;
    const iVolume =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'volume') : 0;
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>ORDER DETAILS</Typography>

          {isMyOrder && (
            <div className={classes.trafficLights}>
              <TrafficLights
                cancelAll={this.cancel}
                suspendAll={this.suspend}
                activateAll={this.activate}
              />
            </div>
          )}

          <div className={classes.section}>
            <List open classes={{ root: classes.list }} disablePadding>
              {Object.keys(userData).map((item, index) => (
                <ListItem key={index} button classes={{ root: classes.listItem }}>
                  <ListItemText primary={item} className={classes.key} />
                  <ListItemText primary={userData[item]} align="right" />
                </ListItem>
              ))}
              {isCompanyOrder === true && (
                <ListItem key="recipients" button classes={{ root: classes.listItem }}>
                  <ListItemText primary="Order Sent To:" className={classes.key} />
                  <ListItemText
                    primary={
                      <>
                        {list.map((v) => {
                          return <div>{v}</div>;
                        })}
                      </>
                    }
                    align="right"
                  />
                </ListItem>
              )}
              <ListItem key={'Comments'} button classes={{ root: classes.listItem }}>
                <ListItemText primary={'Comments'} className={classes.key} />
                <ListItemText primary={order.textMessage} align="right" />
              </ListItem>
            </List>
          </div>
          <div className={classes.section}>
            <TotalsRow rows={order.assets || []} />
          </div>
          <div className={classes.section}>
            <div
              style={{
                padding: '0px 16px 16px',
              }}
            >
              <ListItemText primary={'Order'} className={classes.key} />
              {orderRows.map((row, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <ReadOnlyField
                      displayName={'weightClass'}
                      value={row[iWeightClass].value || ''}
                      allFieldValues={allFieldValues}
                    />
                    <ReadOnlyField
                      displayName={'volume'}
                      value={row[iVolume].value || ''}
                      allFieldValues={allFieldValues}
                      adornment={'cartons'}
                    />
                    <ReadOnlyField
                      displayName={'price'}
                      value={row[iPrice].value || ''}
                      allFieldValues={allFieldValues}
                      adornment={order.currency || ''}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.section}>
            <div
              style={{
                padding: '0px 16px 0px',
              }}
            >
              <ListItemText primary={'Details'} className={classes.key} />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexFlow: 'row wrap',
                padding: ' 0px 16px 16px',
              }}
            >
              {orderRow.map((item, index) => {
                return item.key !== 'weightClass' &&
                  item.key !== 'volume' &&
                  item.key !== 'originalVolume' &&
                  item.key !== 'price' ? (
                  <ReadOnlyField
                    displayName={item.key}
                    value={item.value}
                    allFieldValues={allFieldValues}
                  />
                ) : null;
              })}
            </div>
          </div>
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton title="Close" onClick={this.props.onClose} />
                {/* <ConfirmButton onClick={this.onSave} /> */}
              </>
            </ToolbarBottom>
          </div>
        </div>
      </Modal>
    );
  }
}
OrderDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const rooms = []; // state.rooms,

function mapStateToProps(state, props) {
  const orderId = props.order ? props.order.id : null;
  return {
    user: getActiveUser(state),
    users: getUsersObject(state),
    organisations: getOrganisationsObject(state),
    rooms,
    orgs: getOrganisations(state),
    orderById: getById(state, orderId),
    allFieldValues: getAllFieldValues(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAmendOrderForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    updateOrders: (payload) => {
      dispatch(updateOrders(UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderDetail));
