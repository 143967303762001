import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = ``;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
    this.update = this.update.bind(this);
  }

  async getAll(data = {}) {
    const type = data.type || 'organisations';
    const url = `${this.baseUrl}/${type}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(organisationId = null, data = {}) {
    const type = 'organisations';
    const url = `${this.baseUrl}/${type}/${encodeURIComponent(organisationId)}/logo`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };
    const apiResponse = await coreApi.getResponse(url, params, options);
    const response = { id: organisationId, ...apiResponse };
    return response;
  }

  async update(organisationId = null, data = {}) {
    const type = 'organisations';
    const url = `${this.baseUrl}/${type}/${encodeURIComponent(organisationId)}/logo`;
    const { body, token } = data;
    const params = {};

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const apiResponse = await coreApi.getResponse(url, params, options);

    const response = { id: organisationId, ...data.body };

    return response;
  }
}

const api = new Api();
Object.freeze(api);

export default api;
