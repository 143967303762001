class HTTPError extends Error {
  constructor(status, message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }

    this.name = 'HTTPError';
    this.status = status;
  }
}
export default HTTPError;
