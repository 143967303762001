import * as actionTypes from '../actionTypes';
import { createAction } from '../../../commons/actions';
import { takeEvery, put } from 'redux-saga/effects';
import { isObject } from '../../../commons/utils/functions';
import contracts from '../../contracts';
function* initInventory(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    if (isObject(widget)) {
      payload.widget = widget;
    }
    const segmentValues = contracts.utils.getMarketSegments();
    payload.segmentValues = segmentValues;
    payload.sgementValue = segmentValues[0];

    yield put(createAction(actionTypes.INIT_INVENTORY_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.INIT_INVENTORY_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

export function* watchInitInventory() {
  yield takeEvery(actionTypes.INIT_INVENTORY, initInventory);
}
