import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Icon, TextField } from '@material-ui/core';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import PopUp from '../../../../commons/components/popUp';
import { CancelButton } from '../../../../commons/components/buttons';
import { ConfirmButton } from '../../../../commons/components/buttons';
import CustomText from '../../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  nameChange: {
    display: 'block',
    padding: '25px 8px',
  },
  nameChangeInput: { display: 'block' },
});

const SaveRfqForm = (props) => {
  const { classes } = props;
  const items = [...props.savedRfqPresets];

  const [title, setTitle] = useState('');
  const handleChange = (name) => (event) => {
    setTitle(event.target.value);
  };
  const onSave = () => {
    if (title !== '') {
      props.saveRfqPreset(title);
    }
  };

  const _saveRfqAs = CustomText({ value: 'saveRfqAs' });

  return (
    <PopUp
      open={props.open}
      title={<CustomText value="saveRfqAs" upperCase />}
      onClose={props.onClose}
    >
      <div className={classes.container}>
        <div className={classes.listContainer}>
          <List open classes={{ root: classes.list }}>
            {items.map((item) => (
              <ListItem
                key={item.id}
                button
                classes={{ root: classes.listItem }}
                onClick={() => setTitle(item.name)}
              >
                <ListItemText primary={item.name} />

                <ListItemIcon align="right">
                  <Icon>save</Icon>
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </div>
        <TextField
          classes={{ root: classes.nameChange }}
          InputProps={{ className: classes.nameChangeInput }}
          value={title}
          onChange={handleChange('title')}
          placeholder={_saveRfqAs}
        />
        <div className={classes.toolbarContainer}>
          <ToolbarBottom>
            <>
              <CancelButton title={'cancel'} onClick={props.onClose} />
              <ConfirmButton title={'save'} onClick={onSave} />
            </>
          </ToolbarBottom>
        </div>
      </div>
    </PopUp>
  );
};
SaveRfqForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SaveRfqForm);
