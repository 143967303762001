import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import debounceRender from 'react-debounce-render';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserTab from './common/UserTab';
import ActiveMessaging from './ActiveMessaging';
import ChatTabs from './common/ChatTabs';
import ChatsList from './common/ChatsList';
import CustomText from '../../../commons/components/formFields/customText';
import {
  getActiveTab,
  getSelectedChatId,
  getMappedFilteredChats,
  getNewMessagesCount,
  getChatGroups,
  getChatContacts,
} from '../selectors';
import {
  setActiveTab as setActiveTabAction,
  selectChat as selectChatAction,
  createChat as createChatAction,
} from '../actions';

import auth from '../../auth';
import users from '../../users';
import organisations from '../../organisations';
import schemas from '../../schemas';

const styles = (theme) => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
    backgroundColor: theme.palette.background.primary,
  },
  userTabContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    borderRight: ' 1px solid',
    borderRightColor: theme.palette.widgetBorder,
    minWidth: '300px',
    maxWidth: '360px',
  },

  activeContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  autocomplete: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
  },
});

const getUserName = (userId, object) => {
  const user = object[userId];
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
};

const Chat = ({
  classes,
  activeTab,
  setActiveTab,
  selectedChatId,
  user,
  chats,
  usersObject,
  organisationsObject,
  selectChat,
  createChat,
  newMessagesCount,
  chatGroups,
  chatContacts,
}) => {
  const roomInfo = {
    name: getUserName(user.id, usersObject),
    loggedIn: true,
    userId: user.id,
    organisationId: user.organisationId,
  };
  const [value, setValue] = useState(null);

  // TODO: REFACTOR. USE NAME CONSTANTS. NOT INDEX!
  const getChatsView = () => {
    if (value !== null) {
      return [value];
    }
    let allChats = [];
    switch (activeTab) {
      case 0:
        allChats = chats;
        break;
      case 2:
        allChats = chatGroups;
        break;
      case 1:
        allChats = chatContacts;
        break;
      default:
    }

    return allChats;
  };

  // TODO: REFACTOR!
  const onRoomClick = ({ id, name }) => {
    const tabs = ['recent', 'user', 'organisation'];

    const mapIds = {
      organisation: 'organisationId',
      user: 'id',
    };

    const type = tabs[activeTab];

    const payload = {
      id,
      name,
      type: type.toUpperCase(),
      ownId: user[mapIds[type]],
    };

    selectChat(payload);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  return (
    <div className={`${classes.container} undraggable`}>
      <div className={classes.userTabContainer}>
        <UserTab roomInfo={roomInfo} />
        <Autocomplete
          id="combo-box-demo"
          options={getChatsView()}
          getOptionLabel={(option) => {
            if (option.type === 'USER') {
              const ids = option.userIds.filter((id) => id !== user.id);
              const chatUser = usersObject[ids[0]];
              return `${chatUser.firstName} ${chatUser.lastName}`;
            } else if (option.type === 'ORGANISATION') {
              return option.shortName || '';
            } else {
              return option.name || '';
            }
          }}
          className={classes.autocomplete}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<CustomText value="search" />}
              variant="filled"
              size="small"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
          onChange={(e, value) => setValue(value)}
          //onInputChange={(e, value) => setInputValue(value)}
          fullWidth
        />
        <ChatTabs
          tabValue={activeTab}
          handleTabChange={(e, value) => {
            //setInputValue(null);
            setValue(null);
            setActiveTab(value);
          }}
          groupsCount={newMessagesCount.groups}
          contactsCount={newMessagesCount.contacts}
          recentCount={newMessagesCount.recent}
        />
        <ChatsList
          rooms={getChatsView()}
          selectedChatId={selectedChatId}
          onRoomClick={onRoomClick}
        />
      </div>
      <div className={classes.activeContainer}>
        <ActiveMessaging selectedChatId={selectedChatId} />
      </div>
    </div>
  );
};

Chat.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  usersObject: PropTypes.object.isRequired,
  newMessagesCount: PropTypes.object.isRequired,
  chats: PropTypes.array.isRequired,
  selectedChatId: PropTypes.string,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectChat: PropTypes.func.isRequired,
  createChat: PropTypes.func.isRequired,
  chatGroups: PropTypes.array,
  chatContacts: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    theme: state.themes,
    activeTab: getActiveTab(state),
    selectedChatId: getSelectedChatId(state),
    user: auth.selectors.getServerUser(state),
    usersObject: users.selectors.getAllObject(state),
    organisationsObject: organisations.selectors.getAllObject(state),
    chats: getMappedFilteredChats(state),
    newMessagesCount: getNewMessagesCount(state),
    chatGroups: getChatGroups(state),
    chatContacts: getChatContacts(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch(setActiveTabAction(payload));
    },
    selectChat: (payload) => {
      dispatch(selectChatAction(payload));
    },
    createChat: (payload) => {
      dispatch(createChatAction(payload));
    },
  };
};

const debouncedChat = debounceRender(withStyles(styles)(Chat), 200, { leading: false });

export default connect(mapStateToProps, mapDispatchToProps)(debouncedChat);
