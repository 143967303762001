import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  RESET_USERS,
  INSERT_USERS_FROM_SOCKET_SUCCESS,
  UPDATE_USERS_FROM_SOCKET_SUCCESS,
} from '../constants';

import { insertItems, updateItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        status: 'pending',
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        status: 'pending',
      };
    case INSERT_USERS_FROM_SOCKET_SUCCESS:
    case GET_USER_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_USERS_FROM_SOCKET_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...updateItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_USERS:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
