import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Modal, Typography, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import { getFieldsArray } from '../../../contracts/utils/modelFunctions';
import {
  CancelButton,
  ResetButton,
  SaveButton,
  ClearButton,
  ConfirmButton,
} from '../../../../commons/components/buttons';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { formatDate, formatDateTime } from '../../../../commons/config/formatters';
import TotalsRow from '../TotalsRow';
import CustomText from '../../../../commons/components/formFields/customText';
import CustomTextForCsv from '../../../../commons/components/formFields/customTextForCsv';
import { getEnumerations } from '../../../contracts/utils/modelFunctions';
import {
  isTradeDetailsOpen,
  getTradesForNotification,
  getTradeDetailsTitle,
  getAllObject,
  getOrganisationsObject,
  getUsersObject,
  isAmendTradeOpen,
  isPostTradeFormOpen,
  getMyOrganisation,
} from '../../selectors';
import { isModalChatOpen } from '../../../chat/selectors';
import {
  TRADE_DETAILS_OPEN,
  POST_TRADE_FORM_OPEN,
  AMEND_TRADE_FORM_OPEN,
  TRADE_CANCELLATION,
  tradeCancellationStatus,
} from '../../constants';
import { createAction } from '../../../../commons/actions';
import { getKeyValuePairsFromTrade } from '../../../contracts/utils/modelFunctions';
import { fields, fieldMap } from '../../config';
import fileUpload from '../../../fileUpload';
import ReactToPrint from 'react-to-print';
import { CSVLink, CSVDownload } from 'react-csv';
import authModule from '../../../auth';
import TradeAmendment from '../TradeAmendment';
import PostTradeForm from '../PostTradeForm';
import { MODAL_CHAT_OPEN } from '../../../chat/constants';
import ModalChat from '../../../chat/components/ModalChat';
import { useLanguage } from '../../../../commons/contexts/LanguageProvider';
const tstData = [
  ['firstname', 'lastname', 'email'],
  ['Ahmed', 'Tomi', 'ah@smthing.co.com'],
  ['Raed', 'Labes', 'rl@smthing.co.com'],
  ['Yezzi', 'Min l3b', 'ymin@cocococo.com'],
];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',

    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    //backgroundColor: theme.palette.primary.row,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  sectionFlexRow: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.sm,
  },
  title: { fontSize: theme.fontSize.lg },
  postTradeData: {
    flex: 1,
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.tableBorder}`,
    },
  },
  executed: {
    backgroundColor: theme.palette.teal,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },
  cancelled: {
    backgroundColor: theme.palette.red,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },
  amended: {
    backgroundColor: theme.palette.amber,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },

  cancelRequest: {
    color: theme.palette.amber_700,
    fontWeight: '700',
  },
  none: {},
  toolbarRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleContainer: {
    padding: '0px 16px 0px',
  },
  sectionContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 0px 16px 0px',
  },
  assetRowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 8px 0px 0px',
  },
});

const TradeDetails = (props) => {
  const [fileToShow, setFileToShow] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [filesRetrieved, setFilesRetrieved] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const componentRef = useRef();
  const csvLinkRef = useRef();
  const { language } = useLanguage();

  useEffect(() => {
    props.getFiles();
  }, []);

  useEffect(() => {
    if (fileId !== null && fileToShow === null) {
      const files = [...props.myFiles, ...props.sharedFiles];
      const file = files.filter((f) => f.id === fileId)[0];
      if (file) {
        if (file.preview) {
          const newFile = Object.assign(file);
          setFileToShow(newFile);
        }
      }
    }
  }, [props.myFiles, props.sharedFiles]);

  useEffect(() => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  }, [csvData]);

  /* componentDidUpdate(props) {
    if (!this.state.filesRetrieved) {
      this.props.getFiles();
      this.setState({ filesRetrieved: true });
    }
    if (this.state.fileId !== null && this.state.fileToShow === null) {
      const files = [...this.props.myFiles, ...this.props.sharedFiles];
      const file = files.filter((f) => f.id === this.state.fileId)[0];
      if (file) {
        if (file.preview) {
          const newFile = Object.assign(file);
          this.setState({ fileToShow: newFile });
        }
      }
    }
  } */

  const handlePostTradeClick = () => {
    const payload = {
      type: 'open',
      open: true,
      tradeId: props.trades[0].tradeGroupId,
    };
    props.onPostTradeClick(payload);
  };
  const handleAmendTradeClick = () => {
    const payload = {
      type: 'open',
      open: true,
      tradeId: props.trades[0].tradeGroupId,
    };
    props.onAmendTradeClick(payload);
  };
  const getData = (trade, seller) => {
    let data = {};
    if (trade) {
      if (seller) {
        const sellerUserId = trade.sellBrokerUserId;
        const sellerCompanyId = trade.sellBeneficiaryOrganisationId;
        const sellerBrokerId = trade.sellBrokerOrganisationId;
        const sellerUser = props.usersObject[sellerUserId] || {};
        const sellerCompany = props.organisationsObject[sellerCompanyId] || {};
        const sellerBroker = props.organisationsObject[sellerBrokerId] || {};
        data = {
          sellerCompany: { value: sellerCompany.name },
          sellerBroker: { value: sellerBroker.name },
          sellerUser: { value: `${sellerUser.firstName} ${sellerUser.lastName}` },
        };
        if (trade.sellerData) {
          Object.keys(trade.sellerData).forEach((key) => {
            if (key !== 'files') {
              const field = fields.buyerData[key] ? fields.buyerData[key] : fields.sellerData[key];
              if (field && field.type === 'date') {
                data[key] = { value: formatDate(trade.sellerData[key]) };
              } else {
                data[key] = { value: trade.sellerData[key] };
              }
            }
          });
          const files = trade.sellerData.files || [];
          data.files = files;
        }
      } else {
        const buyerUserId = trade.buyBrokerUserId;
        const buyerCompanyId = trade.buyBeneficiaryOrganisationId;
        const buyerBrokerId = trade.buyBrokerOrganisationId;
        const buyerUser = props.usersObject[buyerUserId] || {};
        const buyerCompany = props.organisationsObject[buyerCompanyId] || {};
        const buyerBroker = props.organisationsObject[buyerBrokerId] || {};
        data = {
          buyerCompany: { value: buyerCompany.name },
          buyerBroker: { value: buyerBroker.name },
          buyerUser: { value: `${buyerUser.firstName} ${buyerUser.lastName}` },
        };
        if (trade.buyerData) {
          Object.keys(trade.buyerData).forEach((key) => {
            if (key !== 'files') {
              const field = fields.buyerData[key] ? fields.buyerData[key] : fields.sellerData[key];
              //const displayName = field.displayName;

              if (field.type === 'date') {
                data[key] = { value: formatDate(trade.buyerData[key]) };
              } else {
                data[key] = { value: trade.buyerData[key] };
              }
            }
          });
          const files = trade.buyerData.files || [];
          data.files = files;
        }
      }
    }

    return data;
  };
  const itemClick = (item) => {
    if (item.fileId) {
      props.getFileContent({ id: item.fileId });

      setFileId(item.fileId);
    }
  };
  const closeFileViewer = () => {
    setFileId(null);
    setFileToShow(null);
  };

  const updateCancel = (tradeId, cancelStatus = null) => {
    const payload = {
      tradeId,
      body: {
        cancellationStatus: cancelStatus,
      },
      callback: handleSend,
    };

    props.updateCancellation(payload);
  };

  const handleSend = (success) => {
    if (!success) {
      setSnackbarErrorOpen(true);
    }
  };

  const getCsvData = (id) => {
    let trade;
    const { allTrades, organisationsObject } = props;
    if (id) {
      trade = props.allTrades[id];
    }

    if (trade) {
      const { product, species, location } = trade.assets[0].contract.underlying;
      const weightClasses = getEnumerations('rfq', { product, species, location }).weightClass;
      const { cartonNetWeight, deliveryPoint } = trade.assets[0].contract.underlying;
      const { packingDate, deliveryDate, consignee } = trade.assets[0].contract;
      const { trackingNumber = '', invoiceNumber = '' } = trade.sellerData || {};
      const { purchaseOrderNumber = '' } = trade.buyerData || {};
      const {
        buyBeneficiaryOrganisationId,
        buyBrokerOrganisationId,
        sellBeneficiaryOrganisationId,
        sellBrokerOrganisationId,
      } = trade;
      const { totalCartons, packingWeight, totalValue } = (function (trade) {
        let ctns = 0;
        let ctnWeight = 0;
        let val = 0;
        let weight;
        switch (cartonNetWeight) {
          case 'twelve':
            weight = 12;
            break;
          case 'twelveAndHalf':
            weight = 12.5;
            break;
          case 'fifteen':
            weight = 15;
            break;
          case 'thirtyLbs':
            weight = 30;
            break;
          default:
            weight = 1;
        }
        trade.assets.forEach((asset) => {
          const qty = Number(asset.volume);
          ctnWeight = ctnWeight + qty * weight;
          ctns = ctns + qty;
          const price = Number(asset.price);
          const nominal = qty * price * weight;
          val = val + nominal;
        });
        return {
          totalCartons: ctns,
          packingWeight: ctnWeight,
          totalValue: val,
        };
      })(trade);
      const items = [
        { key: 'purchaseOrderNumber', value: purchaseOrderNumber },
        { key: 'packingDate', value: formatDate(packingDate) },
        { key: 'deliveryDate', value: formatDate(deliveryDate) },
        { key: 'deliveryPoint', value: deliveryPoint, type: 'string' },
        { key: 'consignee', value: consignee, type: 'string' },
        { key: 'trackingNumber', value: trackingNumber },
        { key: 'seller', value: sellBeneficiaryOrganisationId, type: 'org' },
        { key: 'sellerBroker', value: sellBrokerOrganisationId, type: 'org' },
        { key: 'buyer', value: buyBeneficiaryOrganisationId, type: 'org' },
        { key: 'buyerBroker', value: buyBrokerOrganisationId, type: 'org' },
        { key: 'invoiceNumber', value: invoiceNumber },
        { key: 'cartons', value: totalCartons },
        { key: 'packingWeight', value: packingWeight },
      ];
      weightClasses.forEach((w) => {
        let sum = 0;
        trade.assets.forEach((asset) => {
          if (asset.contract.underlying.weightClass === w) {
            sum = sum + Number(asset.volume);
          }
        });
        items.push({ key: w, value: sum });
      });
      items.push({ key: 'price', value: '' });
      weightClasses.forEach((w) => {
        let sum = 0;
        let num = 0;
        trade.assets.forEach((asset) => {
          if (asset.contract.underlying.weightClass === w) {
            num++;
            sum = sum + Number(asset.price);
          }
        });
        const val = num === 0 ? 0 : sum / num;
        items.push({ key: w, value: val });
      });
      items.push({ key: 'invoiceAmount', value: totalValue });
      //const text = (val) => CustomText({ value: val });
      const headers = items.map((item) => CustomTextForCsv({ value: item.key, language }));
      //const headers = items.map((item) => item.key);
      const values = items.map((item) => {
        if (item.type) {
          if (item.type === 'string') {
            return CustomTextForCsv({ value: item.value, language });
            return item.value;
          } else if (item.type === 'org') {
            return organisationsObject[item.value].name;
          } else {
            return item.value;
          }
        }
        return item.value;
      });

      return [headers, values];
    } else {
      return [[]];
    }
  };
  const getIsCancellationRequestorSide = (trade) => {
    let direction;
    if (
      props.myOrganisation.id === trade.sellBeneficiaryOrganisationId ||
      props.myOrganisation.id === trade.sellBrokerOrganisationId
    ) {
      direction = 'sell';
    } else {
      direction = 'buy';
    }

    if (
      trade.cancellationInitiatedByOrgId === trade[`${direction}BrokerOrganisationId`] ||
      trade.cancellationInitiatedByOrgId === trade[`${direction}BeneficiaryOrganisationId`]
    ) {
      return true;
    } else {
      return false;
    }
  };
  const downloadCsvClick = () => {
    const trade = props.trades.length > 0 ? props.trades[0] : {};
    const tradeId = trade.tradeGroupId || null;
    const csvData = getCsvData(tradeId);

    setCsvData(csvData);
  };

  const { classes, open, onClose, isSuperAdmin } = props;
  let orderRows = [];
  if (props.trades.length > 0) {
    orderRows = getKeyValuePairsFromTrade(props.trades);
  }
  const orderRow = orderRows[0] || [];
  const trade = props.trades.length > 0 ? props.trades[0] : {};
  const rfqId = trade.rfqId;
  const tradeId = trade.tradeGroupId || null;
  orderRow.push({ key: 'id', value: tradeId });
  const tradeData = {
    status: trade.status,
    tradeTime: formatDateTime(trade.createdTime),
    rfqComments: trade.rfqComments,
  };

  const newTrade = props.allTrades[tradeId] || {};
  const cancellationStatus = trade.cancellationStatus || null;
  if (cancellationStatus !== tradeCancellationStatus.NONE) {
    tradeData['cancellationStatus'] = cancellationStatus;
    tradeData['cancellationRequestor'] = trade.cancellationInitiatedByOrgId
      ? props.organisationsObject[trade.cancellationInitiatedByOrgId].shortName
      : '';
  }
  const amendable =
    newTrade.sellBeneficiaryOrganisationId === props.myOrganisation.id ||
    newTrade.sellBrokerOrganisationId === props.myOrganisation.id;

  const originalTrade = newTrade.originalTradeSnapshot || null;
  const isCancellationRequestorSide = getIsCancellationRequestorSide(newTrade);
  const isCancellationRequestor = props.myOrganisation.id === newTrade.cancellationInitiatedByOrgId;

  const sellerData = newTrade.id ? getData(newTrade, true) : {};
  const buyerData = newTrade.id ? getData(newTrade, false) : {};
  const sellerDataFiles = sellerData.files || [];
  const buyerDataFiles = buyerData.files || [];

  const formFields = getFieldsArray('order', ['responseForm', 'spot', 'fresh']);
  const fieldKeys = formFields ? Object.keys(formFields) : [];
  const formFieldsTop = fieldKeys.filter((f) => formFields[f].formTopSection);
  const formFieldsBottom = fieldKeys.filter((f) => formFields[f].formBottomSection);
  const formFieldsRight = fieldKeys.filter((f) => formFields[f].formRightSection);
  const formFieldsLeft = fieldKeys.filter((f) => formFields[f].formLeftSection);

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>
            <CustomText value="tradeDetails" upperCase />
          </Typography>
          <ToolbarBottom>
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ReactToPrint
                  trigger={() => <ClearButton title={'print'} />}
                  content={() => componentRef.current}
                />

                <ClearButton title={'downloadCsv'} onClick={downloadCsvClick}></ClearButton>
                <CSVLink
                  ref={csvLinkRef}
                  data={csvData}
                  target="_blank"
                  filename="myTrades.csv"
                ></CSVLink>
              </div>
            </>
          </ToolbarBottom>

          <div className={classes.container} ref={componentRef}>
            <div className={classes.section}>
              <List open classes={{ root: classes.list }} disablePadding>
                {Object.keys(tradeData).map((item, index) => {
                  const tradeDataItem = tradeData[item] || '';
                  const class_name =
                    item === 'status'
                      ? tradeDataItem.includes('EXEC')
                        ? classes.executed
                        : tradeDataItem.includes('CANC')
                        ? classes.cancelled
                        : classes.amended
                      : item.includes('Canc')
                      ? classes.cancelRequest
                      : classes.none;
                  return (
                    <ListItem key={index} button classes={{ root: classes.listItem }}>
                      <ListItemText primary={<CustomText value={item} />} className={classes.key} />
                      <ListItemText
                        primary={
                          <div className={class_name}>
                            <CustomText value={tradeData[item]} />
                          </div>
                        }
                        align="right"
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <div className={classes.sectionFlexRow}>
              <div className={classes.postTradeData}>
                <List open classes={{ root: classes.list }} disablePadding>
                  {Object.keys(sellerData).map((item, index) => {
                    if (item !== 'files') {
                      return (
                        <ListItem
                          key={index}
                          button
                          classes={{ root: classes.listItem }}
                          onClick={() => itemClick(sellerData[item])}
                        >
                          <ListItemText
                            primary={<CustomText value={item} />}
                            className={classes.key}
                          />
                          <ListItemText
                            primary={sellerData[item].value}
                            align="right"
                            style={item === 'Status' ? { color: teal[500] } : {}}
                          />
                        </ListItem>
                      );
                    }
                  })}
                  {sellerDataFiles.map((f, index) => {
                    const fileType = f.type;
                    const key = fieldMap[fileType];
                    const item = { value: f.filename, fileId: f.id };

                    return (
                      <ListItem
                        key={`file${index}`}
                        button
                        classes={{ root: classes.listItem }}
                        onClick={() => itemClick(item)}
                      >
                        <ListItemText
                          primary={<CustomText value={key} />}
                          className={classes.key}
                        />
                        <ListItemText primary={item.value} align="right" />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div className={classes.postTradeData}>
                <List open classes={{ root: classes.list }} disablePadding>
                  {Object.keys(buyerData).map((item, index) => {
                    if (item !== 'files') {
                      return (
                        <ListItem
                          key={index}
                          button
                          classes={{ root: classes.listItem }}
                          onClick={() => itemClick(buyerData[item])}
                        >
                          <ListItemText
                            primary={<CustomText value={item} />}
                            className={classes.key}
                          />
                          <ListItemText
                            primary={buyerData[item].value}
                            align="right"
                            style={item === 'Status' ? { color: teal[500] } : {}}
                          />
                        </ListItem>
                      );
                    } else return null;
                  })}
                  {buyerDataFiles.map((f, index) => {
                    const fileType = f.type;
                    const key = fieldMap[fileType];
                    const item = { value: f.filename, fileId: f.id };

                    return (
                      <ListItem
                        key={`file${index}`}
                        button
                        classes={{ root: classes.listItem }}
                        onClick={() => itemClick(item)}
                      >
                        <ListItemText
                          primary={<CustomText value={key} />}
                          className={classes.key}
                        />
                        <ListItemText primary={item.value} align="right" />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>

            <div className={classes.section}>
              <TotalsRow orderTradeArray={[newTrade]} />
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'order'} upperCase />}
                  className={classes.key}
                />
                {newTrade && newTrade.assets && (
                  <React.Fragment>
                    {newTrade.assets.map((row, index) => {
                      let warningWeightClass = false;
                      let warningPrice = false;
                      let warningVolume = false;
                      let warningPackingWeight = false;
                      const weightClass = row.contract.underlying.weightClass;
                      const price = row.price;
                      const volume = row.volume;
                      const packingWeight = row.contract.packingWeight;
                      const unit = row.contract.underlying.unit;
                      const currency = row.contract.underlying.currency;
                      if (originalTrade) {
                        const oldRow = originalTrade ? originalTrade.assets[index] : null;
                        warningWeightClass = true;
                        warningPrice = true;
                        warningVolume = true;
                        warningPackingWeight = true;
                        if (oldRow) {
                          warningWeightClass = oldRow
                            ? oldRow.contract.underlying.weightClass !==
                              row.contract.underlying.weightClass
                            : true;
                          warningPrice = oldRow ? oldRow.price !== row.price : true;
                          warningVolume = oldRow ? oldRow.volume !== row.volume : true;
                          warningPackingWeight = oldRow
                            ? oldRow.contract.packingWeight !== row.contract.packingWeight
                            : true;
                        }
                      }

                      return (
                        <div className={classes.assetRowContainer} key={index}>
                          <ReadOnlyField
                            displayName={'weightClass'}
                            value={weightClass || ''}
                            warning={warningWeightClass}
                          />
                          <ReadOnlyField
                            displayName={'volume'}
                            value={volume || 0}
                            adornment={'cartons'}
                            warning={warningVolume}
                            fieldType="numeric"
                          />
                          <ReadOnlyField
                            displayName={'price'}
                            value={price || 0}
                            adornment={currency}
                            warning={warningPrice}
                            fieldType="numeric"
                          />
                          <ReadOnlyField
                            displayName={'packingWeight'}
                            value={packingWeight || 0}
                            adornment={unit}
                            warning={warningPackingWeight}
                          />
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'company'} upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  newTrade &&
                  newTrade.assets &&
                  formFieldsTop.map((field, index) => {
                    if (formFields[field] && newTrade) {
                      let value = newTrade.assets[0];
                      let oldValue = originalTrade ? originalTrade.assets[0] : null;
                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                        oldValue = oldValue ? oldValue[fullAccessor[i]] : null;
                      }
                      const warning = oldValue ? oldValue !== value : false;
                      return (
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                          warning={warning}
                        />
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'product'} upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  newTrade &&
                  newTrade.assets &&
                  formFieldsLeft.map((field, index) => {
                    if (formFields[field] && newTrade) {
                      let value = newTrade.assets[0];
                      let oldValue = originalTrade ? originalTrade.assets[0] : null;
                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                        oldValue = oldValue ? oldValue[fullAccessor[i]] : null;
                      }
                      const warning = oldValue ? oldValue !== value : false;
                      return (
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                          warning={warning}
                        />
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'freight'} upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  newTrade &&
                  newTrade.assets &&
                  formFieldsRight.map((field, index) => {
                    if (formFields[field] && newTrade) {
                      let value = newTrade.assets[0];
                      let oldValue = originalTrade ? originalTrade.assets[0] : null;
                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                        oldValue = oldValue ? oldValue[fullAccessor[i]] : null;
                      }
                      const warning = oldValue ? oldValue !== value : false;
                      return (
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                          warning={warning}
                        />
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'offer'} upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  newTrade &&
                  newTrade.assets &&
                  formFieldsBottom.map((field, index) => {
                    if (formFields[field] && newTrade) {
                      let value = newTrade.assets[0];
                      let oldValue = originalTrade ? originalTrade.assets[0] : null;
                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                        oldValue = oldValue ? oldValue[fullAccessor[i]] : null;
                      }
                      const warning = oldValue ? oldValue !== value : false;
                      return (
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                          warning={warning}
                        />
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value={'details'} upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {orderRow.map((item, index) => {
                  return item.key === 'createdTime' ||
                    item.key === 'updatedTime' ||
                    item.key === 'id' ? (
                    <ReadOnlyField displayName={item.key} value={item.value} key={index} />
                  ) : null;
                })}
              </div>
            </div>
          </div>
          <fileUpload.components.FileViewer
            file={fileToShow}
            open={fileId !== null}
            onClose={closeFileViewer}
          />
          {props.amendTradeOpen && <TradeAmendment />}
          {props.postTradeOpen && <PostTradeForm />}
          {props.modalChatOpen && <ModalChat />}
          <ToolbarBottom>
            <React.Fragment>
              <CancelButton onClick={onClose} title={'close'} />
              <div className={classes.toolbarRight}>
                {!isSuperAdmin && (
                  <ClearButton
                    onClick={() => props.openChat({ open: true, trade: newTrade })}
                    title={'chat'}
                    icon="chat-bubble"
                  />
                )}
                {(cancellationStatus === tradeCancellationStatus.NONE ||
                  cancellationStatus === tradeCancellationStatus.DECLINED) &&
                  !isSuperAdmin && (
                    <SaveButton
                      onClick={() => updateCancel(tradeId, tradeCancellationStatus.REQUESTED)}
                      title={'requestCancel'}
                    />
                  )}
                {cancellationStatus === tradeCancellationStatus.REQUESTED &&
                  !isSuperAdmin &&
                  !isCancellationRequestorSide && (
                    <SaveButton
                      onClick={() => updateCancel(tradeId, tradeCancellationStatus.APPROVED)}
                      title={'approveCancel'}
                    />
                  )}
                {cancellationStatus === tradeCancellationStatus.REQUESTED &&
                  !isCancellationRequestorSide &&
                  !isSuperAdmin && (
                    <SaveButton
                      onClick={() => updateCancel(tradeId, tradeCancellationStatus.DECLINED)}
                      title={'declineCancel'}
                    />
                  )}
                {cancellationStatus === tradeCancellationStatus.REQUESTED &&
                  isCancellationRequestor && (
                    <SaveButton
                      onClick={() => updateCancel(tradeId, tradeCancellationStatus.NONE)}
                      title={'removeCancel'}
                    />
                  )}
                {!isSuperAdmin && (
                  <ResetButton
                    onClick={handlePostTradeClick}
                    title={'addPostTradeInformation'}
                    icon="add"
                  />
                )}
                {amendable && !isSuperAdmin && (
                  <ClearButton onClick={handleAmendTradeClick} title={'amendTrade'} icon="edit" />
                )}
              </div>
            </React.Fragment>
          </ToolbarBottom>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarErrorOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarErrorOpen(false)}
      >
        <Alert onClose={() => setSnackbarErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
          <CustomText value="unableToSendCancelRequest" upperCase />
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

TradeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isTradeDetailsOpen(state),
    amendTradeOpen: isAmendTradeOpen(state) || false,
    postTradeOpen: isPostTradeFormOpen(state),
    modalChatOpen: isModalChatOpen(state),
    title: getTradeDetailsTitle(state),
    trades: getTradesForNotification(state),
    allTrades: getAllObject(state),
    myFiles: fileUpload.selectors.getAllMyFiles(state),
    sharedFiles: fileUpload.selectors.getAllSharedFiles(state),
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    isSuperAdmin: authModule.selectors.isSuperAdmin(state),
    myOrganisation: getMyOrganisation(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(createAction(TRADE_DETAILS_OPEN, { open: false }));
    },
    openChat: (payload) => {
      dispatch(createAction(MODAL_CHAT_OPEN, payload));
    },
    onPostTradeClick: (payload) => {
      dispatch(createAction(POST_TRADE_FORM_OPEN, payload));
    },
    onAmendTradeClick: (payload) => {
      dispatch(createAction(AMEND_TRADE_FORM_OPEN, payload));
    },
    getFileContent: (payload) => {
      dispatch(createAction(fileUpload.constants.GET_FILE_CONTENT, payload));
    },
    getFiles: (payload) => {
      dispatch(createAction(fileUpload.constants.GET_FILES, payload));
    },
    updateCancellation: (payload) => {
      dispatch(createAction(TRADE_CANCELLATION, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeDetails));
