import { createAction } from '../../../commons/actions';
import { CHATS_SELECT_CHAT } from '../constants';

// TODO: move in constants
export const GET_CHATS_SUCCESS = 'chats/get/success';
export const CHATS_CREATE_CHAT = 'chats/chat/create';
export const CHATS_SEND_MESSAGE = 'chats/send/message';
export const CHATS_CHAT_RECEIVED = 'chats/chat/received';
export const CHATS_SAVE_MESSAGES = 'chats/save/messages';
export const CHATS_SET_ACTIVE_TAB = 'chats/set/active_tab';
export const CHATS_LOAD_HISTORY = 'chats/load/history';
export const CHATS_CREATE_RFQ_CHAT = 'chats/create/rfq_chat';

// TODO: Replace on invoke method
function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

// TODO: Replace on invoke method
export const getChats = makeActionCreator(GET_CHATS_SUCCESS, 'payload', 'meta');
export const createChat = makeActionCreator(CHATS_CREATE_CHAT, 'payload', 'meta');
export const sendMessage = makeActionCreator(CHATS_SEND_MESSAGE, 'payload', 'meta');
export const chatReceived = makeActionCreator(CHATS_CHAT_RECEIVED, 'payload', 'meta');
export const saveMessages = makeActionCreator(CHATS_SAVE_MESSAGES, 'payload', 'meta');
export const setActiveTab = makeActionCreator(CHATS_SET_ACTIVE_TAB, 'payload', 'meta');
export const selectChat = makeActionCreator(CHATS_SELECT_CHAT, 'payload', 'meta');
export const loadHistory = makeActionCreator(CHATS_LOAD_HISTORY, 'payload', 'meta');
export const createRfqChat = makeActionCreator(CHATS_CREATE_RFQ_CHAT, 'payload', 'meta');

export const insertChats = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateChats = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

// TODO: REPLACE: use selectChat
export const selectNewChat = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const receiveMessages = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
