import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createAction } from '../../../../commons/actions';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Badge } from '@material-ui/core';
import chatModule from '../../../chat';

const styles = (theme) => {
  return {
    icon: {
      color: theme.palette.icon,
    },
  };
};

const Chat = (props) => {
  const { chatVisible, rfq, classes } = props;
  const [room, setRoom] = useState(null);
  const chats = useSelector((state) => state.chat.chats);

  const user = useSelector((state) => state.auth.server.user);
  const organisationsObject = useSelector((state) => state.organisations.byId);
  const chatsLength = chats.length;
  const dispatch = useDispatch();
  useEffect(() => {
    getRoom();
  }, [rfq, chatsLength]);

  const createChatRoom = (room) => {
    if (!room.hasOwnProperty('rfqId')) {
      const counterpartyOrgId =
        rfq.brokerOrganisationId === user.organisationId
          ? rfq.selectedOrganisationsIds[0]
          : rfq.brokerOrganisationId;

      const originator = user.organisationId;
      const originatorName = organisationsObject[originator].shortName;
      const counterpartyName = organisationsObject[counterpartyOrgId].shortName;

      const payload = {
        name: `${originatorName}-${counterpartyName} RFQ-${rfq.id}`,
        type: 'RFQ',
        organisationIds: [counterpartyOrgId, originator],
        rfqId: rfq.id,
        callback: () => {},
      };

      dispatch(chatModule.actions.createRfqChat(payload));
    }
  };
  const getRoom = () => {
    let foundRoom = null;
    if (rfq) {
      const counterpartyOrgId =
        rfq.createdByUserId === user.id
          ? rfq.selectedOrganisationsIds[0]
          : rfq.brokerOrganisationId;

      foundRoom = chats.find((item) => {
        return item.rfqId === rfq.id && item.organisationIds.includes(counterpartyOrgId);
      });
    }
    if (foundRoom) {
      markAsRead(foundRoom);
      setRoom(foundRoom || {});
    } else {
      createChatRoom(foundRoom || {});
    }
  };
  const markAsRead = (room) => {
    if (room && Number(room.newMessages) > 0) {
      const payload = {
        action: 'markAsRead',
        items: [{ id: room.id }],
      };
      dispatch(createAction(chatModule.constants.CHATS_UPDATE, payload));
    }
  };

  return (
    <div
      onClick={() => {
        markAsRead(room);
      }}
    >
      <chatModule.components.ActiveMessaging
        selectedChatId={room ? room.id : null}
        isChatVisible={chatVisible}
        style={{ maxHeight: '540px' }}
      />
    </div>
  );
};

export default withStyles(styles)(Chat);
