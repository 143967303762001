export const market = {
  product: {
    name: 'product',
    display: 'Product',
    accessor: 'product',
    type: 'select',
    values: ['SALMON'],
    defaultValues: ['SALMON'],
    default: 'SALMON',
  },
  location: {
    name: 'location',
    display: 'Location',
    accessor: 'location',
    type: 'select',
    values: ['NORWAY', 'SCOTLAND', 'FAROE', 'ICELAND'],
    defaultValues: ['NORWAY', 'SCOTLAND', 'FAROE', 'ICELAND'],
    displayNames: ['SALMON NORWAY', 'SALMON SCOTLAND', 'SALMON FAROE', 'SALMON ICELAND'],
    default: 'NORWAY',
  },

  deliveryPointFob: {
    name: 'Delivery Point FOB',
    display: 'Delivery FOB',
    accessor: 'deliveryPointFob',
    type: 'select',
    values: ['OSLO', 'FARM'],
    defaultValues: ['OSLO'],
    default: 'OSLO',
  },
  deliveryPointDdp: {
    name: 'Delivery Point DDP',
    display: 'Delivery DDP',
    accessor: 'deliveryPointDdp',
    type: 'select',
    values: ['GLOUCESTER', 'GRIMSBY', 'USK', 'PLUNGE', 'TOKYO', 'USTKA', 'BIELSK PODLASKI'],
    defaultValues: ['GLOUCESTER', 'GRIMSBY', 'USK', 'PLUNGE', 'TOKYO', 'USTKA', 'BIELSK PODLASKI'],
    default: '',
  },
  weightClass: {
    name: 'Weight Class',
    display: 'Weight',
    accessor: 'weightClass',
    type: 'select',
    values: ['1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9+'],
    defaultValues: ['1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9+'],
    default: '1-2',
  },
  region: {
    name: 'region',
    display: 'Region',
    accessor: 'region',
    type: 'select',
    values: [1, 2, 3, 4],
    defaultValues: [1, 2, 3, 4],
    default: 1,
  },
  unit: {
    name: 'Unit',
    display: 'Unit',
    accessor: 'unit',
    type: 'select',
    values: ['KG'],
    defaultValues: ['KG'],
    default: 'KG',
  },
  currency: {
    name: 'Currency',
    display: 'Curr.',
    accessor: 'currency',
    type: 'select',
    values: ['EUR', 'GBP'],
    defaultValues: ['EUR'],
    default: 'EUR',
  },
};

export const defaultMarketValues = (location) => {
  const values = {};
  values.unit = 'KG';
  values.region = 1;
  if (location === 'NORWAY') {
    values.currency = 'EUR';
    values.deliveryPointFob = 'OSLO';
  } else if (location === 'SCOTLAND') {
    values.currency = 'GBP';
    values.deliveryPointFob = 'GLASGOW';
  } else if (location === 'FAROE') {
    values.currency = 'EUR';
    values.deliveryPointFob = 'TORSHAVN';
  } else {
    values.currency = 'EUR';
    values.deliveryPointFob = 'REYKJAVIK';
  }
  return values;
};
