import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import Calculator from './components/Calculator';
import ManageCalculators from './components/ManageCalculators';

const reducers = {
  reducer,
};

const components = {
  Calculator,
  ManageCalculators,
};

export default { actions, constants, reducers, sagas, selectors, components };
