export default class RFQInitiate {
  constructor(payload) {
    const product = {};

    product.name = payload.contract.underlying.product;
    product.location = payload.contract.underlying.location;
    product.price = payload.price;

    this.product = product;
    this.weightClasses = [payload.contract.underlying.weightClass];
    this.deliveryDate = payload.contract.deliveryDate;
    this.deliveryFob = payload.contract.underlying.deliveryPointFob;
    this.deliveryDestination = payload.contract.underlying.deliveryPointDdp;
    this.harvestDate = payload.contract.harvestDate;
    this.currency = payload.contract.underlying.currency;
    this.quantity = 0;
    this.volume = payload.volume;
    this.price = payload.price;
    this.visibility = payload.visibility;
    this.recipientList = payload.recipientList;
    this.anonymous = payload.anonymous;
  }
}
