import { takeEvery, put, call, select } from 'redux-saga/effects';

import constants from '../constants';

import {
  initBlotter as actionInitBlotter,
  destroyBlotter as actionDestroyBlotter,
  updateBlotter as actionUpdateBlotter,
  updateBlotterPresets as actionUpdateBlotterPresets,
} from '../actions';

import { saveToLS, getFromLS } from '../../../commons/localStorage';

import { getBlotterPresets } from '../selectors';

import { isObject } from '../../../commons/utils/functions';

//import api from '../api';
//import { getDefault, getConfig } from '../selectors';

import { getColumns } from '../utils/columns';

import auth from '../../auth';
import workspaces from '../../workspaces';
import schemas from '../../schemas';

function* initBlotter(action) {
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
    menu: [],
  };

  const presets = yield select(getBlotterPresets);
  let allFieldValues = yield select(schemas.selectors.getAllFieldValues);

  const meta = { receivedAt: new Date() };
  let savedState = {};
  if (isObject(widget)) {
    payload.widget = widget;
    if (isObject(widget.savedState)) {
      savedState = widget.savedState;
    }
  }

  const tabValue = savedState.tabValue ? savedState.tabValue : 0;

  const columns = getColumns(allFieldValues);

  const blotterSettings = savedState.blotterSettings ? savedState.blotterSettings : presets.initial;

  const blotterPresets = blotterSettings[tabValue]
    ? blotterSettings[tabValue]
    : presets.initial[tabValue];
  const { columnState, filterState, sortState } = blotterPresets;
  payload.columns = columns;
  payload.tabValue = tabValue;
  payload.columnState = columnState;
  payload.filterState = filterState;
  payload.sortState = sortState;

  //const defaultConfig = yield select(getDefault);
  //let contractName = defaultConfig.contractName;

  // if (savedState.contractName) {
  // contractName = savedState.contractName;
  // }

  yield put(actionInitBlotter(constants.INIT_BLOTTER_SUCCESS, payload, meta));
}

function* destroyBlotter(action) {
  const payload = {
    id: action.payload.id,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionDestroyBlotter(constants.DESTROY_BLOTTER_SUCCESS, payload, meta));
}

function* updateBlotter(action) {
  const { widget, eventType, value, tabValue } = action.payload;
  const meta = { receivedAt: new Date() };

  const payload = {
    id: widget.id,
  };

  if (eventType === 'changeTabValue') {
    const presets = yield select(getBlotterPresets);

    let savedState = {};
    if (isObject(widget)) {
      payload.widget = widget;
      if (isObject(widget.savedState)) {
        savedState = widget.savedState;
      }
    }
    const tabValue = value;

    const blotterSettings = savedState.blotterSettings
      ? savedState.blotterSettings
      : presets.initial;
    const blotterPresets = blotterSettings[tabValue]
      ? blotterSettings[tabValue]
      : presets.initial[tabValue];
    const { columnState, filterState, sortState } = blotterPresets;

    payload.tabValue = tabValue;
    payload.columnState = columnState;
    payload.filterState = filterState;
    payload.sortState = sortState;
    if (isObject(widget)) {
      const newWidget = {
        ...widget,
        savedState: widget.savedState
          ? { ...widget.savedState, tabValue: value }
          : { tabValue: value },
      };

      yield put(
        workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
      );

      payload.widget = newWidget;
    }
  } else if (eventType === 'changeColumnState') {
    if (isObject(widget)) {
      const newWidget = {
        ...widget,
      };

      const savedState = { ...widget.savedState } || {};
      const blotterSettings = { ...savedState.blotterSettings } || {};

      blotterSettings[tabValue] = value;
      savedState.blotterSettings = blotterSettings;
      newWidget.savedState = savedState;

      yield put(
        workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
      );

      payload.widget = newWidget;
      /*   payload.tabValue = tabValue;
      payload.columnState = value.columnState;
      payload.filterState = value.filterState;
      payload.sortState = value.sortState; */
    }
  }

  yield put(actionUpdateBlotter(constants.UPDATE_BLOTTER_SUCCESS, payload, meta));
}

function* changeLanguage(action) {
  try {
    let allFieldValues = yield select(schemas.selectors.getAllFieldValues);

    if (Object.keys(allFieldValues).length === 0) {
      const token = yield call(auth.selectors.getToken);

      const options = {
        token,
        params: {},
      };

      const models = yield call(schemas.api.get, options);
      const obj = {};
      models.forEach((m) => (obj[m.name] = m));
      const model = obj.rfqContractUnderlying;

      if (model.fields) {
        model.fields.forEach((field) => {
          field.values.forEach((val) => {
            allFieldValues[val.name] = val;
          });
        });
      }
    }
    const columns = getColumns(allFieldValues);
    const payload = {
      columns: columns,
    };
    const meta = { receivedAt: new Date() };
    yield put(actionUpdateBlotter(constants.UPDATE_BLOTTER_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
  }
}

/**
 *
 * @param {*} action
 */
function* updateBlotterPresets(action) {
  try {
    const { preset } = action.payload;

    const blotterPresets = getFromLS('blotterPresets') || getFromLS('blotterSettings') || [];

    const index = blotterPresets.findIndex((b) => {
      return b.name === preset.name && b.type === preset.type;
    });

    if (index === -1) {
      blotterPresets.push(preset);
    } else {
      preset.id = blotterPresets[index].id;
      blotterPresets[index] = preset;
    }

    //saveToLS('blotterSettings', blotterPresets);
    saveToLS('blotterPresets', blotterPresets);
    const payload = {
      item: blotterPresets,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateBlotterPresets(constants.UPDATE_BLOTTER_PRESETS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateBlotterPresets(constants.UPDATE_BLOTTER_PRESETS_ERROR, { error }));
  }
}

export function* watchUpdateBlotterPresets() {
  yield takeEvery(constants.UPDATE_BLOTTER_PRESETS, updateBlotterPresets);
}

export function* watchInitBlotter() {
  yield takeEvery(constants.INIT_BLOTTER, initBlotter);
}

export function* watchDestroyBlotter() {
  yield takeEvery(constants.DESTROY_BLOTTER, destroyBlotter);
}

export function* watchUpdateBlotter() {
  yield takeEvery(constants.UPDATE_BLOTTER, updateBlotter);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

export function* watchChangeLanguage() {
  yield takeEvery(schemas.actionTypes.CHANGE_LANGUAGE, changeLanguage);
}
