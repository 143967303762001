import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';

import Rfq from './components';
import Form from './components/forms/Form';
import ResponseForm from './components/forms/ResponseForm';
import ExecuteTradeForm from './components/forms/ExecuteTradeForm';
import AmendForm from './components/forms/AmendForm';
import Tab from './components/Tab';

import RFQAmendStatus from './models/RFQAmendStatus';
import RFQContract from './models/RFQContract';
import RFQInitiate from './models/RFQInitiate';
import RFQRequestDetails from './models/RFQRequestDetails';

const components = {
  Rfq,
  forms: {
    Form,
    ResponseForm,
    AmendForm,
    ExecuteTradeForm,
  },
  Tab,
};

const reducers = {
  reducer,
};

const models = {
  RFQAmendStatus,
  RFQContract,
  RFQInitiate,
  RFQRequestDetails,
};

export default { actions, constants, reducers, sagas, selectors, api, components, models };
