import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';

import {
  Icon,
  Button,
  IconButton,
  Typography,
  Grid,
  Collapse,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';

import { checkPrice, checkFxPrice, checkRate } from '../../../commons/config/formatters';

import SelectField from '../../../commons/components/formFields/SelectField';
import NumberFieldFilled from '../../../commons/components/formFields/numberFieldFilled';
import SwitchField from '../../../commons/components/formFields/switchField';
import CalculatorsMenu from './CalculatorsMenu';

import contracts from '../../contracts';

import { isObject } from '../../../commons/utils/functions';
import { getCalculatorsFromSettings } from '../selectors';
import { calculatorManager } from '../actions';
import { CALCULATOR_MANAGER } from '../constants';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
  },
  currText: {
    color: teal[300],
  },

  banner: {
    marginTop: theme.spacing(),
    textAlign: 'left',
  },
  subHeading: {
    marginTop: theme.spacing(),
    textAlign: 'left',
    color: lightBlue[300],
    fontSize: theme.fontSize.xs,
  },

  feesContainer: {
    marginTop: theme.spacing(),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
  },
  gridContainer: {
    alignItems: 'center',
  },

  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textAlign: 'left',
  },
  workspaceTitle: {
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    minWidth: '120px',
  },
});

class CalcTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      menuOpen: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  checkValue = (field, value) => {
    switch (field) {
      case 'importRate':
      case 'exportRate':
      case 'invoiceRate':
      case 'creditInsuranceRate':
      case 'transportInsuranceRate':
      case 'transportRate':
      case 'terminalFeesRate':
        return checkRate(value);

      default:
        return checkPrice(value);
    }
  };

  // TODO: REFACTOR! EXTRACT TO SAGA
  handleChange = (name) => (event) => {
    const { errors } = this.props.calculatorState; // TODO: CRITICAL. IMMUTABLE PROPS> CAN NOT CHANGE PROPS!!!!!!!!!!!!!!!!!!!!
    switch (name) {
      case 'price':
      case 'importRate':
      case 'exportRate':
      case 'invoiceRate':
      case 'creditInsuranceRate':
      case 'transportInsuranceRate':
      case 'transportRate':
      case 'terminalFeesRate':
        if (this.checkValue(name, event.target.value)) {
          errors[name] = { message: '', value: false };
        } else {
          errors[name] = {
            message: 'Value must be a decimal( max 3 places ) > 0.',
            value: true,
          };
        }
        this.props.handleChange(name, event.target.value, errors);
        break;
      case 'ddpPrice':
      case 'collapseOpen':
        this.props.handleChange(name, event.target.checked, errors);
        break;

      case 'transportCurrency':
      case 'localCurrency':
      case 'currencyRate':
        if (checkFxPrice(event.target.value)) {
          errors[name] = { message: '', value: false };
        } else {
          errors[name] = {
            message: 'Value must be a decimal( max 4 places ) > 0.',
            value: true,
          };
        }
        this.props.handleChange(name, event.target.value, errors);
        break;
      case 'fxRateInverted':
        const fxRateInverted = !this.props.calculatorState.fxRateInverted;
        this.props.handleChange(name, fxRateInverted, errors);
        break;
      default:
        this.props.handleChange(name, event.target.value, errors);
    }
  };

  updateCurrencyRate = () => {
    const { localCurrency } = this.props.calculatorState;
    this.props.handleChange('localCurrency', localCurrency, this.props.calculatorState.errors);
  };

  calculatorClick = (e) => {
    this.setState({
      anchorEl: e.target,
      menuOpen: !this.state.menuOpen,
    });
  };

  menuClick = (id) => {
    this.props.changeCalculator(id);
    this.setState({ menuOpen: false });
  };

  openCalculatorManager = () => {
    const payload = {
      action: 'openManager',
    };
    this.props.openCalculatorManager(payload);
  };

  render() {
    const { classes, contract } = this.props;

    let currencies = [];

    if (
      isObject(contract) &&
      isObject(contract.orderFields) &&
      isObject(contract.orderFields.currency) &&
      isObject(contract.orderFields.currency.values)
    ) {
      currencies = Object.keys(contract.orderFields.currency.values);
    }

    let localCurrency = 'EUR';
    let finalCurrency = 'EUR';
    let currencyRate = null;
    let fxRateInverted = null;

    let ddpPrice = null;
    let collapseOpen = null;
    let importRate = null;
    let exportRate = null;
    let creditInsuranceRate = null;
    let transportInsuranceRate = null;
    let rebateRate = null;
    let miscRate = null;
    let transportRate = null;
    let terminalFees = null;
    let cmr = null;
    let otherFee = null;
    let notification = null;
    let bankFee = null;
    let invoiceFee = null;

    if (isObject(this.props.calculatorState)) {
      localCurrency =
        this.props.calculatorState.localCurrency !== undefined
          ? this.props.calculatorState.localCurrency
          : localCurrency;
      finalCurrency =
        this.props.calculatorState.finalCurrency !== undefined
          ? this.props.calculatorState.finalCurrency
          : finalCurrency;
      currencyRate = this.props.calculatorState.currencyRate;
      fxRateInverted = this.props.calculatorState.fxRateInverted;
      ddpPrice = this.props.calculatorState.ddpPrice;
      collapseOpen = this.props.calculatorState.collapseOpen;
      importRate = this.props.calculatorState.importRate;
      exportRate = this.props.calculatorState.exportRate;
      creditInsuranceRate = this.props.calculatorState.creditInsuranceRate;
      transportInsuranceRate = this.props.calculatorState.transportInsuranceRate;
      rebateRate = this.props.calculatorState.rebateRate;
      miscRate = this.props.calculatorState.miscRate;
      transportRate = this.props.calculatorState.transportRate;
      terminalFees = this.props.calculatorState.terminalFees;
      cmr = this.props.calculatorState.cmr;
      otherFee = this.props.calculatorState.otherFee;
      notification = this.props.calculatorState.notification;
      bankFee = this.props.calculatorState.bankFee;
      invoiceFee = this.props.calculatorState.invoiceFee;
    }
    return (
      <div className={classes.container}>
        <div className={classes.root}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={2} className={classes.gridItem}>
              <SelectField
                accessor="localCurrency"
                displayName="Local Currency"
                value={localCurrency}
                values={currencies}
                handleChange={this.handleChange}
                fullWidth
                width={null}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridItem}>
              <SelectField
                accessor="finalCurrency"
                displayName="Final Currency"
                value={finalCurrency}
                values={currencies}
                handleChange={this.handleChange}
                fullWidth
                width={null}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridItem}>
              <NumberFieldFilled
                accessor="currencyRate"
                displayName="Currency Rate"
                value={currencyRate}
                handleChange={this.handleChange}
                fullWidth
                width={null}
                adornment={
                  fxRateInverted
                    ? `${finalCurrency} / ${localCurrency}`
                    : `${localCurrency} / ${finalCurrency}`
                }
                step="0.1"
              />
            </Grid>

            <Grid item xs={1} className={classes.gridItem}>
              <Tooltip title="refresh FX rate">
                <IconButton classes={{ root: classes.button }} onClick={this.updateCurrencyRate}>
                  <Icon>refresh</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="invert Rate">
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={this.handleChange('fxRateInverted')}
                >
                  <Icon>flip_camera_android</Icon>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={5} className={classes.gridItem}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SwitchField
                  checked={ddpPrice}
                  value={ddpPrice}
                  handleChecked={this.handleChange('ddpPrice')}
                  onLabel="Fees"
                  offLabel=""
                />

                <SwitchField
                  checked={collapseOpen}
                  value={collapseOpen}
                  handleChecked={this.handleChange('collapseOpen')}
                  onLabel="Show Fees"
                  offLabel=""
                />

                <FormControlLabel
                  labelPlacement="top"
                  classes={{
                    label: classes.workspaceTitle,
                    labelPlacementTop: classes.formControlLabel,
                  }}
                  label="Calculator"
                  control={
                    <Button
                      onClick={this.calculatorClick}
                      aria-label="Delete"
                      style={{ padding: 0 }}
                    >
                      <Typography variant="subtitle2">{this.props.calculator.name}</Typography>
                    </Button>
                  }
                />
                <CalculatorsMenu
                  anchorEl={this.state.anchorEl}
                  open={this.state.menuOpen}
                  calculators={this.props.calculators}
                  onClick={this.menuClick}
                  showEditor={this.openCalculatorManager}
                  onClose={() => this.setState({ menuOpen: false })}
                />
              </div>
            </Grid>
          </Grid>
          <Collapse in={collapseOpen}>
            <div className={classes.feesContainer}>
              <Typography className={classes.subHeading} style={{ flexGrow: 1 }}>
                Duty
              </Typography>
              <Grid container>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="importRate"
                    displayName="Import"
                    value={importRate}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="exportRate"
                    displayName="Export"
                    value={exportRate}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>
              </Grid>

              <Typography className={classes.subHeading} style={{ flexGrow: 1 }}>
                Variable Cost
              </Typography>
              <Grid container>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="creditInsuranceRate"
                    displayName="Credit Ins."
                    value={creditInsuranceRate || 0}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="transportInsuranceRate"
                    displayName="Transport Ins."
                    value={transportInsuranceRate || 0}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="rebateRate"
                    displayName="Rebate"
                    value={rebateRate}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>{' '}
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="miscRate"
                    displayName="Misc. Rate"
                    value={miscRate}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment="%"
                    step="0.1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="transportRate"
                    displayName="Transport"
                    value={transportRate}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency} / KG`}
                    step="0.1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="terminalFees"
                    displayName="Terminal Fees"
                    value={terminalFees}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency} / KG`}
                    step="0.1"
                  />
                </Grid>
              </Grid>
              <Typography className={classes.subHeading} style={{ flexGrow: 1 }}>
                Fixed Cost
              </Typography>
              <Grid container>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="cmr"
                    displayName="CMR"
                    value={cmr}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency}`}
                    step="1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="notification"
                    displayName="Notification KGH"
                    value={notification}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency}`}
                    step="1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="invoiceFee"
                    displayName="Invoice Fee"
                    value={invoiceFee}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency}`}
                    step="1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="bankFee"
                    displayName="Bank Fee"
                    value={bankFee}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency}`}
                    step="1"
                  />
                </Grid>
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="otherFee"
                    displayName="Other Fee"
                    value={otherFee}
                    handleChange={this.handleChange}
                    fullWidth
                    width={null}
                    adornment={`${localCurrency}`}
                    step="1"
                  />
                </Grid>
              </Grid>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

CalcTool.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    contract: contracts.selectors.getActiveContract(state),
    calculators: getCalculatorsFromSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openCalculatorManager: (payload) => {
      dispatch(calculatorManager(CALCULATOR_MANAGER, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CalcTool));
