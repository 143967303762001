import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const styles = (theme) => ({
  textField: {
    width: '100%',
    maxWidth: 500,
  },

  inputProps: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  root: {},
});

const FormTextField = (props) => {
  const {
    classes,
    input,
    label,
    type,

    meta: { touched, error },
  } = props;

  return (
    <TextField
      label={label}
      key={label}
      type={type}
      inputProps={{
        className: classes.inputProps,
      }}
      className={classes.textField}
      value={input.value}
      onChange={input.onChange}
      error={touched ? error : false}
      helperText={touched ? error : ''}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

FormTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormTextField);
