import { createSelector } from 'reselect';

import { NAME } from '../constants';

const getValueEnumsProp = (state) => {
  const items = {
    species: state[NAME].speciesValues,
    product: state[NAME].productValues,
    deliveryCountry: state[NAME].deliveryCountryValues,
  };
  return items;
};
export const getValueEnums = createSelector(getValueEnumsProp, (items) => items);

const getValuesProp = (state) => {
  const items = {
    species: state[NAME].speciesValue,
    product: state[NAME].productValue,
  };
  return items;
};
export const getValues = createSelector(getValuesProp, (items) => items);

const getOrdersProp = (state) => {
  const items = state[NAME].orders;

  return items;
};
export const getOrders = createSelector(getOrdersProp, (items) => items);

const getTradesProp = (state) => {
  const items = state[NAME].trades;

  return items;
};
export const getTrades = createSelector(getTradesProp, (items) => items);

const getStatsProp = (state) => {
  const items = state[NAME].stats;

  return items;
};
export const getStats = createSelector(getStatsProp, (items) => items);

const emptyLocalStatistics = {};
const getLocalStatisticsByIdProp = (state, id) => {
  const item = state[NAME].items[id];
  return item || emptyLocalStatistics;
};
export const getLocalStatisticsById = createSelector(getLocalStatisticsByIdProp, (item) => item);