import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Icon, TextField, Paper, Button, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BrandProfile from './BrandProfile';
import { ConfirmButton, CancelButton } from '../../../commons/components/buttons';
import { createAction } from '../../../commons/actions';
import { getBrandsAndFactories } from '../selectors';
import schemas from '../../schemas';
import Warning from './Warning';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

class FactoryNumbers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: '',
      addNew: false,
      factoryNumber: '',
      warningItems: [],
      warningValue: null,
    };
  }
  createFieldValue = () => {
    if (this.state.factoryNumber !== '') {
      const payload = {
        body: {
          name: this.state.factoryNumber,
          displayFields: [],
          enabled: true,
        },
        type: 'factoryNumber',
      };

      this.props.createFieldValue(payload);
      this.setState({ addNew: false });
    }
  };

  deleteFieldValueClick = (value) => {
    const warningItems = [];
    this.props.organisations.forEach((org) => {
      if (org.details) {
        if (org.details.factoryNumber) {
          if (org.details.factoryNumber.includes(value)) {
            warningItems.push(org);
          }
        }
      }
    });

    if (warningItems.length === 0) {
      this.deleteFieldValue(value);
    } else {
      this.setState({ warningItems, warningValue: value });
    }
  };

  deleteFieldValue = (value) => {
    const payload = {
      value,
      type: 'factoryNumber',
    };

    this.props.deleteFieldValue(payload);
  };

  render() {
    const { brandsAndFactories, classes } = this.props;
    const factoryNumbers = brandsAndFactories.factoryNumber;

    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
    return (
      <React.Fragment>
        <div className={classes.filterRow}>
          <div className={classes.icon}>
            <Icon>filter_list</Icon>
          </div>
          <Autocomplete
            id="combo-box-demo"
            options={Object.keys(factoryNumbers)}
            getOptionLabel={(option) => option}
            style={{ width: 256, margin: '2px' }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Search Organisation" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ value: value })}
            onInputChange={(e, value) => this.setState({ inputValue: value })}
          />
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            {this.state.addNew === false && (
              <Button
                aria-label="Delete"
                className={classes.fab}
                onClick={() => this.setState({ addNew: true })}
              >
                <Icon className={classes.extendedIcon}>add</Icon>
                <Typography>Add New Factory</Typography>
              </Button>
            )}
            <div
              style={
                this.state.addNew === true
                  ? {
                      width: '100%',
                      transition: 'width .5s',
                    }
                  : { width: '0px' }
              }
            >
              {this.state.addNew === true && (
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    transition: 'width 2s',
                    marginLeft: '16px',
                  }}
                >
                  <TextField
                    variant="filled"
                    size="small"
                    fullWidth
                    label="Enter New Factory"
                    onChange={(e) => this.setState({ factoryNumber: e.target.value })}
                  ></TextField>
                  <ConfirmButton
                    style={{ minWidth: '80px' }}
                    onClick={this.createFieldValue}
                  ></ConfirmButton>
                  <CancelButton
                    style={{ minWidth: '80px' }}
                    onClick={() => this.setState({ addNew: false })}
                  ></CancelButton>
                </div>
              )}
            </div>
          </div>
        </div>
        {Object.keys(factoryNumbers).map((factoryNumber, index) => {
          return (
            <BrandProfile
              brand={factoryNumber}
              deleteFieldValue={() => this.deleteFieldValueClick(factoryNumber)}
            ></BrandProfile>
          );
        })}
        <Warning
          open={this.state.warningItems.length > 0}
          items={this.state.warningItems}
          onClose={() => this.setState({ warningItems: [] })}
          value={this.state.warningValue || ''}
          type="Factory Number"
        ></Warning>
      </React.Fragment>
    );
  }
}

FactoryNumbers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    brandsAndFactories: getBrandsAndFactories(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createFieldValue: (payload) => {
      dispatch(createAction(schemas.actionTypes.CREATE_FIELD_VALUE, payload));
    },
    deleteFieldValue: (payload) => {
      dispatch(createAction(schemas.actionTypes.DELETE_FIELD_VALUE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FactoryNumbers));
