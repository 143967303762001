const globalAny = global;

export const getFromLS = (key) => {
  let ls = {};
  if (globalAny.localStorage) {
    try {
      ls = JSON.parse(globalAny.localStorage.getItem(key)) || {};
    } catch (e) {
      console.error(e);
    }
  }
  return ls[key];
};

export const saveToLS = (key, value) => {
  const ls = {};
  if (globalAny.localStorage) {
    globalAny.localStorage.setItem(
      key,
      JSON.stringify({
        [key]: value,
      }),
    );
  }
  return ls[key];
};

export const removeFromLS = (key) => {
  if (globalAny.localStorage) {
    try {
      globalAny.localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
};

export const removeAWSKeysFromLS = () => {
  if (globalAny.localStorage) {
    const ls = Object.keys(globalAny.localStorage);
    ls.forEach((key) => {
      if (key.includes('Cognito') || key.includes('amplify') || key.includes('io-session'))
        removeFromLS(key);
    });
  }
};

export const getSettingsFromLS = () => {
  const settings = {};

  if (globalAny.localStorage) {
    const ls = Object.keys(globalAny.localStorage);
    ls.forEach((key) => {
      if (!key.includes('Cognito') && !key.includes('amplify') && !key.includes('io-session'))
        settings[key] = JSON.parse(globalAny.localStorage.getItem(key))[key] || {};
    });
  }

  return settings;
};
