import { directions, visibilities } from '../../../commons/config/constants';
import { idGenerator } from '../../../commons/config/functions';

import { market } from '../../market/config/market';

const defaultMarket = [market.product.default, market.location.default].join(' ');

export const orderRow = () => {
  return {
    active: true,
    id: idGenerator(),
    product: market.weightClass.values[0],
    direction: directions.BUY,
    volume: 100,
    mid: 5.5,
    spread: 0.5,
    price: 5.0,
    anonymous: false,
    visibility: visibilities.TRADING_PARTNERS,
    recipients: [],
  };
};

export const defaultOrderList = (num) => {
  const orderList = [];
  const wgts = market.weightClass.values;
  wgts.forEach((wgt) => {
    const newOrderRow = orderRow();
    newOrderRow.product = wgt;
    orderList.push(newOrderRow);
  });
  return {
    name: `OrderList${num}`,
    active: true,
    market: defaultMarket,
    orders: orderList,
    id: idGenerator(),
  };
};
