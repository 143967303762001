import React from 'react';

const translationModel = require('../../../modules/schemas/models/translation_model');

const CustomText = (props) => {
  const { value, language } = props;
  const translateValue = (value) => {
    let returnValue = value;
    if (translationModel) {
      if (translationModel.fields) {
        const field = translationModel.fields.find((f) => f.enum === value);

        if (field) {
          returnValue = field[language];
        }
      }
    }
    returnValue = props.upperCase && returnValue ? returnValue.toUpperCase() : returnValue;
    return returnValue;
  };
  return translateValue(value) || '';
};

export default CustomText;
