import { createSelector } from 'reselect';
import { NAME } from '../constants';

import contracts from '../../contracts';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyFxrate = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyFxrate;
};
export const getById = createSelector(getByIdProp, (item) => item);

const emptyLocalFxrate = {};
const getLocalFxrateByIdProp = (state, id) => {
  const item = state[NAME].items[id];
  return item || emptyLocalFxrate;
};
export const getLocalFxrateById = createSelector(getLocalFxrateByIdProp, (item) => item);

export const getActiveContract = createSelector(contracts.selectors.getActiveContract, (item) => {
  return item;
});

const getAllLocalFxratesProp = (state) => {
  const { items } = state[NAME];
  return Object.values(items);
};
export const getAllLocalFxrates = createSelector(getAllLocalFxratesProp, (items) => items);

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);

const getFxRatesProp = (state, id) => {
  const items = state[NAME].byId;
  const rates = {};
  Object.keys(items).forEach((item) => {
    items[item].rates.forEach((rate) => {
      const currency = `${item}/${rate.quoteCurrency}`;
      const value = rate.rate;
      rates[currency] = value;
    });
  });
  return rates;
};
export const getFxRates = createSelector(getFxRatesProp, (item) => item);
