import * as actionTypes from '../actionTypes';

import { insertItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOGOS:
      return {
        ...state,
        status: 'pending',
      };
    case actionTypes.GET_LOGOS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case actionTypes.GET_LOGOS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case actionTypes.GET_LOGO:
      return {
        ...state,
        status: 'pending',
      };
    case actionTypes.GET_LOGO_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case actionTypes.GET_LOGO_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case actionTypes.RESET_LOGOS:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
