import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Popover,
  Typography,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';

import CustomText from '../formFields/customText';

import rfqs from '../../../modules/rfqs';
import { getContacts, getRfqNotifications } from '../../selectors';

const styles = (theme) => ({
  notifications: {
    width: '440px',
  },
  notificationHeader: {
    color: theme.palette.text.faded_75,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
  },
  messageContainer: {
    backgroundColor: theme.palette.background.primary,
    overflowY: 'scroll',
    padding: theme.spacing(),
  },
  tabContainer: {
    marginTop: theme.spacing(),
  },
});

class NotificationsMenu extends React.Component {
  updateRfqViews = () => {
    const { rfqNotifications } = this.props;

    rfqNotifications.forEach((rfq) => {
      const id = rfq.id || null;
      this.props.updateRfqViews({ id });
    });

    this.props.onClose();
  };

  render() {
    const { classes, anchorEl, open, rfqNotifications, contacts } = this.props;

    return (
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={this.props.onClose}
      >
        <div className={classes.notifications}>
          <div className={classes.notificationHeader}>
            <Typography variant="h6" color="inherit">
              <CustomText value="notifications" />
            </Typography>
            <div>
              <Icon>settings</Icon>
            </div>
          </div>
          <div className={classes.messageContainer}>
            {rfqNotifications.map((item, index) => {
              return (
                <div key={index} className={classes.tabContainer}>
                  <rfqs.components.Tab
                    key={item.id}
                    data={item}
                    contacts={contacts}
                    dismissable
                    updateRfqViews={this.props.updateRfqViews}
                    markAsRead
                  />
                </div>
              );
            })}
          </div>
        </div>
        <List open>
          <ListItem button onClick={this.updateRfqViews}>
            <ListItemIcon>
              <Icon>check</Icon>
            </ListItemIcon>
            <ListItemText primary={<CustomText value={'markAllAsRead'} />} />
          </ListItem>
        </List>
      </Popover>
    );
  }
}

NotificationsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    contacts: getContacts(state),
    rfqNotifications: getRfqNotifications(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(NotificationsMenu));
