import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  snackbar: {
    position: 'relative',
  },
  snackbarContent: {
    backgroundColor: red[500],
    color: theme.palette.text.primary,
    width: '100%',
  },
});

const Toast = (props) => {
  const { classes, message } = props;

  const [open, setOpen] = React.useState(true);

  const onClose = (event) => {
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        message={message}
        className={classes.snackbar}
        ContentProps={{ className: classes.snackbarContent }}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

Toast.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Toast);
