import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alingItems: 'center',
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(),
  },

  currencyName: {
    padding: theme.spacing(),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },

  green: {
    color: teal[300],
  },
  red: {
    color: red[300],
  },
  price: {
    fontSize: '24px',
    color: theme.palette.text.primary,
  },
  change: {
    color: theme.palette.text.hint,
  },
  changeSection: {
    textAlign: 'center',
    padding: '0px 4px',
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 4px',
  },
});
const LfexRow = (props) => {
  const { classes, item } = props;
  return (
    <div className={classes.row}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes.currencyName}>{item.title}</div>
      </div>

      <div className={classes.changeSection}>
        <div className={classes.change}>Change:</div>
        <div className={item.change > 0 ? classes.green : classes.red}>{item.change} NOK</div>
        <div className={item.change > 0 ? classes.green : classes.red}>{item.percentChange}</div>
      </div>

      <div className={classes.priceSection}>
        {item.change > 0 ? (
          <i className={` ${classes.green} material-icons`}>arrow_upward</i>
        ) : (
          <i className={` ${classes.red} material-icons`}>arrow_downward</i>
        )}
        <div className={classes.price}>{item.price}</div>
      </div>
    </div>
  );
};

LfexRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LfexRow);
