import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton } from '../../../commons/components/buttons';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
  },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  orgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: theme.palette.text.disabled,
    padding: '4px 16px 0px 4px',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Warning = (props) => {
  const { classes, items, value, type } = props;

  return (
    <PopUp
      open={props.open}
      title={'This cannot be deleted because it is in use.'}
      onClose={props.onClose}
      containerWidth={640}
    >
      <div className={classes.container}>
        <div className={classes.listContainer} />
        <div className={classes.header}>{type}</div>
        <div className={classes.section}>
          <div>{value}</div>
        </div>
        <div className={classes.header}>{'Organisations'}</div>
        {items.map((item) => {
          return (
            <div className={classes.section}>
              <div>{item.name}</div>
              <div>{item.shortName}</div>
            </div>
          );
        })}

        <div className={classes.toolbarContainer}>
          <ToolbarBottom>
            <>
              <CancelButton title="Cancel" onClick={props.onClose} />
              {/*  <ConfirmButton title="Delete" onClick={props.delete} /> */}
            </>
          </ToolbarBottom>
        </div>
      </div>
    </PopUp>
  );
};
Warning.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Warning);
