import { takeEvery, call, put } from 'redux-saga/effects';

import {
  RESET_CONTRACTS,
  GET_CONTRACT,
  GET_CONTRACTS,
  GET_CONTRACT_ERROR,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_ERROR,
  CHANGE_STATE_CONTRACT,
} from '../constants';

import { getContracts, getContract, changeStateContract } from '../actions';

import api from '../api';

import auth from '../../auth';

import contract from '../models/contract.json';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);
    const contracts = yield call(api.getAll, { token });

    const payload = {
      items: contracts,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getContracts(GET_CONTRACTS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getContracts(GET_CONTRACTS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    // const contract = yield call(api.get);

    const payload = {
      items: [contract],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getContract(GET_CONTRACT_SUCCESS, payload, meta));

    yield put(
      changeStateContract(
        CHANGE_STATE_CONTRACT,
        { source: 'contract' },
        { receivedAt: new Date() },
      ),
    );
  } catch (error) {
    console.error(error);
    yield put(getContract(GET_CONTRACT_ERROR, { error }));
  }
}

export function* watchGetContracts() {
  yield takeEvery(GET_CONTRACTS, getAll);
}

export function* watchGetContract() {
  yield takeEvery(GET_CONTRACT, get);
}

export function* watchResetContracts() {
  yield takeEvery(RESET_CONTRACTS);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
