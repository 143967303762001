import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import clsx from 'clsx';
import MomentUtils from '@date-io/moment';
import CustomText from './customText';
import { textField, fieldFontSize } from './formFieldStyles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import moment from 'moment';

const styles = (theme) => ({
  textStyle: {
    ...textField(theme),
  },
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
  fieldFont: {
    ...fieldFontSize(theme),
    padding: 0,
  },
});

const DateField = (props) => {
  const { classes, accessor, displayName, disablePast } = props;
  const dateStyle = props.style || {};
  const value = props.value ? moment(props.value) : null;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        clearable={props.clearable}
        id={props.key}
        key={props.key}
        variant="filled"
        size="small"
        autoOk
        style={
          props.fullWidth
            ? { ...dateStyle.style }
            : { width: props.width ? props.width : '250px', ...dateStyle }
        }
        className={classes.textStyle}
        disablePast={disablePast}
        label={<CustomText value={accessor} />}
        format="DD/MM/YYYY"
        value={value}
        onChange={props.handleDateChange(accessor)}
        InputProps={{
          disableUnderline: true,
          className: clsx(
            classes.fieldFont,
            props.dark && classes.darkBackground,
            props.warning && classes.warningBackground,
          ),
        }}
        fullWidth
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateField);
