import { eventChannel } from 'redux-saga';
import { call, put, take, cancelled } from 'redux-saga/effects';

import createWebSocketConnection from '../api/socket';
import { chatReceived, receiveMessages } from '../actions';
import { CHATS_RECEIVE_MESSAGES_FROM_SOCKET } from '../constants';

// TODO: REFACTOR!!!
export default function* chatListenerSaga() {
  let channel;

  try {
    const socket = yield call(createWebSocketConnection);

    yield socket.start();

    channel = yield new eventChannel((emit) => {
      const emitMessage = (message) => {
        emit(message);
      };

      socket.on(`OnChatUpdates`, emitMessage);

      return () => {
        socket.off(`OnChatUpdates`, emitMessage);
        socket.stop();
      };
    });

    while (true) {
      const message = yield take(channel);

      if (Array.isArray(message.payload)) {
        for (let i = 0; i < message.payload.length; i += 1) {
          const item = message.payload[i];
          if (item.type === 'Chat') {
            yield put(chatReceived(JSON.parse(item.data)));
          } else if (item.type === 'Message') {
            yield put(receiveMessages(CHATS_RECEIVE_MESSAGES_FROM_SOCKET, JSON.parse(item.data)));
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (yield cancelled()) {
      channel && channel.close();
      // socket = null;
      // socketChannel = null;
      // socket.close();
    } else {
      // yield put({ type: 'CONNECT_ERROR' }); // Socket disconnect
    }
  }
}
