import moment from 'moment';
import { directions, visibilities } from '../../../commons/models/constants';

export const Order = (order) => {
  const newOrder = {
    // anonymous: order.anonymous || false,
    contract: order.contract || {},
    direction: order.direction || 'SELL',

    currency: order.currency || 'EUR',
    deliveryPointDdp: order.deliveryPointDdp || '',
    deliveryPointFob: order.deliveryPointFob || 'OSLO',
    isRfqInitiator: order.isRfqInitiator || false,
    price: order.price || 0,
    recipients: order.recipients || [],
    targetId: order.targetId || [],
    username: order.username || '',
    visibility: order.visibility || 'TRADING_PARTNERS',
    volume: order.volume || 0,
  };
  return newOrder;
};

export const orderFields = {
  status: {
    name: 'Status',
    displayName: 'Status',
    accessor: 'status',
    fullAccessor: 'status',
    parentAccessor: '',
    shortName: 'STATUS',
    type: 'text',
    values: [],
    default: 'ACTIVE',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: 'select',
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
    rfqResponseForm: { visible: true },
  },
  direction: {
    name: 'Direction',
    displayName: 'Direction',
    accessor: 'direction',
    fullAccessor: 'direction',
    parentAccessor: '',
    shortName: 'DIR.',
    type: 'select',
    values: [directions.BUY, directions.SELL],
    default: directions.BUY,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'order',
      component: 'select',
      outputType: 'string',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'order',
      component: 'select',
      outputType: 'string',
      output: true,
    },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
    rfqResponseForm: { visible: true },
  },
  volume: {
    name: 'Volume',
    displayName: 'Volume',
    accessor: 'volume',
    fullAccessor: 'volume',
    parentAccessor: '',
    shortName: 'VOL',
    type: 'number',
    values: [],
    default: 1000,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'order',
      component: 'number',
      outputType: 'number',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'order',
      component: 'number',
      outputType: 'number',
      output: true,
    },
    orderBlotter: {
      visible: true,
      outputType: 'number',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'number',
    },
    rfqResponseForm: { visible: true },
  },
  originalVolume: {
    name: 'OriginalVolume',
    displayName: 'Orig. Vol.',
    accessor: 'originalVolume',
    fullAccessor: 'originalVolume',
    parentAccessor: '',
    shortName: 'ORIG VOL',
    type: 'number',
    values: [],
    default: 10,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: 'number',
      outputType: 'number',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'number',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'number',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'number',
    },
  },
  price: {
    name: 'Price',
    displayName: 'Price',
    accessor: 'price',
    fullAccessor: 'price',
    parentAccessor: '',
    shortName: 'PRICE',
    type: 'number',
    values: [],
    default: 5.6,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: 'number',
      outputType: 'number',
      output: false,
    },
    orderForm: {
      visible: true,
      section: 'order',
      component: 'number',
      outputType: 'number',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'number',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'number',
    },
  },
  nominal: {
    name: 'Nominal',
    displayName: 'Nominal',
    accessor: 'nominal',
    fullAccessor: 'nominal',
    parentAccessor: '',
    shortName: 'Nom.',
    type: 'number',
    values: [],
    default: 0,
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: 'number',
      outputType: 'number',
      output: false,
    },
    orderForm: {
      visible: false,
      section: 'order',
      component: 'number',
      outputType: 'number',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: false,
      outputType: 'number',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'number',
    },
  },
  isRfqInitiator: {
    name: 'IsRfqInitiator',
    displayName: 'Is Rfq Initiator',
    accessor: 'isRfqInitiator',
    fullAccessor: 'isRfqInitiator',
    parentAccessor: '',
    shortName: 'IsRfqInitiator',
    type: 'select',
    values: [true, false],
    default: false,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: 'checkbox',
      outputType: 'boolean',
      output: false,
    },
    orderForm: {
      visible: false,
      section: 'order',
      component: 'checkbox',
      outputType: 'boolean',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'boolean',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'boolean',
    },
  },
  rfqId: {
    name: 'rfqId',
    displayName: 'RfqId',
    accessor: 'rfqId',
    fullAccessor: 'rfqId',
    parentAccessor: '',
    shortName: 'RFQ ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  orderGroupId: {
    name: 'orderGroupId',
    displayName: 'OrderGroupId',
    accessor: 'orderGroupId',
    fullAccessor: 'orderGroupId',
    parentAccessor: '',
    shortName: 'ORD GRP ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: true,
      outputType: 'boolean',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'boolean',
    },
  },
  anonymous: {
    name: 'Anonymous',
    displayName: 'Anon.',
    accessor: 'anonymous',
    fullAccessor: 'anonymous',
    parentAccessor: '',
    shortName: 'ANON',
    type: 'select',
    values: [true, false],
    default: false,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'orderType',
      component: 'checkbox',
      outputType: 'boolean',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'orderType',
      component: 'checkbox',
      outputType: 'boolean',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'boolean',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'boolean',
    },
  },
  visibility: {
    name: 'Visibility',
    displayName: 'Visibility',
    accessor: 'visibility',
    fullAccessor: 'visibility',
    parentAccessor: '',
    shortName: 'VIS.',
    type: 'select',
    values: [
      visibilities.PUBLIC,
      visibilities.TRADING_PARTNERS,
      visibilities.SELECTED_ORGANISATIONS,
    ],
    default: visibilities.TRADING_PARTNERS,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'orderType',
      component: 'select',
      outputType: 'string',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'orderType',
      component: 'select',
      outputType: 'string',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  recipientList: {
    name: 'Recipients',
    displayName: 'Recipients',
    accessor: 'recipientList',
    fullAccessor: 'recipientList',
    parentAccessor: '',
    shortName: 'RECIPIENTS',
    type: 'select',

    values: [],
    default: [],
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'orderType',
      component: 'mutli-select',
      outputType: 'array',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'orderType',
      component: 'mutli-select',
      outputType: 'array',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'array',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'array',
    },
  },
  autoClose: {
    name: 'AutoClose',
    displayName: 'Auto Close',
    accessor: 'autoClose',
    fullAccessor: 'autoClose',
    parentAccessor: '',
    shortName: 'AUTO CLOSE',
    type: 'select',
    values: [true, false],
    default: false,
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'orderType',
      component: 'checkbox',
      outputType: 'boolean',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'orderType',
      component: 'checkbox',
      outputType: 'boolean',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'boolean',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'boolean',
    },
  },

  timeInForce: {
    name: 'TimeInForce',
    displayName: 'Time In Force',
    accessor: 'timeInForce',
    fullAccessor: 'timeInForce',
    parentAccessor: '',
    shortName: 'TIME',
    type: 'date-time',
    values: '',
    default: moment(new Date()).add(2, 'hours').format('YYYY-MM-DDTHH:mm'),
    newOrderForm: { visible: true },

    rfqForm: {
      visible: true,
      section: 'orderType',
      component: 'date-time',
      outputType: 'date-time',
      output: true,
    },
    orderForm: {
      visible: true,
      section: 'orderType',
      component: 'date-time',
      outputType: 'date-time',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'date-time',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'date-time',
    },
  },
  user: {
    name: 'User',
    displayName: 'User',
    accessor: 'user',
    fullAccessor: 'user',
    parentAccessor: '',
    shortName: 'USER',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },

    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  username: {
    name: 'User',
    displayName: 'User',
    accessor: 'username',
    fullAccessor: 'user.name',
    parentAccessor: '',
    shortName: 'USER',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },

    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  userFullName: {
    name: 'User',
    displayName: 'User',
    accessor: 'user.fullName',
    fullAccessor: 'user.fullName',
    parentAccessor: '',
    shortName: 'USER',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },

    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  userId: {
    name: 'UserId',
    displayName: 'UserId',
    accessor: 'user',
    fullAccessor: 'user.id',
    parentAccessor: '',
    shortName: 'USER_ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  actingUserId: {
    name: 'ActingUserId',
    displayName: 'ActingUserId',
    accessor: 'actingUserId',
    fullAccessor: 'actingUserId',
    parentAccessor: '',
    shortName: 'ACTING_USER_ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  actingUser: {
    name: 'ActingUser',
    displayName: 'ActingUser',
    accessor: 'actingUser',
    fullAccessor: 'actingUser',
    parentAccessor: '',
    shortName: 'ACTING_USER_ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  actingUserName: {
    name: 'ActingUser',
    displayName: 'ActingUser',
    accessor: 'actingUser',
    fullAccessor: 'actingUser.name',
    parentAccessor: '',
    shortName: 'ACTING_USER_ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  organisation: {
    name: 'Organisation',
    displayName: 'Organisation',
    accessor: 'organisation.shortName',
    fullAccessor: 'organisation.shortName',
    parentAccessor: '',
    shortName: 'ORG',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: true,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  organisationShortName: {
    name: 'Organisation',
    displayName: 'Organisation',
    accessor: 'organisation.shortName',
    fullAccessor: 'organisation.shortName',
    parentAccessor: '',
    shortName: 'ORG',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  timeOfEntry: {
    name: 'TimeOfEntry',
    displayName: 'Time Of Entry',
    accessor: 'timeOfEntry',
    fullAccessor: 'timeOfEntry',
    parentAccessor: '',
    shortName: 'ENTRY',
    type: 'date-time',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'date-time',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'date-time',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'date-time',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'date-time',
    },
  },
  timeOfUpdate: {
    name: 'TimeOfUpdate',
    displayName: 'Time Of Update',
    accessor: 'timeOfUpdate',
    fullAccessor: 'timeOfUpdate',
    parentAccessor: '',
    shortName: 'UPDATE',
    type: 'date-time',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'date-time',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'date-time',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'date-time',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'date-time',
    },
  },
  id: {
    name: 'Id',
    displayName: 'Id',
    accessor: 'id',
    fullAccessor: 'id',
    parentAccessor: '',
    shortName: 'ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },

  ownUser: {
    name: 'ownUser',
    displayName: 'Own User',
    accessor: 'ownUser',
    fullAccessor: 'ownUser',
    parentAccessor: '',
    shortName: 'OWN USER',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  ownCompany: {
    name: 'ownCompany',
    displayName: 'Own Co.',
    accessor: 'ownCompany',
    fullAccessor: 'ownCompany',
    parentAccessor: '',
    shortName: 'OWN COMPANY',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  ownComments: {
    name: 'ownComments',
    displayName: 'Own Commnets.',
    accessor: 'ownComments',
    fullAccessor: 'ownComments',
    parentAccessor: '',
    shortName: 'OWN COMMMENTS',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  counterpartyUser: {
    name: 'counterpartyUser',
    displayName: 'Cpty User',
    accessor: 'counterpartyUser',
    fullAccessor: 'counterpartyUser',
    parentAccessor: '',
    shortName: 'CPTY USER',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  counterpartyCompany: {
    name: 'counterpartyCompany',
    displayName: 'Cpty Co.',
    accessor: 'counterpartyCompany',
    fullAccessor: 'counterpartyCompany',
    parentAccessor: '',
    shortName: 'CPTY CO',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },

  counterpartyComments: {
    name: 'counterpartyComments',
    displayName: 'Cpty Comments',
    accessor: 'counterpartyComments',
    fullAccessor: 'counterpartyComments',
    parentAccessor: '',
    shortName: 'CPTY COMMENTS',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  rfqComments: {
    name: 'rfqComments',
    displayName: 'RfqComments',
    accessor: 'rfqComments',
    fullAccessor: 'rfqComments',
    parentAccessor: '',
    shortName: 'RFQ COMMENTS',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  textMessage: {
    name: 'textMessage',
    displayName: 'Comments',
    accessor: 'textMessage',
    fullAccessor: 'textMessage',
    parentAccessor: '',
    shortName: 'COMMENTS',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  rfqType: {
    name: 'rfqType',
    displayName: 'Rfq Type',
    accessor: 'rfqType',
    fullAccessor: 'rfqType',
    parentAccessor: '',
    shortName: 'RFQ TYPE',
    type: 'string',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: false },
    orderBlotter: {
      visible: true,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  bidId: {
    name: 'bidId',
    displayName: 'Bid Id',
    accessor: 'bidId',
    fullAccessor: 'bidId',
    parentAccessor: '',
    shortName: 'BID ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  offerId: {
    name: 'offerId',
    displayName: 'Offer Id',
    accessor: 'offerId',
    fullAccessor: 'offerId',
    parentAccessor: '',
    shortName: 'OFFER ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  bidOrderGroupId: {
    name: 'bidOrderGroupId',
    displayName: 'Bid OrderGroup Id',
    accessor: 'bidOrderGroupId',
    fullAccessor: 'bidOrderGroupId',
    parentAccessor: '',
    shortName: 'BID GRP ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },
  offerOrderGroupId: {
    name: 'offerOrderGroupId',
    displayName: 'Offer OrderGroup Id',
    accessor: 'offeOrderGroupId',
    fullAccessor: 'offerOrderGroupId',
    parentAccessor: '',
    shortName: 'OFFER GRP ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: false },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: false,
      outputType: 'string',
    },
  },

  tradeId: {
    name: 'tradeId',
    displayName: 'Trade Id',
    accessor: 'tradeId',
    fullAccessor: 'tradeId',
    parentAccessor: '',
    shortName: 'TRADE ID',
    type: 'text',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'string',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'string',
    },
  },
  timeStamp: {
    name: 'timeStamp',
    displayName: 'Timestamp',
    accessor: 'timeStamp',
    fullAccessor: 'timeStamp',
    parentAccessor: '',
    shortName: 'TIMESTAMP',
    type: 'date-time',
    values: [],
    default: '',
    newOrderForm: { visible: true },

    rfqForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    orderForm: {
      visible: false,
      section: null,
      component: null,
      outputType: 'string',
      output: false,
    },
    rfqResponseForm: { visible: true },
    orderBlotter: {
      visible: false,
      outputType: 'date-time',
    },
    tradeBlotter: {
      visible: true,
      outputType: 'date-time',
    },
  },
};
