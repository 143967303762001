import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, IconButton, Card } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { widgetTypes } from '../../modules/workspaces/config/widgets';

import Chat from '../../modules/chat';

//import Blotters from '../Blotters';

import { getActiveWorkspace } from '../../commons/selectors';

import workspaces from '../../modules/workspaces';

import orders from '../../modules/orders';
import rfqs from '../../modules/rfqs';

import fxrates from '../../modules/fxrates';
import charts from '../../modules/charts';
import admin from '../../modules/admin';
import fileUpload from '../../modules/fileUpload';
import blotters from '../../modules/blotters';
import trades from '../../modules/trades';

import statistics from '../../modules/statistics';
import schemas from '../../modules/schemas';
const ReactGridLayout = require('react-grid-layout');

const styles = (theme) => ({
  gridItem: {
    backgroundColor: theme.palette.background.widget || 'blue',
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
    padding: '0 10px',
  },
  componentContainer: {
    height: 'calc(100% - 58px)',
    display: 'flex',
  },
  banner: {
    height: '48px',
    minHeight: '48px',
    color: 'white',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  bannerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  title: {
    color:
      theme.palette.type === 'light' ? theme.palette.text.faded_75 : theme.palette.text.faded_75,
    fontSize: theme.fontSize.md,
  },
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.text.faded,
    },

    marginRight: '2px',
    marginLeft: '25px',
    width: '200px',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    width: '20px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 5px',
  },
  input: {
    width: '100%',
    marginLeft: '30px',
    color: theme.palette.text.disabled,
  },
  closeButton: {
    color: theme.palette.text.disabled,
    fontSize: '20px',
    padding: '3px',
    border: '1px solid',
    borderRadius: '10px',
  },
});

class GridLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateWidget = (item) => {
    const payload = { item };
    setTimeout(() => {
      this.props.updateWidget(payload);
    }, 0);
  };

  removeLayoutWidget = (item) => {
    const payload = { item };
    setTimeout(() => {
      this.props.removeLayoutWidget(payload);
    }, 0);
  };

  getWidgetTitle = (widget) => {
    switch (widget.type) {
      case widgetTypes.PRIVATEORDERS:
      case widgetTypes.PUBLICORDERS:
      case widgetTypes.PRIVATETRADES:
      case widgetTypes.PUBLICTRADES:
        return 'ALL BLOTTERS';
      default:
        const { allFieldValues } = this.props;
        const title = allFieldValues[widget.name]
          ? allFieldValues[widget.name].displayName.toUpperCase()
          : widget.title;
        return title;
    }
  };

  onLayoutChange = (layouts) => {
    const payload = { layouts };

    this.props.onUpdateWorkspace(payload);

    // setTimeout(() => {
    //   this.props.onUpdateWorkspace(payload);
    // }, 0);
  };

  generateDOM = () => {
    const { activeWorkspace: workspace, allFieldValues } = this.props;

    if (workspace) {
      const { layouts, widgets } = workspace;

      const { classes, theme, themeType } = this.props;

      return layouts.map((item) => {
        const widget = widgets.find((w) => w.i === item.i);

        if (widget) {
          return (
            <Card className={classes.gridItem} key={item.i} data-grid={item}>
              <Toolbar className={classes.banner}>
                <Typography className={classes.title}>{this.getWidgetTitle(widget)}</Typography>
                <div className={classes.bannerRight}>
                  <div style={{ display: 'flex' }}>
                    <IconButton aria-haspopup="true" onClick={() => this.removeLayoutWidget(item)}>
                      <Close className={classes.closeButton} />
                    </IconButton>
                  </div>
                </div>
              </Toolbar>

              <div className={classes.componentContainer}>
                {widget.type === widgetTypes.BLOTTERS ? (
                  <blotters.components.Blotters
                    widget={widget}
                    saveWidgetState={this.updateWidget}
                    onTradeClick={this.props.onTradeClick}
                  />
                ) : null}

                {widget.type === widgetTypes.ORDERMANAGER ? (
                  <orders.components.Manager
                    widget={widget}
                    theme={theme}
                    saveWidgetState={this.updateWidget}
                  />
                ) : null}

                {/*   {widget.type === widgetTypes.INVENTORY ? (
                  <inventory.components.Inventory
                    widget={widget}
                    themeType={themeType}
                    theme={theme}
                  />
                ) : null} */}
                {widget.type === widgetTypes.STATISTICS ? (
                  <statistics.components.Statistics
                    widget={widget}
                    themeType={themeType}
                    theme={theme}
                  />
                ) : null}

                {/*  {widget.type === widgetTypes.CALCULATOR ? (
                  <calculators.components.Calculator
                    widget={widget}
                    saveWidgetState={this.updateWidget}
                  />
                ) : null} */}

                {widget.type === widgetTypes.FXRATES ? (
                  <fxrates.components.FxRates widget={widget} themeType={themeType} theme={theme} />
                ) : null}

                {/*     {widget.type === widgetTypes.LFEXPRICES ? (
                  <charts.components.LfexPrices
                    widget={widget}
                    themeType={themeType}
                    theme={theme}
                  />
                ) : null} */}

                {widget.type === widgetTypes.CHART ? (
                  <charts.components.Chart widget={widget} theme={theme} />
                ) : null}

                {widget.type === widgetTypes.RFQ ? (
                  <rfqs.components.Rfq
                    widget={widget}
                    saveWidgetState={this.updateWidget}
                    allFieldValues={allFieldValues}
                  />
                ) : null}
                {widget.type === widgetTypes.FILE_UPLOAD ? (
                  <fileUpload.components.FileManager
                    widget={widget}
                    saveWidgetState={this.updateWidget}
                  />
                ) : null}
                {widget.type === widgetTypes.CHAT ? <Chat.components.Chat /> : null}
                {widget.type === widgetTypes.ADMIN ? (
                  <admin.components.Admin widget={widget} />
                ) : null}
                {widget.type === widgetTypes.TRADE_LIST ? (
                  <trades.components.TradeList widget={widget} />
                ) : null}
              </div>
            </Card>
          );
        }

        return [];
      });
    }

    return [];
  };

  render() {
    return (
      <ReactGridLayout
        {...this.props}
        onLayoutChange={this.onLayoutChange}
        width={5000}
        draggableCancel=".undraggable"
        measureBeforeMount={false}
        useCSSTransforms={this.props.mounted}
        compactType={this.props.compactType}
        preventCollision={false}
      >
        {this.generateDOM()}
      </ReactGridLayout>
    );
  }
}

GridLayout.defaultProps = {
  cols: 120,
  rowHeight: 30,
  compactType: 'vertical',
  mounted: false,
};

GridLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeWorkspace: getActiveWorkspace(state),
    allWorkspaces: workspaces.selectors.getAll(state),
    allFieldValues: schemas.selectors.getAllFieldValues(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateWorkspace: (payload) => {
      dispatch(workspaces.actions.updateWorkspace(workspaces.constants.UPDATE_WORKSPACE, payload));
    },
    removeLayoutWidget: (payload) => {
      dispatch(
        workspaces.actions.removeLayoutWidget(workspaces.constants.REMOVE_LAYOUT_WIDGET, payload),
      );
    },
    updateWidget: (payload) => {
      dispatch(workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GridLayout));

// const debouncedGridLayout = debounceRender(withStyles(styles)(GridLayout), 100, { leading: true });

// export default connect(mapStateToProps, mapDispatchToProps)(debouncedGridLayout);
