const NAME = 'blotters';

const INIT_BLOTTER = 'blotter/init';
const INIT_BLOTTER_SUCCESS = 'blotter/init/success';
const INIT_BLOTTER_ERROR = 'blotter/init/error';

const DESTROY_BLOTTER = 'blotter/destroy';
const DESTROY_BLOTTER_SUCCESS = 'blotter/destroy/success';
const DESTROY_BLOTTER_ERROR = 'blotter/destroy/error';

const UPDATE_BLOTTER = 'blotter/update';
const UPDATE_BLOTTER_SUCCESS = 'blotter/update/success';
const UPDATE_BLOTTER_ERROR = 'blotter/update/error';

const UPDATE_BLOTTER_PRESETS = 'blotter/presets/update';
const UPDATE_BLOTTER_PRESETS_SUCCESS = 'blotter/presets/update/success';
const UPDATE_BLOTTER_PRESETS_ERROR = 'blotter/presets/update/error';

export default {
  NAME,
  INIT_BLOTTER,
  INIT_BLOTTER_SUCCESS,
  INIT_BLOTTER_ERROR,
  DESTROY_BLOTTER,
  DESTROY_BLOTTER_SUCCESS,
  DESTROY_BLOTTER_ERROR,
  UPDATE_BLOTTER,
  UPDATE_BLOTTER_SUCCESS,
  UPDATE_BLOTTER_ERROR,
  UPDATE_BLOTTER_PRESETS,
  UPDATE_BLOTTER_PRESETS_SUCCESS,
  UPDATE_BLOTTER_PRESETS_ERROR,
};
