import { getFieldsArray } from '../../contracts/utils/modelFunctions';
import {
  formatPrice,
  formatDateTime,
  formatDate,
  formatList,
} from '../../../commons/config/formatters';

const dateFilter = function (filterLocalDateAtMidnight, cellValue) {
  const dateTime = new Date(
    new Date(cellValue).getFullYear(),
    new Date(cellValue).getMonth(),
    new Date(cellValue).getDate(),
  );

  if (cellValue == null) return 0;

  if (dateTime < filterLocalDateAtMidnight) {
    return -1;
  }
  if (dateTime > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

const customComparator = (filter, value, filterValue, allFieldValues) => {
  const keys = Object.keys(allFieldValues);
  const index = keys.findIndex((key) => key.toLowerCase() === value);
  if (index > -1) {
    const formattedValue = allFieldValues[keys[index]].displayName.toUpperCase();
    return formattedValue.includes(filterValue.toUpperCase());
  } else {
    return value.toUpperCase().includes(filterValue.toUpperCase());
  }
};

export const getColumns = (allFieldValues) => {
  const fields = getFieldsArray('trade', [`blotter`]);
  const columns = [];

  const floatingFilter = true;

  fields.forEach((field) => {
    const obj = {};
    obj.headerName = allFieldValues[field.accessor]
      ? allFieldValues[field.accessor].displayName.toUpperCase()
      : field.accessor;
    obj.field = field.fullAccessor;
    obj.colId = field.fullAccessor;
    obj.width = 150;
    obj.resizable = true;
    obj.sortable = true;
    obj.tooltipField = field.fullAccessor;
    obj.enableCellChangeFlash = true;
    obj.floatingFilter = floatingFilter;
    obj.filter =
      field.type === 'date' || field.type === 'dateTime'
        ? 'agDateColumnFilter'
        : 'agTextColumnFilter';

    if (field.type === 'date' || field.type === 'dateTime') {
      obj.filterParams = {
        comparator: dateFilter,
        apply: false,
        newRowsAction: 'keep',
      };
    } else {
      obj.filterParams = {
        apply: false,
        newRowsAction: 'keep',
        textCustomComparator: (filter, value, filterValue) =>
          customComparator(filter, value, filterValue, allFieldValues),
      };
    }

    if (field.type === 'date') {
      obj.valueFormatter = (params) => {
        return formatDate(params.value);
      };
    } else if (field.type === 'dateTime') {
      if (field.accessor === 'createdTime') {
        obj.sort = { direction: 'desc', priority: 0 };
      }
      obj.valueFormatter = (params) => {
        return params.value !== undefined ? formatDateTime(params.value) : '';
      };
    } else if (field.accessor === 'price') {
      obj.valueFormatter = (params) => {
        return formatPrice(params.value);
      };
    } else if (field.accessor === 'recipients') {
      obj.valueFormatter = (params) => {
        const list = [];
        params.forEach((p) => list.push(p.shortName));
        return formatList(list);
      };
    } else {
      obj.valueFormatter = (params) => {
        let displayValue = allFieldValues[params.value]
          ? allFieldValues[params.value].displayName
            ? allFieldValues[params.value].displayName.toUpperCase()
            : params.value
          : params.value;
        return displayValue;
      };
    }

    columns.push(obj);
  });
  return columns;
};

export const updateColumns = (columns, allFieldValues) => {
  if (!columns || columns.length === 0) return [];
  const fields = getFieldsArray('trade', [`blotter`]);
  fields.forEach((field) => {
    const i = columns.findIndex((c) => c.field === field.fullAccessor);

    if (i > -1) {
      columns[i].headerName = allFieldValues[field.accessor]
        ? allFieldValues[field.accessor].displayName.toUpperCase()
        : field.accessor;

      if (field.type !== 'date' && field.type !== 'dateTime') {
        columns[i].filterParams = {
          apply: false,
          newRowsAction: 'keep',
          textCustomComparator: (filter, value, filterValue) =>
            customComparator(filter, value, filterValue, allFieldValues),
        };
      }
    }

    if (
      field.type !== 'date' &&
      field.type !== 'dateTime' &&
      field.accessor !== 'price' &&
      field.accesor !== 'volume' &&
      field.accessor !== 'recipients'
    ) {
      columns[i].valueFormatter = (params) => {
        let displayValue = allFieldValues[params.value]
          ? allFieldValues[params.value].displayName
            ? allFieldValues[params.value].displayName.toUpperCase()
            : params.value
          : params.value;
        return displayValue;
      };
    }
  });
  return columns;
};
