import grey from '@material-ui/core/colors/grey';

export const getColor = (initial) => {
  switch (initial) {
    case 'A':
      return { color: '#E57373', image: 'none', initial };
    case 'B':
      return { color: '#D32F2F', image: 'none', initial };
    case 'C':
      return { color: '#EC407A', image: 'none', initial };
    case 'D':
      return { color: '#AB47BC', image: 'none', initial };
    case 'E':
      return { color: '#673AB7', image: 'none', initial };
    case 'F':
      return { color: '#5C6BC0', image: 'none', initial };
    case 'G':
      return { color: '#EF5350', image: 'none', initial };
    case 'H':
      return { color: '#3949AB', image: 'none', initial };
    case 'I':
      return { color: '#64B5F6', image: 'none', initial };
    case 'J':
      return { color: '#1E88E5', image: 'none', initial };
    case 'K':
      return { color: '#29B6F6', image: 'none', initial };
    case 'L':
      return { color: '#0288D1', image: 'none', initial };
    case 'M':
      return { color: '#00ACC1', image: 'none', initial };
    case 'N':
      return { color: '#4DD0E1', image: 'none', initial };
    case 'O':
      return { color: '#4DB6AC', image: 'none', initial };
    case 'P':
      return { color: '#00796B', image: 'none', initial };
    case 'Q':
      return { color: '#4CAF50', image: 'none', initial };
    case 'R':
      return { color: '#FFF176', image: 'none', initial };
    case 'S':
      return { color: '#FBC02D', image: 'none', initial };
    case 'T':
      return { color: '#FFD54F', image: 'none', initial };
    case 'U':
      return { color: '#FFA000', image: 'none', initial };
    case 'V':
      return { color: '#FFB74D', image: 'none', initial };
    case 'W':
      return { color: '#FF5722', image: 'none', initial };
    case 'X':
      return { color: '#795548', image: 'none', initial };
    case 'Y':
      return { color: '#90A4AE', image: 'none', initial };
    case 'Z':
      return { color: '#455A64', image: 'none', initial };

    default:
      return { color: grey, image: 'none', initial };
  }
};
