import { createAction } from '../../../commons/actions';

export const checkAuth = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const signIn = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const signOut = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateData = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateSettings = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
