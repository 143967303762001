import moment from 'moment';
import { createSelector } from 'reselect';
import { NAME } from '../constants';
import { getServerUser } from '../../auth/selectors';
import { getAllObject, getOrgConnectionState, getMappedUsers } from '../../users/selectors';
import {
  getAll as getAllOrganisations,
  getAllObject as getAllOrganisationsObject,
  getMyOrganisation,
} from '../../organisations/selectors';

const getSelectedChatIdProp = (state) => state[NAME].selectedChatId;

const emptyChat = {};
const getSelectedChatProp = (state, props) => {
  return state[NAME].chats.find((element) => element.id === props.selectedChatId) || emptyChat;
};
const getActiveTabProp = (state) => state[NAME].activeTab;
const getChatsProp = (state) => {
  return state[NAME].chats;
};
const getNewMessagesCountProp = (state) => {
  return state[NAME].chats.reduce(
    (acc, item) => {
      switch (item.type) {
        case 'USER':
          acc.contacts += item.newMessages;
          acc.recent += item.newMessages;
          break;
        case 'ORGANISATION':
        case 'GLOBAL':
          acc.groups += item.newMessages;
          acc.recent += item.newMessages;
          break;
        case 'RFQ':
          acc.rfq += item.newMessages;
          break;
        default:
      }
      acc.all += item.newMessages;
      return acc;
    },
    {
      contacts: 0,
      groups: 0,
      recent: 0,
      rfq: 0,
      all: 0,
    },
  );
};

export const getSelectedChatId = createSelector(getSelectedChatIdProp, (id) => id);
export const getSelectedChat = createSelector(getSelectedChatProp, (chat) => chat);
export const getActiveTab = createSelector(getActiveTabProp, (activeTab) => activeTab);
export const getChats = createSelector(getChatsProp, (activeChats) => activeChats);
export const getMessages = createSelector(getSelectedChatProp, (chat) => chat.messages || []);
export const getAllMessages = createSelector(getChatsProp, (chats) =>
  chats.reduce(
    (acc, chat) => [...acc, ...chat.messages.map((item) => ({ ...item, type: chat.type }))],
    [],
  ),
);
export const getNewMessagesCount = createSelector(getNewMessagesCountProp, (count) => count);
export const getNewMessages = createSelector(getChatsProp, getServerUser, (chats, user) => {
  const { id: userId } = user;

  const messages = [];

  chats.forEach((chat) => {
    chat.messages.forEach((message) => {
      if (
        message.createdBy !== userId &&
        (chat.lastReadMessageTime === null ||
          moment(message.createdTime).valueOf() > moment(chat.lastReadMessageTime).valueOf())
      ) {
        messages.push({ ...message, type: chat.type, chatId: chat.id });
      }
    });
  });
  return messages;
});

const findNewestMessage = (messages) => {
  return messages.reduce((acc, item) => {
    const time = moment(item.createdTime).format('x');
    if (time > acc) {
      return time;
    }
    return acc;
  }, 0);
};

// TODO: REFACTOR!
export const getMappedChats = createSelector(
  [getChats, getServerUser, getAllObject, getOrgConnectionState, getAllOrganisationsObject],
  (chats, user, users, orgState, organisations) => {
    const { id: userId, organisationId } = user;
    return chats
      .map((item) => {
        const mapped = {
          ...item,
          avatar: item.name,
        };
        if (item.type === 'USER') {
          mapped.userId = item.userIds.find((id) => id !== userId);
          const chatUser = users[mapped.userId];
          mapped.loggedIn = chatUser ? chatUser.connectionState === 'online' : false;
        }
        if (item.type === 'ORGANISATION') {
          const orgId = item.organisationIds.find((id) => id !== organisationId) || organisationId;
          const orgObject = organisations[orgId];
          if (orgObject !== null && orgObject !== undefined) {
            mapped.name = orgObject.name;
            mapped.shortName = orgObject.shortName;
          }
          mapped.organisationId = orgId;
          mapped.loggedIn = orgState[mapped.organisationId];
        }
        if (item.type === 'GLOBAL') {
          mapped.loggedIn = true;
        }
        return mapped;
      })
      .sort((a, b) => {
        return findNewestMessage(b.messages) - findNewestMessage(a.messages);
      });
  },
);

// TODO: REFACTOR!
export const getMappedFilteredChats = createSelector(getMappedChats, (chats) =>
  chats.filter(({ type }) => type !== 'RFQ').filter((chat) => chat.messages.length > 0),
);

// TODO: REFACTOR!
export const getChatGroups = createSelector(
  [getAllOrganisations, getMappedChats, getOrgConnectionState, getServerUser, getMyOrganisation],
  (orgs, chats, orgConnectionState, user, ownOrg) => {
    const allowTypes = ['ORGANISATION'];

    const global = chats.find((item) => item.type === 'GLOBAL');

    const filteredOrgs = orgs.filter(({ id }) => {
      const tradingPartnersIds = ownOrg.tradingPartnersIds || [];
      return id === ownOrg.id || tradingPartnersIds.includes(id);
    });

    const foundedChats = {};

    const mappedChats = filteredOrgs.map((item) => {
      // const matchedChat = chats.find(
      //   (chat) =>
      //     chat.organisationIds.includes(ownOrg.id) &&
      //     (chat.organisationIds.length === 1 ||
      //       (chat.organisationIds.includes(item.id) && item.id !== ownOrg.id)) &&
      //     allowTypes.includes(chat.type),
      // );
      const matchedChat = chats.find(
        (chat) =>
          !foundedChats[chat.id] &&
          allowTypes.includes(chat.type) &&
          ((item.id !== ownOrg.id &&
            chat.organisationIds.includes(ownOrg.id) &&
            chat.organisationIds.includes(item.id)) ||
            (item.id === ownOrg.id &&
              chat.organisationIds.includes(item.id) &&
              chat.organisationIds.length === 1)),
      );

      if (matchedChat) {
        foundedChats[matchedChat.id] = true;
        return matchedChat;
        // return {
        //   id: matchedChat.id,
        //   loggedIn: orgConnectionState[item.id],
        //   newMessages: matchedChat.newMessages,
        //   organisationId: item.id,
        //   shortName: item.shortName,
        //   name: item.name,
        //   type: 'organisation',
        // };
      }

      return {
        id: item.id,
        loggedIn: orgConnectionState[item.id],
        newMessages: 0,
        organisationId: item.id,
        shortName: item.shortName,
        name: item.name,
        type: 'organisation',
      };
    });
    const sortedGroups = [...mappedChats].sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    const groups = [global, ...sortedGroups].filter((element) => !!element);

    return groups;
  },
);

// TODO: REFACTOR!
export const getChatContacts = createSelector(
  [getMappedUsers, getMappedChats, getServerUser, getAllOrganisations, getMyOrganisation],
  (users, chats, user, organisations, ownOrg) => {
    const allowTypes = ['USER'];

    if (!ownOrg) return [];
    const tradingPartnersIds = ownOrg.tradingPartnersIds || [];
    const filteredUsers = users
      .filter(
        ({ organisationId }) =>
          organisationId === ownOrg.id || tradingPartnersIds.includes(organisationId),
      )
      .filter((item) => item.id !== user.id);

    const mappedUsers = filteredUsers.map((item) => {
      const matchedChat = chats.find(
        (chat) =>
          chat.userIds.includes(user.id) &&
          chat.userIds.includes(item.id) &&
          item.id !== user.id &&
          allowTypes.includes(chat.type),
      );

      if (matchedChat) {
        return matchedChat;
        // return {
        //   id: matchedChat.id,
        //   loggedIn: item.loggedIn,
        //   newMessages: matchedChat.newMessages,
        //   userId: item.id,
        //   name: item.name,
        //   type: item.type,
        //   firstName: item.firstName,
        //   lastName: item.lastName,
        // };
      }

      return {
        id: item.id,
        loggedIn: item.loggedIn,
        newMessages: 0,
        userId: item.id,
        name: item.name,
        type: item.type,
        firstName: item.firstName,
        lastName: item.lastName,
      };
    });

    const contacts = mappedUsers.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return contacts;
  },
);

export const getActiveUser = createSelector(getServerUser, (user) => user);

const getLastCreatedTimeChatProp = (state) => state[NAME].lastCreatedTimeChat;
export const getLastCreatedTimeChat = createSelector(
  getLastCreatedTimeChatProp,
  (lastCreatedTimeChat) => lastCreatedTimeChat,
);

const getLastCreatedTimeMessageProp = (state) => state[NAME].lastCreatedTimeMessage;
export const getLastCreatedTimeMessage = createSelector(
  getLastCreatedTimeMessageProp,
  (lastCreatedTimeMessage) => lastCreatedTimeMessage,
);

const isModalChatOpenProp = (state) => state[NAME].modalChat.open;
export const isModalChatOpen = createSelector(isModalChatOpenProp, (item) => item);

const modalChatTradeProp = (state) => state[NAME].modalChat.trade;
export const modalChatTrade = createSelector(modalChatTradeProp, (item) => item);
