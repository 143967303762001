import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';
import CustomText from '../../../commons/components/formFields/customText';
import Tab from '../../rfqs/components/Tab';
import { UPDATE_END_RFQ_PROMPT } from '../constants';
import { updateEndRfqPrompt } from '../actions';
import { isTradeDetailsOpen, isEndRfqPromptOpen, getEndRfqPromptRfqId } from '../selectors';

import rfqsModule from '../../rfqs';
import schemas from '../../schemas';
const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
});

// TODO:Refactor
class EndRfqPrompt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  endRFQ = (rfq) => {
    const { id: userId } = this.props.user;

    if (rfq.status !== rfqsModule.constants.RFQ_STATUS_ENDED) {
      const payload = {
        userId,
        status: rfqsModule.constants.RFQ_STATUS_ENDED,
        rfqs: [rfq],
      };

      this.props.endRFQ(payload);
      this.props.onClose();
    }
  };
  continueRFQ = () => {
    this.props.onClose();
  };

  activeRfq = () => {
    let foundRfq = null;
    const { rfqs, rfqId } = this.props;

    if (rfqId !== null) {
      foundRfq = rfqs[rfqId] || null;
    }

    return foundRfq;
  };

  render() {
    const { classes, contacts, user, tradeDetailsOpen, open } = this.props;
    const activeRfq = this.activeRfq();
    let isMyRfq = false;

    if (activeRfq !== null) {
      isMyRfq = activeRfq.createdByUserId === user.id;
    }

    const isOpen = open === true && tradeDetailsOpen === false && isMyRfq === true;

    return (
      <PopUp open={isOpen} title={<CustomText value="endThisRfq" />} onClose={this.props.onClose}>
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <div style={{ padding: '16px' }}>
              <Tab data={activeRfq} contacts={contacts} showDetails />

              <Typography align="center">
                <CustomText value="yourRfqHasGeneratedATrade" />
              </Typography>
              <Typography align="center" variant="h5">
                <CustomText value="doYouWishToEndTheRfq" />
              </Typography>
            </div>
          </div>

          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton title={'continueRfq'} onClick={() => this.continueRFQ()} />
                <ConfirmButton title={'endThisRfq'} onClick={() => this.endRFQ(activeRfq)} />
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
EndRfqPrompt.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: rfqsModule.selectors.getActiveUser(state),
    //contacts: rfqsModule.selectors.getOrganisations(state),
    rfqId: getEndRfqPromptRfqId(state),
    rfqs: rfqsModule.selectors.getAllObject(state),
    tradeDetailsOpen: isTradeDetailsOpen(state),
    open: isEndRfqPromptOpen(state),
    allFieldValues: schemas.selectors.getAllFieldValues(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    endRFQ: (payload) => {
      dispatch(rfqsModule.actions.updateRFQs(rfqsModule.constants.UPDATE_RFQS, payload));
    },
    onClose: (payload) => {
      dispatch(updateEndRfqPrompt(UPDATE_END_RFQ_PROMPT, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EndRfqPrompt));
