export const NAME = 'charts';

export const INIT_CHART = 'chart/init';
export const INIT_CHART_SUCCESS = 'chart/init/success';
export const INIT_CHART_ERROR = 'chart/init/error';

export const DESTROY_CHART = 'chart/destroy';
export const DESTROY_CHART_SUCCESS = 'chart/destroy/success';
export const DESTROY_CHART_ERROR = 'chart/destroy/error';

export const UPDATE_CHART = 'chart/update';
export const UPDATE_CHART_SUCCESS = 'chart/update/success';
export const UPDATE_CHART_ERROR = 'chart/update/error';

export const INIT_TABLE = 'table/init';
export const INIT_TABLE_SUCCESS = 'table/init/success';
export const INIT_TABLE_ERROR = 'table/init/error';

export const DESTROY_TABLE = 'table/destroy';
export const DESTROY_TABLE_SUCCESS = 'table/destroy/success';
export const DESTROY_TABLE_ERROR = 'table/destroy/error';

export const UPDATE_TABLE = 'table/update';
export const UPDATE_TABLE_SUCCESS = 'table/update/success';
export const UPDATE_TABLE_ERROR = 'table/update/error';

export const CURRENCY_NOK = 'NOK';
