import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import { directions, status, PRICE_DECIMALS } from '../../../commons/models/constants';
import { getCartonNetWeightEnums } from '../../contracts/utils/modelFunctions';
import { getMyOrganisation } from '../selectors';
import CustomText from '../../../commons/components/formFields/customText';
import { formatDateWithDay } from '../../../commons/config/formatters';

const styles = (theme) => ({
  totalsContainerBuy: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: lightBlue[300],
    //padding: theme.spacing(2),
    backgroundColor: lightBlue[500],
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  totalsContainerSell: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: teal[300],
    //padding: theme.spacing(2),
    backgroundColor: teal[500],
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },

  totalsContainerCancelled: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: red[300],
    //padding: theme.spacing(2),
    backgroundColor: red[500],
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },

  totalsContainerSuspended: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: amber[300],
    //padding: theme.spacing(2),
    backgroundColor: amber[500],
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  totalsContainerFilled: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    //border: '1px solid',
    //borderColor: theme.palette.divider,
    //padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },

  title: {
    color: theme.palette.text.faded_75_white,
    textAlign: 'center',
  },

  volumeNominal: {
    color: theme.palette.common.white,
    fontSize: theme.fontSize.md,
    textAlign: 'right',
  },
  units: {
    color: theme.palette.text.faded_75_white,
    fontSize: theme.fontSize.sm,
    paddingLeft: theme.spacing(),
  },
  titleFilled: {
    color: theme.palette.text.faded_75,
    textAlign: 'center',
  },

  volumeNominalFilled: {
    color: theme.palette.text.primary,
    fontSize: theme.fontSize.md,
    textAlign: 'right',
  },
  unitsFilled: {
    color: theme.palette.text.faded_75,
    fontSize: theme.fontSize.sm,
    paddingLeft: theme.spacing(),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const getTotals = (obj, orgId) => {
  let totals = {
    volume: 0,
    averagePrice: 0,
    nominal: 0,
    packingWeight: 0,
    direction: directions.BUY,
    unit: 'cartons',
    currency: 'AUD',
    status: status.ACTIVE,

    counterpartyId:
      obj.buyBrokerOrganisationId === orgId || obj.buyBeneficiaryOrganisationId === orgId
        ? obj.sellBrokerOrganisationId
        : obj.buyBrokerOrganisationId,
  };
  if (obj.assets) {
    if (obj.assets.length > 0) {
      const { cartonNetWeight, species, location, currency } = obj.assets[0].contract.underlying;
      const cartonNetWeightValue =
        getCartonNetWeightEnums({ species, location })[cartonNetWeight] || 1;

      totals.direction =
        obj.buyOrganisationId === orgId || obj.buyBeneficiaryOrganisationId === orgId
          ? directions.BUY
          : directions.SELL;
      totals.status = obj.status;
      totals.currency = currency;

      obj.assets.forEach((asset) => {
        const price = Number(asset.price);
        const volume = Number(asset.volume) || 0;
        const packingWeight = asset.contract.packingWeight
          ? Number(asset.contract.packingWeight)
          : volume * cartonNetWeightValue.numericalValue;

        totals.packingWeight = totals.packingWeight + packingWeight;
        totals.volume = totals.volume + volume;

        const nominal = packingWeight
          ? packingWeight * price
          : volume * price * cartonNetWeightValue.numericalValue;

        totals.nominal = totals.nominal + nominal;
      });
      totals.averagePrice = totals.packingWeight !== 0 ? totals.nominal / totals.packingWeight : 0;
    }
  }
  return totals;
};

const TotalsItem = (props) => {
  let titleClass = props.classes.title;
  let volumeClass = props.classes.volumeNominal;
  let unitsClass = props.classes.units;

  if (props.status === status.FILLED || props.noColor) {
    titleClass = props.classes.titleFilled;
    volumeClass = props.classes.volumeNominalFilled;
    unitsClass = props.classes.unitsFilled;
  }

  return (
    <div className={props.classes.gridItem}>
      <div className={props.classes.itemsContainer}>
        <div className={props.classes.priceRow}>
          <span className={volumeClass}>{props.value1}</span>
          <span className={unitsClass}>
            <CustomText value={props.unit1 || ''} upperCase />
          </span>
        </div>
        <div className={props.classes.priceRow}>
          <span className={volumeClass}>{props.value2}</span>
          <span className={unitsClass}>
            <CustomText value={props.unit2 || ''} upperCase />
          </span>
        </div>
      </div>
    </div>
  );
};

const TotalsRow = (props) => {
  const { classes } = props;
  const myOrganisation = useSelector(getMyOrganisation);
  const organisationsObject = useSelector((state) => state.organisations.byId);
  const total = getTotals(props.orderTradeObject, myOrganisation.id);
  const counterparty = total.counterpartyId ? organisationsObject[total.counterpartyId] || {} : {};

  const direction = total.direction;
  let containerStyle =
    direction === directions.BUY ? classes.totalsContainerBuy : classes.totalsContainerSell;
  if (total.status === status.SUSPENDED || total.status === status.IN_PROGRESS) {
    containerStyle = classes.totalsContainerSuspended;
  }
  if (total.status === status.CANCELLED) {
    containerStyle = classes.totalsContainerCancelled;
  }
  if (total.status === status.FILLED || props.noColor) {
    containerStyle = classes.totalsContainerFilled;
  }

  const deliveryPoint = (function (deliveryPoint) {
    if (deliveryPoint === 'ANY') {
      return ' ';
    } else {
      const strings = deliveryPoint.split('_');
      return strings[strings.length - 1];
    }
  })(props.orderTradeObject.assets[0].contract.underlying.deliveryPoint);
  const deliveryDate = props.orderTradeObject
    ? props.orderTradeObject.assets[0].contract.deliveryDate
    : null;
  const formattedDate = deliveryDate ? formatDateWithDay(deliveryDate) : '';
  return (
    <div className={containerStyle}>
      <TotalsItem
        value1={counterparty.name}
        unit1={''}
        value2={counterparty.shortName}
        unit2={''}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
      <TotalsItem
        value1={total.volume}
        unit1={'cartons'}
        value2={total.nominal.toFixed(0)}
        unit2={total.currency}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
      <TotalsItem
        title={'nominal'}
        value1={deliveryPoint}
        unit1={''}
        value2={formattedDate}
        unit2={''}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
    </div>
  );
};

TotalsRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TotalsRow);
