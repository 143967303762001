export const NAME = 'fxrates';

export const GET_FXRATES = 'fxrates/get';
export const GET_FXRATES_SUCCESS = 'fxrates/get/success';
export const GET_FXRATES_ERROR = 'fxrates/get/error';

export const RESET_FXRATES = 'fxrates/reset';

export const GET_FXRATE = 'fxrate/get';
export const GET_FXRATE_SUCCESS = 'fxrate/get/success';
export const GET_FXRATE_ERROR = 'fxrate/get/error';

export const INSERT_FXRATES_FROM_SOCKET = 'fxrates/insert/from/socket';
export const INSERT_FXRATES_FROM_SOCKET_SUCCESS = 'fxrates/insert/from/socket/success';
export const INSERT_FXRATES_FROM_SOCKET_ERROR = 'fxrates/insert/from/socket/error';

export const UPDATE_FXRATES_FROM_SOCKET = 'fxrates/update/from/socket';
export const UPDATE_FXRATES_FROM_SOCKET_SUCCESS = 'fxrates/update/from/socket/success';
export const UPDATE_FXRATES_FROM_SOCKET_ERROR = 'fxrates/update/from/socket/error';

export const INIT_FXRATE = 'fxrate/init';
export const INIT_FXRATE_SUCCESS = 'fxrate/init/success';
export const INIT_FXRATE_ERROR = 'fxrate/init/error';

export const DESTROY_FXRATE = 'fxrate/destroy';
export const DESTROY_FXRATE_SUCCESS = 'fxrate/destroy/success';
export const DESTROY_FXRATE_ERROR = 'fxrate/destroy/error';

export const UPDATE_FXRATE = 'fxrate/update';
export const UPDATE_FXRATE_SUCCESS = 'fxrate/update/success';
export const UPDATE_FXRATE_ERROR = 'fxrate/update/error';
