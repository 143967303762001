import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '@material-ui/core';

import { avatarSize } from '../../models/constants';
import { getColor } from './colors';
import UserInfo from './userInfo';
import organisations from '../../../modules/organisations';
import users from '../../../modules/users';
import logos from '../../../modules/logos';
import { isObject } from '../../../commons/utils/functions';

const styles = (theme) => ({
  noMaxWidth: { maxWidth: '400px' },
});

// TODO: REFACTOR AND FORMAT CODE
class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showBackground: true,
    };
  }

  render() {
    const { classes } = this.props;
    let organisationShortName = '';
    let organisationName = '';
    let userName = this.props.userName || '';
    let logo = '';

    let tooltip = '';

    const getOrganisation = (orgId) => {
      if (orgId !== null && orgId !== undefined) {
        const org = this.props.organisations[orgId];
        const logoObject = this.props.logos[orgId];

        if (isObject(org)) {
          organisationShortName = org === null || org === undefined ? '' : org.shortName;
          organisationName = org === null || org === undefined ? 'Anonymous' : org.name;
        }
        if (isObject(logoObject)) {
          if (logoObject.body) {
            logo = logoObject.body;
          }
        }
      }
    };

    if (this.props.userId) {
      const user = this.props.users[this.props.userId];

      if (isObject(user)) {
        userName = `${user.firstName} ${user.lastName}`;
        getOrganisation(user.organisationId);
      } else {
        userName = 'Anonymous';
      }
    }

    if (this.props.organisationId) {
      getOrganisation(this.props.organisationId);
    }

    if (this.props.tooltip === true) {
      tooltip = (
        <UserInfo
          userName={userName}
          organisationName={organisationName}
          organisationShortName={organisationShortName}
        />
      );
    }

    const avatar = this.props.avatar || '';

    let initial = null;

    if (this.props.name === 'Public') {
      initial = 'P';
    } else {
      if (organisationShortName !== null) {
        initial = organisationShortName.charAt(0) || avatar.charAt(0) || userName.charAt(0);
      } else {
        initial = 'A';
      }
    }

    const { color } = getColor(initial);

    const styles = {
      color: {
        backgroundColor: this.state.showBackground ? color : 'transparent',
        color: 'white',
      },
      size: {
        height: this.props.size,
        width: this.props.size,
        fontSize: this.props.size === avatarSize.SMALL ? '1.0em' : '1.00em',
      },
      font: {
        fontSize: this.props.size === avatarSize.SMALL ? '1.0em' : '1.00em',
      },
    };

    return (
      <Tooltip title={tooltip} classes={{ tooltip: classes.noMaxWidth }}>
        <Avatar
          src={`data:image/png;base64,${logo}`}
          //src={`https://resource.lfex.matchtech.cloud/clientLogos/${organisationShortName}.png`}
          //src={`${process.env.REACT_APP_RESOURCES_URL}/clientLogos/${organisationShortName}.png`}
          style={{ ...styles.size, ...styles.color }}
          onError={() => this.setState({ showBackground: true })}
          alt={initial}
          imgProps={{
            onLoad: () => {
              this.setState({ showBackground: false });
            },
          }}
        />
      </Tooltip>
    );
  }
}
UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.string,
  organisationId: PropTypes.string,
  avatar: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    organisations: organisations.selectors.getAllObject(state),
    users: users.selectors.getAllObject(state),
    logos: logos.selectors.getAllObject(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(UserAvatar));
