import { createAction } from '../../../commons/actions';

export const getContracts = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const getContract = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const changeStateContract = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
