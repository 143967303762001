import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { IconButton, Collapse, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CustomText from './formFields/customText';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(),
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    marginBottom: theme.spacing(),
  },

  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: '4px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    padding: '4px',
  },
});

const CollapseContainerAlt = (props) => {
  const { classes, children, title } = props;
  const _show = CustomText({ value: 'show' });
  const _hide = CustomText({ value: 'hide' });
  const [expanded, setExpanded] = useState(false);

  const myRef = useRef();
  const click = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setTimeout(() => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 500);
    }
  };

  return (
    <div className={classes.container} style={props.style || {}}>
      <div className={classes.header}>
        <Typography>
          <CustomText value={title} upperCase />
        </Typography>
        <Tooltip title={expanded ? _hide : _show} aria-label={expanded ? _hide : _show}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={click}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
      <div ref={myRef}></div>
    </div>
  );
};

CollapseContainerAlt.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(CollapseContainerAlt);
