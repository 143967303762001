import { createSelector } from 'reselect';

import { NAME } from '../constants';

import orders from '../../orders';
import organisations from '../../organisations';
import contracts from '../../contracts';
import auth from '../../auth';
import settings from '../../settings';

import * as fxratesSelectors from '../../fxrates/selectors';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyMarket = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyMarket;
};
export const getById = createSelector(getByIdProp, (item) => item);

const emptyActiveMarket = {};
const getActiveMarketProp = (state) => {
  const id = 1;
  const item = state[NAME].byId[id];
  return item || emptyActiveMarket;
};
export const getActiveMarket = createSelector(getActiveMarketProp, (item) => {
  return item;
});

export const getActiveUser = createSelector(auth.selectors.getServerUser, (item) => {
  return item;
});

export const getActiveContract = createSelector(contracts.selectors.getActiveContract, (item) => {
  return item;
});

export const getDefaultSegment = createSelector(getActiveMarket, (item) => {
  const defaultSegment = 'southernRock australia';
  //const defaultSegment = getSegmentDefaultValueSegmentName(item);
  return defaultSegment;
});

export const getOrganisations = createSelector(organisations.selectors.getAll, (items) => items);

export const getOrders = createSelector(orders.selectors.getAll, (items) => items);

export const getSenders = createSelector(
  getOrders,
  organisations.selectors.getAllObject,
  (ords, orgs) => {
    const senders = [''];

    const existSenders = {};

    ords.forEach((ord) => {
      const { organisationId } = ord;
      if (!existSenders[organisationId]) {
        const shortName = orgs[organisationId] ? orgs[organisationId].shortName : organisationId;
        // TODO: add shortName organisation: order.organisation.shortName
        senders.push(shortName);
        existSenders[organisationId] = true;
      }
    });

    senders.sort();

    return senders;
  },
);

const emptyLocalMarket = {};
const getLocalMarketByIdProp = (state, id) => {
  const item = state[NAME].items[id];
  return item || emptyLocalMarket;
};
export const getLocalMarketById = createSelector(getLocalMarketByIdProp, (item) => item);

const getAllLocalMarketsProp = (state) => {
  const { items } = state[NAME];
  return Object.values(items);
};
export const getAllLocalMarkets = createSelector(getAllLocalMarketsProp, (items) => items);

export const getFxratesObject = createSelector(fxratesSelectors.getAllObject, (itemsObject) => {
  return itemsObject;
});

export const getTradingPartners = createSelector(settings.selectors.getTradingPartners, (items) => {
  return items;
});
