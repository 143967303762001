import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getTheme } from '../../../commons/selectors';
import { createAction } from '../../../commons/actions';
import * as actionTypes from '../actionTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

import * as selectors from '../selectors';

import DateField from '../../../commons/components/formFields/dateField';
import moment from 'moment';
import schemas from '../../schemas';
import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  responsiveContainer: {
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
    padding: '24px 8px',
  },
  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.2,
    letterSpacing: '1px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
});

const Statistics = (props) => {
  const [tradeDates, setTradeDates] = useState(null);
  const [counter, setCounter] = useState(1);
  const dateFilter = props.stats.dateFilter || 'tradeDate';
  const { deliveryDate } = props.stats;
  const value = props.stats.species || '';
  const deliveryCountry = props.stats.deliveryCountry || '';

  useEffect(() => {
    const { id, widget } = props;
    const payload = {
      id,
      widget,
    };
    props.initStatistics(payload);

    return function cleanup() {
      const payload = {
        id: props.id,
      };
      props.destroyStatistics(payload);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    refresh();
  }, [counter]);

  const handleValueChange = (e, name) => {
    const value = e.target.value;
    const { id } = props;
    const payload = {
      id,
      action: 'update',
      name,
      value,
      tradeDates,
      deliveryDate,
    };

    props.updateStatistics(payload);
  };

  const handleDateChange = (name) => (date) => {
    //const val= date.format("yyyy-MM-DD")
    const newDates = getDates(date);
    setTradeDates(newDates);
    const { id } = props;
    const payload = {
      id,
      action: 'refresh',
      tradeDates: newDates,
      deliveryDate,
      dateFilter,
    };

    props.updateStatistics(payload);
  };

  const handleDeliveryDateChange = (name) => (date) => {
    const { id } = props;
    const payload = {
      id,
      action: 'update',
      name: 'deliveryDate',
      tradeDates,
      value: date,
    };

    props.updateStatistics(payload);
  };

  const refresh = () => {
    const { id } = props;

    const payload = {
      id,
      action: 'refresh',
      tradeDates,
      deliveryDate,
      dateFilter,
    };
    props.updateStatistics(payload);
  };

  function getDates(dt = null) {
    const date = dt === null ? new Date() : new Date(dt);
    const next = dt === null ? new Date() : new Date(dt);
    next.setDate(next.getDate() + 1);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let day2 = next.getDate();
    let month2 = next.getMonth() + 1;
    let year2 = next.getFullYear();
    const createdDateFrom = `${year}-${month}-${day}`;
    const createdDateTo = `${year2}-${month2}-${day2}`;
    const createdDateFromUTC = moment(createdDateFrom).toISOString();
    const createdDateToUTC = moment(createdDateTo).toISOString();

    return {
      createdDateFrom,
      createdDateTo,
      createdDateFromUTC,
      createdDateToUTC,
    };
  }

  const { classes, theme } = props;

  const data = props.stats.stats;

  const createdDateFrom = tradeDates ? tradeDates.createdDateFrom : new Date();
  const _cartons = CustomText({ value: 'cartons' });
  return (
    <div className={` ${classes.container} undraggable`}>
      <div className={classes.header}>
        <FormControl>
          <InputLabel>
            <CustomText value="species" />
          </InputLabel>
          <Select
            value={String(value)}
            label="Fred"
            disableUnderline
            className={classes.headerTitle}
            onChange={(e) => handleValueChange(e, 'species')}
          >
            {props.valueEnums.species.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  <CustomText value={option} upperCase />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel style={{ width: 250 }}>
            <CustomText value="deliveryCountry" />
          </InputLabel>
          <Select
            value={String(deliveryCountry)}
            disableUnderline
            className={classes.headerTitle}
            onChange={(e) => handleValueChange(e, 'deliveryCountry')}
          >
            {props.valueEnums.deliveryCountry.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  <CustomText value={option} upperCase />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel style={{ width: 250 }}>
            <CustomText value={'filters'} />
          </InputLabel>
          <Select
            value={dateFilter}
            disableUnderline
            className={classes.headerTitle}
            onChange={(e) => handleValueChange(e, 'dateFilter')}
          >
            {['tradeDate', 'deliveryDate'].map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  <CustomText value={option} upperCase />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {dateFilter === 'tradeDate' && (
          <DateField
            accessor={'createdDateFrom'}
            displayName={'tradeDate'}
            value={createdDateFrom}
            disablePast={false}
            handleDateChange={handleDateChange}
            clearable={false}
            width={124}
          />
        )}
        {dateFilter === 'deliveryDate' && (
          <DateField
            accessor={'deliveryDate'}
            displayName={'deliveryDate'}
            value={deliveryDate}
            disablePast={false}
            handleDateChange={handleDeliveryDateChange}
            clearable={false}
            width={124}
          />
        )}

        {/* <ClearButton title="Refresh" icon="refresh" onClick={refresh}></ClearButton> */}
      </div>

      <div className={classes.responsiveContainer}>
        <ResponsiveContainer width="99%" height="97%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="1 1" stroke={theme.palette.dividerAlt} />
            <XAxis
              type="number"
              stroke={theme.palette.text.primaryLight}
              axisLine={false}
              unit={` ${_cartons}`}
            />
            <YAxis type="category" dataKey="name" stroke={theme.palette.text.primary} width={190} />
            <Tooltip
              wrapperStyle={{
                width: 200,
                backgroundColor: theme.palette.background.light,
                color: theme.palette.text.primary,
              }}
              contentStyle={{
                backgroundColor: theme.palette.background.light,
                color: theme.palette.text.primary,
              }}
              cursor={{ fill: theme.palette.background.light }}
            />
            <Legend
              verticalAlign="top"
              height="24px"
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              wrapperStyle={{ top: -5 }}
            />
            <Bar
              name={<CustomText value={'offers'} />}
              dataKey="Offers"
              fill={theme.palette.lightBlue}
            />
            {/*  <Bar name={_indicative} dataKey="Indicative" fill={theme.palette.amber} /> */}
            <Bar
              name={<CustomText value={'trades'} />}
              dataKey="Trades"
              fill={theme.palette.teal}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    valueEnums: selectors.getValueEnums(state),
    theme: getTheme(state),
    stats: selectors.getLocalStatisticsById(state, cId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStatistics: (payload) => {
      dispatch(createAction(actionTypes.UPDATE_STATISTICS, payload, { receivedAt: new Date() }));
    },
    initStatistics: (payload) => {
      dispatch(createAction(actionTypes.INIT_STATISTICS, payload, { receivedAt: new Date() }));
    },
    destroyStatistics: (payload) => {
      dispatch(createAction(actionTypes.DESTROY_STATISTICS, payload, { receivedAt: new Date() }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Statistics));
