export const INIT_THEME = 'theme/init';
export const INIT_THEME_SUCCESS = 'theme/init/success';
export const INIT_THEME_ERROR = 'theme/init/error';

export const CHANGE_THEME = 'theme/change';
export const CHANGE_THEME_SUCCESS = 'theme/change/success';
export const CHANGE_THEME_ERROR = 'theme/change/error';

export const UPDATE_BLOTTER_SETTINGS = 'blotter/settings/update';
export const UPDATE_BLOTTER_SETTINGS_SUCCESS = 'blotter/settings/update/success';
export const UPDATE_BLOTTER_SETTINGS_ERROR = 'blotter/settings/update/error';
