import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  UPDATE_ORDERS,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_ORDERS_ERROR,
  RESET_ORDERS,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_ORDERS_FROM_SOCKET_SUCCESS,
  UPDATE_ORDERS_FROM_SOCKET_ERROR,
  UPDATE_ORDERS_FROM_SOCKET,
  INSERT_ORDERS_FROM_SOCKET,
  INSERT_ORDERS_FROM_SOCKET_SUCCESS,
  INSERT_ORDERS_FROM_SOCKET_ERROR,
  INIT_ORDERS_SETTINGS,
  INIT_ORDERS_SETTINGS_SUCCESS,
  INIT_ORDERS_SETTINGS_ERROR,
  ORDERS_MANAGER_UPDATE_SUCCESS,
  ORDERS_MANAGER_UPDATE_ERROR,
  ORDERS_MANAGER_UPDATE,
  ORDERS_MANAGER_TABLE_ADD_ROW,
  ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS,
  ORDERS_MANAGER_TABLE_ADD_ROW_ERROR,
  ORDERS_MANAGER_TABLE_SEND_ORDERS_SUCCESS,
  ORDERS_MANAGER_TABLE_SEND_ORDERS_ERROR,
  ORDERS_MANAGER_TABLE_SEND_ORDERS,
  UPDATE_EDITOR_ORDER_LISTS_ERROR,
  UPDATE_EDITOR_ORDER_LISTS,
  UPDATE_EDITOR_ORDER_LISTS_SUCCESS,
  UPDATE_AMEND_ORDER_FORM,
  UPDATE_AMEND_ORDER_FORM_SUCCESS,
  UPDATE_AMEND_ORDER_FORM_ERROR,
  SEND_ORDER_AMENDMENT_SUCCESS,
  SEND_ORDER_AMENDMENT,
  SEND_ORDER_AMENDMENT_ERROR,
  UPDATE_AMEND_MULTIPLE_ORDER_FORM,
  SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_SUCCESS,
  SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_ERROR,
  UPDATE_AMEND_MULTIPLE_ORDER_FORM_SUCCESS,
  ORDERS_MANAGER_INIT_SUCCESS,
  ORDERS_MANAGER_INIT,
  ORDERS_MANAGER_INIT_ERROR,
  UPDATE_FORM_ERROR,
  CREATE_ORDER_FROM_RFQ,
  CREATE_ORDER_FROM_RFQ_SUCCESS,
  CREATE_ORDER_FROM_RFQ_ERROR,
} from '../constants';

import { isObject, isBoolean, insertItems, updateItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  forms: {
    create: {
      open: false,
      status: null,
      error: null,
      receivedAt: null,
    },
    update: {
      open: false,
      status: null,
      error: null,
      receivedAt: null,
    },
    amend: {
      order: {
        item: {},
        errors: {}, // for item
        open: false,
        status: null,
        error: null, // for status operation
        receivedAt: null,
      },
      multiple: {
        item: null,
        errors: {}, // for item
        rfq: null,
        open: false,
        status: null,
        error: null, // for status operation
        receivedAt: null,
      },
    },
  },
  settings: {
    items: [],
    status: null,
    error: null,
    receivedAt: null,
  },
  tables: {
    manager: {
      editorOrderLists: {
        visible: false,
        items: [],
        status: null,
        error: null,
        receivedAt: null,
      },
      orderListId: null,
      market: null,
      orders: [],
      globals: {},
      calculator: { calculatorState: {} },
      others: {},
      status: null,
      error: null,
      receivedAt: null,
    },
  },
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;
  let nextSettings = null;

  switch (action.type) {
    case INIT_ORDERS_SETTINGS:
      nextSettings = {
        ...state.settings,
        status: 'pending',
      };

      return {
        ...state,
        settings: nextSettings,
      };
    case INIT_ORDERS_SETTINGS_SUCCESS:
      nextSettings = {
        ...state.settings,
        items: action.payload.items,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return {
        ...state,
        settings: nextSettings,
      };
    case INIT_ORDERS_SETTINGS_ERROR:
      nextSettings = {
        ...state.settings,
        status: 'error',
        error: action.payload,
      };
      return {
        ...state,
        settings: nextSettings,
      };
    case GET_ORDERS:
      return {
        ...state,
        status: 'pending',
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_ORDERS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        status: 'pending',
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_ORDER_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case CREATE_ORDER_FROM_RFQ:
    case ORDERS_MANAGER_TABLE_SEND_ORDERS:
    case CREATE_ORDER:
      return {
        ...state,
        status: 'pending',
      };
    case ORDERS_MANAGER_TABLE_SEND_ORDERS_SUCCESS:
      nextState = {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (isObject(action.payload.others)) {
        nextState.tables.manager.others = {
          ...nextState.tables.manager.others,
          ...action.payload.others,
        };
      }

      return nextState;
    case SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_SUCCESS:
    case CREATE_ORDER_FROM_RFQ_SUCCESS:
    case CREATE_ORDER_SUCCESS:
      nextState = {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return nextState;
    case ORDERS_MANAGER_TABLE_SEND_ORDERS_ERROR:
    case CREATE_ORDER_FROM_RFQ_ERROR:
    case CREATE_ORDER_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case UPDATE_ORDERS_FROM_SOCKET:
    case UPDATE_ORDERS:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_ORDERS_FROM_SOCKET_SUCCESS:
    case UPDATE_ORDERS_SUCCESS:
      return {
        ...state,
        ...updateItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_ORDERS_FROM_SOCKET_ERROR:
    case UPDATE_ORDERS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case UPDATE_FORM:
      nextState = { ...state };

      nextState.forms[action.payload.type] = {
        ...nextState.forms[action.payload.type],
        status: 'pending',
      };
      return nextState;
    case UPDATE_FORM_SUCCESS:
      nextState = { ...state };

      nextState.forms[action.payload.type] = {
        ...nextState.forms[action.payload.type],
        ...action.payload.details,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return nextState;
    case UPDATE_FORM_ERROR:
      nextState = { ...state };

      nextState.forms[action.payload.type] = {
        ...nextState.forms[action.payload.type],
        status: 'error',
        error: action.payload.error,
      };
      return nextState;
    case INSERT_ORDERS_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case INSERT_ORDERS_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case INSERT_ORDERS_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_ORDERS:
      return {
        ...initialState,
      };
    case ORDERS_MANAGER_TABLE_ADD_ROW:
    case ORDERS_MANAGER_UPDATE:
      return {
        ...state,
        tables: {
          ...state.tables,
          manager: {
            ...state.tables.manager,
            status: 'pending',
          },
        },
      };
    case ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS:
    case ORDERS_MANAGER_UPDATE_SUCCESS:
      const nextStateManager = {
        ...state.tables.manager,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (typeof action.payload.orderListId !== 'undefined') {
        nextStateManager.orderListId = action.payload.orderListId;
        nextStateManager.globals = {};
      }

      if (Array.isArray(action.payload.orders)) {
        nextStateManager.orders = [...action.payload.orders];
      }

      if (typeof action.payload.market !== 'undefined') {
        nextStateManager.market = action.payload.market;
      }

      if (typeof action.payload.calculator !== 'undefined') {
        nextStateManager.calculator = { ...action.payload.calculator };
      }

      if (isObject(action.payload.others)) {
        nextStateManager.others = {
          ...nextStateManager.others,
          ...action.payload.others,
        };
      }

      if (isObject(action.payload.globals)) {
        nextStateManager.globals = {
          ...nextStateManager.globals,
          ...action.payload.globals,
        };
      }

      nextState = {
        ...state,
        tables: {
          ...state.tables,
          manager: nextStateManager,
        },
      };

      if (typeof action.payload.items !== 'undefined') {
        nextState.settings.items = [...action.payload.items];
      }
      return nextState;
    case ORDERS_MANAGER_TABLE_ADD_ROW_ERROR:
    case ORDERS_MANAGER_UPDATE_ERROR:
      return {
        ...state,
        tables: {
          ...state.tables,
          manager: {
            ...state.tables.manager,
            status: 'error',
            error: action.payload,
          },
        },
      };
    case UPDATE_EDITOR_ORDER_LISTS:
      return {
        ...state,
        tables: {
          ...state.tables,
          manager: {
            ...state.tables.manager,
            editorOrderLists: {
              ...state.tables.manager.editorOrderLists,
              status: 'pending',
            },
          },
        },
      };
    case UPDATE_EDITOR_ORDER_LISTS_SUCCESS:
      const nextStateEditorOrderLists = {
        ...state.tables.manager.editorOrderLists,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (typeof action.payload.items !== 'undefined') {
        nextStateEditorOrderLists.items = [...action.payload.items];
      }

      if (isBoolean(action.payload.visible)) {
        nextStateEditorOrderLists.visible = action.payload.visible;

        if (nextStateEditorOrderLists.visible) {
          nextStateEditorOrderLists.items = JSON.parse(JSON.stringify(state.settings.items));
        } else {
          nextStateEditorOrderLists.items = [];
        }
      }

      nextState = {
        ...state,
        tables: {
          ...state.tables,
          manager: {
            ...state.tables.manager,
            editorOrderLists: nextStateEditorOrderLists,
          },
        },
      };

      if (isBoolean(action.payload.save)) {
        if (action.payload.save) {
          nextState.settings.items = nextStateEditorOrderLists.items;
          nextStateEditorOrderLists.items = [];
        }
      }
      return nextState;
    case UPDATE_EDITOR_ORDER_LISTS_ERROR:
      return {
        ...state,
        tables: {
          ...state.tables,
          manager: {
            ...state.tables.manager,
            editorOrderLists: {
              ...state.tables.manager.editorOrderLists,
              status: 'error',
              error: action.payload.error,
            },
          },
        },
      };
    case SEND_ORDER_AMENDMENT:
    case UPDATE_AMEND_ORDER_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            order: {
              ...state.forms.amend.order,
              status: 'pending',
            },
          },
        },
      };

    case SEND_ORDER_AMENDMENT_SUCCESS:
    case UPDATE_AMEND_ORDER_FORM_SUCCESS:
      const nextStateAmendOrder = {
        ...state.forms.amend.order,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      const { open, item, errors } = action.payload;

      if (isObject(item)) {
        nextStateAmendOrder.item = { ...nextStateAmendOrder.item, ...item };
      }

      if (isObject(errors)) {
        nextStateAmendOrder.errors = { ...nextStateAmendOrder.errors, ...errors };
      }

      if (isBoolean(open)) {
        nextStateAmendOrder.open = open;

        if (!open) {
          nextStateAmendOrder.item = {};
          nextStateAmendOrder.errors = {};
        }
      }

      nextState = {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            order: nextStateAmendOrder,
          },
        },
      };
      return nextState;
    case SEND_ORDER_AMENDMENT_ERROR:
    case UPDATE_AMEND_ORDER_FORM_ERROR:
      return {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            order: {
              ...state.forms.amend.order,
              status: 'error',
              error: action.payload.error,
            },
          },
        },
      };
    case UPDATE_AMEND_MULTIPLE_ORDER_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            multiple: {
              ...state.forms.amend.multiple,
              status: 'pending',
            },
          },
        },
      };

    case UPDATE_AMEND_MULTIPLE_ORDER_FORM_SUCCESS:
      const nextStateAmendMultipleOrder = {
        open: action.payload.open,
        item: action.payload.item,
        rfq: action.payload.rfq,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      nextState = {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            multiple: nextStateAmendMultipleOrder,
          },
        },
      };
      return nextState;
    case SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_ERROR:
      // TODO: DUPLICATE? WHY? REFACTOR!!!
      //case SEND_ORDER_AMENDMENT_ERROR:
      //case UPDATE_AMEND_ORDER_FORM_ERROR:
      return {
        ...state,
        forms: {
          ...state.forms,
          amend: {
            ...state.forms.amend,
            multiple: {
              ...state.forms.amend.multiple,
              status: 'error',
              error: action.payload.error,
            },
          },
        },
      };
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    case ORDERS_MANAGER_INIT:
    case ORDERS_MANAGER_INIT_SUCCESS:
    case ORDERS_MANAGER_INIT_ERROR:
    default: {
      return state;
    }
  }
};

export default reducer;
