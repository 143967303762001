import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card, Chip, Collapse, Icon, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import UserAvatar from '../../../commons/components/userAvatar';
import { formatDateTime } from '../../../commons/config/formatters';
import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    padding: theme.spacing(0.5),
  },
  sectionHeader: {
    fontWeight: 'bold',
    color: theme.palette.text.subtitle,
  },
  sectionBody: {
    padding: theme.spacing(),
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(),
  },
  organisationName: {
    padding: theme.spacing(),
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const UserProfile = (props) => {
  const {
    classes,
    isSuperAdmin,
    isOrganisationAdmin,
    myOrganisation,
    user = {},
    organisationsObject,
    myUser,
  } = props;
  const [expanded, setExpanded] = React.useState(false);
  const statusColor = user.connectionState === 'online' ? teal['300'] : red[700];
  const details = user.details || {};
  const species = details.species || [];
  const organisation = organisationsObject[user.organisationId] || {};
  const editable =
    isSuperAdmin ||
    (isOrganisationAdmin && organisation.id === myOrganisation.id) ||
    myUser.id === user.id;

  return (
    <React.Fragment key={props.key}>
      <div className={classes.container}>
        <div className={classes.section}>
          <Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={classes.section}>
              <div className={classes.companyHeader}>
                <UserAvatar organisationId={user.organisationId} />
                <div
                  className={classes.organisationName}
                >{`${user.firstName} ${user.lastName}    |    ${organisation.shortName}`}</div>
              </div>
            </div>

            <div className={classes.actionIcons}>
              <IconButton>
                <FiberManualRecordIcon style={{ color: statusColor }} />
              </IconButton>
              {editable && (
                <IconButton
                  className={classes.expand}
                  onClick={() => props.editUser(user)}
                  aria-label="show more"
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}

              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </Card>
        </div>
        <Collapse in={expanded} style={{ paddingLeft: '32px' }}>
          {expanded && (
            <React.Fragment>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="organisation" />
                    </div>
                    <div className={classes.sectionBody}>
                      {`${organisation.name} 
                      `}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="title" />
                    </div>
                    <div className={classes.sectionBody} style={{ whiteSpace: 'pre-wrap' }}>
                      {user.title}
                    </div>
                  </div>
                </Card>
              </div>

              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="ourProducts" />
                    </div>
                    <div className={classes.sectionBody}>
                      {species.map((specie) => {
                        return (
                          <Chip
                            key={specie}
                            label={<CustomText value={specie} upperCase />}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Card>
              </div>
              {user.roles && (
                <div className={classes.section}>
                  <Card>
                    <div className={classes.section}>
                      <div className={classes.sectionHeader}>
                        <CustomText value="roles" />
                      </div>
                      <div className={classes.sectionBody}>
                        {user.roles.map((role) => {
                          return (
                            <Chip
                              key={role}
                              label={<CustomText value={role} />}
                              className={classes.chip}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="lastSeen" />
                    </div>
                    <div className={classes.sectionBody}>
                      {user.lastSeenTime ? formatDateTime(user.lastSeenTime) : ''}
                    </div>
                  </div>
                </Card>
              </div>
              {user.email && (
                <div className={classes.section}>
                  <Card>
                    <div className={classes.section}>
                      <div className={classes.sectionHeader}>
                        <CustomText value="email" />
                      </div>
                      <div className={classes.sectionBody}>{user.email}</div>
                    </div>
                  </Card>
                </div>
              )}
              {user.phoneNumber && (
                <div className={classes.section}>
                  <Card>
                    <div className={classes.section}>
                      <div className={classes.sectionHeader}>
                        <CustomText value="phoneNumber" />
                      </div>
                      <div className={classes.sectionBody}>{user.phoneNumber}</div>
                    </div>
                  </Card>
                </div>
              )}
              {user.createdTime && (
                <div className={classes.section}>
                  <Card>
                    <div className={classes.section}>
                      <div className={classes.sectionHeader}>
                        <CustomText value="User Created Time" />
                      </div>
                      <div className={classes.sectionBody}>
                        {user.createdTime ? formatDateTime(user.createdTime) : ''}
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {user.updatedTime && (
                <div className={classes.section}>
                  <Card>
                    <div className={classes.section}>
                      <div className={classes.sectionHeader}>
                        <CustomText value="User Updated Time" />
                      </div>
                      <div className={classes.sectionBody}>
                        {user.updatedTime ? formatDateTime(user.updatedTime) : ''}
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </React.Fragment>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(UserProfile);
