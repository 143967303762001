const BUY = 'BUY';
const SELL = 'SELL';
const PUBLIC = 'PUBLIC';
const TRADING_PARTNERS = 'TRADING_PARTNERS';
const SELECTED_ORGANISATIONS = 'SELECTED_ORGANISATIONS';
const RFQ = 'RFQ';
const INDIVIDUAL = 'INDIVIDUAL';
const ACTIVE = 'ACTIVE';
const SUSPENDED = 'SUSPENDED';
const CANCELLED = 'CANCELLED';
const PARTIALLY_FILLED = 'PARTIALLY-FILLED';
const IN_PROGRESS = 'IN_PROGRESS';
const FILLED = 'FILLED';
const ENDED = 'ENDED';
const NONE = 'NONE';
const REQUESTED = 'REQUESTED';
const RECEIVED = 'RECEIVED';
const GROUP = 'GROUP';

const STANDARD = "STANDARD"
const CONTRACT = "CONTRACT"
const VAP = "VAP"
const VAP_CONTRACT = "VAP_CONTRACT"

export const historyStatus = { NONE, REQUESTED, RECEIVED };

export const directions = { BUY, SELL };
export const rfqTypes ={ STANDARD, CONTRACT, VAP, VAP_CONTRACT}
export const visibilities = { PUBLIC, TRADING_PARTNERS, SELECTED_ORGANISATIONS };
export const roomTypes = { GROUP, INDIVIDUAL, RFQ };
export const status = {
  ACTIVE,
  SUSPENDED,
  CANCELLED,
  PARTIALLY_FILLED,
  FILLED,
  ENDED,
  IN_PROGRESS,
};
