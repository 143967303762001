import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from '@material-ui/core';

import SelectField from '../../../../commons/components/formFields/SelectField';
import TextInputField from '../../../../commons/components/formFields/TextInputField';
import MultipleSelectField from '../../../../commons/components/formFields/multipleSelectField';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';

import {} from '../../selectors';
import { createAdminItem, updateAdminItem } from '../../actions';
import { CREATE_ADMIN_ITEM, UPDATE_ADMIN_ITEM } from '../../constants';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    maxHeight: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  },
  title: { fontSize: theme.fontSize * 1.3 },

  subheader: {
    fontSize: '.75rem',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.5),
  },
});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class AddOrganisation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      shortName: '',
      type: '',
      tradingPartnersIds: [],
      beneficiaries: [],
      brokers: [],
      address1: '',
      address2: '',
      address3: '',
      city: '',
      postcode: '',
      locality: '',
      country: '',
      brand: [],
      processingPoints: '',
      species: [],
      factoryNumber: [],
      description: '',
      nameError: { value: false, message: '' },
      shortNameError: { value: false, message: '' },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleChange = (name) => (event) => {
    const value = event.target.value;
    let error = { value: false, message: '' };
    if (value === '' || value === null || value === undefined) {
      error = { value: true, message: 'Value is required' };
    }
    this.setState({ [name]: event.target.value, [`${name}Error`]: error });
  };

  handleChecked = (id, name) => () => {
    const checked = this.state[name];
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ [name]: newChecked });
  };
  handleMultiCheck = (eventType, name) => {
    const newChecked = [];
    switch (eventType) {
      case 'all':
        this.props.organisations.forEach((org) => {
          newChecked.push(org.id);
        });
        break;
      case 'buyer':
      case 'seller':
        this.props.organisations.forEach((org) => {
          if (org.type.toLowerCase() === eventType) {
            newChecked.push(org.id);
          }
        });
        break;
      default:
    }
    this.setState({ [name]: newChecked });
  };
  getErrors = () => {
    let hasErrors = false;
    let { nameError, shortNameError } = this.state;
    if (this.state.name === '' || this.state.name === null || this.state.name === undefined) {
      nameError = { value: true, message: 'Value is required' };
      hasErrors = true;
    } else {
      nameError = { value: false, message: '' };
    }
    if (
      this.state.shortName === '' ||
      this.state.shortName === null ||
      this.state.shortName === undefined
    ) {
      shortNameError = { value: true, message: 'Value is required' };
      hasErrors = true;
    } else {
      shortNameError = { value: false, message: '' };
    }
    return { hasErrors, nameError, shortNameError };
  };
  send = () => {
    const errors = this.getErrors();
    if (!errors.hasErrors) {
      const name = this.state.name;
      const shortName = this.state.shortName;
      const tradingPartnersIds = this.state.tradingPartnersIds;
      const beneficiaries = this.state.beneficiaries;
      const brokers = this.state.brokers;
      const type = this.state.type;
      const description = this.state.description;
      const address = {
        address1: this.state.address1,
        address2: this.state.address2,
        address3: this.state.address3,
        city: this.state.city,
        postcode: this.state.postcode,
        locality: this.state.locality,
        country: this.state.country,
      };
      const details = {
        species: this.state.species,
        brand: this.state.brand,
        factoryNumber: this.state.factoryNumber,
        product: [],
        country: [],
        region: [],
      };

      const payload = {
        type: 'organisations',
        body: {
          name,
          shortName,
          type,
          address,
          tradingPartnersIds,
          beneficiaries,
          brokers,
          details,
          description,
        },
      };
      console.log('payload', payload);
      this.props.create(payload);
      this.props.closeForm();
    }
    this.setState({ nameError: errors.nameError, shortNameError: errors.shortNameError });
  };

  render() {
    const { classes, open, organisations } = this.props;
    const { tradingPartnersIds, beneficiaries, brokers } = this.state;
    const brands = Object.keys(this.props.brandsAndFactories.brand);
    const factoryNumbers = Object.keys(this.props.brandsAndFactories.factoryNumber);

    return (
      <div>
        <Modal open={open} onClose={this.closeForm}>
          <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
            <Typography className={classes.title}>ADD ORGANISATION</Typography>
            <div className={classes.container}>
              <TextInputField
                accessor="name"
                displayName="Organisation Full Name"
                value={this.state.name}
                handleChange={this.handleChange}
                error={this.state.nameError}
                fullWidth
              />
              <TextInputField
                accessor="shortName"
                displayName="Organisation Short Name"
                value={this.state.fullName}
                handleChange={this.handleChange}
                error={this.state.shortNameError}
                fullWidth
              />
              <SelectField
                accessor="type"
                displayName="Organisation Type"
                value={this.state.type || ''}
                values={['BUYER', 'SELLER', 'AGENT']}
                handleChange={(name) => (e) => {
                  this.setState({
                    [name]: e.target.value,
                    typeError: { value: false, message: '' },
                  });
                }}
                error={this.state.typeError}
                fullWidth
              />
              <TextInputField
                accessor="address1"
                displayName="Address 1"
                value={this.state.address1}
                handleChange={this.handleChange}
                error={this.state.address1Error}
                fullWidth
              />
              <TextInputField
                accessor="address2"
                displayName="Address 2"
                value={this.state.address2}
                handleChange={this.handleChange}
                error={this.state.address2Error}
                fullWidth
              />
              <TextInputField
                accessor="address3"
                displayName="Address 3"
                value={this.state.address3}
                handleChange={this.handleChange}
                error={this.state.address3Error}
                fullWidth
              />
              <TextInputField
                accessor="city"
                displayName="city"
                value={this.state.city}
                handleChange={this.handleChange}
                error={this.state.cityError}
                fullWidth
              />

              <TextInputField
                accessor="postcode"
                displayName="Postcode"
                value={this.state.postcode}
                handleChange={this.handleChange}
                error={this.state.postCodeError}
                fullWidth
              />

              <TextInputField
                accessor="locality"
                displayName="Locality"
                value={this.state.locality}
                handleChange={this.handleChange}
                error={this.state.locality}
                fullWidth
              />
              <TextInputField
                accessor="country"
                displayName="Country"
                value={this.state.country}
                handleChange={this.handleChange}
                error={this.state.country}
                fullWidth
              />
              {/*     <TextInputField
                accessor="brand"
                displayName="Brand"
                value={this.state.brand}
                handleChange={this.handleChange}
                error={this.state.brand}
                fullWidth
              />
              <TextInputField
                accessor="factoryNumber"
                displayName="Factory Number"
                value={this.state.factoryNumber}
                handleChange={this.handleChange}
                error={this.state.factoryNumber}
                fullWidth
              /> */}
              <MultipleSelectField
                accessor="brand"
                key="user_update_brand"
                displayName="Brand"
                value={this.state.brand || []}
                values={brands || []}
                handleChange={this.handleChange}
                fullWidth
              />
              <MultipleSelectField
                accessor="factoryNumber"
                key="user_update_factoryNumber"
                displayName="FactoryNumber"
                value={this.state.factoryNumber || []}
                values={factoryNumbers || []}
                handleChange={this.handleChange}
                fullWidth
              />
              <MultipleSelectField
                accessor="species"
                key="user_update_species"
                displayName="Species"
                value={this.state.species || []}
                values={this.props.species}
                handleChange={this.handleChange}
                fullWidth
              />
              <TextInputField
                accessor="description"
                displayName="Description"
                value={this.state.description}
                handleChange={this.handleChange}
                error={this.state.description}
                fullWidth
                multiline
                maxRows={5}
              />
              <div className={classes.subheader}>Trading Partners</div>
              <div style={{ flex: 1, paddingTop: '16px' }}>
                <div style={{ display: 'flex' }}>
                  <ClearButton
                    title="Select All"
                    onClick={() => this.handleMultiCheck('all', 'tradingPartnersIds')}
                    icon="check_box"
                  ></ClearButton>
                  <ClearButton
                    title="Select None"
                    onClick={() => this.handleMultiCheck('none', 'tradingPartnersIds')}
                    icon="check_box_outline_blank"
                  ></ClearButton>
                  <ClearButton
                    title="Select Buyers"
                    onClick={() => this.handleMultiCheck('buyer', 'tradingPartnersIds')}
                    icon="check"
                  ></ClearButton>
                  <ClearButton
                    title="Select Sellers"
                    onClick={() => this.handleMultiCheck('seller', 'tradingPartnersIds')}
                    icon="check"
                  ></ClearButton>
                </div>
                {organisations.map((org) => {
                  const id = org.id;
                  const labelId = `checkbox-list-label-${id}`;
                  const labelId2 = `checkbox-list-label2-${id}`;
                  return (
                    <ListItem
                      key={id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleChecked(id, 'tradingPartnersIds')}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={tradingPartnersIds.indexOf(org.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={org.name} />
                      <ListItemText
                        id={labelId2}
                        primary={org.shortName}
                        style={{ textAlign: 'right' }}
                      />
                    </ListItem>
                  );
                })}
              </div>

              <div className={classes.subheader}>Beneficiaries</div>
              <div style={{ flex: 1, paddingTop: '16px' }}>
                <div style={{ display: 'flex' }}>
                  <ClearButton
                    title="Select All"
                    onClick={() => this.handleMultiCheck('all', beneficiaries)}
                    icon="check_box"
                  ></ClearButton>
                  <ClearButton
                    title="Select None"
                    onClick={() => this.handleMultiCheck('none', beneficiaries)}
                    icon="check_box_outline_blank"
                  ></ClearButton>
                  <ClearButton
                    title="Select Buyers"
                    onClick={() => this.handleMultiCheck('buyer', beneficiaries)}
                    icon="check"
                  ></ClearButton>
                  <ClearButton
                    title="Select Sellers"
                    onClick={() => this.handleMultiCheck('seller', beneficiaries)}
                    icon="check"
                  ></ClearButton>
                </div>
                {organisations.map((org) => {
                  const id = org.id;
                  const labelId = `checkbox-list-label-${id}`;
                  const labelId2 = `checkbox-list-label2-${id}`;
                  return (
                    <ListItem
                      key={id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleChecked(id, 'beneficiaries')}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={beneficiaries.indexOf(org.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={org.name} />
                      <ListItemText
                        id={labelId2}
                        primary={org.shortName}
                        style={{ textAlign: 'right' }}
                      />
                    </ListItem>
                  );
                })}
              </div>

              <div className={classes.subheader}>Brokers</div>
              <div style={{ flex: 1, paddingTop: '16px' }}>
                <div style={{ display: 'flex' }}>
                  <ClearButton
                    title="Select All"
                    onClick={() => this.handleMultiCheck('all', brokers)}
                    icon="check_box"
                  ></ClearButton>
                  <ClearButton
                    title="Select None"
                    onClick={() => this.handleMultiCheck('none', brokers)}
                    icon="check_box_outline_blank"
                  ></ClearButton>
                  <ClearButton
                    title="Select Buyers"
                    onClick={() => this.handleMultiCheck('buyer', brokers)}
                    icon="check"
                  ></ClearButton>
                  <ClearButton
                    title="Select Sellers"
                    onClick={() => this.handleMultiCheck('seller', brokers)}
                    icon="check"
                  ></ClearButton>
                </div>
                {organisations.map((org) => {
                  const id = org.id;
                  const labelId = `checkbox-list-label-${id}`;
                  const labelId2 = `checkbox-list-label2-${id}`;
                  return (
                    <ListItem
                      key={id}
                      role={undefined}
                      dense
                      button
                      onClick={this.handleChecked(id, 'brokers')}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={brokers.indexOf(org.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={org.name} />
                      <ListItemText
                        id={labelId2}
                        primary={org.shortName}
                        style={{ textAlign: 'right' }}
                      />
                    </ListItem>
                  );
                })}
              </div>
            </div>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.closeForm} />
                <ConfirmButton onClick={this.send} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
      </div>
    );
  }
}

AddOrganisation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    update: (payload) => {
      dispatch(updateAdminItem(UPDATE_ADMIN_ITEM, payload));
    },
    create: (payload) => {
      dispatch(createAdminItem(CREATE_ADMIN_ITEM, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddOrganisation));
