import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { TextField, FormControl, InputAdornment } from '@material-ui/core';
import { textField } from './formFieldStyles';
import CustomText from './customText';
import clsx from 'clsx';
const styles = (theme) => ({
  textField: textField(theme),
  inputTextHighlight: {
    color: lightBlue[300],
  },
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
});

const TextFieldFilled = (props) => {
  const { classes, accessor, displayName, value, error } = props;

  return (
    <FormControl className={props.noMargin ? null : classes.textField} fullWidth={props.fullWidth}>
      <TextField
        id={accessor}
        type="text"
        label={<CustomText value={accessor} />}
        variant="filled"
        size="small"
        readOnly
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={value}
        onChange={props.handleChange(accessor)}
        error={props.error ? error.value : false}
        helperText={props.error ? error.message : ''}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,

          className: clsx(
            props.highlight ? classes.inputTextHighlight : null,
            props.dark && classes.darkBackground,
            props.warning && classes.warningBackground,
          ),
          inputProps: {
            step: props.step ? props.step : 1,
          },

          endAdornment: (
            <InputAdornment position="end">
              {props.adornment ? props.adornment : ' '}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

TextFieldFilled.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFieldFilled);
