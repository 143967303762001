// TODO: split this saga into smaller ones
import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import moment from 'moment';
import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_FROM_MANAGER_SUCCESS,
  CREATE_ORDER_FROM_MANAGER_ERROR,
  CREATE_ORDER_FROM_RFQ,
  CREATE_ORDER_FROM_RFQ_SUCCESS,
  CREATE_ORDER_FROM_RFQ_ERROR,
  GET_ORDERS,
  RESET_ORDERS,
  CREATE_ORDER,
  GET_ORDER,
  UPDATE_ORDERS,
  UPDATE_DATA_ORDER,
  UPDATE_DATA_ORDER_ERROR,
  UPDATE_DATA_ORDER_SUCCESS,
  UPDATE_ORDERS_ERROR,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  INSERT_ORDERS_FROM_SOCKET,
  INSERT_ORDERS_FROM_SOCKET_SUCCESS,
  INSERT_ORDERS_FROM_SOCKET_ERROR,
  UPDATE_ORDERS_FROM_SOCKET,
  UPDATE_ORDERS_FROM_SOCKET_SUCCESS,
  UPDATE_ORDERS_FROM_SOCKET_ERROR,
  INIT_ORDERS_SETTINGS,
  INIT_ORDERS_SETTINGS_SUCCESS,
  CREATE_ORDER_FROM_MANAGER,
  ORDERS_MANAGER_TABLE_UPDATE,
  ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS,
  ORDERS_MANAGER_TABLE_UPDATE_SUCCESS,
  ORDERS_MANAGER_TABLE_UPDATE_ERROR,
  ORDERS_MANAGER_TABLE_ADD_ROW_ERROR,
  ORDERS_MANAGER_TABLE_SEND_ORDERS_SUCCESS,
  ORDERS_MANAGER_TABLE_SEND_ORDERS_ERROR,
  ORDERS_MANAGER_UPDATE,
  ORDERS_MANAGER_UPDATE_SUCCESS,
  ORDERS_MANAGER_UPDATE_ERROR,
  ORDERS_MANAGER_TABLE_ADD_ROW,
  ORDERS_MANAGER_TABLE_SEND_ORDERS,
  UPDATE_EDITOR_ORDER_LISTS,
  UPDATE_EDITOR_ORDER_LISTS_SUCCESS,
  UPDATE_EDITOR_ORDER_LISTS_ERROR,
  UPDATE_AMEND_ORDER_FORM,
  UPDATE_AMEND_ORDER_FORM_SUCCESS,
  UPDATE_AMEND_ORDER_FORM_ERROR,
  SEND_ORDER_AMENDMENT,
  SEND_ORDER_AMENDMENT_SUCCESS,
  SEND_ORDER_AMENDMENT_ERROR,
  ERROR_PRICE,
  ERROR_VOLUME,
  UPDATE_AMEND_MULTIPLE_ORDER_FORM,
  SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM,
  UPDATE_AMEND_MULTIPLE_ORDER_FORM_SUCCESS,
  SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_ERROR,
  SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_SUCCESS,
  UPDATE_AMEND_MULTIPLE_ORDER_FORM_ERROR,
  ORDERS_MANAGER_INIT,
  ORDERS_MANAGER_INIT_SUCCESS,
  ORDERS_MANAGER_INIT_ERROR,
  CHANGE_STATE_ORDERS,
} from '../constants';

import {
  getOrders,
  getOrder,
  createOrder,
  updateOrders,
  updateDataOrder,
  resetOrders,
  updateForm as actionUpdateForm,
  insertOrders,
  initOrdersSettings as actionInitOrdersSettings,
  ordersManager,
  editorOrderLists as actionUpdateEditorOrderLists,
  changeStateOrders,
} from '../actions';

import api from '../api';

import auth from '../../auth';
import workspaces from '../../workspaces';
import calculatorsModule from '../../calculators';
import contracts from '../../contracts';

import { isObject } from '../../../commons/utils/functions';
import {
  getActiveOrderListIdFromManagerOrder,
  getOrdersFromSettings,
  getCalculatorsFromSettings,
  getOrdersFromOrderList,
  getGlobalsFromManagerOrder,
  getMarketFromManagerOrder,
  getCalcultorFromManagerOrder,
  getOrderListsEditorOrderLists,
  getActiveContract,
  getOrderManager,
  getFxratesObject,
  getActiveUser,
  getOrganisationsObject,
  getOrganisations,
  getLastUpdatedTime,
  getOrderForm,
  getAmendedOrders,
} from '../selectors';

import { getFromLS, saveToLS, removeFromLS } from '../../../commons/localStorage';

import { defaultOrderList, orderRow } from '../config/orderLists';

import { idGenerator } from '../../../commons/config/functions';

import { calcFinalPrice } from '../../../commons/config/calculators';
import { directions, PRICE_DECIMALS } from '../../../commons/models/constants';

import { widgetTypes } from '../../workspaces/config/widgets';

import { checkPrice, checkVolume } from '../../../commons/config/formatters';
import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';

import {
  getEnumerations,
  getDefaultFormState,
  getContractType,
  getFieldsArray,
  getUpdatedFormState,
  createApiOrder,
  getCartonNetWeightEnums,
} from '../../contracts/utils/modelFunctions';

function* initOrdersSettings() {
  const orderList = defaultOrderList(1);
  orderList.id = new Date().getTime().toString();

  const orderList2 = defaultOrderList(2);
  orderList2.active = false;

  const items = getFromLS('orderLists') || [orderList, orderList2] || [];

  yield put(
    actionInitOrdersSettings(INIT_ORDERS_SETTINGS_SUCCESS, { items }, { receivedAt: new Date() }),
  );
}

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const orders = yield call(api.getAll, options);

    const payload = {
      items: orders,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getOrders(GET_ORDERS_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getOrders(GET_ORDERS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const order = yield call(api.get);

    const payload = {
      items: [order],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getOrder(GET_ORDER_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getOrder(GET_ORDER_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const data = yield select(getOrderForm);
    const order = createApiOrder([data]);
    const token = yield call(auth.selectors.getToken);
    const createdOrder = yield call(api.create, { token, body: order });
    const payload = {
      items: [createdOrder],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createOrder(CREATE_ORDER_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(createOrder(CREATE_ORDER_ERROR, { error }));
    }
  }
}

function* createOrderFromRfq(action) {
  try {
    const { orderRows, textMessage, defaultOrders, timeInForce, status, callback } = action.payload;
    const order = createApiOrder(orderRows, textMessage);
    const enums = getCartonNetWeightEnums(order);
    const user = yield select(getActiveUser);
    /*  if (user.organisationId === order.beneficiaryOrganisationId) {
      order.beneficiaryOrganisationId = null;
    } */
    order.textMessage = textMessage;
    order.timeInForce = timeInForce ? moment.utc(moment(timeInForce)).format() : null;
    order.status = status;

    if (defaultOrders !== null && defaultOrders !== undefined) {
      if (defaultOrders.length > 0) {
        //order.extensions = JSON.stringify({ defaultOrders });
      }
    }
    console.log('orders:sage:createOrder:payload', JSON.stringify(order));
    const token = yield call(auth.selectors.getToken);
    const createdOrder = yield call(api.create, { token, body: order });
    const payload = {
      items: [createdOrder],
    };

    const meta = {
      receivedAt: new Date(),
    };
    if (callback) callback(true);

    yield put(createOrder(CREATE_ORDER_FROM_RFQ_SUCCESS, payload, meta));

    // yield put(
    //   changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    // );
  } catch (error) {
    if (action.payload.callback) action.payload.callback(false);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork
    console.log('CreateOrderFromRfq:error:', error);
    if (!checkedError) {
      yield put(createOrder(CREATE_ORDER_FROM_RFQ_ERROR, { error }));
    }
  }
}

function* createOrderFromManager(action) {
  try {
    const {
      data: {
        harvestWeek,
        harvestDate,
        currency,
        incoTerms,
        instrument,
        packingStation,
        quality,
        region,
        state,
        certification,
      },
      contract,
      market,
      order,
    } = action.payload;

    const defaultValues = contracts.utils.getDefaultSegmentValues(contract, market);

    const newContract = {
      instrument,
      week: harvestWeek,
      underlying: {
        certification,
        currency,
        deliveryPoint: defaultValues.deliveryPointFob,
        incoTerms,
        location: getLocation(market),
        packingStation,
        product: getProduct(market),
        quality,
        region,
        state,
        unit: defaultValues.unit,
        weightClass: order.product,
      },
    };

    if (harvestDate) {
      newContract.harvestDate = harvestDate;
    }

    const selectedOrganisationsIds = [];

    if (
      order.visibility === 'SELECTED_ORGANISATIONS' &&
      Array.isArray(order.recipients) &&
      order.recipients.length > 0
    ) {
      order.recipients.forEach((org) => selectedOrganisationsIds.push(org.id));
    }

    const newOrder = {
      assets: [
        {
          contract: newContract,
          price: order.price,
          volume: order.volume,
          direction: order.direction,
        },
      ],
      selectedOrganisationsIds,
      rfqId: null,
      targetIds: [],
      textMessage: order.textMessage || '',
      timeInForce: order.timeInForce || null,
      visibility: order.visibility,
    };

    const token = yield call(auth.selectors.getToken);
    const createdOrder = yield call(api.create, { token, body: newOrder });
    const payload = {
      items: [createdOrder],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createOrder(CREATE_ORDER_FROM_MANAGER_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(createOrder(CREATE_ORDER_FROM_MANAGER_ERROR, { error }));
    }
  }
}
/**
 *
 * @param {*} action
 */
// TODO: ADD ONE SCHEMA FOR ORDER
function* update(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items: [],
    };

    const meta = {
      receivedAt: new Date(),
    };

    if (Array.isArray(items)) {
      const token = yield call(auth.selectors.getToken);

      const tasks = [];

      items.forEach((item) => {
        if (!item.orderId) return;

        const newOrder = {};

        Object.keys(item).forEach((key) => {
          if (typeof item[key] !== 'undefined') {
            newOrder[key] = item[key];
          }
        });

        if (Object.keys(newOrder).length > 0) {
          tasks.push(call(api.update, { token, body: newOrder }));
        }
      });
      if (tasks.length > 0) {
        const orders = yield all(tasks);
        payload.items = orders;
      }
    }

    yield put(updateOrders(UPDATE_ORDERS_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(updateOrders(UPDATE_ORDERS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* updateData() {
  try {
    const dataOrder = yield call(api.updateData);

    const payload = {
      items: dataOrder,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateDataOrder(UPDATE_DATA_ORDER_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(updateDataOrder(UPDATE_DATA_ORDER_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* updateForm(action) {
  try {
    const { type, action: actionForm, details } = action.payload;

    const payload = {
      type,
    };

    if (type === 'create') {
      if (actionForm === 'open') {
        const contractType = getContractType();
        const orderState = getDefaultFormState('order', {
          [contractType.segment]: details.segmentValue,
          [contractType.market]: details.marketValue,
        });

        const enumerations = getEnumerations('order', orderState);
        const fresh = orderState.state ? orderState.state.toLowerCase() : 'fresh';
        const fields = getFieldsArray('order', ['spot', fresh]);

        payload.details = {
          orderState,
          enumerations,
          fields,
          open: true,
          contractType,
        };
      } else if (actionForm === 'close') {
        payload.details = {
          open: false,
        };
      } else if (actionForm === 'update') {
        const oldOrderState = yield select(getOrderForm);
        const orderState = getUpdatedFormState(action.payload, oldOrderState);
        const enumerations = getEnumerations('order', orderState);
        const fresh = orderState.state ? orderState.state.toLowerCase() : 'fresh';
        const fields = getFieldsArray('order', ['spot', fresh]);
        payload.details = {
          orderState,
          enumerations,
          fields,
        };
      }
    }

    const meta = {
      receivedAt: new Date(),
    };
    yield put(actionUpdateForm(UPDATE_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(UPDATE_FORM_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* insertOrdersFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(insertOrders(INSERT_ORDERS_FROM_SOCKET_SUCCESS, payload, meta)); // TODO: DUPLICATE INSERT: // from api and socket

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    yield put(insertOrders(INSERT_ORDERS_FROM_SOCKET_ERROR, { error }));
  }
}

function* updateFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateOrders(UPDATE_ORDERS_FROM_SOCKET_SUCCESS, payload, meta)); // TODO: DUPLICATE UPDATE: // from api and socket

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    yield put(updateOrders(UPDATE_ORDERS_FROM_SOCKET_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
// TODO: refactor
function* updateAmendOrderForm(action) {
  try {
    const { action: actionForm, open, order, fieldName, value } = action.payload;

    const payload = {};

    if (actionForm === 'amendOrder') {
      payload.open = open;
      const { id, price, volume } = order;
      payload.item = { id, price, volume };
    } else if (actionForm === 'closeForm') {
      payload.open = false;
    } else if (actionForm === 'updateOrder') {
      switch (fieldName) {
        case 'price':
          payload.item = { price: value };
          payload.errors = {};

          if (checkPrice(value)) {
            payload.errors.price = { message: '', value: false };
          } else {
            payload.errors.price = { message: ERROR_PRICE, value: true };
          }
          break;

        case 'volume':
          payload.item = { volume: value };
          payload.errors = {};

          if (checkVolume(value)) {
            payload.errors.volume = { message: '', value: false };
          } else {
            payload.errors.volume = { message: ERROR_VOLUME, value: true };
          }
          break;

        default:
          payload.item = { [fieldName]: value };
      }
    }

    const meta = {
      receivedAt: new Date(),
    };
    yield put(actionUpdateForm(UPDATE_AMEND_ORDER_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(UPDATE_AMEND_ORDER_FORM_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */

function* sendOrderAmendment() {
  try {
    const payload = {};

    const orders = yield select(getAmendedOrders);
    //const errors = yield select(getErrorsFromAmendOrderForm);
    //if ((!errors.price || !errors.price.value) && (!errors.volume || !errors.volume.value)) {
    const amendOrder = {
      assets: [],
    };
    orders.forEach((order) => {
      const obj = {
        id: order.id,
        price: parseFloat(order.price),
        volume: parseInt(order.volume, 10),
      };
      amendOrder.orderId = order.orderGroupId;
      amendOrder.assets.push(obj);
    });

    console.log('orders');

    const token = yield call(auth.selectors.getToken);
    yield call(api.update, { token, body: amendOrder });

    // }

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateForm(SEND_ORDER_AMENDMENT_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionUpdateForm(SEND_ORDER_AMENDMENT_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
// TODO: refactor
function* updateAmendMultipleOrderForm(action) {
  try {
    const { payload } = action;

    const meta = {
      receivedAt: new Date(),
    };
    yield put(actionUpdateForm(UPDATE_AMEND_MULTIPLE_ORDER_FORM_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(UPDATE_AMEND_MULTIPLE_ORDER_FORM_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* sendOrderFromAmendMultipleOrderForm(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const { amendOrder } = action.payload;

    const body = {
      orderId: amendOrder.orderId,
      status: amendOrder.status,
      assets: amendOrder.assets,
    };
    const amendedOrder = yield call(api.update, { token, body });

    const meta = {
      receivedAt: new Date(),
    };

    const payload = {
      items: [amendedOrder],
    };

    yield put(actionUpdateForm(SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_SUCCESS, payload, meta));

    yield put(
      changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.error(error);
    yield put(actionUpdateForm(SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* reset() {
  yield put(resetOrders(RESET_ORDERS));
}

export function* watchInitOrdersSettings() {
  yield takeEvery(INIT_ORDERS_SETTINGS, initOrdersSettings);
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getAll);
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, get);
}

export function* watchCreateOrder() {
  yield takeEvery(CREATE_ORDER, create);
}

export function* watchCreateOrderFromRfq() {
  yield takeEvery(CREATE_ORDER_FROM_RFQ, createOrderFromRfq);
}

export function* watchCreateOrderFromManager() {
  yield takeEvery(CREATE_ORDER_FROM_MANAGER, createOrderFromManager);
}

export function* watchInsertOrdersFromSocket() {
  yield takeEvery(INSERT_ORDERS_FROM_SOCKET, insertOrdersFromSocket);
}

export function* watchUpdateOrders() {
  yield takeEvery(UPDATE_ORDERS, update);
}

export function* watchUpdateOrdersFromSocket() {
  yield takeEvery(UPDATE_ORDERS_FROM_SOCKET, updateFromSocket);
}

export function* watchUpdateDataOrder() {
  yield takeEvery(UPDATE_DATA_ORDER, updateData);
}

export function* watchResetOrders() {
  yield takeEvery(RESET_ORDERS, reset);
}

export function* watchUpdateForm() {
  yield takeEvery(UPDATE_FORM, updateForm);
}

export function* watchUpdateAmendOrderForm() {
  yield takeEvery(UPDATE_AMEND_ORDER_FORM, updateAmendOrderForm);
}

export function* watchSendOrderFromAmendOrderForm() {
  yield takeEvery(SEND_ORDER_AMENDMENT, sendOrderAmendment);
}

export function* watchUpdateAmendMultipleOrderForm() {
  yield takeEvery(UPDATE_AMEND_MULTIPLE_ORDER_FORM, updateAmendMultipleOrderForm);
}

export function* watchSendOrderFromAmendMultipleOrderForm() {
  yield takeEvery(SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM, sendOrderFromAmendMultipleOrderForm);
}

/**
 *
 * @param {*} action
 */
// TODO: Refactor
function* updateOrdersManager(action) {
  try {
    const {
      payload: {
        orderListId,
        calculatorId,
        action: actionOrdersManager,
        fieldName,
        active,
        value,
        number,
        accessor,
        orderId,
        errors,
        openedMenu,
        anchorEl,
        anchorElCalculator,
        openedCalculator,
        snackbarOpen,
      },
    } = action;

    const payload = {};

    if (actionOrdersManager === 'closeSnackbar') {
      payload.others = { snackbarOpen };
    } else if (actionOrdersManager === 'toogleOpenCalculator') {
      payload.others = { anchorElCalculator, openedCalculator };
    } else if (actionOrdersManager === 'closeOrderListsMenu') {
      payload.others = { openedMenu };
    } else if (actionOrdersManager === 'showOrderListsMenu') {
      payload.others = { openedMenu, anchorEl };
    } else if (actionOrdersManager === 'changeCalculator') {
      const calculators = yield select(getCalculatorsFromSettings);
      const calculator = calculators.find((c) => c.id === calculatorId);
      payload.calculator = JSON.parse(JSON.stringify(calculator));

      const orders = yield select(getOrdersFromOrderList);
      applyNewCurrency(orders, payload.calculator); // TODO:add setTimeout for mackrotask
      payload.orders = orders;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.orders = payload.orders;
      orderList.calculatorId = calculator.id;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'updateCalculator') {
      const calculator = yield select(getCalcultorFromManagerOrder);

      if (typeof calculator.id !== 'undefined') {
        calculator.calculatorState[fieldName] = value;
        calculator.calculatorState.errors = errors;

        if (fieldName === 'localCurrency' || fieldName === 'finalCurrency') {
          const fxrates = yield select(getFxratesObject);
          const curr = updateCurrencyRate(fieldName, value, calculator, fxrates);
          calculator.calculatorState.currencyRate = curr;
          calculator.calculatorState.feeCurrencyRate = 1;
        } else if (fieldName === 'currencyRate') {
          calculator.calculatorState.feeCurrencyRate = 1;
        } else if (fieldName === 'fxRateInverted') {
          let curr = calculator.calculatorState.currencyRate;
          curr = 1 / parseFloat(curr);
          calculator.calculatorState.currencyRate = numberFormat(curr);
          calculator.calculatorState.feeCurrencyRate = 1;
        }

        payload.calculator = calculator;

        const orders = yield select(getOrdersFromOrderList);
        applyNewCurrency(orders, payload.calculator); // TODO:add setTimeout for mackrotask
        payload.orders = orders;

        yield put(
          calculatorsModule.actions.updateCalculators(
            calculatorsModule.constants.UPDATE_CALCULATORS,
            { items: [payload.calculator], action: 'none' },
          ), // TODO:add setTimeout for mackrotask
        );

        // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
        const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
        const orderLists = yield select(getOrdersFromSettings);

        const orderList = orderLists.find((ol) => {
          return ol.id === activeOrderListId;
        });

        orderList.orders = payload.orders;
        payload.items = orderLists;
        saveToLS('orderLists', orderLists);
      }
    } else if (actionOrdersManager === 'changeMarket') {
      payload.market = value;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.market = payload.market;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'proccessProductTable') {
      const orders = yield select(getOrdersFromOrderList);
      const calculator = yield select(getCalcultorFromManagerOrder);

      orders.forEach((order) => {
        if (order.id === orderId) {
          if (
            fieldName === 'mid' ||
            fieldName === 'spread' ||
            fieldName === 'price' ||
            fieldName === 'direction' ||
            fieldName === 'volume'
          ) {
            order[fieldName] = value;
            order = calculatePrice(calculator, fieldName, order);
          } else if (fieldName === 'recipients') {
            const recipients = [];
            value.forEach((v) => {
              let found = false;
              const index = recipients.findIndex((r) => r === v);
              if (index !== -1) {
                found = true;
              }
              if (found === false) {
                recipients.push(v);
              }
            });
            order[fieldName] = recipients;
          } else if (fieldName === 'visibility') {
            order[fieldName] = value;
            if (value === 'TRADING_PARTNERS') {
              order.recipients = [];
            }
          } else {
            order[fieldName] = value;
          }
        }
      });

      payload.orders = orders;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.orders = payload.orders;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'applySpinner') {
      const incr = 0.1 * number;

      const orders = yield select(getOrdersFromOrderList);
      const calculator = yield select(getCalcultorFromManagerOrder);

      orders.forEach((order) => {
        if (
          accessor === 'mid' ||
          accessor === 'spread' ||
          accessor === 'price' ||
          accessor === 'direction'
        ) {
          order[accessor] = parseFloat(order[accessor]) + parseFloat(incr);
          order = calculatePrice(calculator, accessor, order);
        } else {
          const increment = 1 * number;
          const { volume } = order;
          order.volume = parseInt(volume, 10) + parseInt(increment, 10);
        }
      });

      payload.orders = orders;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.orders = payload.orders;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'applyGlobals') {
      const orders = yield select(getOrdersFromOrderList);
      const globals = yield select(getGlobalsFromManagerOrder);

      if (globals[fieldName]) {
        const accessor = fieldName.toLowerCase();
        const calculator = yield select(getCalcultorFromManagerOrder);

        orders.forEach((order) => {
          if (
            accessor === 'mid' ||
            accessor === 'spread' ||
            accessor === 'price' ||
            accessor === 'volume'
          ) {
            if (!isNaN(globals[fieldName])) {
              order[accessor] = globals[fieldName];
              order = calculatePrice(calculator, accessor, order);
            }
          } else if (accessor === 'visibility') {
            order[accessor] = globals[fieldName];
            if (globals[fieldName] === 'TRADING_PARTNERS') {
              order.recipients = [];
              globals.RECIPIENTS = [];
            }
          } else if (accessor === 'direction') {
            order[accessor] = globals[fieldName];
            order = calculatePrice(calculator, accessor, order);
          } else {
            order[accessor] = globals[fieldName];
          }
        });
      }
      payload.orders = orders;
      payload.globals = globals;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.globals = payload.globals;
      orderList.orders = payload.orders;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'globalsChange') {
      const globals = yield select(getGlobalsFromManagerOrder);
      globals[fieldName] = value;

      if (fieldName === 'VISIBILITY') {
        if (value === 'SELECTED ORGANISATIONS') {
          const organisations = yield select(getOrganisations);
          globals.RECIPIENTS = [...organisations];
        } else if (value === 'TRADING PARTNERS') {
          const activeUser = yield select(getActiveUser);
          const organisationsObject = yield select(getOrganisationsObject);
          globals.RECIPIENTS = getTradingPartnersIds(activeUser, organisationsObject);
        } else {
          globals.RECIPIENTS = [];
        }
      }

      payload.globals = globals;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id === activeOrderListId;
      });

      orderList.globals = payload.globals;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'deleteOrder' && action.payload.order) {
      let orders = yield select(getOrdersFromOrderList);

      const index = orders.indexOf(action.payload.order);

      orders = [...orders];
      orders.splice(index, 1);

      payload.orders = orders;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.orders = orders;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'changeStatus') {
      const orders = yield select(getOrdersFromOrderList);
      const globals = {
        [fieldName]: active,
      };

      const accessor = fieldName === 'ANON' ? 'anonymous' : fieldName.toLowerCase();

      orders.forEach((order) => {
        order[accessor] = active;
      });

      payload.globals = globals;
      payload.orders = orders;

      // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
      const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
      const orderLists = yield select(getOrdersFromSettings);

      const orderList = orderLists.find((ol) => {
        return ol.id == activeOrderListId;
      });

      orderList.globals = payload.globals;
      orderList.orders = payload.orders;
      payload.items = orderLists;
      saveToLS('orderLists', orderLists);
    } else if (actionOrdersManager === 'changeOrderList') {
      const id = yield select(getActiveOrderListIdFromManagerOrder);

      // if (typeof openedMenu !== 'undefined') {
      //   payload.others = { openedMenu };
      // }

      if (id !== orderListId) {
        if (!payload.others) {
          payload.others = { openedMenu: false };
        } else {
          payload.others.openedMenu = false;
        }

        const orderLists = yield select(getOrdersFromSettings);

        const orderList = orderLists.find((o) => o.id === orderListId);
        if (orderList) {
          let orders = null;

          if (Array.isArray(orderList.orders)) {
            orders = JSON.parse(JSON.stringify(orderList.orders));
          } else {
            orders = [];
          }

          payload.orderListId = orderListId;
          payload.orders = orders;
          payload.market = orderList.market;
          payload.globals = orderList.globals || {};

          const calculators = yield select(getCalculatorsFromSettings);

          if (calculators.length > 0) {
            const calculator = yield select(getCalcultorFromManagerOrder);

            let calculatorIndex = -1;

            if (typeof calculator.id !== 'undefined') {
              calculatorIndex = calculators.findIndex((c) => c.id === calculator.id);
            }

            if (calculatorIndex === -1 && typeof orderList.calculatorId !== 'undefined') {
              calculatorIndex = calculators.findIndex((c) => c.id === orderList.calculatorId);
            }
            const activeWorkspace = yield select(workspaces.selectors.getActiveWorkspace);
            const { widgets } = activeWorkspace;

            const widget = widgets.find((w) => w.type === widgetTypes.ORDERMANAGER);

            if (calculatorIndex === -1) {
              if (
                isObject(widget) &&
                isObject(widget.savedState) &&
                typeof widget.savedState.calculatorId !== 'undefined'
              ) {
                calculatorIndex = calculators.findIndex(
                  (c) => c.id === widget.savedState.calculatorId,
                );
              }
            }
            if (orderList.calculatorId) {
              calculatorIndex = calculators.findIndex((c) => c.id === orderList.calculatorId);
            }

            if (calculatorIndex === -1) {
              calculatorIndex = calculators.findIndex((c) => c.active === true);
              if (calculatorIndex === -1) {
                calculatorIndex = 0;
              }
            }

            payload.calculator = JSON.parse(JSON.stringify(calculators[calculatorIndex]));

            widget.savedState = {
              orderListId: payload.orderListId,
              calculatorId: payload.calculator.id,
            };

            yield put(
              workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: widget }),
            );
          }

          // if (payload.globals.VISIBILITY === 'SELECTED_ORGANISATIONS') {
          //   const organisations = yield select(getOrganisations);
          //   payload.globals.RECIPIENTS = [...organisations];
          // } else if (payload.globals.visibility === 'TRADING_PARTNERS') {
          //   const activeUser = yield select(getActiveUser);
          //   const organisationsObject = yield select(getOrganisationsObject);
          //   payload.globals.RECIPIENTS = getTradingPartnersIds(activeUser, organisationsObject);
          // } else {

          //   payload.globals.RECIPIENTS = [];
          // }
        }
      }
    }
    const meta = {
      receivedAt: new Date(),
    };

    yield put(ordersManager(ORDERS_MANAGER_UPDATE_SUCCESS, payload, meta));
  } catch (error) {
    yield put(ordersManager(ORDERS_MANAGER_UPDATE_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
// TODO: Refactor
export function* updateEditorOrderLists(action) {
  try {
    const {
      payload: { orderListId, action: actionEditorOrderLists, show, name },
    } = action;

    const payload = {};

    if (actionEditorOrderLists === 'saveOrderLists') {
      const orderLists = yield select(getOrderListsEditorOrderLists);
      saveToLS('orderLists', orderLists);
      payload.items = orderLists;
      payload.save = true;
    } else if (actionEditorOrderLists === 'changeNameOrderList') {
      const orderLists = yield select(getOrderListsEditorOrderLists);

      const orderList = orderLists.find((ol) => ol.id === orderListId);
      orderList.name = name;

      payload.items = orderLists;
    } else if (actionEditorOrderLists === 'addOrderList') {
      const orderLists = yield select(getOrderListsEditorOrderLists);

      const index = orderLists.length + 1;

      orderLists.push(defaultOrderList(index));

      payload.items = orderLists;
    } else if (actionEditorOrderLists === 'deleteOrderList') {
      const orderLists = yield select(getOrderListsEditorOrderLists);

      let activeOrderList = false;

      if (orderLists.length > 1) {
        const newOrderLists = [];

        orderLists.forEach((ol) => {
          if (ol.id !== orderListId) {
            newOrderLists.push(ol);
          } else {
            activeOrderList = ol.active;
          }
        });

        if (activeOrderList) {
          newOrderLists[0].active = true;
        }

        payload.items = newOrderLists;
      }
    } else if (actionEditorOrderLists === 'copyOrderList') {
      const orderLists = yield select(getOrderListsEditorOrderLists);

      const orderList = orderLists.find((ol) => ol.id === orderListId);
      const newOrderList = defaultOrderList(orderLists.length);

      newOrderList.market = orderList.market;
      newOrderList.orders = JSON.parse(JSON.stringify(orderList.orders));

      newOrderList.name = `${orderList.name} (COPY)`;
      newOrderList.active = false;
      orderLists.push(newOrderList);
      payload.items = orderLists;
    } else if (actionEditorOrderLists === 'showOrderListEditor') {
      payload.visible = show;
    }

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionUpdateEditorOrderLists(UPDATE_EDITOR_ORDER_LISTS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(actionUpdateEditorOrderLists(UPDATE_EDITOR_ORDER_LISTS_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* updateOrdersManagerTable() {
  try {
    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    yield put(ordersManager(ORDERS_MANAGER_TABLE_UPDATE_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(ordersManager(ORDERS_MANAGER_TABLE_UPDATE_ERROR, { error }));
  }
}

function* addRowOrdersManagerTable() {
  try {
    let orders = yield select(getOrdersFromOrderList);

    orders = [...orders];

    let order = null;

    if (orders.length > 0) {
      order = JSON.parse(JSON.stringify(orders[orders.length - 1]));
      order.id = idGenerator();
    } else {
      order = orderRow();
    }

    orders.push(order);

    const payload = {
      orders,
    };

    const meta = {
      receivedAt: new Date(),
    };

    // TODO: Extract to method. This section for save into LocalStorage. Can add macrotask: setTimeout.
    const activeOrderListId = yield select(getActiveOrderListIdFromManagerOrder);
    const orderLists = yield select(getOrdersFromSettings);

    const orderList = orderLists.find((ol) => {
      return ol.id == activeOrderListId;
    });

    orderList.orders = orders;
    payload.items = orderLists;
    saveToLS('orderLists', orderLists);

    yield put(ordersManager(ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(ordersManager(ORDERS_MANAGER_TABLE_ADD_ROW_ERROR, { error }));
  }
}

function* sendOrdersOrdersManagerTable(action) {
  try {
    let {
      payload: { harvestWeek, harvestDate, specifyHarvestDate, newIncoTerms },
    } = action;
    const activeContract = yield select(getActiveContract);
    const market = yield select(getMarketFromManagerOrder);
    const calculator = yield select(getCalcultorFromManagerOrder);

    harvestDate = specifyHarvestDate ? harvestDate : null;
    const instrument = 'OUTRIGHT';
    const certification = 'ANY';
    const currency = calculator.calculatorState.finalCurrency;
    //const newIncoTerms = incoTerms.FCA;
    const packingStation = 'ANY';
    const quality = 'ANY';
    const region = 'ANY';
    const state = 'FRESH';

    const defaultValues = contracts.utils.getDefaultSegmentValues(activeContract, market);

    const token = yield call(auth.selectors.getToken);

    const orders = yield select(getOrdersFromOrderList);

    const createdOrders = [];

    for (let i = 0; i < orders.length; i += 1) {
      const order = orders[i];

      if (order.active) {
        const newOrder = {
          instrument,
          week: harvestWeek,
          useByDate: null,
          processingDate: null,
          harvestDate: null,
          deliveryDate: null,

          certification,
          currency,
          deliveryPoint: defaultValues.deliveryPointFob,
          incoTerms: newIncoTerms,
          location: getLocation(market),
          packingStation,
          product: getProduct(market),
          quality,
          region,
          state,
          unit: defaultValues.unit,
          weightClass: order.product,
          price: Math.round(order.price * 100) / 100,
          volume: order.volume,
          direction: order.direction,
          visibility: order.visibility,
          targetIds: [],
        };

        if (harvestDate) {
          newOrder.harvestDate = harvestDate;
        }

        const selectedOrganisationsIds = [];

        if (
          order.visibility === 'SELECTED_ORGANISATIONS' &&
          Array.isArray(order.recipients) &&
          order.recipients.length > 0
        ) {
          const organisations = yield select(getOrganisations);

          order.recipients.forEach((shortName) => {
            const org = organisations.find((item) => item.shortName === shortName);

            selectedOrganisationsIds.push(org);
          });
        }
        newOrder.selectedOrganisationsIds = selectedOrganisationsIds;

        const apiOrder = createApiOrder([newOrder]);

        const createdOrder = yield call(api.create, { token, body: apiOrder });

        createdOrders.push(createdOrder);
      }
    }

    const payload = {
      others: {
        snackbarOpen: true,
        ordersSent: createdOrders.length,
      },
      items: createdOrders,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(ordersManager(ORDERS_MANAGER_TABLE_SEND_ORDERS_SUCCESS, payload, meta));

    if (payload.items.length > 0) {
      yield put(
        changeStateOrders(CHANGE_STATE_ORDERS, { source: 'orders' }, { receivedAt: new Date() }),
      );
    }
  } catch (error) {
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(ordersManager(ORDERS_MANAGER_TABLE_SEND_ORDERS_ERROR, { error }));
    }
  }
}

function* initOrdersManager() {
  try {
    const payload = {};

    /* let orderListId = null;

    if (Object.prototype.hasOwnProperty.call(widget, 'savedState')) {
      if (Object.prototype.hasOwnProperty.call(widget.savedState, 'orderListId')) {
        orderListId = widget.savedState.orderListId;
      }
    } */

    const meta = {
      receivedAt: new Date(),
    };

    yield put(ordersManager(ORDERS_MANAGER_INIT_SUCCESS, payload, meta));
  } catch (error) {
    yield put(ordersManager(ORDERS_MANAGER_INIT_ERROR, { error }));
  }
}

export function* watchOrdersManagerInit() {
  yield takeEvery(ORDERS_MANAGER_INIT, initOrdersManager);
}

export function* watchOrdersManagerUpdate() {
  yield takeEvery(ORDERS_MANAGER_UPDATE, updateOrdersManager);
}

export function* watchOrdersManagerTableUpdate() {
  yield takeEvery(ORDERS_MANAGER_TABLE_UPDATE, updateOrdersManagerTable);
}

export function* watchOrdersManagerTableAddRow() {
  yield takeEvery(ORDERS_MANAGER_TABLE_ADD_ROW, addRowOrdersManagerTable);
}

export function* watchOrdersManagerTableSendOrders() {
  yield takeEvery(ORDERS_MANAGER_TABLE_SEND_ORDERS, sendOrdersOrdersManagerTable);
}

export function* watchUpdateEditorOrderLists() {
  yield takeEvery(UPDATE_EDITOR_ORDER_LISTS, updateEditorOrderLists);
}

function* changeStateCalculators(action) {
  try {
    const {
      payload: { source },
    } = action;

    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    if (source === 'calculators') {
      const orderManager = yield select(getOrderManager);
      const { orderListId, calculator } = orderManager;

      if (orderListId) {
        if (typeof calculator.id === 'undefined') {
          const orderLists = yield select(getOrdersFromSettings);
          const orderList = orderLists.find((o) => o.id === orderListId);

          if (orderList) {
            let orders = null;

            if (!Array.isArray(orderList.orders)) {
              orders = JSON.parse(JSON.stringify(orderList.orders));
            } else {
              orders = [];
            }

            payload.orders = orders;
            payload.market = orderList.market;
            payload.globals = orderList.globals;

            const calculators = yield select(getCalculatorsFromSettings);

            if (calculators.length > 0) {
              let calculatorIndex = -1;

              if (calculatorIndex === -1 && typeof orderList.calculatorId !== 'undefined') {
                calculatorIndex = calculators.find((c) => c.id === orderList.calculatorId);
              }

              const activeWorkspace = yield select(workspaces.selectors.getActiveWorkspace);
              const { widgets } = activeWorkspace;

              const widget = widgets.find((w) => w.type === widgetTypes.ORDERMANAGER);

              if (calculatorIndex === -1) {
                if (
                  isObject(widget) &&
                  isObject(widget.savedState) &&
                  typeof widget.savedState.calculatorId !== 'undefined'
                ) {
                  calculatorIndex = calculators.findIndex(
                    (c) => c.id === widget.savedState.calculatorId,
                  );
                }
              }

              if (calculatorIndex === -1) {
                calculatorIndex = calculators.findIndex((c) => c.active === true);
                if (calculatorIndex === -1) {
                  calculatorIndex = 0;
                }
              }

              if (calculators[calculatorIndex]) {
                payload.calculator = JSON.parse(JSON.stringify(calculators[calculatorIndex]));

                widget.savedState = {
                  orderListId,
                  calculatorId: payload.calculator.id,
                };

                yield put(
                  workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, {
                    item: widget,
                  }),
                );
              }
            }

            // TODO: DUPLICATE: EXTRACT TO SINGLE METHOD
            if (payload.globals.visibility === 'SELECTED ORGANISATIONS') {
              const organisations = yield select(getOrganisations);
              payload.globals.RECIPIENTS = [...organisations];
            } else if (payload.globals.visibility === 'TRADING PARTNERS') {
              const activeUser = yield select(getActiveUser);
              const organisationsObject = yield select(getOrganisationsObject);
              payload.globals.RECIPIENTS = getTradingPartnersIds(activeUser, organisationsObject);
            } else {
              payload.globals.RECIPIENTS = [];
            }
            yield put(ordersManager(ORDERS_MANAGER_UPDATE_SUCCESS, payload, meta));
          }
        }
      }
    }
  } catch (error) {
    yield put(ordersManager(ORDERS_MANAGER_UPDATE_ERROR, { error }));
  }
}

export function* watchChangeStateCalculators() {
  yield takeEvery(calculatorsModule.constants.CHANGE_STATE_CALCULATORS, changeStateCalculators);
}

function getLocation(segmentName) {
  const s = segmentName.split(' ');
  return s[1];
}
function getProduct(segmentName) {
  const s = segmentName.split(' ');
  return s[0];
}

function calculatePrice(calculator, changedField, order) {
  const { spread } = order;
  const { mid } = order;
  const { price } = order;
  const { direction } = order;
  const { calculatorState } = calculator;
  const quantity = order.volume;

  if (
    changedField === 'mid' ||
    changedField === 'spread' ||
    changedField === 'direction' ||
    changedField === 'volume'
  ) {
    if (direction === directions.BUY) {
      const startPrice = parseFloat(mid) - parseFloat(spread);
      order.price = calcFinalPrice(calculatorState, startPrice, quantity);
    } else {
      const startPrice = parseFloat(mid) + parseFloat(spread);
      order.price = calcFinalPrice(calculatorState, startPrice, quantity);
    }
  } else if (direction === directions.BUY) {
    const startPrice = calcFinalPrice(calculatorState, price, quantity, true);
    const orderSpread = parseFloat(mid) - startPrice;
    order.spread = orderSpread.toFixed(PRICE_DECIMALS);
  } else {
    const startPrice = calcFinalPrice(calculatorState, price, quantity, true);
    const orderSpread = startPrice - parseFloat(mid);
    order.spread = orderSpread.toFixed(PRICE_DECIMALS);
  }

  return order;
}

function updateCurrencyRate(name, value, calculator, fxrates = {}) {
  const localCurrency = name === 'localCurrency' ? value : calculator.calculatorState.localCurrency;
  const finalCurrency = name === 'finalCurrency' ? value : calculator.calculatorState.finalCurrency;

  let rates = fxrates[localCurrency] ? fxrates[localCurrency].rates : [];
  let fx = rates.find((r) => r.quoteCurrency === finalCurrency);

  let rate = fx ? fx.rate : null;

  let currencyRate;
  if (rate === undefined) {
    rates = fxrates[finalCurrency] ? fxrates[finalCurrency].rates : [];
    fx = rates.find((r) => r.quoteCurrency === localCurrency);
    rate = fx ? fx.rate : null;
    currencyRate = rate === undefined ? 1 : 1 / rate || 1;
  } else {
    currencyRate = rate === undefined ? 1 : rate || 1;
  }

  if (calculator.calculatorState.fxRateInverted) {
    currencyRate = 1 / currencyRate;
  }

  return numberFormat(currencyRate);
}

function numberFormat(number) {
  return Math.round(number * 10000) / 10000;
}

function applyNewCurrency(orders, calculator) {
  orders.forEach((order) => {
    order = calculatePrice(calculator, 'mid', order);
  });
}

// TODO: Extract method into one module and invoke this method
function getTradingPartnersIds(activeUser, organisationsObject) {
  const recipients = [];

  if (organisationsObject[activeUser.organisationId]) {
    const { tradingPartnersIds } = organisationsObject[activeUser.organisationId];

    if (Array.isArray(tradingPartnersIds) && tradingPartnersIds.length > 0) {
      tradingPartnersIds.forEach((partnerId) => {
        if (organisationsObject[partnerId]) {
          recipients.push(organisationsObject[partnerId]);
        }
      });
    }
  }

  return recipients;
}

function signOutSuccess() {
  removeFromLS('orderLists');
}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
