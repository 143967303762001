import { createAction } from '../../../commons/actions';

export const getFxrates = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const getFxrate = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const insertFxrates = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateFxrates = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const resetFxrates = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const initFxrate = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const destroyFxrate = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateFxrate = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
