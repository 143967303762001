// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION || 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID || 'eu-west-2_sHIbrW73P',
  aws_user_pools_web_client_id:
    process.env.REACT_APP_USER_POOLS_WEBCLIENT_ID || '5b84sm92d9l8kihaiucqn2qhvs',
  oauth: {},
};

export default awsmobile;
