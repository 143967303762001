import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Typography,
  Icon,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';

import { widgets } from '../../../modules/workspaces/config/widgets';

import CustomText from '../formFields/customText';

const drawerWidth = 280;
const menuItems = {
  widgets,
};
const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  img: {
    width: '48%',
    padding: '20px',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  workspaceTitle: {
    color: theme.palette.text.faded_75,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    color: theme.palette.text.faded_50,
    margin: '10px 15px 0px 15px',
  },
  headerTitleIcon: {
    color: theme.palette.icon,
    opacity: 0.5,
    margin: '10px 15px 0px 15px',
  },

  item: {
    opacity: 1,
  },
  icon: {
    color: theme.palette.icon,
  },
  divider: {
    backgroundColor: theme.palette.widgetBorder,
  },
});

const DrawerHeader = (props) => {
  const { classes } = props;
  return (
    <>
      <div className={classes.toolbar}>
        <div style={{ textAlign: 'center' }} />
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    </>
  );
};

const SectionHeader = (props) => {
  const { classes } = props;
  return (
    <>
      <div
        className={classes.header}
        style={{
          display: props.open ? 'flex' : 'none',
        }}
      >
        <Typography className={classes.headerTitle}>
          <CustomText value={props.title} />
        </Typography>
        <Icon className={classes.headerTitleIcon}>settings</Icon>
      </div>
    </>
  );
};

const SectionListItem = (props) => {
  const { classes } = props;
  return (
    <React.Fragment key={props.id}>
      <ListItem button key={props.id} onClick={props.onClick}>
        <Tooltip title={<CustomText value={props.title} />}>
          <ListItemIcon className={classes.icon}>{props.icon}</ListItemIcon>
        </Tooltip>

        <ListItemText className={classes.item}>
          <CustomText value={props.title} />
        </ListItemText>
      </ListItem>
    </React.Fragment>
  );
};

const MenuDrawer = (props) => {
  const { classes } = props;

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
      open={props.open}
    >
      <DrawerHeader {...props} />
      <Divider className={classes.divider} />

      <SectionHeader title={'windows'} {...props} />

      <List>
        {menuItems.widgets.map((item, index) => {
          return (
            <React.Fragment key={item.type}>
              <SectionListItem
                id={item.type}
                onClick={() => props.addLayout(item.type)}
                title={item.name}
                icon={item.icon}
                {...props}
              />
            </React.Fragment>
          );
        })}
      </List>
      <Divider className={classes.divider} />
      <SectionHeader title={'workspaces'} {...props} />

      <List>
        <SectionListItem
          id="default-workspace"
          onClick={() => props.addDefaultWorkspace(0)}
          title={'defaultWorkspace1'}
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />

        <SectionListItem
          id="default-workspace"
          onClick={() => props.addDefaultWorkspace(1)}
          title={'defaultWorkspace2'}
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />

        <SectionListItem
          id="default-workspace"
          onClick={() => props.addDefaultWorkspace(2)}
          title={'defaultWorkspace3'}
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />

        <SectionListItem
          id="default-workspace"
          onClick={() => props.addDefaultWorkspace(3)}
          title={'defaultWorkspace4'}
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />

        <SectionListItem
          id="default-workspace"
          onClick={() => props.addDefaultWorkspace(4)}
          title={'defaultWorkspace5'}
          icon={<Icon className={classes.icon}>view_quilt</Icon>}
          {...props}
        />

        <SectionListItem
          id="clear-workspace"
          onClick={props.clearLayout}
          title={'clearWorkspace'}
          icon={<ViewQuiltOutlinedIcon className={classes.icon} />}
          {...props}
        />

        <SectionListItem
          id="manage-workspace"
          onClick={() => props.showManageWorkspaces({ action: 'showManage' })}
          title={'manageWorkspaces'}
          icon={<Icon className={classes.icon}>sort</Icon>}
          {...props}
        />

        <SectionListItem
          id="palette-workspace"
          onClick={props.togglePalette}
          title={'changePalette'}
          icon={<Icon className={classes.icon}>color_lens</Icon>}
          {...props}
        />
      </List>

      <Divider className={classes.divider} />
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MenuDrawer);
