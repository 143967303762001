export default class RFQContract {
  constructor() {
    this.schemaContract = {
      deliveryDate: {
        from: null,
        to: null,
      },
      harvestDate: {
        from: null,
        to: null,
      },
      freezeDate: {
        from: null,
        to: null,
      },
      instrument: null,
      week: null,
      underlying: {
        certification: {
          name: 'certifications',
        },
        currency: null,
        deliveryPoints: null,
        incoTerms: null,
        location: {
          name: 'locations',
        },
        packingStation: {
          name: 'packingStations',
        },
        product: null,
        // quality: null,
        region: {
          name: 'regions',
        },
        quality: {
          name: 'quality',
        },
        colorFan: {
          name: 'colorFan',
        },
        state: {
          name: 'states',
        },
        unit: null,
        weightClass: {
          name: 'weightClasses',
        },
      },
    };
  }

  build(order) {
    const underlying = {};
    const { underlying: schemaUnderlying } = this.schemaContract;

    Object.keys(schemaUnderlying).forEach((key) => {
      if (key === 'deliveryPoints') {
        let deliveryPoints = [];

        if (order.incoTerms === 'FCA') {
          deliveryPoints = order.deliveryPointFob;
        } else {
          deliveryPoints = order.deliveryPointDdp;
        }

        underlying.deliveryPoints = deliveryPoints;
      } else if (key === 'states'){
        underlying.states = [order.state]

      }else if (typeof order[key] !== 'undefined' && order[key] !== null) {
        let name = key;

        if (schemaUnderlying[key] && schemaUnderlying[key].name) {
          name = schemaUnderlying[key].name;
        }

        underlying[name] = order[key];
      }
    });

    const newContract = { underlying };

    if (typeof order.week !== 'undefined') {
      newContract.week = order.week;
    }

    if (typeof order.instrument !== 'undefined') {
      newContract.instrument = order.instrument;
    }

    newContract.deliveryDate = {
      from: order.minDeliveryDate,
      to: order.maxDeliveryDate,
    };

    newContract.harvestDate = {
      from: order.minHarvestDate,
      to: order.maxHarvestDate,
    };

    if (newContract.underlying.states.includes('FROZEN') || newContract.underlying.states.includes('ANY')) {
  
      newContract.freezeDate = {
        from: order.minFreezeDate,
        to: order.maxFreezeDate,
      };
    }else{
      newContract.freezeDate = {
        from: null,
        to: null,
      };
    }
 
 ;

    return newContract;
  }
}
