import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CancelButton } from '../../../commons/components/buttons';
import * as constants from '../constants';
import { isModalChatOpen, modalChatTrade, getActiveUser, getChats } from '../selectors';

import CustomText from '../../../commons/components/formFields/customText';
import { createAction } from '../../../commons/actions';
import ActiveMessaging from './ActiveMessaging';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    maxWidth: '1000px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    //overflowY: 'auto',
  },
  title: { fontSize: theme.fontSize.lg },
});

const getRoom = (rooms, rfq, user) => {
  let room = {};
  if (rfq) {
    const counterpartyOrgId =
      rfq.createdByUserId === user.id ? rfq.selectedOrganisationsIds[0] : rfq.brokerOrganisationId;

    room = rooms.find((item) => {
      return item.rfqId === rfq.id && item.organisationIds.includes(counterpartyOrgId);
    });
  }

  return room || {};
};

const ModalChat = (props) => {
  const { classes, open, trade, user, rooms } = props;
  const rfqId = useMemo(() => trade.rfqId, [trade]);

  const rfqs = useSelector((state) => state.rfqs.byId);
  const rfq = useMemo(() => rfqs[rfqId], [rfqs, rfqId]);
  const room = useMemo(() => getRoom(rooms, rfq, user));

  useEffect(() => {
    if (room) {
      const payload = {
        action: 'markAsRead',
        items: [{ id: room.id }],
      };
      props.markAsRead(payload);
    }
  });
  return (
    <Modal open={open} onClose={props.onClose}>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>
          <CustomText value="chat" upperCase />
        </Typography>
        <div
          style={{ display: 'flex', flex: 2, flexDirection: 'column', minWidth: 0, minHeight: 0 }}
        >
          <ActiveMessaging selectedChatId={room.id} isChatVisible={true} />
        </div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    open: isModalChatOpen(state),
    trade: modalChatTrade(state),
    user: getActiveUser(state),
    rooms: getChats(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(createAction(constants.MODAL_CHAT_OPEN, { open: false }));
    },
    markAsRead: (payload) => {
      dispatch(createAction(constants.CHATS_UPDATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalChat));
