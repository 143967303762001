export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getUserObject = (order) => {
  let userName = '';
  let organisation = '';
  let organisationShortName = '';

  if (order !== undefined) {
    userName = order.user ? order.user.fullName : 'ANON';
    organisation = order.organisation ? order.organisation.name : 'ANON';
    organisationShortName = order.organisation ? order.organisation.shortName : 'ANON';
  }

  return {
    userName,
    organisationName: organisation,
    organisationShortName,
  };
};

export const getUserId = (message) => {
  let userId = '';

  if (message !== undefined) {
    if (message.hasOwnProperty('userId')) {
      if (message.userId !== undefined && message.userId !== null) {
        if (message.userId.hasOwnProperty('id')) {
          userId = message.userId.id;
        } else {
          userId = message.userId;
        }
      }
    } else if (message.hasOwnProperty('user')) {
      userId = message.user.id;
    }
  }

  return userId;
};

export const getOrganisationId = (message) => {
  let orgId = '';

  if (message.hasOwnProperty('organisation')) {
    orgId = message.organisation.id;
  } else if (message.hasOwnProperty('user')) {
    if (message.user.hasOwnProperty('organisation')) {
      if (typeof message.user.organisation === 'string') {
        orgId = message.user.organisation;
      } else if (typeof message.user.organisation === 'object') {
        orgId = message.user.organisation.id;
      }
    }
  }
  return orgId;
};

export const getTimestamp = () => {
  return new Date().getTime().toString();
};

export const getOrganisationShortName = (message) => {
  let shortName = '';

  if (message !== undefined) {
    if (message.hasOwnProperty('organisation')) {
      if (message.organisation.hasOwnProperty('shortName')) {
        shortName = message.organisation.shortName;
      }
    }
  }

  return shortName;
};

export const getOrganisationShortNameFromId = (id, organisations) => {
  let shortName = 'Anon';
  const index = organisations.findIndex((c) => c.id === id);

  shortName = index !== -1 ? organisations[index].shortName : 'Anon';
  return shortName;
};

export const getOrganisationObjectFromId = (id, organisations) => {
  const index = organisations.findIndex((c) => c.id === id);

  const obj = index !== -1 ? organisations[index] : {};
  return obj;
};

export const createId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const idGenerator = () => {
  return (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
};

export const createUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
