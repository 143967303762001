import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import SelectField from '../../../commons/components/formFields/SelectField';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';
import CollapseContainer from '../../../commons/components/collapseContainer';
import DateField from '../../../commons/components/formFields/dateField';

import { directions, status } from '../../../commons/models/constants';
import { formatDateTime } from '../../../commons/config/formatters';

import UserAvatar from '../../../commons/components/userAvatar';
import TotalsRow from './TotalsRow';
//import SelectField from '../../../commons/components/formFields/selectField';

import { getAll, getOrganisationsObject, getMyOrganisation } from '../selectors';
import * as actionTypes from '../constants';
import { createAction } from '../../../commons/actions';
import usersModule from '../../users';
import TradeListRow from './TradeListRow';
import contract from '../../contracts/models/orderUnderlying';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
  },
  listTabContainer: {
    overflowY: 'scroll',
    flex: 1,
    padding: theme.spacing(),
  },

  timeOfUpdateContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  timeOfUpdateText: {
    color: theme.palette.text.primary,
    fontSize: theme.fontSize.sm,
    paddingHorizontal: 4,
  },
});

const TradeList = (props) => {
  const { classes } = props;
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const theme = useSelector((state) => state.themes.theme);
  const [counterparty, setCounterparty] = useState('');
  const [species, setSpecies] = useState([]);
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));
  const [startDate, setStartDate] = useState(moment().add(-5, 'days'));

  const getCounterparty = (trade) => {
    const counterpartyId =
      trade.buyBrokerOrganisationId === props.myOrganisation.id ||
      trade.buyBeneficiaryOrganisationId === props.myOrganisation.id
        ? trade.sellBrokerOrganisationId
        : trade.buyBrokerOrganisationId;
    return props.organisations[counterpartyId] || {};
  };
  const getCounterpartyShortName = (trade) => {
    const counterparty = getCounterparty(trade);
    return counterparty.shortName || null;
  };
  const getDirection = (trade) => {
    const direction =
      trade.buyBrokerOrganisationId === props.myOrganisation.id ||
      trade.buyBeneficiaryOrganisationId === props.myOrganisation.id
        ? directions.BUY
        : directions.SELL;
    return direction;
  };
  const filterTradeList = (trades) => {
    const filteredList = [...trades]

      .filter((item) => {
        if (counterparty === '' || counterparty === undefined || counterparty === null) {
          return true;
        } else {
          const counterpartyShortName = getCounterpartyShortName(item);
          return counterpartyShortName === counterparty;
        }
      })
      .filter((item) => {
        if (!species) {
          return true;
        }
        if (Array.isArray(species)) {
          if (species.length === 0) {
            return true;
          } else {
            return species.some((value) => value === item.assets[0].contract.underlying.species);
          }
        } else {
          return item.assets[0].contract.underlying.species === species;
        }
      })
      .filter((item) => {
        if (startDate === null || startDate === undefined) {
          return true;
        } else {
          const timeOfEntry = moment(item.updatedTime);
          const tempstartDate = moment(startDate).startOf('day');

          return timeOfEntry > tempstartDate;
        }
      })
      .filter((item) => {
        if (endDate === null || endDate === undefined) {
          return true;
        } else {
          const timeOfEntry = moment(item.updatedTime);
          const tempendDate = moment(endDate).endOf('day');

          return timeOfEntry < tempendDate;
        }
      });

    filteredList.sort((a, b) => {
      a = moment(a.updatedTime);
      b = moment(b.updatedTime);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    return filteredList;
  };

  const allcounterpartys = (trades) => {
    const list = [''];
    trades.forEach((trade) => {
      const counterpartyShortName = getCounterpartyShortName(trade);
      const i = list.findIndex((l) => l === counterpartyShortName);
      if (i < 0) {
        list.push(counterpartyShortName);
      }
    });
    list.sort();

    return list;
  };

  const getColors = (totals, theme) => {
    const direction = totals.direction || 'SELL';
    let borderColor =
      direction === directions.SELL ? theme.color.green.primary : theme.color.lightBlue.primary;
    let tileBackground =
      direction === directions.SELL ? theme.color.green.dark : theme.color.lightBlue.dark;
    let toolbarBackground =
      direction === directions.SELL ? theme.color.green.primary : theme.color.lightBlue.primary;
    let timestampBackground = 'transparent';
    let priceColor =
      direction === directions.SELL ? theme.color.green.light : theme.color.lightBlue.light;
    let volumeColor =
      direction === directions.SELL ? theme.color.green.light : theme.color.lightBlue.light;
    if (totals.status === status.SUSPENDED || totals.cancellationStatus === 'REQUESTED') {
      borderColor = theme.color.amber.primary;
      tileBackground = theme.color.amber.dark;
      toolbarBackground = theme.color.amber.primary;
      timestampBackground = theme.color.amber.primary;
      priceColor = theme.color.amber.light;
      volumeColor = theme.color.amber.light;
    }
    if (totals.status === status.CANCELLED) {
      borderColor = theme.color.red.primary;
      tileBackground = theme.color.red.primary;
      toolbarBackground = theme.color.red.primary;
      timestampBackground = theme.color.red.primary;
      priceColor = theme.color.red.light;
      volumeColor = theme.color.red.light;
    }
    if (totals.status === status.FILLED) {
      borderColor = theme.color.border.primary;
      tileBackground = 'transparent';
      toolbarBackground = 'transparent';
      timestampBackground = 'transparent';
      priceColor = theme.color.text.primary;
      volumeColor = theme.color.text.primary;
    }

    return {
      borderColor,
      tileBackground,
      toolbarBackground,
      timestampBackground,
      priceColor,
      volumeColor,
    };
  };

  const trades = useMemo(
    () => filterTradeList(props.trades),
    [props.trades, endDate, startDate, counterparty, species],
  );

  const counterpartys = useMemo(() => allcounterpartys(props.trades), [props.trades]);
  const allSpecies = Object.keys(contract.fields.species.enum);
  const onTradePress = (id) => {
    console.log('onTradePress');
    props.onTradeClick({ open: true, tradeIds: [id] });
  };

  return (
    <div className={`${classes.container} undraggable`}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: '4px 4px 0px' }}>
        <DateField
          accessor="startDate"
          displayName={'startDate'}
          value={startDate}
          disablePast={false}
          handleDateChange={(name) => (date) => {
            setStartDate(date);
          }}
          //style={{ marginLeft: 0, marginRight: 0 }}
          fullWidth
        />
        <DateField
          accessor="endDate"
          displayName={'endDate'}
          value={endDate}
          disablePast={false}
          handleDateChange={(name) => (date) => {
            setEndDate(date);
          }}
          // style={{ marginLeft: 0, marginRight: 0 }}
          fullWidth
          clearable
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 4px' }}>
        <SelectField
          accessor="counterparty"
          displayName={'counterpartyName'}
          value={counterparty}
          values={counterpartys}
          handleChange={(name) => (e) => setCounterparty(e.target.value)}
          width={null}
          //style={{ marginLeft: 0, marginRight: 0 }}
          fullWidth
        />
        <MultipleSelectFieldFilled
          accessor="species"
          displayName="species"
          disableUnderline={true}
          value={species}
          values={allSpecies}
          handleChange={(name) => (e) => {
            setSpecies(e.target.value);
          }}
          //style={{ marginLeft: 0, marginRight: 0 }}
          fullWidth
        />
      </div>
      <div className={classes.listTabContainer}>
        {trades.map((trade, index) => {
          const counterparty = getCounterparty(trade);

          const id = counterparty.id;
          const totals = {
            direction: getDirection(trade),
            status: trade.status,
            cancellationStatus: trade.cancellationStatus,
          };
          const colors = getColors(totals, theme);

          const timeStamp = trade.updatedTime ? formatDateTime(trade.updatedTime) : '';

          return (
            <TradeListRow
              key={index}
              index={index}
              onTradePress={() => onTradePress(trade.id)}
              organisationId={id}
              myOrganisationId={props.myOrganisation.id}
              trade={trade}
              colors={colors}
            ></TradeListRow>
          );
        })}
      </div>
      {/*    <CollapseContainer
        onClick={() => setFiltersExpanded(!filtersExpanded)}
        expanded={filtersExpanded}
        title="filters"
        noFlex
      >
        <div>
        
        </div>
      </CollapseContainer> */}
    </div>
  );
};

TradeList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    users: usersModule.selectors.getAllObject(state),
    organisations: getOrganisationsObject(state),
    trades: getAll(state),
    myOrganisation: getMyOrganisation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTradeClick: (payload) => {
      dispatch(createAction(actionTypes.TRADE_DETAILS_OPEN, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeList));
