export const NAME = 'admin';

export const GET_ADMINS = 'admins/get';
export const GET_ADMINS_SUCCESS = 'admins/get/success';
export const GET_ADMINS_ERROR = 'admins/get/error';

export const RESET_ADMINS = 'admins/reset';

export const GET_ADMIN = 'admin/get';
export const GET_ADMIN_SUCCESS = 'admin/get/success';
export const GET_ADMIN_ERROR = 'admin/get/error';

export const INIT_ADMIN = 'admin/init';
export const INIT_ADMIN_SUCCESS = 'admin/init/success';
export const INIT_ADMIN_ERROR = 'admin/init/error';

export const DESTROY_ADMIN = 'admin/destroy';
export const DESTROY_ADMIN_SUCCESS = 'admin/destroy/success';
export const DESTROY_ADMIN_ERROR = 'admin/destroy/error';

export const UPDATE_ADMIN = 'admin/update';
export const UPDATE_ADMIN_SUCCESS = 'admin/update/success';
export const UPDATE_ADMIN_ERROR = 'admin/update/error';

export const CREATE_ADMIN_ITEM = 'admin/create/item';
export const CREATE_ADMIN_ITEM_SUCCESS = 'admin/create/item/success';
export const CREATE_ADMIN_ITEM_ERROR = 'admin/create/item/error';

export const UPDATE_ADMIN_ITEM = 'admin/update/item';
export const UPDATE_ADMIN_ITEM_SUCCESS = 'admin/update/item/success';
export const UPDATE_ADMIN_ITEM_ERROR = 'admin/update/item/error';

export const CREATE_FIELD = 'admin/create/field';
export const CREATE_FIELD_SUCCESS = 'admin/create/field/success';
export const CREATE_FIELD_ERROR = 'admin/create/field/error';

export const GET_BROKERS = 'admin/get/brokers';
export const GET_BROKERS_SUCCESS = 'admin/get/brokers/success';
export const GET_BROKERS_ERROR = 'admin/get/brokers/error';

export const CREATE_BROKERS = 'admin/create/brokers';
export const CREATE_BROKERS_SUCCESS = 'admin/create/brokers/success';
export const CREATE_BROKERS_ERROR = 'admin/create/brokers/error';

export const UPDATE_BROKERS = 'admin/update/brokers';
export const UPDATE_BROKERS_SUCCESS = 'admin/update/brokers/success';
export const UPDATE_BROKERS_ERROR = 'admin/update/brokers/error';
