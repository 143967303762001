import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, MenuItem, FormControl } from '@material-ui/core';
import { textField, fieldFontSize } from './formFieldStyles';
import clsx from 'clsx';
import { formatDateTime } from '../../config/formatters';

const styles = (theme) => ({
  text1: textField(theme),
  textFont: fieldFontSize(theme),
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  menu: {
    maxHeight: '400px',
  },
  fileDate: { color: theme.palette.text.secondary },
});

const SelectFieldFiles = (props) => {
  const { classes, accessor, displayName, allFieldValues = {} } = props;
  const disabled = props.disabled === true;

  let { values } = props;

  const inputProps =
    disabled === true
      ? {}
      : props.addUnderline
      ? { disableUnderline: false }
      : { disableUnderline: true };

  const _displayName = allFieldValues[accessor]
    ? allFieldValues[accessor].displayName
    : displayName;
  return (
    <FormControl
      className={props.noMargin ? null : classes.text1}
      fullWidth={props.fullWidth}
      style={{ ...props.style }}
    >
      <TextField
        id={accessor}
        key={accessor}
        select
        disabled={disabled}
        label={_displayName}
        fullWidth={props.fullWidth}
        classes={{ root: classes.root }}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        //value={value}
        renderValue={(value) => `${value.filename}`}
        variant="filled"
        error={props.error ? props.error.value : false}
        helperText={props.error ? props.error.message : ''}
        onChange={props.handleChange(accessor)}
        SelectProps={{
          MenuProps: {
            className: `${classes.menu} undraggable`,
          },
        }}
        InputProps={{
          ...inputProps,
          className: clsx(classes.textFont, props.dark && classes.darkBackground),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
      >
        {values
          .sort((a, b) => {
            if (a.updatedTime < b.updatedTime) return 1;
            if (a.updatedTime > b.updatedTime) return -1;
            return 0;
          })
          .map((option, index) => {
            const fileDate = option.updatedTime ? formatDateTime(option.updatedTime) : '';
            if (index > 0) return null;
            return (
              <MenuItem key={option.id} value={option}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>{option.filename}</div>
                  <div className={classes.fileDate}>{fileDate}</div>
                </div>
              </MenuItem>
            );
          })}
      </TextField>
    </FormControl>
  );
};

SelectFieldFiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectFieldFiles);
