import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Icon, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomText from './customText';

const styles = (theme) => ({});
const AutocompleteField = (props) => {
  const { options, getOptionLabel, label, accessor } = props;
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      style={{ width: 256, margin: '2px' }}
      renderInput={(params) => (
        <TextField {...params} label={<CustomText value={label} />} variant="filled" size="small" />
      )}
      onChange={props.handleChange(accessor)}
      onInputChange={props.handleInputChange(accessor)}
    />
  );
};

AutocompleteField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteField);
