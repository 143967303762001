import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.chatsUrl = `/chats`;
    this.messagesUrl = `/messages`;

    this.getAllChats = this.getAllChats.bind(this);
    this.createChat = this.createChat.bind(this);
    this.updateChat = this.updateChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.getMessages = this.getMessages.bind(this);
  }

  async getAllChats(data = {}) {
    const url = `${this.chatsUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async createChat({ token, body }) {
    const url = `${this.chatsUrl}`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, {}, options);
  }

  async updateChat(data = {}) {
    const url = `${this.chatsUrl}/${encodeURIComponent(data.body.chatId)}`;

    const params = data.params || {};

    const { chatId, ...body } = data.body;

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async getMessages({ token, chatId, createdTimeFrom, createdTimeTo }) {
    const url = `${this.chatsUrl}/${chatId}/messages`;
    const params = {
      limit: 100,
    };

    if (createdTimeFrom) {
      params.createdTimeFrom = createdTimeFrom;
    }

    if (createdTimeTo) {
      params.createdTimeTo = createdTimeTo;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return coreApi.getResponse(url, params, options);
  }

  async sendMessage({ token, body, chatId }) {
    const url = `${this.chatsUrl}/${chatId}/messages`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, {}, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
