import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton } from '../../../commons/components/buttons';
import CustomText from '../../../commons/components/formFields/customText';
import { tradingPartnersListOpen } from '../actions';
import { TRADING_PARTNERS_LIST_OPEN } from '../constants';
import {
  isTradingPartnersListOpen,
  getTradingPartners,
  getOrganisationsObject,
  getUsers,
  getMyOrganisation,
} from '../selectors';

import OrganisationProfile from '../../admin/components/OrganisationProfile';
import UserAvatar from '../../../commons/components/userAvatar';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
  },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  orgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    padding: '8px',
  },
  sectionHeader: {
    fontWeight: 'bold',
    color: theme.palette.text.subtitle,
  },
  sectionBody: {
    padding: '8px',
  },
  companyImage: {
    maxHeight: '200px',
    objectFit: 'cover',
  },
  chip: {
    margin: '2px',
  },
});

class TradingPartnersList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      org: null,
    };
  }

  handleCloseOrg = () => {
    this.setState({ org: null });
  };

  handleOpenOrg = (org) => {
    this.setState({ org });
  };

  render() {
    const { classes, tradingPartners, users, organisationsObject, myOrganisation } = this.props;

    const containerWidth = this.state.org ? 940 : 640;

    return (
      <PopUp
        open={this.props.open}
        title={<CustomText value="tradingPartners" upperCase />}
        onClose={this.props.onClose}
        containerWidth={containerWidth}
      >
        <React.Fragment>
          {this.state.org !== null && (
            <React.Fragment>
              <OrganisationProfile
                organisation={this.state.org}
                organisationsObject={organisationsObject}
                editOrganisation={false}
                key={this.state.org ? this.state.org.id : 1}
                isSuperAdmin={false}
                isOrganisationAdmin={false}
                users={users}
                myOrganisation={myOrganisation}
                expanded={true}
              />{' '}
              <div className={classes.toolbarContainer}>
                <ToolbarBottom>
                  <>
                    <CancelButton title={'return'} onClick={this.handleCloseOrg} />
                  </>
                </ToolbarBottom>
              </div>
            </React.Fragment>
          )}
          {this.state.org === null && (
            <div className={classes.container}>
              <div className={classes.listContainer}>
                <List open classes={{ root: classes.list }}>
                  {tradingPartners.map((org) => (
                    <ListItem
                      key={org.id}
                      button
                      classes={{ root: classes.listItem }}
                      onClick={() => this.handleOpenOrg(org)}
                    >
                      <ListItemIcon>
                        <UserAvatar organisationId={org.id} />
                      </ListItemIcon>
                      <ListItemText primary={org.name} />
                      <ListItemText primary={org.shortName} align="right" />
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className={classes.toolbarContainer}>
                <ToolbarBottom>
                  <CancelButton title={'cancel'} onClick={this.props.onClose} />
                </ToolbarBottom>
              </div>
            </div>
          )}
        </React.Fragment>
      </PopUp>
    );
  }
}
TradingPartnersList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isTradingPartnersListOpen(state),
    tradingPartners: getTradingPartners(state),
    users: getUsers(state),
    myOrganisation: getMyOrganisation(state),
    organisationsObject: getOrganisationsObject(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(tradingPartnersListOpen(TRADING_PARTNERS_LIST_OPEN, { open: false }));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TradingPartnersList));
