import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import moment from 'moment';

const styles = (theme) => ({
  flexCenter: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
  },

  item: {
    minWidth: '200px',
    maxWidth: '70%',
    position: 'relative',
    padding: '10px',
    color: theme.palette.text.disabled,
    borderRadius: '20px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    backgroundColor: theme.palette.tableBorder,
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
  },
});

const getDateValue = (day) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
  if (day === today) {
    return 'TODAY';
  }
  if (day === yesterday) {
    return 'YESTERDAY';
  }
  return moment(day, 'YYYY-MM-DD').format('dddd, MMM Do YYYY');
};

const DayHeader = ({ classes, day }) => {
  return (
    <Grid container className={classes.flexCenter}>
      <Grid item className={classes.item}>
        <div>{getDateValue(day)}</div>
      </Grid>
    </Grid>
  );
};

DayHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  day: PropTypes.string.isRequired,
};
export default withStyles(styles)(DayHeader);
