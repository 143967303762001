import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, MenuItem, FormControl } from '@material-ui/core';
import { textField, fieldFontSize } from './formFieldStyles';
import clsx from 'clsx';
import CustomText from './customText';

const styles = (theme) => ({
  text1: textField(theme),
  textFont: fieldFontSize(theme),
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
  menu: {
    maxHeight: '400px',
  },
});

const SelectFieldOrgs = (props) => {
  const { classes, accessor, displayName, value } = props;
  const disabled = props.disabled === true;
  const _organisations = useSelector((state) => state.organisations.byId);
  let { values } = props || [];

  const inputProps =
    disabled === true
      ? {}
      : props.addUnderline
      ? { disableUnderline: false }
      : { disableUnderline: true };

  const _displayName = displayName ? displayName : accessor;

  return (
    <FormControl
      className={props.noMargin ? null : classes.text1}
      fullWidth={props.fullWidth}
      style={{ ...props.style }}
    >
      <TextField
        id={accessor}
        key={accessor}
        select
        disabled={disabled}
        label={<CustomText value={_displayName} />}
        fullWidth={props.fullWidth}
        classes={{ root: classes.root }}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={value}
        renderValue={(selected) => <div>{_organisations ? _organisations[value].name : ''}</div>}
        variant="filled"
        error={props.error ? props.error.value : false}
        helperText={props.error ? props.error.message : ''}
        onChange={props.handleChange(accessor)}
        SelectProps={{
          MenuProps: {
            className: `${classes.menu} undraggable`,
          },
        }}
        InputProps={{
          ...inputProps,
          className: clsx(
            classes.textFont,
            props.dark && classes.darkBackground,
            props.warning && classes.warningBackground,
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
      >
        {values.map((option, index) => {
          let object = _organisations[option];
          let optionValue = `${object.name} | ${object.shortName}`;

          return (
            <MenuItem key={index} value={option}>
              {optionValue}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

SelectFieldOrgs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectFieldOrgs);
