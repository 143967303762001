import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  IconButton,
  Icon,
  Button,
  TextField,
} from '@material-ui/core';

import PopUp from '../../../commons/components/popUp';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton, ConfirmButton, ClearButton } from '../../../commons/components/buttons';
import CustomText from '../../../commons/components/formFields/customText';
import { UPDATE_MANAGE_WORKSPACES } from '../constants';

import { updateManageWorkspaces as actionUpdateManageWorkspaces } from '../actions';
import { getManageWorkspaces } from '../selectors';
import schemas from '../../schemas';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: { backgroundColor: theme.palette.background.primary },
  containerItems: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.primary,
  },

  textField: { marginTop: theme.spacing(), marginBottom: theme.spacing() },
  fab: { marginTop: theme.spacing(), marginBottom: theme.spacing() },
  nameChange: {
    display: 'block',
    padding: '0px 25px',
  },
  nameChangeInput: { display: 'block' },
  listItem: {
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
  },
});

class ManageWorkspace extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      showText: -1,
    };
  }

  handleToggle = (id) => () => {
    const payload = {
      action: 'toogleWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  addWorkspace = () => {
    const payload = {
      action: 'addWorkspace',
    };

    this.props.updateManageWorkspaces(payload);
  };

  deleteWorkspace = (id) => {
    const payload = {
      action: 'deleteWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  onCopy = (e, id) => {
    const payload = {
      action: 'copyWorkspace',
      id,
    };

    this.props.updateManageWorkspaces(payload);
  };

  onSave = () => {
    const payload = {
      action: 'saveWorkspaces',
    };

    this.props.updateManageWorkspaces(payload);
  };

  onEditClick = (id) => {
    if (this.state.showText === id) {
      this.setState({ showText: -1 });
    } else {
      this.setState({ showText: id });
    }
  };

  onNameChange = (e, id) => {
    const payload = {
      action: 'changeName',
      id,
      value: e.target.value,
    };

    this.props.updateManageWorkspaces(payload);
  };

  close = () => {
    const payload = {
      action: 'closeManage',
    };

    this.props.updateManageWorkspaces(payload);
  };

  render() {
    const { classes, manageWorkspaces } = this.props;
    const { open, items: workspaces } = manageWorkspaces;

    return (
      <PopUp open={open} title={<CustomText value="workspaces" upperCase />}>
        <React.Fragment>
          <div className={classes.container}>
            <div className={classes.containerItems}>
              <List className={classes.root}>
                {workspaces.map((value) => (
                  <div key={`${value.id}_w`} className={classes.listItem}>
                    <ListItem
                      key={value.id}
                      role={undefined}
                      //
                      button
                      onClick={this.handleToggle(value.id)}
                    >
                      <Checkbox checked={value.active} tabIndex={-1} disableRipple />
                      <ListItemText primary={value.name} />

                      <ListItemSecondaryAction>
                        <Tooltip
                          title={<CustomText value="copy" />}
                          aria-label={<CustomText value="copy" />}
                        >
                          <IconButton onClick={(e) => this.onCopy(e, value.id)}>
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={<CustomText value="rename" />}
                          aria-label={<CustomText value="rename" />}
                        >
                          <IconButton onClick={() => this.onEditClick(value.id)}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={<CustomText value="delete" />}
                          aria-label={<CustomText value="delete" />}
                        >
                          <IconButton onClick={() => this.deleteWorkspace(value.id)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {this.state.showText === value.id && (
                      <div>
                        <TextField
                          classes={{ root: classes.nameChange }}
                          InputProps={{ className: classes.nameChangeInput }}
                          onChange={(e) => this.onNameChange(e, value.id)}
                          placeholder={<CustomText value="addNewName" />}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </List>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ClearButton
              title={'addNewWorkspace'}
              onClick={this.addWorkspace}
              icon="add"
              style={{ width: '100%', justifyContent: 'center' }}
            ></ClearButton>
          </div>

          <ToolbarBottom>
            <React.Fragment>
              <CancelButton title={'cancel'} onClick={this.close} />
              <ConfirmButton title={'apply'} onClick={this.onSave} />
            </React.Fragment>
          </ToolbarBottom>
        </React.Fragment>
      </PopUp>
    );
  }
}

ManageWorkspace.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

const mapStateToProps = (state) => {
  return {
    manageWorkspaces: getManageWorkspaces(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateManageWorkspaces: (payload) => {
      dispatch(actionUpdateManageWorkspaces(UPDATE_MANAGE_WORKSPACES, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageWorkspace));
