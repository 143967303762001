import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography, ListItemText, List, ListItem } from '@material-ui/core';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';
import DetailsRow from './DetailsRow';
import DetailsAssetsRow from './DetailsAssetsRow';
import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton } from '../../../commons/components/buttons';
import { getKeyValuePairsFromRfq, getFieldsArray } from '../../contracts/utils/modelFunctions';

import { getOrganisations, getActiveUser, getUsersObject } from '../selectors';
import { visibilities } from '../../../commons/models/constants';

import CustomText from '../../../commons/components/formFields/customText';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    flex: 1,
    backgroundColor: theme.palette.background.primary,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
  },

  section: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionTitleContainer: {
    padding: '0px 16px 0px',
  },
  sectionTitle: {
    display: 'flex',
    color: theme.palette.text.subtitle,
    paddingLeft: theme.spacing(1),
    flex: 1,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  sectionContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 0px 16px 0px',
  },
  sectionBody: {
    display: 'flex',
    padding: theme.spacing(),
    //backgroundColor: 'rgba(0,0,0,.1)',
    flex: 1,
  },
  title: { fontSize: theme.fontSize.lg },
  orderRowsContainer: {
    padding: theme.spacing(),
    paddingTop: 0,
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
  rowsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    padding: theme.spacing(),
    //backgroundColor: 'rgba(0,0,0,.1)',
  },
});

const getRecipients = (selectedOrganisationsIds, organisations, visibility) => {
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationFullNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return [visibility];
  }

  list.sort();

  return list;
};

const Details = (props) => {
  const { classes, activeRfq, organisations, user, users } = props;
  let recipients = [];
  let details = [];
  let extensions = [];
  let orderItems = [];
  let rfqTypeFields = [];
  let assets = [];
  let sender = '';
  let senderUser = '';
  let beneficiary;

  if (activeRfq !== null) {
    if (activeRfq.brokerOrganisationId === user.organisationId) {
      const visibility = activeRfq.visibility;
      const selectedOrganisationsIds = activeRfq.selectedOrganisationsIds || [];

      recipients = getRecipients(selectedOrganisationsIds, organisations, visibility);
    }
    sender = getOrganisationFullNameFromId(activeRfq.brokerOrganisationId, organisations);
    senderUser = activeRfq.createdByUserId
      ? users[activeRfq.createdByUserId]
        ? `${users[activeRfq.createdByUserId].firstName} ${
            users[activeRfq.createdByUserId].lastName
          }`
        : ''
      : '';

    beneficiary = getOrganisationFullNameFromId(activeRfq.beneficiaryOrganisationId, organisations);

    const values = getKeyValuePairsFromRfq(activeRfq);

    details = values.order;
    extensions = values.extensions || {};

    orderItems = extensions.find((item) => item.key === 'Order Items') || {};
    if (orderItems.value === undefined) orderItems.value = [];
    assets = values.assets || [];
  }

  const formFields = getFieldsArray('order', ['responseForm', 'spot', 'fresh']);
  const fieldKeys = formFields ? Object.keys(formFields) : [];
  const formFieldsTop = fieldKeys.filter((f) => formFields[f].formTopSection);
  const formFieldsBottom = fieldKeys.filter((f) => formFields[f].formBottomSection);
  const formFieldsRight = fieldKeys.filter((f) => formFields[f].formRightSection);
  const formFieldsLeft = fieldKeys.filter((f) => formFields[f].formLeftSection);
  return (
    <Modal open={props.open} onClose={props.closeForm}>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>
          <CustomText value="offerDetails" upperCase />
        </Typography>

        <div className={classes.container}>
          <div className={classes.section}>
            <List open classes={{ root: classes.list }} disablePadding>
              <ListItem button classes={{ root: classes.listItem }}>
                <ListItemText
                  primary={<CustomText value="sender" upperCase />}
                  className={classes.key}
                />
                <ListItemText primary={sender} align="right" />
              </ListItem>
              <ListItem button classes={{ root: classes.listItem }}>
                <ListItemText
                  primary={<CustomText value="user" upperCase />}
                  className={classes.key}
                />
                <ListItemText primary={senderUser} align="right" />
              </ListItem>
              <ListItem button classes={{ root: classes.listItem }}>
                <ListItemText
                  primary={<CustomText value="beneficiary" upperCase />}
                  className={classes.key}
                />
                <ListItemText primary={beneficiary} align="right" />
              </ListItem>
              <ListItem button classes={{ root: classes.listItem }}>
                <ListItemText
                  primary={<CustomText value="rfqSentTo" upperCase />}
                  className={classes.key}
                />
                {recipients.map((r) => (
                  <ListItemText primary={r} align="right" />
                ))}
              </ListItem>
            </List>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="offer" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.orderRowsContainer}>
              {orderItems.value.map((item, index) => {
                return <DetailsRow key={index} orderRow={item} index={index} />;
              })}
              {assets.map((asset, index) => {
                return (
                  <DetailsAssetsRow
                    key={asset.id}
                    orderRow={asset}
                    index={index}
                    activeRfq={activeRfq || {}}
                  />
                );
              })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="company" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {formFields &&
                activeRfq &&
                formFieldsTop.map((field, index) => {
                  if (formFields[field]) {
                    let value = activeRfq;

                    const fullAccessor = formFields[field].fullAccessor.split('.');
                    for (let i = 0; i < fullAccessor.length; i++) {
                      value = value[fullAccessor[i]];
                    }
                    let val = value;
                    if (Array.isArray(val)) {
                      value = val.join(' , ');
                    } else {
                      value = val;
                    }

                    return (
                      <React.Fragment key={index}>
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                        />
                      </React.Fragment>
                    );
                  } else return null;
                })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="product" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {formFields &&
                activeRfq &&
                formFieldsLeft.map((field, index) => {
                  if (formFields[field]) {
                    let value = activeRfq;

                    const fullAccessor = formFields[field].fullAccessor.split('.');
                    for (let i = 0; i < fullAccessor.length; i++) {
                      value = value[fullAccessor[i]];
                    }
                    let val = value;
                    if (Array.isArray(val)) {
                      value = val.join(' , ');
                    } else {
                      value = val;
                    }

                    return (
                      <React.Fragment key={index}>
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                        />
                      </React.Fragment>
                    );
                  } else return null;
                })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="freight" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {formFields &&
                activeRfq &&
                formFieldsRight.map((field, index) => {
                  if (formFields[field]) {
                    let value = activeRfq;
                    let tempAccessor = formFields[field].fullAccessor;
                    if (tempAccessor === 'contract.packingDate') {
                      tempAccessor = 'contract.packingDate.to';
                    }
                    if (tempAccessor === 'contract.deliveryDate') {
                      tempAccessor = 'contract.deliveryDate.to';
                    }
                    const fullAccessor = tempAccessor.split('.');
                    if (fullAccessor)
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                      }
                    let val = value;

                    if (Array.isArray(val)) {
                      value = val.join(' , ');
                    } else {
                      value = val;
                    }

                    return (
                      <React.Fragment key={index}>
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                        />
                      </React.Fragment>
                    );
                  } else return null;
                })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="offer" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {formFields &&
                activeRfq &&
                formFieldsBottom.map((field, index) => {
                  if (formFields[field]) {
                    let value = activeRfq;

                    const fullAccessor = formFields[field].fullAccessor.split('.');
                    for (let i = 0; i < fullAccessor.length; i++) {
                      value = value[fullAccessor[i]];
                    }
                    let val = value;
                    if (Array.isArray(val)) {
                      value = val.join(' , ');
                    } else {
                      value = val;
                    }

                    return (
                      <React.Fragment key={index}>
                        <ReadOnlyField
                          displayName={formFields[field].accessor}
                          value={value}
                          key={index}
                        />
                      </React.Fragment>
                    );
                  } else return null;
                })}
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value="details" upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {['direction', 'createdTime', 'updatedTime', 'status', 'timeInForce', 'id'].map(
                (name, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ReadOnlyField
                        displayName={name}
                        value={activeRfq ? activeRfq[name] : ''}
                        key={index}
                      />
                    </React.Fragment>
                  );
                },
              )}
            </div>
          </div>
        </div>
        <ToolbarBottom>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CancelButton title={'close'} onClick={props.closeForm} />
          </div>
        </ToolbarBottom>
      </div>
    </Modal>
  );
};

Details.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state),
    user: getActiveUser(state),
    users: getUsersObject(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Details));
