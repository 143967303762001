import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { lightBlue } from '@material-ui/core/colors';
import FileRow from './FileRow';
import FileViewer from './FileViewer';
import SelectField from '../../../commons/components/formFields/SelectField';
import MultipleSelectFieldOrgs from '../../../commons/components/formFields/multipleSelectFieldOrgs';
import { ResetButton } from '../../../commons/components/buttons';
import SnackbarCustom from '../../../commons/components/snackbarCustom';
import constants from '../constants';
import { createFile as actionCreateFile } from '../actions';
import { getTradingPartners } from '../selectors';
import { visibilities } from '../../../commons/models/constants';
import CustomText from '../../../commons/components/formFields/customText';
import { createUuid } from '../../../commons/config/functions';
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(),
  },
  dropContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    color: theme.palette.text.opacity,
    padding: theme.spacing(),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.primary,
    height: '190px',
    maxHeight: '190px',
  },

  fileInput: {
    opacity: 0,
    position: 'absolute',
    ZIndex: -1,
  },
  uploadIcon: {
    fontSize: '48px',
  },
  browse: {
    padding: theme.spacing(),
    border: `1px solid ${lightBlue[300]}`,
    color: lightBlue[300],
    '&:hover': {
      transition: 'all .5s ease',
      backgroundColor: lightBlue[500],
      color: 'white',
    },
    cursor: 'pointer',
  },
  itemContainer: {
    padding: theme.spacing(0.5),
  },
  uploadedFiles: {
    padding: theme.spacing(),
    flex: 1,
    overflowY: 'scroll',
  },
  buttonRow: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const sellerDocumentTypes = [
  'INVOICE',
  'PACKING_LIST',
  'PRODUCT_SPECIFICATION',
  'AIRWAY_BILL',
  'HEALTH_CERTIFICATE',
  'CERTIFICATE_OF_ORIGIN',
  'FULL_PACK',
  'OTHER',
];

const buyerDocumentTypes = ['INSPECTION_REPORT', 'OTHER'];
const Dropzone2 = (props) => {
  const { classes } = props;
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [showFile, setShowFile] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentType, setDocumentType] = useState('INVOICE');
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [snackbarFileSuccessOpen, setSnackbarFileSuccessOpen] = useState(false);
  const [snackbarFileErrorOpen, setSnackbarFileErrorOpen] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  useEffect(() => {
    let types = [];
    if (props.formType && props.formType === 'buyerData') {
      types = buyerDocumentTypes;
    } else {
      types = sellerDocumentTypes;
    }
    setDocumentTypes(types);
    setDocumentType(types[0]);
  }, []);
  const handleSuccessClose = () => {
    setSnackbarSuccessOpen(false);
  };
  const handleErrorClose = () => {
    setSnackbarErrorOpen(false);
  };
  const handleFileErrorClose = () => {
    setSnackbarFileErrorOpen(false);
    setRejectedFiles([]);
  };
  const handleFileUploadResult = (result) => {
    if (result) {
      setSnackbarSuccessOpen(true);
    } else {
      setSnackbarErrorOpen(true);
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*, .pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      const nextFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            documentType: documentType,
            filename: file.name,
          }),
        ),
      ];
      setRejectedFiles(fileRejections);
      if (fileRejections.length > 0) {
        setSnackbarFileErrorOpen(true);
      } else {
        setSnackbarFileSuccessOpen(true);
      }
      props.setNewFiles(nextFiles);
      setDragging(false);
    },
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
  });
  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);

    setFiles(newFiles);
  };
  const handleShowFile = (file) => {
    setFile(file);
    setShowFile(true);
  };

  const handleDocumentType = (name) => (event) => {
    setDocumentType(event.target.value);
  };

  const createFiles = () => {
    files.forEach((file) => {
      //const orgIds = organisations.map((org) => org.id);
      const payload = {
        data: {
          file,
          //visibility,
          //organisations,
        },

        callback: handleFileUploadResult,
      };

      //props.createFile(payload);
    });

    setFiles([]);
  };

  return (
    <div className={`${classes.container} undraggable`}>
      <div className={classes.buttonRow}>
        <SelectField
          accessor="documentType"
          displayName="documentType"
          value={documentType}
          values={documentTypes}
          handleChange={handleDocumentType}
          fullWidth
        ></SelectField>
      </div>

      <div
        className={classes.dropContainer}
        {...getRootProps()}
        style={dragging ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <div className={classes.itemContainer}>
          <div className={`material-icons ${classes.uploadIcon}`}>get_app</div>
        </div>
        <div className={classes.itemContainer}>
          <CustomText value="dragAndDropFiles"></CustomText>
        </div>

        <input style={{ flex: 1 }} {...getInputProps()} id="upload-file"></input>

        <div className={classes.itemContainer}>
          <CustomText value="or"></CustomText>
        </div>
        <div className={classes.itemContainer}>
          <div type="button" onClick={open} className={classes.browse}>
            <CustomText value="browseFiles"></CustomText>
          </div>
        </div>
      </div>

      <div className={classes.uploadedFiles}>
        {files.map((file, index) => (
          <FileRow
            file={file}
            index={index}
            key={file.name}
            removeFile={() => removeFile(index)}
            showFile={() => handleShowFile(file)}
            handleDocumentNameChange={() => {}}
          />
        ))}
        <SnackbarCustom
          open={snackbarSuccessOpen}
          message={'fileSuccessInfo'}
          messageType="success"
          onClose={handleSuccessClose}
        ></SnackbarCustom>

        <SnackbarCustom
          open={snackbarErrorOpen}
          message={'fileFailureInfo'}
          messageType="error"
          onClose={handleSuccessClose}
        ></SnackbarCustom>

        <SnackbarCustom
          open={snackbarFileSuccessOpen}
          message="newFileAdded"
          messageType="success"
          onClose={() => setSnackbarFileSuccessOpen(false)}
        ></SnackbarCustom>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarFileErrorOpen}
          autoHideDuration={6000}
          onClose={handleFileErrorClose}
        >
          <Alert onClose={handleFileErrorClose} severity="error" sx={{ width: '100%' }}>
            {rejectedFiles.map((file) => {
              return file.errors[0].message;
            })}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

Dropzone2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tradingPartners: getTradingPartners(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (payload) => {
      dispatch(actionCreateFile(constants.CREATE_FILE, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dropzone2));
