import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => {
  return items;
});
export const getAll = createSelector(getAllObject, (items) => {
  return Object.values(items);
});

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyCalculator = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyCalculator;
};
export const getById = createSelector(getByIdProp, (item) => item);

const getLocalChartsObjectProp = (state) => {
  const { items } = state[NAME];
  return items;
};
export const getLocalChartsObject = createSelector(getLocalChartsObjectProp, (items) => items);
export const getLocalCharts = createSelector(getLocalChartsObjectProp, (items) =>
  Object.values(items),
);

const emptyLocalChart = {};
const getLocalChartProp = (state, id) => {
  const item = state[NAME].items[id];
  return item || emptyLocalChart;
};
export const getLocalChart = createSelector(getLocalChartProp, (item) => item);

const getTableProp = (state, id) => {
  const item = state[NAME].tables[id];
  return item || emptyLocalChart;
};
export const getTable = createSelector(getTableProp, (item) => item);

const getDefaultProp = (state) => {
  const { default: defaultConfig } = state[NAME];
  return defaultConfig;
};
export const getDefault = createSelector(getDefaultProp, (item) => item);

const getConfigProp = (state) => {
  const { config } = state[NAME];
  return config;
};
export const getConfig = createSelector(getConfigProp, (item) => item);
