import {
  INIT_CALCULATORS_SETTINGS,
  INIT_CALCULATORS_SETTINGS_SUCCESS,
  INIT_CALCULATORS_SETTINGS_ERROR,
  INIT_CALCULATORS,
  INIT_CALCULATORS_ERROR,
  UPDATE_CALCULATORS,
  UPDATE_CALCULATORS_SUCCESS,
  UPDATE_CALCULATORS_ERROR,
  INIT_CALCULATORS_SUCCESS,
  INIT_CALCULATOR_SUCCESS,
  DESTROY_CALCULATOR_SUCCESS,
  UPDATE_CALCULATOR_SUCCESS,
  CALCULATOR_MANAGER_SUCCESS,
} from '../constants';

import { isObject, insertItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  items: {},
  settings: {
    items: [],
    status: null,
    error: null,
    receivedAt: null,
  },
  manager: {
    open: false,
    id: null,
  },
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;
  let nextSettings = null;

  switch (action.type) {
    case INIT_CALCULATOR_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInitCalculator, ...otherNextInitCalculatorProps } = action.payload;

      if (typeof idInitCalculator !== 'undefined') {
        if (!nextState.items[idInitCalculator]) {
          nextState.items = {
            ...nextState.items,
            [idInitCalculator]: {
              id: idInitCalculator,
              ...otherNextInitCalculatorProps,
            },
          };
        }
      }
      return nextState;
    case DESTROY_CALCULATOR_SUCCESS:
      nextState = {
        ...state,
      };

      const { id } = action.payload;

      if (typeof id !== 'undefined') {
        if (nextState.items[id]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[id];
        }
      }
      return nextState;
    case UPDATE_CALCULATOR_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idUpdateCalculator, ...otherNextUpdateCalculatorProps } = action.payload;

      if (typeof idUpdateCalculator !== 'undefined') {
        //const existingIds = Object.keys(nextState.items);
        //if (existingIds.length === 1) {
        const itemId = idUpdateCalculator;
        nextState.items = {
          ...nextState.items,
          [itemId]: {
            ...nextState.items[itemId],
            ...otherNextUpdateCalculatorProps,
          },
        };
        //}
      }
      return nextState;
    case INIT_CALCULATORS:
      return {
        ...state,
        status: 'pending',
      };
    case INIT_CALCULATORS_SUCCESS:
      nextState = {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return nextState;
    case INIT_CALCULATORS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case UPDATE_CALCULATORS:
      return {
        ...state,
        status: 'pending',
      };
    // TODO: REVIEW AND REFACTOR!!!!!!!!!!!!!! CRITICAL!
    case UPDATE_CALCULATORS_SUCCESS:
      nextState = {
        ...state,
        // ...updateItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      const { id: idChangeCalculator, stateCalculator: stateChangedCalculator } = action.payload;

      if (typeof idChangeCalculator !== 'undefined' && isObject(stateChangedCalculator)) {
        nextState.items = {
          ...nextState.items,
          [idChangeCalculator]: {
            ...stateChangedCalculator,
          },
        };
      }

      if (isObject(action.payload.settings)) {
        nextState.settings = {
          ...nextState.settings,
          ...action.payload.settings,
        };

        let changed = false;

        Object.keys(nextState.items).forEach((key) => {
          const calc = nextState.items[key].calculator;
          const calcSettings = nextState.settings.items.find((item) => item.id === calc.id);

          if (calcSettings && calcSettings.name !== calc.name) {
            calc.name = calcSettings.name;
            changed = true;
          }
        });

        if (changed) {
          nextState.items = { ...nextState.items };
        }
      }

      return nextState;
    case UPDATE_CALCULATORS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case INIT_CALCULATORS_SETTINGS:
      nextSettings = {
        ...state.settings,
        status: 'pending',
      };
      return {
        ...state,
        settings: nextSettings,
      };
    case INIT_CALCULATORS_SETTINGS_SUCCESS:
      nextSettings = {
        ...state.settings,
        items: action.payload.items,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return {
        ...state,
        settings: nextSettings,
      };
    case INIT_CALCULATORS_SETTINGS_ERROR:
      nextSettings = {
        ...state.settings,
        status: 'error',
        error: action.payload,
      };
      return {
        ...state,
        settings: nextSettings,
      };
    case CALCULATOR_MANAGER_SUCCESS:
      nextState = {
        ...state,
      };

      const { open } = action.payload;

      nextState.manager = {
        ...nextState.manager,
      };

      if (Object.prototype.toString.call(open) === '[object Boolean]') {
        nextState.manager.open = open;
      }

      return nextState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
