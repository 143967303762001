import { createSelector } from 'reselect';
import { NAME } from '../constants';
import auth from '../../auth';

const getAllObjectProp = (state) => state[NAME].items;
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getDefaultProp = (state) => state[NAME].default;
export const getDefault = createSelector(getDefaultProp, (item) => item);

const getStatusProp = (state) => state[NAME].status;
export const getStatus = createSelector(getStatusProp, (status) => status);

const getManageWorkspacesProp = (state) => {
  const { manage } = state[NAME];
  return manage;
};
export const getManageWorkspaces = createSelector(getManageWorkspacesProp, (item) => item);

const isOpenManageWorkspacesProp = createSelector(getManageWorkspaces, (item) => {
  const { open } = item;
  return open;
});
export const isOpenManageWorkspaces = createSelector(isOpenManageWorkspacesProp, (open) => open);

const getItemsManageWorkspacesProp = createSelector(getManageWorkspaces, (manage) => {
  const { items } = manage;
  return items;
});
export const getItemsManageWorkspaces = createSelector(
  getItemsManageWorkspacesProp,
  (items) => items,
);

// const emptyActiveWorkspace = { layouts: [], widgets: [] };
const getActiveWorkspaceProp = (state) => {
  const activeItem = state[NAME].items.find((item) => item.active === true);
  return activeItem; // || emptyActiveWorkspace;
};
export const getActiveWorkspace = createSelector(getActiveWorkspaceProp, (item) => {
  return item;
});

const getAllManageProp = (state) => state[NAME].manageItems;
export const getAllManage = createSelector(getAllManageProp, (items) => items);

const emptyActiveWorkspaceLayputs = [];
export const getActiveWorkspaceLayouts = createSelector(getActiveWorkspace, (item) => {
  return Array.isArray(item.layouts) ? item.layouts : emptyActiveWorkspaceLayputs;
});

const emptyActiveWorkspaceWidgets = [];
export const getActiveWorkspaceWidgets = createSelector(getActiveWorkspace, (item) => {
  return Array.isArray(item.widgets) ? item.widgets : emptyActiveWorkspaceWidgets;
});

export const getActiveWorkspaceName = createSelector(getActiveWorkspace, (item) => {
  return item ? item.name : '';
});

export const getSettings = createSelector(auth.selectors.getAppSettings, (settings) => {
  return settings[NAME] || null;
});
