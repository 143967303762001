export const INIT_STATISTICS = 'statistics/init';
export const INIT_STATISTICS_SUCCESS = 'statistics/init/success';
export const INIT_STATISTICS_ERROR = 'statistics/init/error';

export const DESTROY_STATISTICS = 'statistics/destroy';
export const DESTROY_STATISTICS_SUCCESS = 'statistics/destroy/success';
export const DESTROY_STATISTICS_ERROR = 'statistics/destroy/error';

export const UPDATE_STATISTICS = 'statistics/update';
export const UPDATE_STATISTICS_SUCCESS = 'statistics/update/success';
export const UPDATE_STATISTICS_ERROR = 'statistics/update/error';
