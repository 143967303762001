import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.text.subtitle,
    fontSize: theme.fontSize.md,
  },
});

const DetailCell = (props) => {
  const { classes, incoTerms, week, currency } = props;
  return (
    <div className={classes.row}>
      <div className={classes.column}>
        <div className={classes.text}>{incoTerms}</div>
        <div className={classes.text}>{week}</div>
      </div>
      <div className={classes.column}>
        <div className={classes.text}>{currency}</div>
      </div>
    </div>
  );
};
DetailCell.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailCell);
