export const GET_LOGOS = 'logos/get';
export const GET_LOGOS_SUCCESS = 'logos/get/success';
export const GET_LOGOS_ERROR = 'logos/get/error';
export const GET_LOGO = 'logo/get';
export const GET_LOGO_SUCCESS = 'logo/get/success';
export const GET_LOGO_ERROR = 'logo/get/error';
export const UPDATE_LOGO = 'logo/update';
export const UPDATE_LOGO_SUCCESS = 'logo/update/success';
export const UPDATE_LOGO_ERROR = 'logo/update/error';
export const RESET_LOGOS = 'logo/reset';
