import { getBlotterFields } from '../../modules/contracts/utils/functions';
import { formatPrice, formatDateTime, formatDate, formatList } from './formatters';

const dateFilter = function (filterLocalDateAtMidnight, cellValue) {
  const dateTime = new Date(
    new Date(cellValue).getFullYear(),
    new Date(cellValue).getMonth(),
    new Date(cellValue).getDate(),
  );

  if (cellValue == null) return 0;

  if (dateTime < filterLocalDateAtMidnight) {
    return -1;
  }
  if (dateTime > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const orderColumns = (contract, floatingFilter) => {
  const columns = [];
  const blotter = 'orderBlotter';

  const fields = getBlotterFields(contract, blotter);

  fields.forEach((field) => {
    const obj = {};
    obj.headerName = field.displayName.toUpperCase();
    obj.field = field.fullAccessor;
    obj.width = 150;
    obj.resizable = true;
    obj.sortable = true;
    obj.tooltipField = field.fullAccessor;
    obj.enableCellChangeFlash = true;
    obj.floatingFilter = floatingFilter;
    obj.filter =
      field[blotter].outputType === 'date' || field[blotter].outputType === 'date-time'
        ? 'agDateColumnFilter'
        : 'agTextColumnFilter';

    if (field[blotter].outputType === 'date' || field[blotter].outputType === 'date-time') {
      obj.filterParams = {
        comparator: dateFilter,
        apply: false,
        newRowsAction: 'keep',
      };
    } else {
      obj.filterParams = { apply: false, newRowsAction: 'keep' };
    }

    if (field[blotter].outputType === 'date') {
      obj.valueFormatter = (params) => {
        return formatDate(params.value);
      };
    } else if (field[blotter].outputType === 'date-time') {
      if (field[blotter].accessor === 'timeOfUpdate') {
        obj.sort = { direction: 'desc', priority: 0 };
      }
      obj.valueFormatter = (params) => {
        return params.value !== undefined ? formatDateTime(params.value) : '';
      };
    } else if (field.accessor === 'price') {
      obj.valueFormatter = (params) => {
        return formatPrice(params.value);
      };
    }

    columns.push(obj);
  });

  return columns;
};

export const tradeColumns = (contract, floatingFilter) => {
  const columns = [];
  const blotter = 'tradeBlotter';

  const fields = getBlotterFields(contract, blotter);
  fields.forEach((field) => {
    const obj = {};
    obj.headerName = field.displayName.toUpperCase();
    obj.field = field.fullAccessor;
    obj.width = 150;
    obj.resizable = true;
    obj.sortable = true;
    obj.tooltipField = field.fullAccessor;
    obj.floatingFilter = floatingFilter;
    obj.filter =
      field[blotter].outputType === 'date' || field[blotter].outputType === 'date-time'
        ? 'agDateColumnFilter'
        : 'agTextColumnFilter';

    if (field[blotter].outputType === 'date') {
      obj.valueFormatter = (params) => {
        return formatDate(params.value);
      };
      // obj.filterParams
    } else if (field[blotter].outputType === 'date-time') {
      if (field[blotter].accessor === 'timeOfUpdate') {
        obj.sort = { direction: 'desc', priority: 0 };
      }

      obj.valueFormatter = (params) => {
        return formatDateTime(params.value);
      };
    } else if (field.accessor === 'price') {
      obj.valueFormatter = (params) => {
        return formatPrice(params.value);
      };
    } else if (field === 'recipients') {
      obj.valueFormatter = (params) => {
        const list = [];
        params.forEach((p) => list.push(p.shortName));
        return formatList(list);
      };
    }
    obj.filterParams = { apply: false, newRowsAction: 'keep' };
    columns.push(obj);
  });

  return columns;
};

export const defaultOrderBlotter = () => {
  return [
    {
      colId: 'contract.underlying.product',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.location',
      hide: false,
      aggFunc: null,
      width: 93,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.weightClass',
      hide: false,
      aggFunc: null,
      width: 65,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.incoTerms',
      hide: false,
      aggFunc: null,
      width: 82,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'direction',
      hide: false,
      aggFunc: null,
      width: 88,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'volume',
      hide: false,
      aggFunc: null,
      width: 78,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'originalVolume',
      hide: false,
      aggFunc: null,
      width: 92,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.unit',
      hide: false,
      aggFunc: null,
      width: 94,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'price',
      hide: false,
      aggFunc: null,
      width: 93,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    // {
    //   colId: 'contract.underlying.deliveryPointFob',
    //   hide: true,
    //   aggFunc: null,
    //   width: 150,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    // {
    //   colId: 'contract.underlying.deliveryPointDdp',
    //   hide: true,
    //   aggFunc: null,
    //   width: 264,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    {
      colId: 'contract.underlying.deliveryPoint',
      hide: true,
      aggFunc: null,
      width: 264,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.region',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.packingStation',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.currency',
      hide: false,
      aggFunc: null,
      width: 95,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.certification',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.quality',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.state',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.presentation',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.instrument',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.week',
      hide: false,
      aggFunc: null,
      width: 112,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.harvestDate',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.deliveryDate',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'status',
      hide: false,
      aggFunc: null,
      width: 99,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'rfqId',
      hide: false,
      aggFunc: null,
      width: 86,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'orderGroupId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    // {
    //   colId: 'anonymous',
    //   hide: true,
    //   aggFunc: null,
    //   width: 150,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    {
      colId: 'visibility',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'recipientList',
      hide: true,
      aggFunc: null,
      width: 217,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    // {
    //   colId: 'autoClose',
    //   hide: true,
    //   aggFunc: null,
    //   width: 216,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    {
      colId: 'timeInForce',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'user.fullName',
      hide: false,
      aggFunc: null,
      width: 102,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'organisation.shortName',
      hide: false,
      aggFunc: null,
      width: 69,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'timeOfEntry',
      hide: false,
      aggFunc: null,
      width: 163,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'timeOfUpdate',
      hide: false,
      aggFunc: null,
      width: 177,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'textMessage',
      hide: false,
      aggFunc: null,
      width: 177,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'id',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
  ];
};

export const defaultTradeBlotter = () => {
  return [
    {
      colId: 'contract.underlying.product_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.location_1',
      hide: false,
      aggFunc: null,
      width: 104,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.incoTerms_1',
      hide: false,
      aggFunc: null,
      width: 95,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    // {
    //   colId: 'contract.underlying.deliveryPointFob',
    //   hide: true,
    //   aggFunc: null,
    //   width: 150,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    // {
    //   colId: 'contract.underlying.deliveryPointDdp',
    //   hide: true,
    //   aggFunc: null,
    //   width: 150,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null,
    // },
    {
      colId: 'contract.underlying.deliveryPoint_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.region_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.packingStation_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.weightClass_1',
      hide: false,
      aggFunc: null,
      width: 76,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'direction_1',
      hide: false,
      aggFunc: null,
      width: 84,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'volume_1',
      hide: false,
      aggFunc: null,
      width: 82,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.unit_1',
      hide: false,
      aggFunc: null,
      width: 78,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'price_1',
      hide: false,
      aggFunc: null,
      width: 77,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.currency_1',
      hide: false,
      aggFunc: null,
      width: 92,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'nominal',
      hide: false,
      aggFunc: null,
      width: 128,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.certification_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.quality_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.state_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.presentation_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.underlying.state_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.instrument_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.week_1',
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.harvestDate_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'contract.deliveryDate_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'status_1',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'rfqId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'id',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'ownUser',
      hide: false,
      aggFunc: null,
      width: 122,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'ownCompany',
      hide: false,
      aggFunc: null,
      width: 96,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'ownComments',
      hide: false,
      aggFunc: null,
      width: 96,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'ownCompany',
      hide: false,
      aggFunc: null,
      width: 96,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'counterpartyUser',
      hide: false,
      aggFunc: null,
      width: 99,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'counterpartyCompany',
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'counterpartyComments',
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'rfqComments',
      hide: false,
      aggFunc: null,
      width: 96,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'bidId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'offerId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'bidOrderGroupId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'offerOrderGroupId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'tradeId',
      hide: true,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
    {
      colId: 'timeStamp',
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null,
    },
  ];
};

export const initialBlotterSettings = () => {
  const blotters = defaultBlotterSettings();
  return {
    0: blotters.order,
    1: blotters.order,
    2: blotters.trade,
  };
};

export const defaultBlotterSettings = () => {
  return {
    order: {
      type: 'ORDER',
      name: 'DEFAULT_ORDER',
      id: 'order-1',
      editable: false,
      columnState: defaultOrderBlotter(),
      sortState: [{ colId: 'timeOfUpdate', sort: 'desc' }],
      filterState: [],
      floatingFilter: false,
    },
    trade: {
      type: 'TRADE',
      name: 'DEFAULT_TRADE',
      id: 'trade-1',
      columnState: defaultTradeBlotter(),
      editable: false,
      sortState: [{ colId: 'timeStamp', sort: 'desc' }],
      filterState: [],
      floatingFilter: false,
    },
  };
};
