import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import {
  RESET_ORGANISATIONS,
  GET_ORGANISATION,
  GET_ORGANISATIONS,
  GET_ORGANISATION_ERROR,
  GET_MY_ORGANISATION,
  GET_MY_ORGANISATION_SUCCESS,
  GET_MY_ORGANISATION_ERROR,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATIONS_SUCCESS,
  GET_ORGANISATIONS_ERROR,
} from '../constants';

import api from '../api';

import { getOrganisations, getOrganisation } from '../actions';

import auth from '../../auth';

import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';
import { getLastUpdatedTime } from '../selectors';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);
    const isSuperAdmin = yield select(auth.selectors.isSuperAdmin);
    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const organisations = yield call(api.getAll, options);
    const payload = {
      items: organisations,
    };
    if (isSuperAdmin) {
      if (organisations) {
        const tasks = [];
        organisations.forEach((org) => {
          const organisationId = org.id;
          tasks.push(call(api.get, organisationId, options));
        });
        if (tasks.length > 0) {
          const orgs = yield all(tasks);
          payload.items = orgs;
        }
      }
    }

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getOrganisations(GET_ORGANISATIONS_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getOrganisations(GET_ORGANISATIONS_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get(action) {
  try {
    const organisationId = action.payload.organisationId;

    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const organisation = yield call(api.get, organisationId, options);

    const payload = {
      items: [organisation],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getOrganisation(GET_ORGANISATION_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getOrganisation(GET_ORGANISATION_ERROR, { error }));
    }
  }
}

function* getMy() {
  try {
    const myUser = yield select(auth.selectors.getServerUser);
    const organisationId = myUser.organisationId;

    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastUpdatedTime = yield select(getLastUpdatedTime);

    if (lastUpdatedTime) {
      options.params.updatedTimeFrom = lastUpdatedTime;
    }

    const organisation = yield call(api.get, organisationId, options);

    const payload = {
      item: organisation,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getOrganisation(GET_MY_ORGANISATION_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getOrganisation(GET_MY_ORGANISATION_ERROR, { error }));
    }
  }
}

export function* watchGetOrganisations() {
  yield takeEvery(GET_ORGANISATIONS, getAll);
}

export function* watchGetOrganisation() {
  yield takeEvery(GET_ORGANISATION, get);
}

export function* watchGetMyOrganisation() {
  yield takeEvery(GET_MY_ORGANISATION, getMy);
}

export function* watchResetOrganisations() {
  yield takeEvery(RESET_ORGANISATIONS);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
