import defaultWorkspaces from './defaultWorkspaces';

const getId = () => {
  return new Date().getTime().toString();
};

export const getDefaultWorkspaces = () => {
  return defaultWorkspaces;
};
export const defaultWorkspace = (num = 0) => {
  return defaultWorkspaces[num];
};

export const blankWorkspace = (num, title = 'Workspace') => {
  return {
    name: `${title}${num}`,
    active: false,
    layouts: [],
    widgets: [],
    id: getId(),
  };
};
