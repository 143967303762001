import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import FxRow from './FxRow';
import { getFxRates } from '../selectors';
import { INIT_FXRATE, DESTROY_FXRATE } from '../constants';
import { destroyFxrate, initFxrate } from '../actions';
import { getFromLS, saveToLS } from '../../../commons/localStorage';
import schemas from '../../schemas';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomText from '../../../commons/components/formFields/customText';
import AutocompleteField from '../../../commons/components/formFields/autocompleteField';
import { setSubmitFailed } from 'redux-form';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
    padding: '24px 8px',
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  toolRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currencyName: {
    padding: theme.spacing(),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  currencyRate: {
    padding: theme.spacing(),
    color: teal[300],
    fontSize: '16px',
  },
});

const FxRates = (props) => {
  const { classes, fxrates } = props;
  const [selected, setSelected] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [uniqueCurrencies, setUniqueCurrencies] = useState([]);
  const [currency1, setCurrency1] = useState(null);
  const [currency2, setCurrency2] = useState(null);

  useEffect(() => {
    console.log('Running effct 1');
    const { id, widget } = props;

    const payload = {
      id,
      widget,
    };

    props.initFxrate(payload);
    getSavedSettings();
    return () => {
      const payload = {
        id: props.id,
      };
      props.destroyFxrate(payload);
    };
  }, []);

  useEffect(() => {
    console.log('Running effct 2');
    const nextFxrates = Object.keys(props.fxrates);

    if (sortOrder.length !== nextFxrates.length) {
      nextFxrates.forEach((rate) => {
        if (!sortOrder.includes(rate)) {
          sortOrder.push(rate);
        }
      });
      setSortOrder(sortOrder);
      setSubmitFailed(sortOrder);
    }
    const _uniqueCurrencies = [];
    nextFxrates.forEach((rate) => {
      const firstRate = rate.split('/')[0];
      _uniqueCurrencies.push(firstRate);
    });

    const newSet = Array.from(new Set([..._uniqueCurrencies])).sort();

    setUniqueCurrencies(newSet);
  }, [props.fxrates]);

  const getSavedSettings = () => {
    const saved = getFromLS('SelectedFxRates');

    if (saved) {
      const selected = saved.selected || [];
      const showAll = saved.showAll || true;
      const sortOrder = saved.sortOrder || [];
      setSelected(selected);
      setShowAll(showAll);
      setSortOrder(sortOrder);
      setFilteredOrder(sortOrder);
    }
  };

  const handleChecked = (currency) => {
    let _selected = [];
    if (selected.includes(currency)) {
      _selected = [...selected].filter((s) => s !== currency);
    } else {
      _selected = [...selected];
      _selected.push(currency);
    }
    setSelected(_selected);
    saveToLS('SelectedFxRates', { _selected, sortOrder, showAll });
  };
  const handleSwitch = (event) => {
    const _showAll = event.target.checked;
    setShowAll(_showAll);
    saveToLS('SelectedFxRates', { selected, sortOrder, _showAll });
  };
  const handleDragEnd = (e) => {
    const index = e.destination.index;
    let _sortOrder = [];
    let currency = e.draggableId;

    sortOrder.forEach((item) => {
      if (item !== currency) _sortOrder.push(item);
    });

    _sortOrder.splice(index, 0, currency);
    setSortOrder(_sortOrder);
    saveToLS('SelectedFxRates', { selected, _sortOrder, showAll });
  };
  const handleChange = (name) => (e, value) => {
    let _currency1 = currency1;
    let _currency2 = currency2;
    if (name === 'currency1') {
      _currency1 = value;
      setCurrency1(value);
    } else {
      _currency2 = value;
      setCurrency2(value);
    }
    console.log('_currency1', _currency1);
    console.log('_currency2', _currency2);
    const _filteredOrder = sortOrder.filter((s) => {
      if (_currency1) {
        if (_currency2) {
          return s.includes(_currency1) && s.includes(_currency2);
        } else {
          return s.includes(_currency1);
        }
      } else if (_currency2) {
        return s.includes(_currency2);
      } else {
        return true;
      }
    });

    setFilteredOrder(_filteredOrder);
  };

  return (
    <div className={` ${classes.container} undraggable`}>
      <div className={classes.header}>
        <AutocompleteField
          accessor="currency1"
          options={uniqueCurrencies}
          getOptionLabel={(option) => option}
          label="currency"
          handleChange={handleChange}
          handleInputChange={() => {}}
        />
        <AutocompleteField
          accessor="currency2"
          options={uniqueCurrencies}
          getOptionLabel={(option) => option}
          label="currency"
          handleChange={handleChange}
          handleInputChange={() => {}}
        />
      </div>
      <div className={classes.ratesContainer}>
        <DragDropContext onDragEnd={(e) => handleDragEnd(e)}>
          <Droppable droppableId={'FXRATES'}>
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filteredOrder.map((item, index) => {
                    const _selected = selected.includes(item);
                    const rate = fxrates[item] || 0;
                    return showAll || _selected ? (
                      <Draggable key={item} index={index} draggableId={item}>
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <FxRow
                                item={{ currency: item, rate: rate, checked: _selected }}
                                handleChecked={handleChecked}
                              ></FxRow>
                            </div>
                          );
                        }}
                      </Draggable>
                    ) : null;
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={classes.toolRow}>
        <div style={{ padding: '0px 4px' }}>
          <CustomText value="showAll" />
        </div>
        <Switch checked={showAll} onChange={handleSwitch}></Switch>
      </div>
    </div>
  );
};

FxRates.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    fxrates: getFxRates(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initFxrate: (payload) => {
      dispatch(initFxrate(INIT_FXRATE, payload));
    },
    destroyFxrate: (payload) => {
      dispatch(destroyFxrate(DESTROY_FXRATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FxRates));
