import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card, Icon, IconButton } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    padding: theme.spacing(0.5),
  },
  sectionHeader: {
    fontWeight: 'bold',
    color: theme.palette.text.subtitle,
  },
  sectionBody: {
    padding: theme.spacing(),
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(),
  },
  organisationName: {
    padding: theme.spacing(),
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const BrandProfile = (props) => {
  const { classes, brand } = props;

  return (
    <React.Fragment key={props.key}>
      <div className={classes.container}>
        <div className={classes.section}>
          <Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={classes.section}>
              <div className={classes.companyHeader}>
                <div className={classes.organisationName}>{brand}</div>
              </div>
            </div>

            <div className={classes.actionIcons}>
              <IconButton>
                <Icon>edit</Icon>
              </IconButton>

              <IconButton onClick={props.deleteFieldValue}>
                <Icon>delete</Icon>
              </IconButton>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

BrandProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(BrandProfile);
