export const NAME = 'orders';

export const GET_ORDERS = 'orders/get';
export const GET_ORDERS_SUCCESS = 'orders/get/success';
export const GET_ORDERS_ERROR = 'orders/get/error';

export const UPDATE_ORDERS = 'orders/update';
export const UPDATE_ORDERS_SUCCESS = 'orders/update/success';
export const UPDATE_ORDERS_ERROR = 'orders/update/error';

export const UPDATE_ORDERS_FROM_SOCKET = 'orders/update/from/socket';
export const UPDATE_ORDERS_FROM_SOCKET_SUCCESS = 'orders/update/from/socket/success';
export const UPDATE_ORDERS_FROM_SOCKET_ERROR = 'orders/update/from/socket/error';

export const INIT_ORDERS_SETTINGS = 'orders/settings/init';
export const INIT_ORDERS_SETTINGS_SUCCESS = 'orders/settings/init/success';
export const INIT_ORDERS_SETTINGS_ERROR = 'orders/settings/init/error';

export const RESET_ORDERS = 'orders/reset';

export const GET_ORDER = 'order/get';
export const GET_ORDER_SUCCESS = 'order/get/success';
export const GET_ORDER_ERROR = 'order/get/error';

export const CREATE_ORDER = 'order/create';
export const CREATE_ORDER_SUCCESS = 'order/create/success';
export const CREATE_ORDER_ERROR = 'order/create/error';

export const CREATE_ORDER_FROM_MANAGER = 'order/from/manager/create';
export const CREATE_ORDER_FROM_MANAGER_SUCCESS = 'order/from/manager/create/success';
export const CREATE_ORDER_FROM_MANAGER_ERROR = 'order/from/manager/create/error';

export const CREATE_ORDER_FROM_RFQ = 'order/from/rfq/create';
export const CREATE_ORDER_FROM_RFQ_SUCCESS = 'order/from/rfq/create/success';
export const CREATE_ORDER_FROM_RFQ_ERROR = 'order/from/rfq/create/error';

export const UPDATE_DATA_ORDER = 'dataOrder/update';
export const UPDATE_DATA_ORDER_SUCCESS = 'dataOrder/update/success';
export const UPDATE_DATA_ORDER_ERROR = 'dataOrder/update/error';

export const UPDATE_FORM = 'form/update';
export const UPDATE_FORM_SUCCESS = 'form/update/success';
export const UPDATE_FORM_ERROR = 'form/update/error';

export const INSERT_ORDERS_FROM_SOCKET = 'order/insert/from/socket';
export const INSERT_ORDERS_FROM_SOCKET_SUCCESS = 'order/insert/from/socket/success';
export const INSERT_ORDERS_FROM_SOCKET_ERROR = 'order/insert/from/socket/error';

export const UPDATE_ORDERS_MANAGER_TABLE = 'orders/manager/table/update';
export const UPDATE_ORDERS_MANAGER_TABLE_SUCCESS = 'orders/manager/table/update/success';
export const UPDATE_ORDERS_MANAGER_TABLE_ERROR = 'orders/manager/table/update/error';

export const ORDERS_MANAGER_INIT = 'orders/manager/init';
export const ORDERS_MANAGER_INIT_SUCCESS = 'orders/manager/init/success';
export const ORDERS_MANAGER_INIT_ERROR = 'orders/manager/init/error';

export const ORDERS_MANAGER_UPDATE = 'orders/manager/update';
export const ORDERS_MANAGER_UPDATE_SUCCESS = 'orders/manager/update/success';
export const ORDERS_MANAGER_UPDATE_ERROR = 'orders/manager/update/error';

export const ORDERS_MANAGER_TABLE_UPDATE = 'orders/manager/table/update';
export const ORDERS_MANAGER_TABLE_UPDATE_SUCCESS = 'orders/manager/table/update/success';
export const ORDERS_MANAGER_TABLE_UPDATE_ERROR = 'orders/manager/table/update/error';

export const ORDERS_MANAGER_TABLE_ADD_ROW = 'orders/manager/table/add/row';
export const ORDERS_MANAGER_TABLE_ADD_ROW_SUCCESS = 'orders/manager/table/add/row/success';
export const ORDERS_MANAGER_TABLE_ADD_ROW_ERROR = 'orders/manager/table/add/row/error';

export const ORDERS_MANAGER_TABLE_SEND_ORDERS = 'orders/manager/table/send/orders';
export const ORDERS_MANAGER_TABLE_SEND_ORDERS_SUCCESS = 'orders/manager/table/send/orders/success';
export const ORDERS_MANAGER_TABLE_SEND_ORDERS_ERROR = 'orders/manager/table/send/orders/error';

export const UPDATE_EDITOR_ORDER_LISTS = 'editor/orderLists/update';
export const UPDATE_EDITOR_ORDER_LISTS_SUCCESS = 'editor/orderLists/update/success';
export const UPDATE_EDITOR_ORDER_LISTS_ERROR = 'editor/orderLists/update/error';

export const UPDATE_AMEND_ORDER_FORM = 'form/amend/order/update';
export const UPDATE_AMEND_ORDER_FORM_SUCCESS = 'form/amendorder/update/success';
export const UPDATE_AMEND_ORDER_FORM_ERROR = 'form/amend/order/update/error';

export const SEND_ORDER_AMENDMENT = 'form/amend/order/send/order';
export const SEND_ORDER_AMENDMENT_SUCCESS = 'form/amend/order/send/order/success';
export const SEND_ORDER_AMENDMENT_ERROR = 'form/amend/order/send/order/error';

export const ERROR_VOLUME = 'Volume must be an integer > 0.';
export const ERROR_PRICE = 'Price must be a decimal( max 3 places ) > 0.';

export const UPDATE_AMEND_MULTIPLE_ORDER_FORM = 'form/amend/multiple/order/update';
export const UPDATE_AMEND_MULTIPLE_ORDER_FORM_SUCCESS = 'form/amend/multiple/order/update/success';
export const UPDATE_AMEND_MULTIPLE_ORDER_FORM_ERROR = 'form/amend/multiple/order/update/error';

export const SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM = 'form/amend/multiple/order/send/order';
export const SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_SUCCESS =
  'form/amend/multiple/order/send/order/success';
export const SEND_ORDER_FROM_AMEND_MULTIPLE_ORDER_FORM_ERROR =
  'form/amend/multiple/order/send/order/error';

export const CHANGE_STATE_ORDERS = 'orders/state/change';
