// TODO: Analyse createAction from redux

export const createAction = (type, payload, meta) => {
  return {
    type,
    payload,
    meta,
  };
};
export const socketAction = (type, payload, meta) => {
  return {
    type,
    payload,
    meta,
  };
};
