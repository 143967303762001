import { createSelector } from 'reselect';

const getThemesProp = (state) => state.themes;
export const getThemes = createSelector(getThemesProp, (themes) => themes);

const getThemeProp = (state) => state.themes.theme;
export const getTheme = createSelector(getThemeProp, (theme) => theme);

const getThemeTypeProp = (state) => state.themes.type;
export const getThemeType = createSelector(getThemeTypeProp, (type) => type);
