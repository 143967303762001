import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import NumberFieldFilled from '../../../../commons/components/formFields/numberFieldFilled';
import TextFieldFilled from '../../../../commons/components/formFields/textFieldFilled';
import { errorMessages } from '../../../../commons/models/constants';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';

const styles = (theme) => ({
  tableCell: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: '0px',
    flex: 1,
    maxWidth: '200px',
    minWidth: '200px',
    minHeight: '253.33px',
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  tableCellNoUnderline: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: '0px',
    flex: 1,
    maxWidth: '200px',
    minWidth: '200px',
    minHeight: '253.33px',
    borderBottomWidth: '0px',
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  tableCellIcon: {
    borderBottomWidth: '0px',
  },
  tableCellCalculator: {
    padding: 0,
    '&:last-child': {
      paddingRight: '0px',
    },
  },
  container: { marginTop: '10px' },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
});

class AmendMultipleOrderFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      showCalculator: false,
    };
  }

  propsToState = () => {
    this.setState({
      ...this.props.orderRow.order,
    });
  };

  componentDidMount() {
    this.propsToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderRow !== prevProps.orderRow) {
      this.propsToState();
    }
  }

  updatePrice = (price) => {
    this.setState({ price });
    this.props.orderRow.order.price = price;
    this.props.updateOrderRow(this.props.orderRow);
  };

  calculatorClick = () => {
    this.setState({ showCalculator: !this.state.showCalculator });
  };

  checkValid = (key, value) => {
    const returnValue = { message: '', value: false };
    switch (key) {
      case 'price':
        const price = checkPrice(value);
        returnValue.value = !price;
        returnValue.message = price ? '' : errorMessages.price;
        break;
      case 'volume':
        const volume = checkVolume(value);

        returnValue.value = !volume;
        returnValue.message = volume ? '' : errorMessages.volume;
        break;
      case 'targetOrganisations':
        returnValue.value = value.length !== 0;
        returnValue.message = errorMessages.targetOrganisations;
        break;
      default:
    }
    return returnValue;
  };

  handleChange = (name) => (event) => {
    const { errors } = this.state;
    const valid = this.checkValid(name, event.target.value);
    errors[name] = valid;

    this.setState({
      [name]: event.target.value,
      errors,
    });
    this.props.orderRow.order[name] = event.target.value;
    this.props.updateOrderRow(this.props.orderRow);
  };

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  getErrors = (key) => {
    if (this.state.errors[key]) {
      return this.state.errors[key];
    }
    return { message: '', value: false };
  };

  render() {
    const { classes, contract } = this.props;

    let subSegment = {};

    if (
      Object.prototype.toString.call(contract) === '[object Object]' &&
      Object.prototype.toString.call(contract.orderFields) === '[object Object]' &&
      Object.prototype.toString.call(contract.orderFields[contract.subSegment]) ===
        '[object Object]'
    ) {
      subSegment = contract.orderFields[contract.subSegment];
    }

    /*  const rfqCurrency =
      this.props.activeRfq === undefined || this.props.activeRfq === null
        ? 'EUR'
        : this.props.activeRfq.contract.underlying.currency;
 */
    const FIELD_WIDTH = '150px';
    const harvestDate =
      this.state.contract !== undefined
        ? moment(this.state.contract.harvestDate).format('DD-MM-YYYY')
        : '';
    const deliveryDate =
      this.state.contract !== undefined
        ? this.state.contract.deliveryDate !== null
          ? moment(this.state.contract.deliveryDate).format('DD-MM-YYYY')
          : ''
        : '';

    const subsegment = this.state.contract
      ? this.state.contract.underlying[subSegment.accessor]
      : '';
    const region = this.state.contract ? this.state.contract.underlying.region : '';
    const packingStation = this.state.contract ? this.state.contract.underlying.packingStation : '';
    const quality = this.state.contract ? this.state.contract.underlying.quality : '';
    const certification = this.state.contract ? this.state.contract.underlying.certification : '';
    return (
      <>
        <Grid container className={classes.rowMargin}>
          <Grid item>
            <TextFieldFilled
              accessor={subSegment.accessor}
              displayName={subSegment.displayName}
              value={subsegment}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
              // any={field.orderForm.any}
            />
          </Grid>
          <Grid item>
            <NumberFieldFilled
              accessor="volume"
              displayName="Volume"
              value={this.state.volume || 0}
              handleChange={this.handleChange}
              error={this.getErrors('volume')}
              adornment={this.state.unit}
              width={FIELD_WIDTH}
            />
          </Grid>

          <Grid item>
            <NumberFieldFilled
              accessor="price"
              displayName="price"
              value={this.state.price || 0}
              handleChange={this.handleChange}
              error={this.getErrors('price')}
              adornment={this.state.currency}
              width={FIELD_WIDTH}
              step={0.1}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="region"
              displayName="Region"
              value={region}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="packingStation"
              displayName="Packing Station"
              value={packingStation}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="quality"
              displayName="Quality"
              value={quality}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="certification"
              displayName="Certification"
              value={certification}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="harvestDate"
              displayName="Harvest Date"
              value={harvestDate}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>
          <Grid item>
            <TextFieldFilled
              accessor="deliveryDate"
              displayName="Delivery Date"
              value={deliveryDate}
              handleChange={() => void 0}
              width={FIELD_WIDTH}
            />
          </Grid>

          {/* <Grid item>
            <Tooltip title="View Calculator" aria-label="View Calculator">
              <IconButton onClick={this.calculatorClick}>
                <TableChartIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>

        {/* <Grid container className={classes.tableRowVisible}>
          <Grid item className={classes.tableCellCalculator} colSpan={25} align="center">
         
            <Collapse in={this.state.showCalculator}>
              <calculators.components.Calculator
                id={`rfq[${activeRfq.id}]`}
                isRfq
                quantity={this.state.volume}
                quantityUnit={this.state.unit}
                updatePrice={this.updatePrice}
                updateDefaultCalculatorId={this.props.updateDefaultCalculatorId}
                defaultCalculatorId={this.props.defaultCalculatorId}
              />
            </Collapse>
          
          </Grid>
        </Grid>  */}
      </>
    );
  }
}

AmendMultipleOrderFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AmendMultipleOrderFormRow);
