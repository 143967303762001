import moment from 'moment';
import {
  GET_CHATS_SUCCESS,
  CHATS_CHAT_RECEIVED,
  CHATS_SAVE_MESSAGES,
  CHATS_SET_ACTIVE_TAB,
} from '../actions';

import {
  CHATS_SELECT_CHAT_SUCCESS,
  CHATS_UPDATE_SUCCESS,
  CHATS_RECEIVE_MESSAGES_FROM_SOCKET_SUCCESS,
  CHATS_INSERT_CHATS_SUCCESS,
  MODAL_CHAT_OPEN,
} from '../constants';

import auth from '../../auth';

// TODO: REFACTOR! CHANGE STRUCTURE: byId & allIds
const initialState = {
  chats: [],
  selectedChatId: null,
  activeTab: 0,
  lastCreatedTimeChat: null,
  lastCreatedTimeMessage: null,
  modalChat: {
    open: false,
    trade: null,
  },
};

// TODO: REFACTOR. more than one action and masks!
function filterAndSortMessages(messages) {
  const existing = {};
  let lastCreatedTime = null;

  const sortedMessages = messages
    .filter((item) => {
      if (!existing[item.id]) {
        existing[item.id] = true;

        if (
          item.createdTime &&
          (lastCreatedTime === null || new Date(lastCreatedTime) < new Date(item.createdTime))
        ) {
          lastCreatedTime = item.createdTime;
        }

        return true;
      }
      return false;
    })
    .sort((a, b) => {
      return moment(a.createdTime).format('x') - moment(b.createdTime).format('x');
    });

  return { messages: sortedMessages, lastCreatedTime };
}

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case GET_CHATS_SUCCESS:
      if (Array.isArray(action.payload)) {
        nextState = {
          ...state,
        };

        const formattedChats = action.payload.map((chat) => {
          if (
            chat.createdTime &&
            (nextState.lastCreatedTimeChat === null ||
              new Date(nextState.lastCreatedTimeChat) < new Date(chat.createdTime))
          ) {
            nextState.lastCreatedTimeChat = chat.createdTime;
          }

          return {
            ...chat,
            messages: [],
            newMessages: 0,
          };
        });

        nextState.chats = formattedChats;

        return nextState;
      }

      return state;

    case CHATS_INSERT_CHATS_SUCCESS:
      if (Array.isArray(action.payload.chats)) {
        nextState = {
          ...state,
        };

        const newChats = [];

        action.payload.chats.forEach((chat) => {
          const c = state.chats.find((ch) => ch.id === chat.id);

          if (!c) {
            newChats.push({ ...chat, messages: [], newMessages: 0 });

            if (
              chat.createdTime &&
              (nextState.lastCreatedTimeChat === null ||
                new Date(nextState.lastCreatedTimeChat) < new Date(chat.createdTime))
            ) {
              nextState.lastCreatedTimeChat = chat.createdTime;
            }
          }
        });

        nextState.chats = [...nextState.chats, ...newChats];

        return nextState;
      }

      return state;
    // TODO: REFACTOR! UPDATE ONLY ONE CHAT WITHOUT COPY ALL CHATS. CHANGE STRUCTURE: byId, allIds
    case CHATS_RECEIVE_MESSAGES_FROM_SOCKET_SUCCESS:
      if (typeof action.payload.chatId !== 'undefined') {
        nextState = {
          ...state,
        };

        nextState.chats = nextState.chats.map((chat) => {
          if (chat.id === action.payload.chatId) {
            let updateChat = null;

            if (typeof action.payload.chat !== 'undefined') {
              updateChat = {
                ...chat,
                ...action.payload.chat,
              };
            } else {
              updateChat = {
                ...chat,
              };
            }

            if (typeof action.payload.message !== 'undefined') {
              const mapMessages = filterAndSortMessages([
                ...updateChat.messages,
                action.payload.message,
              ]);

              updateChat.messages = mapMessages.messages;

              if (
                mapMessages.lastCreatedTime &&
                (nextState.lastCreatedTimeMessage === null ||
                  new Date(nextState.lastCreatedTimeMessage) <
                    new Date(mapMessages.lastCreatedTime))
              ) {
                nextState.lastCreatedTimeMessage = mapMessages.lastCreatedTime;
              }
            }

            if (typeof action.payload.newMessages !== 'undefined') {
              updateChat.newMessages += action.payload.newMessages;
            }

            if (typeof action.payload.lastReadMessageTime !== 'undefined') {
              updateChat.lastReadMessageTime += action.payload.lastReadMessageTime;
            }

            return updateChat;
          }

          return {
            ...chat,
          };
        });

        return nextState;
      }

      return state;
    case CHATS_CHAT_RECEIVED:
      if (state.chats.find(({ id }) => id === action.payload.id)) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        chats: [...state.chats, { ...action.payload, messages: [], newMessages: 0 }],
      };
    case CHATS_SAVE_MESSAGES:
      nextState = {
        ...state,
      };

      nextState.chats = nextState.chats.map((item) => {
        if (item.id === action.payload.chatId) {
          const mapMessages = filterAndSortMessages([...item.messages, ...action.payload.messages]);

          const nextChat = {
            ...item,
            messages: mapMessages.messages,
          };

          if (
            mapMessages.lastCreatedTime &&
            (nextState.lastCreatedTimeMessage === null ||
              new Date(nextState.lastCreatedTimeMessage) < new Date(mapMessages.lastCreatedTime))
          ) {
            nextState.lastCreatedTimeMessage = mapMessages.lastCreatedTime;
          }

          nextChat.newMessages = getCountNewMessages(nextChat, action.payload.userId);

          return nextChat;
        }
        return {
          ...item,
        };
      });

      return nextState;
    case CHATS_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case CHATS_UPDATE_SUCCESS:
      // TODO: REFACTOR! REPLACE on byId, allIds
      return {
        ...state,
        chats: state.chats.map((chat) => {
          const newChat = action.payload.chats.find((c) => c.id === chat.id);

          if (newChat) {
            const updateChat = {
              ...chat,
              ...newChat,
            };

            if (typeof action.payload.newMessages !== 'undefined') {
              updateChat.newMessages = action.payload.newMessages;
            }

            return updateChat;
          }

          return {
            ...chat,
          };
        }),
      };
    case CHATS_SELECT_CHAT_SUCCESS:
      // TODO: REFACTOR! REPLACE on byId, allIds
      return {
        ...state,
        selectedChatId: action.payload.id,
        chats: state.chats.map((item) => {
          if (item.id === action.payload.id) {
            let newSelectedChat = null;

            if (typeof action.payload.chat !== 'undefined') {
              newSelectedChat = {
                ...item,
                ...action.payload.chat,
              };
            } else {
              newSelectedChat = {
                ...item,
              };
            }

            if (typeof action.payload.newMessages !== 'undefined') {
              newSelectedChat.newMessages = action.payload.newMessages;
            }

            if (typeof action.payload.lastReadMessageTime !== 'undefined') {
              newSelectedChat.lastReadMessageTime = action.payload.lastReadMessageTime;
            }

            return newSelectedChat;
          }
          return {
            ...item,
          };
        }),
      };
    case MODAL_CHAT_OPEN:
      const newModalChat = state.modalChat;

      if (action.payload) {
        newModalChat.open = action.payload.open;
        newModalChat.trade = action.payload.trade || null;
      }
      return {
        ...state,
        newModalChat,
      };
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

function getCountNewMessages(chat, userId) {
  let countNewMessages = 0;

  chat.messages.forEach((message) => {
    if (
      message.createdBy !== userId &&
      (chat.lastReadMessageTime === null ||
        moment(message.createdTime).valueOf() > moment(chat.lastReadMessageTime).valueOf())
    ) {
      countNewMessages += 1;
    }
  });

  return countNewMessages;
}

export default reducer;
