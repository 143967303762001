import moment from 'moment';
import { orderFields } from '../models/order';

export const getMarket = (market) => {
  return market.market;
};

export const getSegment = (market) => {
  return market.segment;
};

export const getSegmentValues = (market) => {
  if (
    Object.prototype.toString.call(market) === '[object Object]' &&
    Object.prototype.toString.call(market.contract) === '[object Object]' &&
    Object.prototype.toString.call(market.contract.underlying) === '[object Object]' &&
    Object.prototype.toString.call(market.contract.underlying[market.segment]) === '[object Object]'
  ) {
    return Object.values(market.contract.underlying[market.segment].values);
  }

  return [];
};

export const getSegmentDefaultValue = (market) => {
  const values = getSegmentValues(market);
  return values[0];
};

export const getSegmentDefaultValueSegmentName = (market) => {
  const values = getSegmentValues(market);
  return Array.isArray(values) && values.length > 0 ? values[0].segmentName : null;
};

export const getSubSegment = (market) => {
  return market.subSegment;
};

export const getSubSegmentObject = (market) => {
  return market.contract.underlying[market.subSegment];
};

export const getSubSegmentValues = (market) => {
  return Object.keys(market.contract.underlying[market.subSegment].values);
};
export const getSubSegmentShortName = (market) => {
  return market.contract.underlying[market.subSegment].shortName;
};
export const getsubSegmentDefaultValue = (market) => {
  const values = getSubSegmentValues(market);
  return values[0];
};

export const getSingleSegmentDefaultValues = (market, name) => {
  const segments = getSegmentValues(market);
  const segment = segments.find((seg) => seg.name === name);
  return segment.defaultValues;
};

export const getAllContractFields = (market) => {
  const fields = [];
  Object.keys(market.contract.underlying).forEach((key) => {
    fields.push(market.contract.underlying[key]);
  });
  Object.keys(market.contract).forEach((key) => {
    if (key !== 'underlying') {
      fields.push(market.contract[key]);
    }
  });
  return fields;
};

export const getOrderFormContractFields = (market) => {
  const fields = [];
  Object.keys(market.contract.underlying).forEach((key) => {
    if (market.contract.underlying[key].useOrderForm === 'true') {
      fields.push(market.contract.underlying[key]);
    }
  });
  Object.keys(market.contract).forEach((key) => {
    if (market.contract[key].useOrderForm === 'true' && key !== 'underlying') {
      fields.push(market.contract[key]);
    }
  });
  return fields;
};

export const getRfqFormContractFields = (market) => {
  const fields = [];
  Object.keys(market.contract.underlying).forEach((key) => {
    if (market.contract.underlying[key].useRfqForm === 'true') {
      fields.push(market.contract.underlying[key]);
    }
  });
  Object.keys(market.contract).forEach((key) => {
    if (market.contract[key].useRfqForm === 'true' && key !== 'underlying') {
      fields.push(market.contract[key]);
    }
  });
  return fields;
};

export const defaultRfqRequest = (market, segmentName) => {
  const newOrder = {};
  const contract = {};
  const underlying = {};
  if (segmentName === null || segmentName === undefined) {
    segmentName = getSegmentDefaultValueSegmentName(market);
  }

  const segments = getSegmentValues(market);
  const segment = segments.find((seg) => seg.segmentName === segmentName);

  Object.keys(market.contract.underlying).forEach((key) => {
    const values = Object.values(market.contract.underlying[key].values || []) || [];

    const val = values.length === 0 ? null : values[0].name;

    if (market.contract.underlying[key].rfqType === 'multiple') {
      underlying[key] = [val];
    } else {
      underlying[key] = val;
    }
  });

  Object.keys(segment.defaultValues).forEach((key) => {
    underlying[key] = segment.defaultValues[key];
  });
  underlying[market.segment] = segment.name;

  Object.keys(market.contract).forEach((key) => {
    const values = Object.values(market.contract[key].values || []) || [];
    if (key === 'underlying') {
      contract[key] = underlying;
    } else if (market.contract[key].type === 'date') {
      const days = values[0];
      contract[key] = moment().add(days, 'days');
    } else {
      contract[key] = values.length === 0 ? null : values[0];
    }
  });

  newOrder.contract = contract;

  Object.keys(orderFields).forEach((key) => {
    if (orderFields[key].newOrderForm === true) {
      newOrder[key] = orderFields[key].default;
    }
  });

  return newOrder;
};

export const getUnderlyingOptions = (market, segmentValue, standard) => {
  const underlying = {};

  const segments = getSegmentValues(market);
  const segment = segments.find((seg) => seg.name === segmentValue);

  Object.keys(market.contract.underlying).forEach((key) => {
    const underlyingValues = [];

    Object.values(market.contract.underlying[key].values || []).forEach((val) => {
      underlyingValues.push(val.name);
    });

    underlying[key] = underlyingValues;
  });

  Object.keys(segment.defaultValues).forEach((key) => {
    if (standard) {
      // underlying[key] = [segment.defaultValues[key].name];
      underlying[key] = [segment.defaultValues[key]];
    } else {
      const values = [...underlying[key]];
      if (values.indexOf(segment.defaultValues[key]) === -1) {
        values.unshift(segment.defaultValues[key]);
      }
      underlying[key] = [...values];
    }
  });

  return underlying;
};
