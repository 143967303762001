import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';

import { TextField, FormControl, InputAdornment, Tooltip } from '@material-ui/core';
import { textField, fieldFontSize } from './formFieldStyles';
import { formatDate, formatDateTime } from '../../config/formatters';
import CustomText from './customText';
import clsx from 'clsx';

const styles = (theme) => ({
  text1: textField(theme),
  textFont: fieldFontSize(theme),
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
  inputTextHighlight: {
    color: lightBlue[300],
    ...fieldFontSize(theme),
  },
  root: {
    '& .MuiFilledInput-input': {
      padding: '25px 12px 7px   !important',
    },
  },
  ellipsis: {
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

const ReadOnlyField = (props) => {
  const { classes, value, displayName = ' ', adornment = ' ', fieldType = 'string' } = props;
  let displayValue;
  if (fieldType === 'numeric') {
    displayValue = value;
  } else {
    displayValue = value ? CustomText({ value }) : ' ';
  }

  let textStyle = {};
  if (
    (displayName.toUpperCase().includes('MIN') ||
      displayName.toUpperCase().includes('MAX') ||
      displayName.toUpperCase().includes('DATE')) &&
    displayName !== 'updatedTime'
  ) {
    if (value !== null && value !== undefined) {
      if (displayName.toUpperCase().includes('HARVEST')) {
        //textStyle = { color: teal[300] };
        displayValue = value ? formatDate(value) : '';
      } else if (displayName.toUpperCase().includes('DELIVERY')) {
        //textStyle = { color: lightBlue[300] };
        displayValue = value ? formatDate(value) : '';
      } else if (displayName.toUpperCase() === 'MAX. FREEZE') {
        displayValue = value;
      } else if (displayName.toUpperCase().includes('FREEZE')) {
        displayValue = value ? formatDate(value) : '';
      } else {
        displayValue = value ? formatDate(value) : '';
      }
    } else {
      displayValue = '';
    }
  } else if (displayName.toUpperCase().includes('DIRECTION')) {
    if (value === 'BUY') {
      textStyle = { backgroundColor: lightBlue[500], color: 'white' };
    } else if (value === 'SELL') {
      textStyle = { backgroundColor: teal[500], color: 'white' };
    }
  } else if (displayName.toUpperCase().includes('START DAY')) {
    displayValue = formatDate(value);
  } else if (displayName.toUpperCase().includes('DELIVERY DATE')) {
    displayValue = formatDate(value);
  } else if (displayName.toUpperCase().includes('PROCESSING DATE')) {
    displayValue = formatDate(value);
  } else if (displayName.toUpperCase().includes('PRESENTATION')) {
    //textStyle = { color: lightBlue[300] };
  } else if (displayName.toLowerCase().includes('time')) {
    displayValue = formatDateTime(value);
  }

  return (
    <FormControl className={props.noMargin ? null : classes.text1} fullWidth={props.fullWidth}>
      <Tooltip title={<CustomText value={displayValue} />}>
        <TextField
          id={props.key}
          key={props.key}
          type="text"
          label={<CustomText value={displayName} />}
          variant="filled"
          size="small"
          readOnly
          multiline={props.multiline}
          style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
          value={displayValue}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            className: clsx(
              props.highlight ? classes.inputTextHighlight : classes.textFont,
              props.dark && classes.darkBackground,
              props.warning && classes.warningBackground,
              props.ellipsis && classes.ellipsis,
            ),
            style: textStyle,
            endAdornment: (
              <InputAdornment position="end">
                <CustomText value={adornment} />
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

ReadOnlyField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReadOnlyField);
