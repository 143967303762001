import { call, put, select, fork, takeEvery, take, all } from 'redux-saga/effects';

import moment from 'moment';

import {
  getChats,
  getMessages,
  getSelectedChatId,
  getActiveUser,
  getLastCreatedTimeMessage,
  getLastCreatedTimeChat,
} from '../selectors';
import api from '../api';
import {
  CHATS_CREATE_CHAT,
  CHATS_SEND_MESSAGE,
  CHATS_LOAD_HISTORY,
  CHATS_CREATE_RFQ_CHAT,
  getChats as getChatsAction,
  updateChats as actionUpdateChats,
  saveMessages,
  selectChat as actionSelectChat,
  selectNewChat as actionSelectNewChat,
  chatReceived,
  receiveMessages as actionReceiveMessages,
  insertChats as actionInsertChats,
} from '../actions';

import chatListenerSaga from './socket';
import { checkError } from '../../auth/sagas';

import { CHECK_AUTH_SUCCESS } from '../../auth/constants';

import workspaces from '../../workspaces';

import { isObject } from '../../../commons/utils/functions';

import {
  CHATS_UPDATE,
  CHATS_UPDATE_SUCCESS,
  CHATS_UPDATE_ERROR,
  CHATS_SELECT_CHAT,
  CHATS_SELECT_CHAT_SUCCESS,
  CHATS_RECEIVE_MESSAGES_FROM_SOCKET,
  CHATS_RECEIVE_MESSAGES_FROM_SOCKET_SUCCESS,
  CHATS_RECEIVE_MESSAGES_FROM_SOCKET_ERROR,
  CHATS_INSERT_CHATS_SUCCESS,
  CHATS_INSERT_CHATS_ERROR,
} from '../constants';

import auth from '../../auth';
import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';

function findChatToSelect(chats) {
  if (!Array.isArray(chats) || chats.length === 0) return null;
  const globalChat = chats.find((item) => item.type === 'GLOBAL');
  return globalChat ? globalChat.id : chats[0].id;
}

// TODO: REFACTOR! TEMP SOLUTION FOR RELEASE
function* getAllChats() {
  try {
    let token = yield call(auth.selectors.getToken);
    if (!token) {
      yield take(CHECK_AUTH_SUCCESS);
      token = yield call(auth.selectors.getToken);
    }

    const chats = yield call(api.getAllChats, { token });

    yield put(getChatsAction(chats));
    yield put(actionSelectChat(findChatToSelect(chats)));

    for (let i = 0; i < chats.length; i += 1) {
      const messages = yield select(getMessages, { selectedChatId: chats[i].id });

      if (messages.length > 0) {
        const oldestMessage = messages.filter((a, b) => {
          return moment(a.createdTime).format('x') - moment(b.createdTime).format('x');
        })[0];
        yield call(getMessagesSaga, {
          chatId: chats[i].id,
          createdTimeTo: oldestMessage.createdTime,
        });
      } else {
        yield call(getMessagesSaga, { chatId: chats[i].id });
      }
    }
  } catch (e) {
    console.error(e);
    yield call(checkError, e);
  }
}

function* createChatSaga(action) {
  try {
    const chats = yield select(getChats);
    const idsKey = `${action.payload.type.toLowerCase()}Ids`;

    // TODO: REPLACE!
    const exist = chats.find((item) => {
      return (
        item.type === 'GLOBAL' ||
        (item.type === action.payload.type &&
          (action.payload.id !== action.payload.ownId // TODO: Review. id chat !== own id = organisation or user Id???
            ? item[idsKey].includes(action.payload.id)
            : item[idsKey].includes(action.payload.id) && item[idsKey].length === 1))
      );
    });

    if (exist) {
      yield put(actionSelectChat(exist.id));
    } else {
      const token = yield call(auth.selectors.getToken);
      const body = {
        type: action.payload.type,
        name: action.payload.name,
      };
      body[idsKey] = [action.payload.id];
      if (action.payload.id !== action.payload.ownId) {
        body[idsKey].push(action.payload.ownId);
      }
      const createdChat = yield call(api.createChat, { token, body });
      yield put(chatReceived(createdChat));
      yield put(actionSelectChat(createdChat.id));
    }
  } catch (e) {
    console.error(e);
    yield call(checkError, e);
  }
}

function* createRfqChatSaga(action) {
  try {
    const chats = yield select(getChats);
    const exist = chats.find((item) => item.rfqId === action.payload.rfqId);

    if (!exist) {
      const token = yield call(auth.selectors.getToken);

      const body = {
        type: action.payload.type,
        name: action.payload.name,
        rfqId: action.payload.rfqId,
        organisationIds: action.payload.organisationIds,
      };

      const createdChat = yield call(api.createChat, { token, body });

      yield put(chatReceived(createdChat));

      // TODO. CRITICAL!!! NEED REMOVE AND EXTRACT ALL FUNCTIONALITY FROM RFQ IN SAGA. ONLY FOR RELEASE. AFTER RELEASE NEED REFACTOR.
      if (Object.prototype.toString.call(action.payload.callback) === '[object Function]') {
        action.payload.callback();
      }
    }
  } catch (e) {
    console.error(e);
    yield call(checkError, e);
  }
}

function* createChatListener() {
  yield takeEvery(CHATS_CREATE_CHAT, createChatSaga);
}

function* createRfqChatListener() {
  yield takeEvery(CHATS_CREATE_RFQ_CHAT, createRfqChatSaga);
}

function* sendMessageSaga({ payload: { text, chatId } }) {
  try {
    if (!text || text.length === 0 || (text.match(/\s+/g) && text.match(/\s+/g)[0] === text)) {
      return;
    }
    const token = yield call(auth.selectors.getToken);
    const body = {
      text,
    };
    yield call(api.sendMessage, { token, body, chatId });
  } catch (e) {
    console.error(e);
    yield call(checkError, e);
  }
}

function* sendMessageSagaListener() {
  yield takeEvery(CHATS_SEND_MESSAGE, sendMessageSaga);
}

function* getMessagesSaga({ chatId, createdTimeFrom, createdTimeTo }) {
  try {
    const token = yield call(auth.selectors.getToken);
    const messages = yield call(api.getMessages, { token, chatId, createdTimeFrom, createdTimeTo });
    const { id: userId } = yield select(getActiveUser);

    yield put(saveMessages({ messages, chatId, userId }));
  } catch (e) {
    console.error(e);
    yield call(checkError, e);
  }
}

function* loadHistoryListener() {
  while (true) {
    const action = yield take(CHATS_LOAD_HISTORY);
    const messages = yield select(getMessages, { selectedChatId: action.payload });
    if (messages.length > 0) {
      const oldestMessage = messages.filter((a, b) => {
        return moment(a.createdTime).format('x') - moment(b.createdTime).format('x');
      })[0];
      yield call(getMessagesSaga, {
        chatId: action.payload,
        createdTimeTo: oldestMessage.createdTime,
      });
    } else {
      yield call(getMessagesSaga, { chatId: action.payload });
    }
  }
}

function* updateChats(action) {
  try {
    const {
      payload: { action: actionChats, items = [] },
    } = action;

    const payload = {
      items: [],
    };

    const meta = {
      receivedAt: new Date(),
    };

    if (actionChats === 'markAsRead') {
      if (Array.isArray(items) && items.length > 0) {
        const token = yield call(auth.selectors.getToken);

        const tasks = [];

        items.forEach((item) => {
          if (!item.id) return;

          const updateChat = {
            chatId: item.id,
            lastReadMessageTime: moment().toISOString(),
          };

          tasks.push(call(api.updateChat, { token, body: updateChat }));
        });

        if (tasks.length > 0) {
          const chats = yield all(tasks);
          // TODO: REFACTOR. TEMP SOLUTION. MERGE ALL PROPS FROM SERVER SIDE
          const updatedChats = chats.map((c) => {
            return {
              id: c.id,
              lastReadMessageTime: c.lastReadMessageTime,
            };
          });

          payload.chats = updatedChats;
          payload.newMessages = 0;
        }
      }
    }

    yield put(actionUpdateChats(CHATS_UPDATE_SUCCESS, payload, meta));
  } catch (error) {
    const checkedError = yield call(checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionUpdateChats(CHATS_UPDATE_ERROR, { error }));
    }
  }
}

function* watchUpdateChats() {
  yield takeEvery(CHATS_UPDATE, updateChats);
}

function* selectChat(action) {
  try {
    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    if (isObject(action.payload)) {
      const {
        payload: { id, name, type, ownId },
      } = action;

      const chats = yield select(getChats);

      const chat = chats.find((c) => c.id === id);

      if (chat) {
        payload.id = id;
      } else {
        const idsKey = `${type.toLowerCase()}Ids`;

        const body = {
          type,
          name,
        };

        body[idsKey] = [id];

        if (id !== ownId) {
          body[idsKey].push(ownId);
        }

        const token = yield call(auth.selectors.getToken);

        const createdChat = yield call(api.createChat, { token, body });

        payload.id = createdChat.id;
        payload.chat = createdChat;
        payload.newMessages = 0;
      }
    } else {
      const { payload: chatId } = action;
      payload.id = chatId;
    }

    yield put(actionSelectNewChat(CHATS_SELECT_CHAT_SUCCESS, payload, meta));
  } catch (error) {
    const checkedError = yield call(checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      // yield put(actionSelectNewChat(CHATS_SELECT_CHAT_ERROR, { error }));
    }
  }
}

function* watchSelectChat() {
  yield takeEvery(CHATS_SELECT_CHAT, selectChat);
}

function* receiveMessagesFromSocket(action) {
  try {
    const { payload: message = {} } = action;

    const payload = {};

    if (typeof message.chatRoomId !== 'undefined') {
      payload.chatId = message.chatRoomId;
      payload.message = message;

      const user = yield select(getActiveUser);

      if (message.createdBy !== user.id) {
        const selectedChatId = yield select(getSelectedChatId);
        if (selectedChatId === message.chatRoomId) {
          const widgets = yield select(workspaces.selectors.getActiveWorkspaceWidgets);
          if (widgets.find(({ type }) => type === 'CHAT')) {
            const updateChat = {
              chatId: selectedChatId,
              lastReadMessageTime: moment().toISOString(),
            };
            const token = yield call(auth.selectors.getToken);
            const chat = yield call(api.updateChat, { token, body: updateChat }); // TODO: Review for use socket
            payload.lastReadMessageTime = chat.lastReadMessageTime;
          } else {
            payload.newMessages = 1;
          }
        } else {
          payload.newMessages = 1;
        }
      }
    }

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionReceiveMessages(CHATS_RECEIVE_MESSAGES_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    const checkedError = yield call(checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionReceiveMessages(CHATS_RECEIVE_MESSAGES_FROM_SOCKET_ERROR, { error }));
    }
  }
}

function* watchReceiveMessagesFromSocket() {
  yield takeEvery(CHATS_RECEIVE_MESSAGES_FROM_SOCKET, receiveMessagesFromSocket);
}

function* switchActiveTab() {
  const widgets = yield select(workspaces.selectors.getActiveWorkspaceWidgets);
  if (widgets.find(({ type }) => type === 'CHAT')) {
    const selectedChat = yield select(getSelectedChatId);
    if (!selectedChat) {
      const chats = select(getAllChats);
      actionSelectChat(findChatToSelect(chats));
    }
  } else {
    actionSelectChat(null);
  }
}

function* changeWorkspaceListener() {
  yield takeEvery(workspaces.constants.UPDATE_WORKSPACE_SUCCESS, switchActiveTab);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* getAll() {
  try {
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastCreatedTime = yield select(getLastCreatedTimeChat);

    if (lastCreatedTime) {
      options.params.createdTimeFrom = lastCreatedTime;
    }

    const chats = yield call(api.getAllChats, options);

    const payload = {
      chats,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actionInsertChats(CHATS_INSERT_CHATS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(actionInsertChats(CHATS_INSERT_CHATS_ERROR, { error }, { receivedAt: new Date() }));
  }
}

function* getAllMessages() {
  const chats = yield select(getChats);

  if (chats.length > 0) {
    const lastCreatedTime = yield select(getLastCreatedTimeMessage);

    const chat = {};

    if (lastCreatedTime) {
      chat.createdTimeFrom = lastCreatedTime;
    }

    // TODO: REFACTOR! CHAINS AND WAIT!! USE multi REQUEST OR ASYN|SETTIMEOUT FOR SPLIT OPERATIONS
    for (let i = 0; i < chats.length; i += 1) {
      yield call(getMessagesSaga, {
        ...chat,
        chatId: chats[i].id,
      });
    }
  }
}

// TODO: TEMP SOLUTION FOR RELEASE! REFACTOR. JOIN WITH getAllChats. EXTRACT getChats, selectChat and getMessgaes into single methods.
function* refreshChats() {
  try {
    yield call(getAll);
    yield call(getAllMessages);
  } catch (e) {
    yield call(checkError, e);
  }
}

function* socketReconnected() {
  yield call(refreshChats);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}

export default function* initChats() {
  yield fork(chatListenerSaga);
  yield fork(createChatListener);
  yield fork(sendMessageSagaListener);
  yield fork(loadHistoryListener);
  yield fork(createRfqChatListener);
  yield fork(getAllChats);
  yield fork(changeWorkspaceListener);
  yield fork(watchUpdateChats);
  yield fork(watchSelectChat);
  yield fork(watchReceiveMessagesFromSocket);
  yield fork(watchSignOutSuccess);
  yield fork(watchSocketReconnected);
}
