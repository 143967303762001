import { createSelector } from 'reselect';
import { NAME, RFQ_STATUS_ENDED } from '../constants';

import contracts from '../../contracts';
import organisations from '../../organisations';
import auth from '../../auth';
import users from '../../users';
import orders from '../../orders';
import settings from '../../settings';
import schemas from '../../schemas';
import * as marketSelectors from '../../market/selectors';

import { getFromLS } from '../../../commons/localStorage';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyOrder = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyOrder;
};
export const getById = createSelector(getByIdProp, (item) => item);

export const getActiveContract = createSelector(
  contracts.selectors.getActiveContract,
  (item) => item,
);

export const getActiveMarket = createSelector(marketSelectors.getActiveMarket, (item) => item);

const getFormCreateProp = (state) => {
  const { create } = state[NAME].forms;
  return create;
};
export const getFormCreate = createSelector(getFormCreateProp, (form) => form);

const isOpenFormCreateProp = (state) => {
  const { create } = state[NAME].forms;
  const value = create.open;
  return value;
};
export const isOpenFormCreate = createSelector(isOpenFormCreateProp, (open) => open);

export const getOrganisations = createSelector(organisations.selectors.getAll, (items) => items);

const getRfqFormOrderProp = createSelector(getFormCreate, (form) => form.item);
export const getRfqFormOrder = createSelector(getRfqFormOrderProp, (item) => item);

export const getActiveUser = createSelector(auth.selectors.getServerUser, (item) => item);

const getRfqFormFieldsProp = createSelector(getFormCreate, (form) => form.formFields);
export const getRfqFormFields = createSelector(getRfqFormFieldsProp, (item) => item);

const getRfqOrderRowsProp = createSelector(getFormCreate, (form) => form.orderRows);
export const getRfqOrderRows = createSelector(getRfqOrderRowsProp, (item) => item);

const getEnumerationsProp = createSelector(getFormCreate, (form) => form.enumerations);
export const getEnumerations = createSelector(getEnumerationsProp, (item) => item);

export const getUsername = createSelector(auth.selectors.getUsername, (name) => name);
export const getUserId = createSelector(auth.selectors.getUserId, (name) => name);

const getSavedRfqPresetsProp = createSelector(getFormCreate, (form) => form.savedRfqPresets);
export const getSavedRfqPresets = createSelector(getSavedRfqPresetsProp, (item) => item);

const getLastRfqProp = createSelector(getFormCreate, (form) => form.lastRfq);
export const getLastRfq = createSelector(getLastRfqProp, (item) => item);

const getContractTypeProp = (state) => {
  const contractType = state[NAME].forms.create.contractType;
  return contractType || {}; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
};
export const getContractType = createSelector(getContractTypeProp, (contractType) => {
  return contractType; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
});

const emptyActiveUserOrganistion = {};
export const getActiveUserOrganisation = createSelector(
  getActiveUser,
  organisations.selectors.getAllObject,
  (activeUser, orgs) => {
    if (orgs[activeUser.organisationId]) {
      return orgs[activeUser.organisationId];
    }

    return emptyActiveUserOrganistion;
  },
);

const emptySendersRFQs = [''];
export const getSendersRFQs = createSelector(
  getAll,
  organisations.selectors.getAllObject,
  (rfqs, orgs) => {
    const sendersRFQs = [''];

    rfqs.forEach((rfq) => {
      const org = orgs[rfq.organisationId];

      if (org) {
        const { shortName } = org;
        const i = sendersRFQs.findIndex((sender) => sender === shortName);
        if (i < 0) {
          sendersRFQs.push(shortName);
        }
      }
    });

    if (sendersRFQs.length > 0) {
      sendersRFQs.sort();
      return sendersRFQs;
    }

    return emptySendersRFQs;
  },
);

const emptyRecipientsRFQs = [''];
export const getRecipientsRFQs = createSelector(
  getAll,
  organisations.selectors.getAllObject,
  (rfqs, orgs) => {
    const recipientsRFQs = [''];

    rfqs.forEach((rfq) => {
      const selected = rfq.selectedOrganisationsIds || [];
      selected.forEach((o) => {
        const org = orgs[o];

        if (org) {
          const { shortName } = org;
          const i = recipientsRFQs.findIndex((sender) => sender === shortName);
          if (i < 0) {
            recipientsRFQs.push(shortName);
          }
        }
      });
    });

    if (recipientsRFQs.length > 0) {
      recipientsRFQs.sort();
      return recipientsRFQs;
    }

    return emptyRecipientsRFQs;
  },
);

export const getOrganisationsObject = createSelector(
  organisations.selectors.getAllObject,
  (item) => item,
);
export const getMyOrganisation = createSelector(
  organisations.selectors.getMyOrganisation,
  (item) => item,
);
export const getUsersObject = createSelector(users.selectors.getAllObject, (item) => item);

export const getOrders = createSelector(orders.selectors.getAll, (items) => items);

const getViewsProps = (state) => {
  const { items } = state[NAME].views;

  if (items.length > 0) {
    return items;
  }

  const viewsFromLS = getFromLS('rfqViews');

  if (Array.isArray(viewsFromLS)) {
    return viewsFromLS;
  }

  return items;
};
export const getViews = createSelector(getViewsProps, (items) => items);

const getDismissedProps = (state) => {
  const { items } = state[NAME].dismissed;

  if (items.length > 0) {
    return items;
  }

  const dismissedFromLS = getFromLS('rfqDismissed');

  if (Array.isArray(dismissedFromLS)) {
    return dismissedFromLS;
  }

  return items;
};
export const getDismissed = createSelector(getDismissedProps, (items) => items);

const getFormResponseProp = (state) => {
  const { response } = state[NAME].forms;
  return response;
};
export const getFormResponse = createSelector(getFormResponseProp, (form) => form);

//RFQ RESPONSE FORM*************

const isOpenFormResponseProp = (state) => {
  const { response } = state[NAME].forms;
  return response.open;
};
export const isOpenFormResponse = createSelector(isOpenFormResponseProp, (open) => open);

const getRfqResponseFormProp = (state) => {
  const { response } = state[NAME].forms;
  return response.rfq;
};
export const getRfqResponseForm = createSelector(getRfqResponseFormProp, (item) => item);

const getOrdersResponseFormProp = (state) => {
  const { response } = state[NAME].forms;
  return response.orders;
};
export const getOrdersResponseForm = createSelector(getOrdersResponseFormProp, (item) => item);

const getDefaultOrdersResponseFormProp = (state) => {
  const { response } = state[NAME].forms;
  return response.defaultOrders;
};
export const getDefaultOrdersResponseForm = createSelector(
  getDefaultOrdersResponseFormProp,
  (item) => item,
);

const getOrderToCancelResponseFormProp = (state) => {
  const { response } = state[NAME].forms;
  return response.orderToCancel;
};
export const getOrderToCancelResponseForm = createSelector(
  getOrderToCancelResponseFormProp,
  (items) => items,
);

const getOrdersResponseFormEnumerationsProp = (state) => {
  const { response } = state[NAME].forms;
  return response.enumerations;
};
export const getOrdersResponseFormEnumerations = createSelector(
  getOrdersResponseFormEnumerationsProp,
  (items) => items,
);

const getResponseFormFieldsProp = (state) => {
  const { response } = state[NAME].forms;
  return response.formFields;
};
export const getResponseFormFields = createSelector(getResponseFormFieldsProp, (items) => items);
// *************

//TRADE EXECUTE FORM*************
const isOpenFormExecuteProp = (state) => {
  const { execute } = state[NAME].forms;
  return execute.open;
};
export const isOpenFormExecute = createSelector(isOpenFormExecuteProp, (open) => open);

const getRfqExecuteFormProp = (state) => {
  const { execute } = state[NAME].forms;
  return execute.rfq;
};
export const getRfqExecuteForm = createSelector(getRfqExecuteFormProp, (item) => item);

const getOrdersExecuteFormProp = (state) => {
  const { execute } = state[NAME].forms;
  return execute.orders;
};
export const getOrdersExecuteForm = createSelector(getOrdersExecuteFormProp, (item) => item);
const getOrderToCancelExecuteFormProp = (state) => {
  const { execute } = state[NAME].forms;
  return execute.orderToCancel;
};
export const getOrderToCancelExecuteForm = createSelector(
  getOrderToCancelExecuteFormProp,
  (items) => items,
);
const getExecuteFormFieldsProp = (state) => {
  const { execute } = state[NAME].forms;
  return execute.formFields;
};
export const getExecuteFormFields = createSelector(getExecuteFormFieldsProp, (items) => items);

/**********************/

//RFQ RESPSONSE AMEND FORM*************
const isOpenFormAmendProp = (state) => {
  const { amend } = state[NAME].forms;
  return amend.open;
};
export const isOpenFormAmend = createSelector(isOpenFormAmendProp, (open) => open);

const getRfqAmendFormProp = (state) => {
  const { amend } = state[NAME].forms;
  return amend.rfq;
};
export const getRfqAmendForm = createSelector(getRfqAmendFormProp, (item) => item);

const getOrdersAmendFormProp = (state) => {
  const { amend } = state[NAME].forms;
  return amend.orders;
};
export const getOrdersAmendForm = createSelector(getOrdersAmendFormProp, (item) => item);

const getAmendFormFieldsProp = (state) => {
  const { amend } = state[NAME].forms;
  return amend.formFields;
};
export const getAmendFormFields = createSelector(getAmendFormFieldsProp, (items) => items);

/**********************/

export const getMyActiveRfqs = createSelector(
  getAll,
  auth.selectors.getServerUser,
  (rfqs, user) => {
    const { id: userId } = user;
    const myActiveRfqs = [...rfqs]
      .filter((r) => r.createdByUserId === userId)
      .filter((r) => r.status !== RFQ_STATUS_ENDED);

    return myActiveRfqs;
  },
);

export const getTradingPartners = createSelector(settings.selectors.getTradingPartners, (items) => {
  return items;
});

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);

export const getAllFieldValues = createSelector(schemas.selectors.getAllFieldValues, (items) => {
  return items;
});
