import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  containerItems: {
    //padding: theme.spacing(1),
  },
});

const DetailsAssetsRow = (props) => {
  const { classes, allFieldValues } = props;
  const orderRow = props.orderRow || {};
  const isOdd = props.index % 2 ? classes.containerOdd : classes.container;

  return (
    <div className={isOdd}>
      <div className={classes.containerItems} style={{ flex: 1 }}>
        <ReadOnlyField displayName={'weightClass'} value={orderRow.contract.weightClass} />
        <ReadOnlyField displayName={'volume'} value={orderRow.volume} adornment={'cartons'} />
        <ReadOnlyField
          displayName={'price'}
          value={orderRow.price}
          adornment={props.activeRfq.currency}
        />
      </div>
    </div>
  );
};

DetailsAssetsRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsAssetsRow);
