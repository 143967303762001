import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card, Chip, Collapse, Icon, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import UserAvatar from '../../../commons/components/userAvatar';
import CustomText from '../../../commons/components/formFields/customText';
import UserList from './UserList';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    padding: theme.spacing(0.5),
  },
  sectionHeader: {
    fontWeight: 'bold',
    color: theme.palette.text.subtitle,
  },
  sectionBody: {
    padding: theme.spacing(),
  },
  companyHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(),
  },
  organisationName: {
    padding: theme.spacing(),
  },
  actionIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  chip: {
    marginRight: '1px',
  },
});

const OrganisationProfile = (props) => {
  const { classes, isSuperAdmin, isOrganisationAdmin, myOrganisation } = props;
  const [expanded, setExpanded] = React.useState(false);
  const organisation =
    props.organisation.id === myOrganisation.id ? myOrganisation : props.organisation;
  const organisationType = organisation.type ? organisation.type.toLowerCase() : '';

  const details = organisation.details || {};
  const address = organisation.address || {};
  const species = details.species || [];
  const factoryNumber = details.factoryNumber || [];
  const brand = details.brand || [];
  const users = props.users
    ? [...props.users].filter((u) => u.organisationId === organisation.id)
    : [];
  const loggedIn = users.some((user) => user.connectionState === 'online');
  const editable = isSuperAdmin || (isOrganisationAdmin && organisation.id === myOrganisation.id);
  const statusColor = loggedIn ? teal['300'] : red[700];

  return (
    <React.Fragment key={props.key}>
      <div className={classes.container}>
        <div className={classes.section}>
          <Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={classes.section}>
              <div className={classes.companyHeader}>
                <UserAvatar organisationId={organisation.id} />
                <div
                  className={classes.organisationName}
                >{`${organisation.name}    |    ${organisation.shortName}`}</div>
              </div>
            </div>

            <div className={classes.actionIcons}>
              <IconButton>
                <FiberManualRecordIcon style={{ color: statusColor }} />
              </IconButton>
              {editable && (
                <IconButton
                  className={classes.expand}
                  onClick={() => props.editOrganisation(organisation)}
                  aria-label="show more"
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}

              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </Card>
        </div>

        <Collapse in={expanded || props.expanded} style={{ paddingLeft: '32px' }}>
          {(expanded || props.expanded) && (
            <React.Fragment>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="type" />
                    </div>
                    <div className={classes.sectionBody}>
                      <CustomText value={organisationType} upperCase />
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="address" />
                    </div>
                    <div className={classes.sectionBody}>
                      {`${address.address1 ? address.address1 + ',' : ''} 
                      ${address.address2 ? address.address2 + ',' : ''} 
                      ${address.address3 ? address.address3 + ',' : ''} 
                      ${address.city ? address.city + ',' : ''} 
                      ${address.locality ? address.locality + ',' : ''} 
                      ${address.postcode ? address.postcode + ',' : ''} 
                      ${address.country ? address.country : ''} `}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>{<CustomText value="aboutUs" />}</div>
                    <div className={classes.sectionBody} style={{ whiteSpace: 'pre-wrap' }}>
                      {organisation.description}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>{<CustomText value="brand" />}</div>
                    <div className={classes.sectionBody}>
                      {brand.map((b) => {
                        return <Chip key={b} label={b} className={classes.chip} />;
                      })}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      {<CustomText value="factoryNumber" />}
                    </div>
                    <div className={classes.sectionBody}>
                      {factoryNumber.map((f) => {
                        return <Chip key={f} label={f} className={classes.chip} />;
                      })}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="ourProducts" />
                    </div>
                    <div className={classes.sectionBody}>
                      {species.map((specie) => {
                        return (
                          <Chip
                            key={specie}
                            label={<CustomText value={specie} upperCase />}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Card>
              </div>
              <div className={classes.section}>
                <Card>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      <CustomText value="ourTeam" />
                    </div>
                    <div className={classes.sectionBody}>
                      <UserList rows={users} />
                    </div>
                  </div>
                </Card>
              </div>
            </React.Fragment>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

OrganisationProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(OrganisationProfile);
