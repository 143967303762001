import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { TextField, FormControl, InputAdornment } from '@material-ui/core';

import { textField, fieldFontSizeLarge, fieldFontSize } from './formFieldStyles';

const styles = (theme) => ({
  text1: {
    ...textField(theme),
  },
  textFont: fieldFontSize(theme),
  textFontLarge: fieldFontSizeLarge(theme),
  inputTextHighlight: {
    color: lightBlue[300],
    ...fieldFontSize(theme),
  },
  root: {
    '& .MuiFilledInput-input': {
      padding: '25px 12px 7px   !important',
    },
  },
  adornment: {
    fontSize: theme.fontSize.xl,
  },
});

const NumberFieldFilled = (props) => {
  const { classes, accessor, displayName, value, error } = props;
  const textFont = props.fontSizeLarge ? classes.textFontLarge : classes.textFont;

  return (
    <FormControl className={classes.text1} fullWidth={props.fullWidth}>
      <TextField
        id={accessor}
        type="number"
        label={displayName}
        variant="filled"
        fullWidth
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={value || ''}
        classes={props.fontSizeLarge ? { root: classes.root } : null}
        onChange={props.handleChange(accessor)}
        error={props.error ? error.value : false}
        helperText={props.error ? error.message : ''}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: props.highlight ? classes.inputTextHighlight : textFont,
          inputProps: {
            step: props.step ? props.step : 1,
          },
          endAdornment: (
            <InputAdornment position="end" classes={{ root: classes.adornment }}>
              {props.adornment ? props.adornment : ' '}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

NumberFieldFilled.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberFieldFilled);
