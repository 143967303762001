import { createSelector } from 'reselect';
import { NAME } from '../constants';
import auth from '../../auth';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => {
  return Object.values(items).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
});

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (items) => items);

const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item;
};
export const getById = createSelector(getByIdProp, (items) => items);

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);
export const getActiveUser = createSelector(auth.selectors.getServerUser, (item) => item);
const getMyOrganisationProp = (state) => {
  const items = state[NAME].myOrganisation;
  return items;
};
export const getMyOrganisation = createSelector(getMyOrganisationProp, (items) => items);
