import constants from '../constants';

import { insertItems } from '../../../commons/utils/functions';

const initialState = {
  byId: {},
  allIds: [],
  items: {},
  content: {},
  status: null,
  error: null,
  receivedAt: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case constants.INIT_FILE_UPLOAD_SUCCESS:
    case constants.UPDATE_FILE_UPLOAD_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInit, items, ...otherProps } = action.payload;

      if (typeof idInit !== 'undefined') {
        let newItem = null;

        if (nextState.items[idInit]) {
          newItem = {
            [idInit]: {
              ...nextState.items[idInit],
              ...otherProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        } else {
          newItem = {
            [idInit]: {
              id: idInit,
              ...otherProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        }

        nextState.items = {
          ...nextState.items,
          ...newItem,
        };

        nextState.status = 'success';
        nextState.receivedAt = action.meta.receivedAt;
      }
      return nextState;
    case constants.DESTROY_FILE_UPLOAD_SUCCESS:
      nextState = {
        ...state,
      };

      const { id } = action.payload;

      if (typeof id !== 'undefined') {
        if (nextState.items[id]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[id];
        }

        if (Object.keys(nextState.items).length === 0) {
          nextState.status = null;
          nextState.error = null;
          nextState.receivedAt = null;
        }
      }
      return nextState;
    case constants.GET_FILE_CONTENT_SUCCESS:
      let nextById = { ...state.byId };
      const { id: fileId, file: newFile } = action.payload;
      nextById[fileId] = newFile;
      return {
        ...state,
        byId: nextById,
      };

    case constants.GET_FILE_SUCCESS:
    case constants.GET_FILES_SUCCESS:
    case constants.CREATE_FILE_SUCCESS:
      const { fileList } = action.payload;
      const payload = {
        items: fileList,
      };
      return {
        ...state,
        ...insertItems(payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

    case constants.GET_FILES_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case constants.DELETE_FILE_SUCCESS:
      nextById = { ...state.byId };
      const { id: deleteFileId } = action.payload;
      delete nextById[deleteFileId];
      return {
        ...state,
        byId: nextById,
      };
    case constants.UPDATE_FILE_META_SUCCESS:
      nextById = { ...state.byId };
      const { id: updateFileId } = action.payload;
      nextById[updateFileId] = action.payload;
      return {
        ...state,
        byId: nextById,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
