import { formatDate, formatDateTime } from '../../../commons/config/formatters';

export default class RFQRequestDetails {
  constructor(payload) {
    this.product = payload.contract.underlying.product || '';
    this.location = payload.contract.underlying.locations || '';
    this.incoTerms = payload.contract.underlying.incoTerms || '';
    this.deliveryPoint = payload.contract.underlying.deliveryPoints || '';
    this.region = payload.contract.underlying.regions || '';
    this.packingStation = payload.contract.underlying.packingStations || '';
    this.week = payload.contract.week || '';
    this.minHarvestDate = payload.contract.harvestDate.from
      ? formatDate(payload.contract.harvestDate.from)
      : '';
    this.maxHarvestDate = payload.contract.harvestDate.to
      ? formatDate(payload.contract.harvestDate.to)
      : '';
    this.minDeliveryDate = payload.contract.deliveryDate.from
      ? formatDate(payload.contract.deliveryDate.from)
      : '';
    this.maxDeliveryDate = payload.contract.deliveryDate.to
      ? formatDate(payload.contract.deliveryDate.to)
      : '';
      this.minFreezeDate = payload.contract.freezeDate.from
      ? formatDate(payload.contract.freezeDate.from)
      : '';
    this.maxFreezeDate = payload.contract.freezeDate.to
      ? formatDate(payload.contract.freezeDate.to)
      : '';  
    this.quality = payload.contract.underlying.quality;
    this.certification = payload.contract.underlying.certifications;
    this.colorFan = payload.contract.underlying.colorFan || [];
    this.state = payload.contract.underlying.states;
    this.weightClass = payload.contract.underlying.weightClasses;
    this.direction = payload.direction;
    this.volume = payload.volume;
    this.currency = payload.contract.underlying.currency;
    this.unit = payload.contract.underlying.unit;
    this.timeInForce = payload.timeInForce ? formatDateTime(payload.timeInForce) : '';
    this.visibility = payload.visibility;
    this.timeOfEntry = payload.createdTime ? formatDateTime(payload.createdTime) : '';
    this.timeOfUpdate = payload.updatedTime ? formatDateTime(payload.updatedTime) : '';
    this.status = payload.status;
    this.id = payload.id;
  }
}
