import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Select, MenuItem, AppBar } from '@material-ui/core';
import { getSegmentValue, getSegmentValues } from '../selectors';

//import { getFromLS, saveToLS } from '../../../commons/localStorage';

//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.row,
    padding: theme.spacing(),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
});

class Inventory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showAll: true,
      sortOrder: [],
    };
  }

  handleSegmentValueChange = () => {};

  render() {
    const { classes, segmentValues, segmentValue } = this.props;

    return (
      <div className={` ${classes.container} undraggable`}>
        <div className={classes.ratesContainer}>
          <AppBar position="static">
            <Toolbar variant="dense">
              <Select
                value={`${segmentValue}`}
                disableUnderline
                name="defaultSegment"
                className={classes.title}
                onChange={this.handleSegmentValueChange}
              >
                {segmentValues.map((seg) => {
                  return (
                    <MenuItem key={seg} value={seg}>
                      {seg}
                    </MenuItem>
                  );
                })}
              </Select>

              <div style={{ flexGrow: 1 }} />

              <div style={{ flexGrow: 1 }} />
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    segmentValues: getSegmentValues(state),
    segmentValue: getSegmentValue(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Inventory));
