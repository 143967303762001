import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, MenuItem, FormControl } from '@material-ui/core';
import { textField, fieldFontSize } from './formFieldStyles';
import clsx from 'clsx';
import CustomText from './customText';

const styles = (theme) => ({
  text1: { ...textField(theme) },
  textFont: fieldFontSize(theme),
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
  menu: {
    maxHeight: '400px',
  },
});

const SelectField = (props) => {
  const { classes, accessor, displayName, value } = props;
  const disabled = props.disabled === true;
  let { values } = props;

  let options = {};
  /*  let displayValue = allFieldValues[value]
    ? allFieldValues[value].displayFields[0].displayName
    : value; */

  if (!Array.isArray(values)) {
    if (Object.prototype.toString.call(values) === '[object Object]') {
      options = { ...values };
      values = Object.keys(values);
    } else {
      values = [];
    }
  }

  if (props.any) {
    if (props.any === true) {
      const index = values.indexOf('ANY');
      if (index === -1) {
        values.splice(0, 0, 'ANY');
      }
    }
  }
  const inputProps =
    disabled === true
      ? {}
      : props.addUnderline
      ? { disableUnderline: false }
      : { disableUnderline: true };

  const _displayName = displayName ? displayName : accessor;

  return (
    <FormControl
      className={props.noMargin ? null : classes.text1}
      fullWidth={props.fullWidth}
      style={{ ...props.style }}
    >
      <TextField
        id={accessor}
        key={accessor}
        select
        disabled={disabled}
        label={<CustomText value={_displayName} />}
        fullWidth={props.fullWidth}
        classes={{ root: classes.root }}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        value={value}
        renderValue={(selected) => <div>{props.orgs ? value.name : value}</div>}
        variant="filled"
        error={props.error ? props.error.value : false}
        helperText={props.error ? props.error.message : ''}
        onChange={props.handleChange(accessor)}
        SelectProps={{
          MenuProps: {
            className: `${classes.menu} undraggable`,
          },
        }}
        InputProps={{
          ...inputProps,
          className: clsx(
            classes.textFont,
            props.dark && classes.darkBackground,
            props.warning && classes.warningBackground,
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
      >
        {values.map((option, index) => {
          let optionValue = props.orgs ? option.shortName : option;
          optionValue = props.files ? option.filename : optionValue;

          return (
            <MenuItem key={index} value={option}>
              {props.orgs ? optionValue : <CustomText value={optionValue} upperCase />}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectField);
