import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Input, MenuItem, Select, Chip } from '@material-ui/core';

const styles = (theme) => ({
  responseHeaders: {},
});

const OrderRecipients = (props) => {
  const { accessor } = props;

  let recipients = null;

  if (Array.isArray(props.recipients)) {
    recipients = props.recipients;
  } else {
    recipients = [];
  }

  const menuItems = recipients.map((r) => (
    <MenuItem key={`${r.shortName}`} value={`${r.shortName}`} selected={false}>
      {`${r.shortName}`}
    </MenuItem>
  ));

  return (
    <Select
      multiple
      name={props.name}
      //id={props.accessor}
      disableUnderline
      value={props.value}
      onChange={props.onChange(accessor)}
      input={<Input fullWidth />}
      renderValue={(selected) => {
        return (
          <div>
            {selected.map((val) => (
              <Chip key={val} label={val} value={val} />
            ))}
          </div>
        );
      }}
    >
      {menuItems}
    </Select>
  );
};

OrderRecipients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderRecipients);
