import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Icon, TextField } from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

const styles = (theme) => ({
  title: { fontSize: theme.fontSize * 1.5 },
  container: { overflowY: 'scroll' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  textField: { marginTop: '8px', marginBottom: '8px' },
  nameChange: {
    display: 'block',
    padding: '25px 8px',
  },
  nameChangeInput: { display: 'block' },
});

class SaveSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  save = () => {
    this.props.save(this.state.title);
    this.setState({ title: '' });
    this.props.close();
  };

  render() {
    const { classes, open, presets, allFieldValues = {} } = this.props;
    const _saveBlotterSettings = allFieldValues.saveBlotterSettings
      ? allFieldValues.saveBlotterSettings.displayName
      : 'Save Blotter Settings';
    const _saveSettingsAs = allFieldValues.saveSettingsAs
      ? allFieldValues.saveSettingsAs.displayName
      : 'Save Settings As...';
    const _cancel = allFieldValues.cancel
      ? allFieldValues.cancel.displayName.toUpperCase()
      : 'CANCEL';
    const _send = allFieldValues.send ? allFieldValues.send.displayName : 'SEND';
    return (
      <PopUp open={open} title={_saveBlotterSettings}>
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              {presets.map((item) => (
                <ListItem
                  key={item.id}
                  button
                  classes={{ root: classes.listItem }}
                  onClick={() => this.setState({ title: item.name })}
                >
                  <ListItemText primary={item.name} />

                  <ListItemIcon align="right">
                    <Icon>save</Icon>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </div>

          <TextField
            classes={{ root: classes.nameChange }}
            InputProps={{ className: classes.nameChangeInput }}
            value={this.state.title}
            onChange={this.handleChange('title')}
            placeholder={_saveSettingsAs}
          />

          <ToolbarBottom>
            <>
              <CancelButton title={_cancel} onClick={this.props.close} />
              <ConfirmButton title={_send} onClick={this.save} />
            </>
          </ToolbarBottom>
        </div>
      </PopUp>
    );
  }
}
SaveSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(SaveSettings));
