import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextBox from './textBox';
import Button from './button';

import { createAction } from '../../commons/actions';
import {
  SIGN_IN,
  SIGN_IN_PASSWORD_RESET_OPEN,
  SIGN_IN_NEW_PASSWORD_VALIDATION_REQUIRED,
} from '../../modules/auth/constants';

import { getStatus, getError } from '../../modules/auth/selectors';

import ooxLogoLongTeal from '../../assets/logos/OOX-Logo-Long-Teal.png';
import clsx from 'clsx';
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--background-color)',
    height: '100vh',
  },

  textContainer: {
    border: '1px solid var(--text-border-color)',
    borderRadius: '3px',
    margin: '16px 0px',
    padding: '16px 8px',
    transition: 'border 0.7s',
    '&:hover': {
      border: '1px solid var(--text-border-color-hover)',
      transition: 'border 0.7s',
    },
    '&:focus': {
      border: '1px solid var(--text-border-color-focus)',
      transition: 'border 0.7s',
    },
  },
  companyPart1: {
    color: 'var(--text-light-color)',
    fontSize: '32px',
  },
  companyPart2: {
    color: 'var(--alternate-dark-color)',
    fontSize: '32px',
  },
  loginBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  contactContainer: {
    padding: '8px',
  },
  contactText: {
    color: 'rgba(255,255,255,.5)',
    paddingTop: '4px',
    paddingBottom: '4px',
    textAlign: 'center',
  },
  forgotPasswordButton: {
    color: 'var(--alternate-dark-color)',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    cursor: 'pointer',
    paddingBottom: '16px',
  },
  errorMessageContainer: {
    height: '50px',
    margin: '8px auto',
    maxWidth: '216px',
  },
  errorHidden: {
    display: 'none',
  },
  errorVisible: {
    display: 'inline',
    overflowWrap: 'normal',
    fontFamily: 'sans-serif',
    color: 'var(--text-error-color)',
  },
});

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      username: null,
      password: null,
    };

    // this.redirect = {
    //   whenAuthed: true,
    //   url: auth.constants.CLIENT_PAGE_URL,
    // };
  }

  handleSubmit = () => {
    const { redirect } = this.props;
    const { username, password } = this.state;
    const payload = {
      username,
      password,
      redirect,
    };

    const { signIn } = this.props;
    signIn(payload);
  };

  handleChange = (id) => (event) => {
    this.setState({ [id]: event.target.value });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { classes, error } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.loginBackground}>
          <div>
            {/*  <span className={classes.companyPart1}>OOX</span>
            <span className={classes.companyPart2}>change</span> */}
            <img
              src={ooxLogoLongTeal}
              alt={'OOXchange'}
              loading="lazy"
              style={{ width: '440px' }}
            />
          </div>
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <TextBox
              icon="person"
              id="username"
              placeholder="Email"
              hover={!this.state.focus}
              onBlur={() => this.onBlur()}
              onFocus={() => this.onFocus()}
              onChange={this.handleChange}
            ></TextBox>
            <TextBox
              icon="lock"
              id="password"
              placeholder="Password"
              hover={!this.state.focus}
              onBlur={() => this.onBlur()}
              onFocus={() => this.onFocus()}
              onChange={this.handleChange}
              visibility
            ></TextBox>
            <Button error={error} onClick={this.handleSubmit} title="LOGIN" />
            <div>
              <button
                className={classes.forgotPasswordButton}
                onClick={this.props.requestPasswordReset}
              >
                Forgot Password?
              </button>
            </div>
            <div>
              <button
                className={classes.forgotPasswordButton}
                onClick={this.props.sendValidationCode}
              >
                Use Validation Code?
              </button>
            </div>

            <div className={classes.errorMessageContainer}>
              <div
                className={clsx({
                  [classes.errorVisible]: true,
                  [classes.errorHidden]: error,
                })}
              >
                {error ? error.error : ''}
              </div>
            </div>
          </div>

          <div className={classes.contactContainer}>
            <div className={classes.contactText}>support@ooxchange.com</div>
            <div className={classes.contactText}>https://www.ooxchange.com/</div>
            <div className={classes.contactText}>© 2023-2024 OOX Limited</div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    themeType: state.themes.type,
    error: getError(state),
    status: getStatus(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (payload) => dispatch(createAction(SIGN_IN, payload)),
  requestPasswordReset: () => dispatch(createAction(SIGN_IN_PASSWORD_RESET_OPEN)),
  sendValidationCode: () => dispatch(createAction(SIGN_IN_NEW_PASSWORD_VALIDATION_REQUIRED)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
