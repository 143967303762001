import { createSelector } from 'reselect';
import { NAME } from '../constants';
import organisations from '../../organisations';
import auth from '../../auth';
import users from '../../users';
import schemas from '../../schemas';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

export const getOrganisationsObject = createSelector(
  organisations.selectors.getAllObject,
  (items) => items,
);

export const getOrganisations = createSelector(organisations.selectors.getAll, (items) => items);

export const getUsersObject = createSelector(users.selectors.getAllObject, (items) => items);

export const getUsers = createSelector(users.selectors.getAll, (items) => items);

export const getMyOrganisation = createSelector(
  organisations.selectors.getMyOrganisation,
  (items) => items,
);
export const getAllFieldValues = createSelector(schemas.selectors.getAllFieldValues, (items) => {
  return items;
});

export const getBrandsAndFactories = createSelector(
  schemas.selectors.getBrandsAndFactories,
  (items) => {
    return items;
  },
);

const getBrokersProp = (state) => {
  const items = state[NAME].brokers;
  return items;
};
export const getBrokers = createSelector(getBrokersProp, (items) => items);
