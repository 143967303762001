import { createSelector } from 'reselect';
import { NAME } from '../constants';

import auth from '../../auth';
import contracts from '../../contracts';
import organisations from '../../organisations';
import calculators from '../../calculators';
import users from '../../users';
import schemas from '../../schemas';

import * as fxratesSelectors from '../../fxrates/selectors';

import settings from '../../settings';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyOrder = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyOrder;
};
export const getById = createSelector(getByIdProp, (item) => item);

const getFormCreateProp = (state) => {
  const form = state[NAME].forms.create;
  return form;
};
export const getFormCreate = createSelector(getFormCreateProp, (form) => form);

const isFormCreateOpenProp = (state) => state[NAME].forms.create.open;
export const isFormCreateOpen = createSelector(isFormCreateOpenProp, (open) => open);

const getFormUpdateProp = (state) => {
  const form = state[NAME].forms.update;
  return form;
};
export const getFormUpdate = createSelector(getFormUpdateProp, (form) => form);

const isFormUpdateOpenProp = (state) => state[NAME].forms.update.open;
export const isFormUpdateOpen = createSelector(isFormUpdateOpenProp, (open) => open);

const getFormsObjectProp = (state) => {
  const { forms } = state[NAME];
  return forms;
};
export const getFormsObject = createSelector(getFormsObjectProp, (forms) => forms);

const emptyOrderForm = {};
const getOrderFormProp = (state) => {
  const orderForm = state[NAME].forms.create.orderState;
  return orderForm || emptyOrderForm; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
};
export const getOrderForm = createSelector(getOrderFormProp, (orderForm) => {
  return orderForm; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
});
const getContractTypeProp = (state) => {
  const contractType = state[NAME].forms.create.contractType;
  return contractType || {}; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
};
export const getContractType = createSelector(getContractTypeProp, (contractType) => {
  return contractType; // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
});

const getEnumerationsProp = (state) => {
  const { enumerations } = state[NAME].forms.create;
  return enumerations || {};
};
export const getEnumerations = createSelector(getEnumerationsProp, (enumerations) => enumerations);

const emptyFormFieldsArray = [];
const getFormFieldsProp = (state) => {
  const { fields } = state[NAME].forms.create;
  return Array.isArray(fields) ? fields : emptyFormFieldsArray;
};
export const getFormFields = createSelector(getFormFieldsProp, (fields) => {
  return fields;
});

export const getOrganisations = createSelector(organisations.selectors.getAll, (items) => items);

export const getActiveUser = createSelector(auth.selectors.getServerUser, (item) => item);

export const getUsersObject = createSelector(users.selectors.getAllObject, (item) => item);

export const getOrganisationsObject = createSelector(
  organisations.selectors.getAllObject,
  (item) => item,
);

export const getActiveContract = createSelector(
  contracts.selectors.getActiveContract,
  (item) => item,
);

const getOrdersFromSettingsProp = (state) => {
  const { items } = state[NAME].settings;
  return items;
};
export const getOrdersFromSettings = createSelector(getOrdersFromSettingsProp, (items) => {
  return items;
});

export const getCalculatorsFromSettings = createSelector(
  calculators.selectors.getCalculatorsFromSettings,
  (items) => items,
);

export const getSegmentValuesFromActiveContract = createSelector(
  contracts.selectors.getActiveContract,
  (item) => {
    //const values = contracts.utils.getSegmentValues(item);
    const values = contracts.utils.getMarketSegments();
    return values;
  },
);

const getOrderManagerProp = (state) => {
  const { manager } = state[NAME].tables;
  return manager;
};
export const getOrderManager = createSelector(getOrderManagerProp, (item) => item);

const getActiveOrderListIdFromManagerOrderProp = (state) => {
  const { manager } = state[NAME].tables;
  const { orderListId } = manager;
  return typeof orderListId !== 'undefined' ? orderListId : null;
};
export const getActiveOrderListIdFromManagerOrder = createSelector(
  getActiveOrderListIdFromManagerOrderProp,
  (id) => {
    return id;
  },
);

const getOrdersFromOrderListProp = (state) => {
  const { manager } = state[NAME].tables;
  const { orders } = manager;
  return orders;
};
export const getOrdersFromOrderList = createSelector(getOrdersFromOrderListProp, (items) => {
  return items;
});

const getGlobalsFromManagerOrderProp = (state) => {
  const { manager } = state[NAME].tables;
  const { globals } = manager;
  return globals;
};
export const getGlobalsFromManagerOrder = createSelector(getGlobalsFromManagerOrderProp, (item) => {
  return item;
});

const getMarketFromManagerOrderProp = (state) => {
  const { manager } = state[NAME].tables;
  const { market } = manager;
  return market;
};
export const getMarketFromManagerOrder = createSelector(getMarketFromManagerOrderProp, (item) => {
  return item;
});

const getCalcultorFromManagerOrderProp = (state) => {
  const { manager } = state[NAME].tables;
  const { calculator } = manager;
  return calculator;
};
export const getCalcultorFromManagerOrder = createSelector(
  getCalcultorFromManagerOrderProp,
  (item) => {
    return item;
  },
);

const getVisibleEditorOrderListsProp = (state) => {
  const { manager } = state[NAME].tables;
  const { visible } = manager.editorOrderLists;
  return visible;
};
export const getVisibleEditorOrderLists = createSelector(getVisibleEditorOrderListsProp, (item) => {
  return item;
});

const getOrderListsEditorOrderListsProp = (state) => {
  const { manager } = state[NAME].tables;
  const { items } = manager.editorOrderLists;
  return items;
};
export const getOrderListsEditorOrderLists = createSelector(
  getOrderListsEditorOrderListsProp,
  (items) => {
    return items;
  },
);

const getOthersFromManagerOrderProp = (state) => {
  const { others } = state[NAME].tables.manager;
  return others;
};
export const getOthersFromManagerOrder = createSelector(getOthersFromManagerOrderProp, (item) => {
  return item;
});

const getAmendOrderFormProp = (state) => {
  const { order } = state[NAME].forms.amend;
  return order;
};
export const getAmendOrderForm = createSelector(getAmendOrderFormProp, (item) => item);

const isOpenAmendOrderFormProp = (state) => {
  const { order } = state[NAME].forms.amend;
  const { open } = order;
  return open;
};
export const isOpenAmendOrderForm = createSelector(isOpenAmendOrderFormProp, (open) => open);

const getOrderFromAmendOrderFormProp = (state) => {
  const { order } = state[NAME].forms.amend;
  const { item } = order;
  const { assets } = state[NAME].byId[item.id] || {};
  const { id } = (assets || [{}])[0];
  return { ...item, assetsId: id };
};
export const getOrderFromAmendOrderForm = createSelector(
  getOrderFromAmendOrderFormProp,
  (item) => item,
);

const getErrorsFromAmendOrderFormProp = (state) => {
  const { order } = state[NAME].forms.amend;
  const { errors } = order;
  return errors;
};
export const getErrorsFromAmendOrderForm = createSelector(
  getErrorsFromAmendOrderFormProp,
  (errors) => errors,
);

const getAmendMulitpleOrderFormProp = (state) => {
  const { multiple } = state[NAME].forms.amend;
  return multiple;
};
export const getAmendMulitpleOrderForm = createSelector(
  getAmendMulitpleOrderFormProp,
  (item) => item,
);

const isOpenAmendMulitpleOrderFormProp = (state) => {
  const { multiple } = state[NAME].forms.amend;
  const { open } = multiple;
  return open;
};
export const isOpenAmendMulitpleOrderForm = createSelector(
  isOpenAmendMulitpleOrderFormProp,
  (open) => open,
);

const getOrderFromAmendMulitpleOrderFormProp = (state) => {
  const { multiple } = state[NAME].forms.amend;
  const { item } = multiple;

  return item;
};
export const getOrderFromAmendMulitpleOrderForm = createSelector(
  getOrderFromAmendMulitpleOrderFormProp,
  (item) => item,
);
const getRfqFromAmendMulitpleOrderFormProp = (state) => {
  const { multiple } = state[NAME].forms.amend;
  const { rfq } = multiple;

  return rfq;
};
export const getRfqFromAmendMulitpleOrderForm = createSelector(
  getRfqFromAmendMulitpleOrderFormProp,
  (item) => item,
);

const getErrorsFromAmendMulitpleOrderFormProp = (state) => {
  const { multiple } = state[NAME].forms.amend;
  const { errors } = multiple;
  return errors;
};
export const getErrorsFromAmendMulitpleOrderForm = createSelector(
  getErrorsFromAmendMulitpleOrderFormProp,
  (errors) => errors,
);

const getOrderListIdFromWidgetProp = (state, widget) => {
  let id = null;

  if (Object.prototype.hasOwnProperty.call(widget, 'savedState')) {
    if (Object.prototype.hasOwnProperty.call(widget.savedState, 'orderListId')) {
      id = widget.savedState.orderListId;
    }
  }
  return id;
};

const emptyOrderList = {};
export const getActiveOrderList = createSelector(
  getOrdersFromSettings,
  getOrderListIdFromWidgetProp,
  (orderLists, orderListId) => {
    let orderIndex = -1;

    if (orderListId) {
      orderIndex = orderLists.findIndex((ol) => ol.id === orderListId);
    }

    if (orderIndex === -1) {
      orderIndex = orderLists.findIndex((w) => w.active === true);
      if (orderIndex === -1) {
        orderIndex = 0;
      }
    }

    const orderList = JSON.parse(JSON.stringify(orderLists[orderIndex] || emptyOrderList));
    return orderList;
  },
);

export const getFxratesObject = createSelector(fxratesSelectors.getAllObject, (itemsObject) => {
  return itemsObject;
});

export const getTradingPartners = createSelector(settings.selectors.getTradingPartners, (items) => {
  return items;
});

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);

const getLastUpdatedTimeProp = (state) => state[NAME].lastUpdatedTime;
export const getLastUpdatedTime = createSelector(
  getLastUpdatedTimeProp,
  (lastUpdatedTime) => lastUpdatedTime,
);

const getAmendedOrdersProp = (state) => {
  const { orders } = state.rfqs.forms.amend;
  return orders;
};
export const getAmendedOrders = createSelector(getAmendedOrdersProp, (orders) => orders);

export const getAllFieldValues = createSelector(schemas.selectors.getAllFieldValues, (items) => {
  return items;
});
