import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

const styles = (theme) => ({
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.primary,
    paddingLeft: theme.spacing(0.5),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },

  currencyName: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  currencyRate: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.primary,
    fontSize: '16px',
  },
});
const FxRow = (props) => {
  const { classes, item } = props;
  return (
    <div className={classes.checkRow}>
      <Checkbox
        classes={{ root: classes.iconButton }}
        checked={item.checked}
        onChange={() => props.handleChecked(item.currency)}
      />
      <div className={classes.row}>
        <div className={classes.currencyName}>{item.currency}</div>
        <div className={classes.currencyRate}>{item.rate.toFixed(4)}</div>
      </div>
    </div>
  );
};

FxRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FxRow);
