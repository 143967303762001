import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { TextField, MenuItem, FormControl } from '@material-ui/core';

import { textField, fieldFontSize } from './formFieldStyles';
import CustomText from './customText';

const styles = (theme) => ({
  textField: textField(theme),
  textFont: fieldFontSize(theme),
  backgroundSell: {
    backgroundColor: '#179f93',
    color: '#fff',
  },
  backgroundBuy: {
    backgroundColor: '#1ab1f5',
    color: '#fff',
  },
  textFieldSell: {},
  textFieldBuy: {},
  menu: {
    width: 400,
  },
});

const DirectionField = (props) => {
  const { classes, accessor, displayName, value, values, defaultValue } = props;
  const background =
    (value || defaultValue) === 'BUY' ? classes.backgroundBuy : classes.backgroundSell;

  return (
    <FormControl className={`${classes.textField} ${background} `} fullWidth={props.fullWidth}>
      <TextField
        inputProps={{
          className: value === 'BUY' ? classes.textFieldBuy : classes.textFieldSell,
        }}
        InputProps={{
          className: classes.textFont,
          disableUnderline: true,
        }}
        fullWidth={props.fullWidth}
        variant="filled"
        size="small"
        id={accessor}
        select
        label={<CustomText value={accessor} />}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        defaultValue={defaultValue}
        value={value}
        onChange={props.handleChange(accessor)}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
      >
        {values.map((direction) => {
          return (
            <MenuItem key={direction} value={direction}>
              <CustomText value={direction} upperCase />
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

DirectionField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DirectionField);
