import { createSelector } from 'reselect';

import { NAME } from '../constants';

const getSegmentValueProp = (state) => {
  const items = state[NAME].segmentValue;
  return items;
};
export const getSegmentValue = createSelector(getSegmentValueProp, (items) => items);

const getSegmentValuesProp = (state) => {
  const items = state[NAME].segmentValues;
  return items;
};
export const getSegmentValues = createSelector(getSegmentValuesProp, (items) => items);
