import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Modal, Typography, ListItemText } from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import { CancelButton, ConfirmButton, ResetButton } from '../../../commons/components/buttons';
import SelectFieldFiles from '../../../commons/components/formFields/SelectFieldFiles';
import TextInputField from '../../../commons/components/formFields/TextInputField';
import DateField from '../../../commons/components/formFields/dateField';
import { getPostTradeForm, getPostTradeFormData, isPostTradeFormOpen } from '../selectors';
import { POST_TRADE_FORM_OPEN } from '../constants';
import { postTradeFormOpen } from '../actions';
import fileUpload from '../../fileUpload';
import { fields } from '../config';
import CollapseContainerAlt from '../../../commons/components/collapseContainer';
import CustomText from '../../../commons/components/formFields/customText';
import FileRow2 from '../../fileUpload/components/FileRow2';
import Dropzone2 from '../../fileUpload/components/Dropzone2';
import { visibilities } from '../../../commons/models/constants';
import { createAction } from '../../../commons/actions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionTitleContainer: {
    padding: '0px 16px 0px',
  },
  sectionContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 0px 16px 0px',
  },
  sectionContentContainerNoFlex: {
    padding: ' 0px 16px 0px',
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  uploads: {
    padding: theme.spacing(),
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
});

/* const documentTypes = {
  INVOICE: { accessor: 'invoice', type: "seller" },
  PACKING_LIST: { accessor: 'packingList', type: "seller" },
  PRODUCT_SPECIFICATION: { accessor: 'productSpecification', type: "seller" },
  AIRWAY_BILL: { accessor: 'airwayBill', type: "seller" },
  HEALTH_CERTIFICATE: { accessor: 'healthCertificate', type: "seller" },
  CERTIFICATE_OF_ORIGIN: { accessor: 'certificateOfOrigin', type: "seller" },
  FULL_PACK: { accessor: 'fullPack' },
  INSPECTION_REPORT: { accessor: 'inspectionReport', type:"buyer" },
  OTHER: { accessor: 'other', type:"seller" },
  OTHER: { accessor: 'other', type:"buyer" },
}; */

const PostTradeForm = (props) => {
  const { classes, open, onClose, form, data } = props;
  const [newFiles, setNewFiles] = useState([]);
  const [fileToShow, setFileToShow] = useState(null);
  const [uploadExpanded, setUploadExpanded] = useState(false);
  const [uploadSuccessCount, setUploadSuccessCount] = useState(0);
  const [uploadFailCount, setUploadFailCount] = useState(0);
  const [formUpdateSuccessOpen, setFormUpdateSuccessOpen] = useState(false);
  const [formUpdateFailOpen, setFormUpdateFailOpen] = useState(false);
  const oldFiles = useMemo(() => (data ? data.files || [] : []), [data.files]);
  console.log('data', data);
  const sendUpdates = () => {
    const payload = {
      type: 'send',
      callback: formUpdateCallback,
    };
    props.onSend(payload);
  };
  const formUpdateCallback = (success) => {
    if (success) {
      setFormUpdateSuccessOpen(true);
      //props.onClose();
    } else {
      setFormUpdateFailOpen(true);
    }
  };
  const resetForm = () => {
    props.onUpdate({ type: 'open', open: true, tradeId: props.form.tradeId });
    setNewFiles([]);
  };
  const openFile = (id) => {
    const payload = {
      id,
      callback: (item) => setFileToShow(item),
    };

    props.getFileContent(payload);
  };
  const handleChange = (name) => (e) => {
    const payload = {
      type: 'update',
      name: name,
      value: e.target.value,
    };
    props.onUpdate(payload);
  };
  const handleDateChange = (name) => (date) => {
    const payload = {
      type: 'update',
      name: name,
      value: date,
    };

    props.onUpdate(payload);
  };

  const fieldsToMap = form ? (fields[form.formType] ? fields[form.formType] : []) : [];
  const detailFields = Object.fromEntries(
    Object.entries(fieldsToMap).filter(([key]) => fieldsToMap[key].type !== 'file'),
  );

  const removeAddedFile = (index) => {
    const newFileArray = Array.from(newFiles);
    newFileArray.splice(index, 1);

    setNewFiles(newFileArray);
  };
  const removeFile = (index) => {
    const newFileArray = [...oldFiles];
    newFileArray.splice(index, 1);
    const payload = {
      type: 'update',
      name: 'files',
      value: newFileArray,
    };

    props.onUpdate(payload);
  };
  const createFiles = () => {
    const filesToCreate = [...newFiles];
    filesToCreate.forEach((file) => {
      //const orgIds = organisations.map((org) => org.id);
      const payload = {
        data: {
          file,
          visibility: visibilities.SELECTED_ORGANISATIONS,
          organisations: form.selectedIds,
        },

        callback: handleFileUploadResult,
      };
      console.log('postTradeForm: createFiles:payload', payload);
      props.createFile(payload);
    });
    setNewFiles([]);
    setUploadExpanded(false);
  };

  const handleFileUploadResult = (item) => {
    console.log(item);
    if (item.success === true) {
      console.log('itemsuccess');
      const newFileArray = data.files || [];
      newFileArray.push(item.file);
      const payload = {
        type: 'update',
        name: 'files',
        value: newFileArray,
      };

      props.onUpdate(payload);
    } else {
      console.log('item fail');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={getModalStyle()} className={classes.paper}>
        <Typography className={classes.title}>
          <CustomText value="postTradeInformation" upperCase />
        </Typography>
        <ToolbarBottom>
          <ResetButton title={'reset'} onClick={resetForm} />
        </ToolbarBottom>
        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value={'details'} upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainer}>
              {Object.values(detailFields).map((field) => {
                switch (field.component) {
                  case 'text':
                    if (field.accessor === 'sellerNote' || field.accessor === 'buyerNote') {
                      return (
                        <TextInputField
                          accessor={field.accessor}
                          key={field.accessor}
                          displayName={field.displayName}
                          value={data[field.accessor] || ''}
                          handleChange={handleChange}
                          fullWidth
                        />
                      );
                    } else {
                      return (
                        <TextInputField
                          accessor={field.accessor}
                          key={field.accessor}
                          displayName={field.displayName}
                          value={data[field.accessor] || ''}
                          handleChange={handleChange}
                        />
                      );
                    }

                  case 'date':
                    return (
                      <DateField
                        accessor={field.accessor}
                        key={field.accessor}
                        displayName={field.displayName}
                        value={data[field.accessor] || new Date()}
                        disablePast={false}
                        handleDateChange={handleDateChange}
                      />
                    );

                  default:
                    return null;
                }
              })}
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitleContainer}>
              <ListItemText
                primary={<CustomText value={'files'} upperCase />}
                className={classes.key}
              />
            </div>
            <div className={classes.sectionContentContainerNoFlex}>
              {oldFiles.map((file, index) => {
                return (
                  <FileRow2
                    file={file}
                    key={index}
                    removeFile={() => removeFile(index)}
                    showFile={() => openFile(file.id)}
                    handleDocumentNameChange={() => {}}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <CollapseContainerAlt
            title="uploadFiles"
            style={{ marginTop: '8px', backgroundColor: '#092e37' }}
            expanded={uploadExpanded}
            onClick={() => setUploadExpanded(!uploadExpanded)}
          >
            <React.Fragment>
              <Dropzone2
                setNewFiles={(files) => setNewFiles([...newFiles, ...files])}
                formType={form.formType}
              ></Dropzone2>
              <div className={classes.sectionContentContainerNoFlex}>
                {newFiles.map((file, index) => {
                  return (
                    <FileRow2
                      file={file}
                      key={index}
                      removeFile={() => removeAddedFile(index)}
                      showFile={() => setFileToShow(file)}
                      handleDocumentNameChange={() => {}}
                    />
                  );
                })}
              </div>{' '}
              <ToolbarBottom>
                <ResetButton title="upload" icon="file_upload" onClick={createFiles} />
              </ToolbarBottom>
            </React.Fragment>
          </CollapseContainerAlt>
        </div>
        <fileUpload.components.FileViewer
          file={fileToShow}
          open={fileToShow !== null}
          onClose={() => setFileToShow(null)}
        />
        <ToolbarBottom>
          <React.Fragment>
            <CancelButton title="cancel" onClick={onClose} />
            <ConfirmButton title="save" onClick={sendUpdates} />
          </React.Fragment>
        </ToolbarBottom>
      </div>
    </Modal>
  );
};

PostTradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isPostTradeFormOpen(state),
    form: getPostTradeForm(state),
    data: getPostTradeFormData(state),
    files: fileUpload.selectors.getAllByType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSend: (payload) => {
      dispatch(createAction(POST_TRADE_FORM_OPEN, payload));
    },
    onUpdate: (payload) => {
      dispatch(createAction(POST_TRADE_FORM_OPEN, payload));
    },
    onClose: () => {
      dispatch(createAction(POST_TRADE_FORM_OPEN, { type: 'close', open: false }));
    },
    createFile: (payload) => {
      dispatch(createAction(fileUpload.constants.CREATE_FILE, payload));
    },
    getFileContent: (payload) => {
      dispatch(createAction(fileUpload.constants.GET_FILE_CONTENT, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PostTradeForm));
