export const NAME = 'rfqs';

export const GET_RFQS = 'rfqs/get';
export const GET_RFQS_SUCCESS = 'rfqs/get/success';
export const GET_RFQS_ERROR = 'rfqs/get/error';

export const GET_RFQ = 'rfq/get';
export const GET_RFQ_SUCCESS = 'rfq/get/success';
export const GET_RFQ_ERROR = 'rfq/get/error';

export const CREATE_RFQ = 'RFQ/create';
export const CREATE_RFQ_SUCCESS = 'RFQ/create/success';
export const CREATE_RFQ_ERROR = 'RFQ/create/error';

export const UPDATE_RFQS = 'rfqs/update';
export const UPDATE_RFQS_SUCCESS = 'rfqs/update/success';
export const UPDATE_RFQS_ERROR = 'rfqs/update/error';

export const RESET_RFQS = 'rfqs/reset';

export const INSERT_RFQS_FROM_SOCKET = 'rfq/insert/from/socket';
export const INSERT_RFQS_FROM_SOCKET_SUCCESS = 'rfq/insert/from/socket/success';
export const INSERT_RFQS_FROM_SOCKET_ERROR = 'rfq/insert/from/socket/error';

export const UPDATE_RFQS_FROM_SOCKET = 'rfqs/update/from/socket';
export const UPDATE_RFQS_FROM_SOCKET_SUCCESS = 'rfqs/update/from/socket/success';
export const UPDATE_RFQS_FROM_SOCKET_ERROR = 'rfqs/update/from/socket/error';

export const UPDATE_FORM = 'rfqs/form/update';
export const UPDATE_FORM_SUCCESS = 'rfqs/form/update/success';
export const UPDATE_FORM_ERROR = 'rfqs/form/update/error';

export const RFQ_STATUS_ACTIVE = 'ACTIVE';
export const RFQ_STATUS_ENDED = 'ENDED';

export const UPDATE_RFQ_VIEWS = 'rfq/update/views';
export const UPDATE_RFQ_VIEWS_SUCCESS = 'rfq/update/views/success';
export const UPDATE_RFQ_VIEWS_ERROR = 'rfq/update/views/error';

export const UPDATE_RFQ_DISMISSED = 'rfq/update/dismissed';
export const UPDATE_RFQ_DISMISSED_SUCCESS = 'rfq/update/dismissed/success';
export const UPDATE_RFQ_DISMISSED_ERROR = 'rfq/update/dismissed/error';

export const UPDATE_RESPONSE_FORM = 'rfqs/form/response/update';
export const UPDATE_RESPONSE_FORM_SUCCESS = 'rfqs/form/response/update/success';
export const UPDATE_RESPONSE_FORM_ERROR = 'rfqs/form/response/update/error';
