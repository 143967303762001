import { takeEvery, put, select, call } from 'redux-saga/effects';

import {
  TRADING_PARTNERS_LIST_OPEN,
  TRADING_PARTNERS_LIST_OPEN_SUCCESS,
  TRADING_PARTNERS_LIST_OPEN_ERROR,
  NOTIFICATION_SETTINGS_OPEN,
  NOTIFICATION_SETTINGS_OPEN_SUCCESS,
  NOTIFICATION_SETTINGS_OPEN_ERROR,
  GENERAL_SETTINGS_OPEN,
  GENERAL_SETTINGS_OPEN_SUCCESS,
  GENERAL_SETTINGS_OPEN_ERROR,
  GENERAL_SETTINGS_UPDATE,
  GENERAL_SETTINGS_UPDATE_SUCCESS,
  GENERAL_SETTINGS_UPDATE_ERROR,
  GET_NOTIFICATION_TYPES,
  GET_NOTIFICATION_TYPES_SUCCESS,
  GET_NOTIFICATION_TYPES_ERROR,
  BLOTTER_PRESETS_OPEN,
  BLOTTER_PRESETS_OPEN_SUCCESS,
  BLOTTER_PRESETS_OPEN_ERROR,
  BLOTTER_PRESETS_UPDATE,
  BLOTTER_PRESETS_UPDATE_SUCCESS,
  BLOTTER_PRESETS_UPDATE_ERROR,
} from '../constants';

import {
  tradingPartnersListOpen,
  notificationSettingsOpen,
  generalSettingsOpen,
  generalSettingsUpdate,
  getNotificationTypes as getNotificationTypesAction,
} from '../actions';

import api from '../api';

import { getNotificationTypes, getSavedRfqPresets, getSavedBlotterPresets } from '../selectors';

import auth from '../../auth';
import * as blotterActions from '../../blotters/actions';
import blotterConstants from '../../blotters/constants';
import { getFromLS, saveToLS } from '../../../commons/localStorage';

/**
 *
 * @param {*} action
 */
function* setTradingPartnersListOpen(action) {
  try {
    // const contract = yield call(api.get);

    const payload = {
      open: action.payload.open,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(tradingPartnersListOpen(TRADING_PARTNERS_LIST_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(tradingPartnersListOpen(TRADING_PARTNERS_LIST_OPEN_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* setNotificationSettingsOpen(action) {
  try {
    yield put(getNotificationTypesAction(GET_NOTIFICATION_TYPES));

    const payload = {
      open: action.payload.open,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(notificationSettingsOpen(NOTIFICATION_SETTINGS_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(notificationSettingsOpen(NOTIFICATION_SETTINGS_OPEN_ERROR, { error }));
  }
}

function* setGeneralSettingsOpen(action) {
  try {
    //yield put(getGerneralTypesAction(GET_NOTIFICATION_TYPES));
    const rfqs = getFromLS('savedRfqPresets') || [];
    const payload = {
      open: action.payload.open,
      savedRfqPresets: rfqs,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(generalSettingsOpen(GENERAL_SETTINGS_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(generalSettingsOpen(GENERAL_SETTINGS_OPEN_ERROR, { error }));
  }
}

function* updateGeneralSettings(action) {
  try {
    const payload = { open: true };
    const rfqs = yield select(getSavedRfqPresets);
    const nextRfqs = JSON.parse(JSON.stringify(rfqs));
    const id = action.payload.id;
    const index = nextRfqs.findIndex((r) => r.id === id);

    if (action.payload.type === 'saveName') {
      if (index > -1) {
        nextRfqs[index].name = action.payload.name;
      }
    } else if (action.payload.type === 'deleteWorkspace') {
      if (index > -1) {
        nextRfqs.splice(index, 1);
      }
    } else if (action.payload.type === 'saveAll') {
      saveToLS('savedRfqPresets', nextRfqs);
      payload.open = false;
    }

    payload.savedRfqPresets = nextRfqs;

    const meta = {
      receivedAt: new Date(),
    };

    yield put(generalSettingsUpdate(GENERAL_SETTINGS_UPDATE_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(generalSettingsUpdate(GENERAL_SETTINGS_UPDATE_ERROR, { error }));
  }
}

function* setBlotterPresetsOpen(action) {
  try {
    const presets = getFromLS('blotterPresets') || getFromLS('blotterSettings') || [];
    const payload = {
      open: action.payload.open,
      savedBlotterPresets: presets,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(generalSettingsOpen(BLOTTER_PRESETS_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(generalSettingsOpen(BLOTTER_PRESETS_OPEN_ERROR, { error }));
  }
}

function* updateBlotterPresets(action) {
  try {
    const payload = { open: true };
    const presets = yield select(getSavedBlotterPresets);
    const nextPresets = JSON.parse(JSON.stringify(presets));
    const id = action.payload.id;
    const index = nextPresets.findIndex((r) => r.id === id);

    if (action.payload.type === 'saveName') {
      if (index > -1) {
        nextPresets[index].name = action.payload.name;
      }
    } else if (action.payload.type === 'delete') {
      if (index > -1) {
        nextPresets.splice(index, 1);
      }
    } else if (action.payload.type === 'saveAll') {
      saveToLS('blotterPresets', nextPresets);
      const meta = {
        receivedAt: new Date(),
      };
      yield put(
        blotterActions.updateBlotterPresets(
          blotterConstants.UPDATE_BLOTTER_PRESETS_SUCCESS,
          { item: nextPresets },
          meta,
        ),
      );
      payload.open = false;
    }

    payload.savedBlotterPresets = nextPresets;
    const meta = {
      receivedAt: new Date(),
    };

    yield put(generalSettingsUpdate(BLOTTER_PRESETS_UPDATE_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(generalSettingsUpdate(BLOTTER_PRESETS_UPDATE_ERROR, { error }));
  }
}

function* notificationTypesLoader() {
  try {
    const existingTypes = yield select(getNotificationTypes);
    if (!existingTypes || !existingTypes.length) {
      const token = yield call(auth.selectors.getToken)
      const data ={ token}
      const notificationTypes = yield api.getNotificationTypes(data);

      const payload = {
        notificationTypes,
      };

      const meta = {
        receivedAt: new Date(),
      };

      yield put(notificationSettingsOpen(GET_NOTIFICATION_TYPES_SUCCESS, payload, meta));
    }
  } catch (error) {
    console.error(error);
    yield put(notificationSettingsOpen(GET_NOTIFICATION_TYPES_ERROR, { error }));
  }
}

export function* watchTradingPartnersOpen() {
  yield takeEvery(TRADING_PARTNERS_LIST_OPEN, setTradingPartnersListOpen);
}

export function* watchNotificationSettingsOpen() {
  yield takeEvery(NOTIFICATION_SETTINGS_OPEN, setNotificationSettingsOpen);
}

export function* watchGetNotificationTypes() {
  yield takeEvery(GET_NOTIFICATION_TYPES, notificationTypesLoader);
}

export function* watchGeneralSettingsOpen() {
  yield takeEvery(GENERAL_SETTINGS_OPEN, setGeneralSettingsOpen);
}

export function* watchGeneralSettingsUpdate() {
  yield takeEvery(GENERAL_SETTINGS_UPDATE, updateGeneralSettings);
}

export function* watchBlotterPresetsOpen() {
  yield takeEvery(BLOTTER_PRESETS_OPEN, setBlotterPresetsOpen);
}

export function* watchBlotterPresetsUpdate() {
  yield takeEvery(BLOTTER_PRESETS_UPDATE, updateBlotterPresets);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
