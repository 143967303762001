import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Modal, Typography, Card, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { directions, status } from '../../../../commons/models/constants';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';

import ExecuteTradeFormRow from './ExecuteTradeFormRow';
import TotalsRow from '../TotalsRow';
import CustomText from '../../../../commons/components/formFields/customText';
import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';
import orders from '../../../orders';
import schemas from '../../../schemas';
import {
  isOpenFormExecute,
  getRfqExecuteForm,
  getOrdersExecuteForm,
  getExecuteFormFields,
  getActiveUser,
  getActiveUserOrganisation,
  getOrganisations,
  getOrganisationsObject,
  getOrderToCancelExecuteForm,
  getMyOrganisation,
} from '../../selectors';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  paperNotRfqOwner: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },

  section: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: 'scroll',
  },

  banner: {
    //backgroundColor: theme.palette.primary.dark,
    //padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },

  title: { fontSize: theme.fontSize * 1.5 },
  comments: {
    padding: '8x !important',
    margin: '2.47px',
  },
});

class ExecuteTradeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      orderRows: [],
      showDetails: false,
      snackbarSuccessOpen: false,
      snakcBarErrorOpen: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  submit = () => {
    this.sendOrder();
  };

  getDirection = (direction) => {
    let value = directions.BUY;
    if (direction === directions.BUY) {
      value = directions.SELL;
    }
    return value;
  };

  sendOrder = () => {
    let orderRows = this.props.orders;
    //const { orderIdToCancel } = this.props;

    if (true) {
      const createOrderPayload = {
        orderRows,
        textMessage: this.state.textMessage,
        callback: this.handleSend,
      };

      /*  if (orderIdToCancel) {
        const orderId = orderIdToCancel;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
        };
        const payload = { items: [orderToAmend] };

        this.props.updateOrders(payload);
      } */
      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      //this.closeForm();
    }
  };

  handleSend = (success) => {
    if (success) {
      this.setState({ snackbarSuccessOpen: true });
      const { orderToCancel } = this.props;
      console.log('handleSend:orderToCancel', orderToCancel);
      if (orderToCancel) {
        const orderId = this.props.orderToCancel.id;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
          //assets: order.assets,
        };
        const payload = { items: [orderToAmend] };
        this.props.updateOrders(payload);
      }
      this.closeForm();
    } else {
      this.setState({ snackbarErrorOpen: true });
    }
  };

  closeForm = () => {
    const payload = {
      type: 'execute',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  render() {
    const { classes, orders, open, formFields, contract, activeRfq } = this.props;
    const orderRows = orders || [];
    const orderRow = orders[0] || {};

    const direction = orderRow.direction || directions.BUY;
    const client = orderRow.selectedOrganisationsIds
      ? orderRow.selectedOrganisationsIds[0].name
      : '';

    const beneficiary = orderRow.beneficiaryOrganisationId
      ? this.props.organisationsObject[orderRow.beneficiaryOrganisationId].name
      : '';
    // const isRfqRequestor = activeRfq ? activeRfq.organisationId === user.organisationId : false;
    const orderRowFields = formFields.allFields || null;
    const orderRowFieldKeys = orderRowFields ? Object.keys(orderRowFields) : [];

    const formFieldsTop = orderRowFieldKeys.filter((f) => orderRowFields[f].formTopSection);
    const formFieldsLeft = orderRowFieldKeys.filter((f) => orderRowFields[f].formLeftSection);
    const formFieldsRight = orderRowFieldKeys.filter((f) => orderRowFields[f].formRightSection);
    const formFieldsBottom = orderRowFieldKeys.filter((f) => orderRowFields[f].formBottomSection);

    const beneficiariesIds = this.props.myOrganisation.beneficiariesIds || [];

    const FIELD_WIDTH = '150px';

    console.log('ExecuteTradeForm:props:orderToCancel', this.props.orderToCancel);

    return (
      <React.Fragment>
        <Modal open={open} onClose={this.closeForm}>
          <div
            style={getModalStyle()}
            className={this.state.showDetails ? classes.paper : classes.paperNotRfqOwner}
          >
            <Typography className={classes.title}>
              <CustomText value="executeTrade" upperCase />?
            </Typography>
            <div className={classes.container}>
              <div className={classes.section}>
                <div className={classes.subsection}>
                  <ReadOnlyField displayName={'direction'} value={direction} fullWidth />
                  <ReadOnlyField displayName={'client'} value={client} fullWidth />
                  {beneficiariesIds.length > 0 && (
                    <ReadOnlyField displayName={'beneficiary'} value={beneficiary} fullWidth />
                  )}

                  <ReadOnlyField
                    displayName={'validity'}
                    value={orderRow.timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                    fullWidth
                  />

                  {orderRow.timeInForce !== null && (
                    <ReadOnlyField
                      displayName={'validity'}
                      value={orderRow.timeInForce}
                      fullWidth
                    />
                  )}
                </div>
              </div>

              <div className={classes.sectionFlex}>
                {this.state.showDetails && (
                  <React.Fragment>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        <CustomText value="company" upperCase />
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsTop.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return (
                                <ReadOnlyField
                                  displayName={orderRowFields[field].accessor}
                                  value={orderRow[field] || ''}
                                  width={FIELD_WIDTH}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                      </Card>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                      <div style={{ flex: 1 }}>
                        <Typography className={classes.banner}>
                          <CustomText value="product" upperCase />
                        </Typography>

                        <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                          {orderRowFields &&
                            formFieldsLeft.map((field) => {
                              if (orderRowFields[field] && orderRow) {
                                return (
                                  <ReadOnlyField
                                    displayName={orderRowFields[field].accessor}
                                    value={orderRow[field] || ''}
                                    width={FIELD_WIDTH}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Card>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography className={classes.banner}>
                          <CustomText value="freight" upperCase />
                        </Typography>

                        <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                          {orderRowFields &&
                            formFieldsRight.map((field) => {
                              if (orderRowFields[field] && orderRow) {
                                return (
                                  <ReadOnlyField
                                    displayName={orderRowFields[field].accessor}
                                    value={orderRow[field] || ''}
                                    width={FIELD_WIDTH}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Card>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        <CustomText value="offer" upperCase />
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsBottom.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return (
                                <ReadOnlyField
                                  displayName={orderRowFields[field].accessor}
                                  value={orderRow[field] || ''}
                                  width={FIELD_WIDTH}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                      </Card>
                    </div>
                  </React.Fragment>
                )}
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ClearButton
                      icon="visibility"
                      title={'details'}
                      onClick={() => this.setState({ showDetails: !this.state.showDetails })}
                    />
                  </div>

                  <div className={classes.orders}>
                    {orders.map((row, index) => {
                      return (
                        <ExecuteTradeFormRow
                          key={row.id}
                          orderRow={row}
                          contract={contract}
                          activeRfq={activeRfq}
                          index={index}
                          formFields={formFields.defaultRowFields}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.totals}>
              <TotalsRow rows={orderRows} direction={direction} />
            </div>
            <ToolbarBottom>
              <>
                <CancelButton title={'cancel'} onClick={this.closeForm} />
                <ConfirmButton title={'tradeButton'} onClick={() => this.submit()} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarSuccessOpen}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarSuccessOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackbarSuccessOpen: false })}
            severity="info"
            sx={{ width: '100%' }}
          >
            <CustomText value="orderRequestSuccess" upperCase />
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarErrorOpen}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarErrorOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackbarErrorOpen: false })}
            severity="error"
            sx={{ width: '100%' }}
          >
            <CustomText value="orderRequestFail" upperCase />
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

ExecuteTradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrdersExecuteForm(state),
    rfq: getRfqExecuteForm(state),
    formFields: getExecuteFormFields(state),
    open: isOpenFormExecute(state),
    orderToCancel: getOrderToCancelExecuteForm(state),
    user: getActiveUser(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    myOrganisation: getMyOrganisation(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    updateOrders: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecuteTradeForm));
