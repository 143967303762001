import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Select, MenuItem, AppBar } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/amber';
import teal from '@material-ui/core/colors/teal';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';

import { getTheme } from '../../../commons/selectors';

import { INIT_CHART, DESTROY_CHART, UPDATE_CHART } from '../constants';
import {
  initChart as actionInitChart,
  destroyChart as actionDestroyChart,
  updateChart as actionUpdateChart,
} from '../actions';
import { getLocalChart, getConfig } from '../selectors';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.primary,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
  },
  select: {
    width: '500px',
    fontSize: '18.85px',
  },
  appBar: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },

  responsiveContainer: {
    // backgroundColor: theme.palette.background.paper,
    flex: 1,
    padding: theme.spacing(),
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
  },
  infoTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(),
  },

  info: {
    color: theme.palette.text.hint,
  },
});

class Chart extends React.Component {
  componentDidMount = () => {
    const { id, initChart, widget } = this.props;

    const payload = {
      id,
      widget,
    };
    initChart(payload);
  };

  componentWillUnmount() {
    const { id, destroyChart } = this.props;

    const payload = { id };
    destroyChart(payload);
  }

  CustomTick = (props) => {
    const { x, y, fill, payload } = props;
    const clr = payload.value === '2020' ? props.style.color : fill;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-4} y={16} dy={0} textAnchor="center" fill={clr} transform="rotate(45)">
          {payload.value}
        </text>
      </g>
    );
  };

  changeMarket = (e) => {
    const { id, updateChart, widget } = this.props;

    const payload = {
      id,
      contractName: e.target.value,
      widget,
    };

    updateChart(payload);
  };

  render() {
    const { classes, theme, chart, config } = this.props;
    const { menu = [], data = [], contractName = null } = chart;

    const chartConfig = config[contractName] || { yAxis: [] };

    const LineChartColors = [teal[500], yellow[500], red[500], theme.palette.text.primary];

    return (
      <div className={classes.container}>
        <div className={classes.appBar}>
          <Select
            className={classes.select}
            value={contractName}
            disableUnderline
            name="market"
            onChange={this.changeMarket}
          >
            {menu.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div className={classes.responsiveContainer}>
          <ResponsiveContainer width="99%" height="99%">
            <LineChart
              label="Chart of PU x UV"
              margin={{ top: 15, right: 30, left: 30, bottom: 50 }}
              data={data}
            >
              <XAxis
                dataKey="week"
                stroke={theme.palette.text.disabled}
                interval={10}
                style={{
                  fontSize: theme.fontSize.sm,
                  color: theme.palette.text.primary,
                }}
                tickSize={15}
                tick={<this.CustomTick />}
              />
              <YAxis
                domain={['dataMin', 'dataMax']}
                stroke={theme.palette.text.disabled}
                unit={` ${chartConfig.yAxisUnit}`}
                style={{ fontSize: '14px' }}
                tickSize={15}
              />
              <Tooltip
                wrapperStyle={{
                  width: 200,
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
                contentStyle={{
                  backgroundColor: theme.palette.background.row,
                  color: theme.palette.text.primary,
                }}
              />
              <Legend verticalAlign="top" />
              <CartesianGrid strokeDasharray="5 5" stroke="rgba(0,0,0,.3)" />
              {chartConfig.yAxis.map((y, index) => {
                return (
                  <Line
                    key={y}
                    type="monotone"
                    dataKey={y}
                    stroke={LineChartColors[index] || LineChartColors[3]}
                    dot={false}
                    activeDot={{ r: 4 }}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.infoRow}>
          <div className={classes.infoTitle}>Last Modified:</div>
          <div className={classes.info}>{this.props.chart.lastModified}</div>
        </div>
      </div>
    );
  }
}

// TODO: Use flow and replace object on shape with schema fields
Chart.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  chart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  initChart: PropTypes.func.isRequired,
  destroyChart: PropTypes.func.isRequired,
  updateChart: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const { id, widgetId, widget } = props;

  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    theme: getTheme(state),
    chart: getLocalChart(state, cId),
    config: getConfig(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initChart: (payload) => {
      dispatch(actionInitChart(INIT_CHART, payload));
    },
    destroyChart: (payload) => {
      dispatch(actionDestroyChart(DESTROY_CHART, payload));
    },
    updateChart: (payload) => {
      dispatch(actionUpdateChart(UPDATE_CHART, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chart));
