import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { lightBlue } from '@material-ui/core/colors';
import FileRow from './FileRow';
import FileViewer from './FileViewer';
import SelectField from '../../../commons/components/formFields/SelectField';
import MultipleSelectFieldOrgs from '../../../commons/components/formFields/multipleSelectFieldOrgs';
import { ResetButton } from '../../../commons/components/buttons';
import constants from '../constants';
import { createFile as actionCreateFile } from '../actions';
import { getTradingPartners } from '../selectors';
import { visibilities } from '../../../commons/models/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(),
  },
  dropContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    color: theme.palette.text.opacity,
    padding: theme.spacing(),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.primary,
    height: '190px',
    maxHeight: '190px',
  },

  fileInput: {
    opacity: 0,
    position: 'absolute',
    ZIndex: -1,
  },
  uploadIcon: {
    fontSize: '48px',
  },
  browse: {
    padding: theme.spacing(),
    border: `1px solid ${lightBlue[300]}`,
    color: lightBlue[300],
    '&:hover': {
      transition: 'all .5s ease',
      backgroundColor: lightBlue[500],
      color: 'white',
    },
    cursor: 'pointer',
  },
  itemContainer: {
    padding: theme.spacing(0.5),
  },
  uploadedFiles: {
    padding: theme.spacing(),
    flex: 1,
    overflowY: 'scroll',
  },
  buttonRow: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
/* const documentTypes = [
  'INVOICE',
  'PACKING_LIST',
  'PRODUCT_SPECIFICATION',
  'FISH_CV',
  'ORDER_SPECIFICATION',
  'AIRWAY_BILL',
  'HEALTH_CERTIFICATE',
  'CERTIFICATE_OF_ORIGIN',
  'FULL_PACK',
  'INSPECTION_REPORT',
  'OTHER',
]; */
const documentTypes = [
  'INVOICE',
  'PACKING_LIST',
  'PRODUCT_SPECIFICATION',
  'AIRWAY_BILL',
  'HEALTH_CERTIFICATE',
  'CERTIFICATE_OF_ORIGIN',
  'FULL_PACK',
  'INSPECTION_REPORT',
  'OTHER',
];
const Dropzone = (props) => {
  const { classes, allFieldValues = {} } = props;
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [showFile, setShowFile] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [visibility, setVisibility] = useState(visibilities.SELECTED_ORGANISATIONS);
  const [organisations, setOrganisations] = useState([]);
  const [errorOrganisations, setErrorOrganisations] = useState({ message: '', value: false });
  const [documentType, setDocumentType] = useState('INVOICE');
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [snackbarFileErrorOpen, setSnackbarFileErrorOpen] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  useEffect(() => {
    if (props.sendTo && organisations.length === 0) {
      setVisibility(visibilities.SELECTED_ORGANISATIONS);
      const org = props.tradingPartners.filter((tp) => tp.id === props.sendTo)[0];
      if (org) {
        setOrganisations([org]);
      }
    }
  });
  const handleSuccessClose = () => {
    setSnackbarSuccessOpen(false);
  };
  const handleErrorClose = () => {
    setSnackbarErrorOpen(false);
  };
  const handleFileErrorClose = () => {
    setSnackbarFileErrorOpen(false);
    setRejectedFiles([]);
  };
  const handleFileUploadResult = (result) => {
    if (result) {
      setSnackbarSuccessOpen(true);
    } else {
      setSnackbarErrorOpen(true);
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*, .pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      const nextFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            type: documentType,
            filename: file.name,
          }),
        ),
      ];
      setRejectedFiles(fileRejections);
      if (fileRejections.length > 0) setSnackbarFileErrorOpen(true);
      setFiles(nextFiles);
      setDragging(false);
    },
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
  });
  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);

    setFiles(newFiles);
  };
  const handleShowFile = (file) => {
    setFile(file);
    setShowFile(true);
  };

  const handleDocumentType = (name) => (event) => {
    setDocumentType(event.target.value);
  };
  const handleVisibilityChange = (name) => (event) => {
    setVisibility(event.target.value);
  };
  const handleOrganisationsChange = (name) => (event) => {
    setOrganisations(event.target.value);
  };
  const handleDocumentNameChange = (name) => (event) => {
    const newFiles = [...files];
    newFiles[name].documentName = event.target.value;
    setFiles(newFiles);
  };
  const createFiles = () => {
    files.forEach((file) => {
      console.log('file', file);
      //const orgIds = organisations.map((org) => org.id);
      const payload = {
        data: {
          file,
          visibility,
          organisations,
        },

        callback: handleFileUploadResult,
      };
      console.log('payload', payload);
      props.createFile(payload);
    });

    setFiles([]);
  };

  const _documentType = allFieldValues.documentType
    ? allFieldValues.documentType.displayName
    : 'Document Type';
  const _visibility = allFieldValues.visibility
    ? allFieldValues.visibility.displayName
    : 'Visibility';
  const _visibleTo = allFieldValues.visibleTo ? allFieldValues.visibleTo.displayName : 'Visible To';
  const _dragAndDropFiles = allFieldValues.dragAndDropFiles
    ? allFieldValues.dragAndDropFiles.displayName
    : 'Drag and Drop Files';
  const _or = allFieldValues.or ? allFieldValues.or.displayName : 'or';
  const _browseFiles = allFieldValues.browseFiles
    ? allFieldValues.browseFiles.displayName
    : 'Browse Files';
  const _fileSuccessInfo = allFieldValues.fileSuccessInfo
    ? allFieldValues.fileSuccessInfo.displayName
    : 'File Successfully Uploaded';
  const _fileFailureInfo = allFieldValues.fileFailureInfo
    ? allFieldValues.fileFailureInfo.displayName
    : 'File Failed to Upload';
  const _upload = allFieldValues.upload
    ? allFieldValues.upload.displayName.toUpperCase()
    : 'UPLOAD';
  console.log('dropzone files', files);
  return (
    <div className={`${classes.container} undraggable`}>
      <div className={classes.buttonRow}>
        <SelectField
          accessor="documentType"
          displayName={_documentType}
          value={documentType}
          values={documentTypes}
          handleChange={handleDocumentType}
          fullWidth
          allFieldValues={allFieldValues}
        ></SelectField>
        {props.showVisibility && (
          <SelectField
            accessor="visibility"
            displayName={_visibility}
            value={visibility}
            values={visibilities}
            handleChange={handleVisibilityChange}
            fullWidth
            allFieldValues={allFieldValues}
          />
        )}
        {props.showVisibility && visibility === 'SELECTED_ORGANISATIONS' && (
          <MultipleSelectFieldOrgs
            key="selectedOrganisationsIds"
            accessor="selectedOrganisationsIds"
            displayName={`${_visibleTo}:`}
            value={organisations}
            values={props.tradingPartners}
            handleChange={handleOrganisationsChange}
            errorMessage={errorOrganisations.message}
            errorValue={errorOrganisations.value}
            fullWidth
          />
        )}
      </div>

      <div
        className={classes.dropContainer}
        {...getRootProps()}
        style={dragging ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <div className={classes.itemContainer}>
          <div className={`material-icons ${classes.uploadIcon}`}>get_app</div>
        </div>
        <div className={classes.itemContainer}>
          <div>{_dragAndDropFiles}</div>
        </div>

        <input style={{ flex: 1 }} {...getInputProps()} id="upload-file"></input>

        <div className={classes.itemContainer}>
          <div>{_or}</div>
        </div>
        <div className={classes.itemContainer}>
          <div type="button" onClick={open} className={classes.browse}>
            {_browseFiles}
          </div>
        </div>
      </div>

      <div className={classes.uploadedFiles}>
        {files.map((file, index) => (
          <FileRow
            file={file}
            index={index}
            key={file.name}
            removeFile={() => removeFile(index)}
            showFile={() => handleShowFile(file)}
            handleDocumentNameChange={handleDocumentNameChange}
          />
        ))}
        <Snackbar open={snackbarSuccessOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
          <Alert onClose={handleSuccessClose} severity="info" sx={{ width: '100%' }}>
            {_fileSuccessInfo}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarErrorOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
            {_fileFailureInfo}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarFileErrorOpen}
          autoHideDuration={6000}
          onClose={handleFileErrorClose}
        >
          <Alert onClose={handleFileErrorClose} severity="error" sx={{ width: '100%' }}>
            {rejectedFiles.map((file) => {
              return file.errors[0].message;
            })}
          </Alert>
        </Snackbar>
      </div>
      <div className={classes.footer}>
        <ResetButton title={_upload} icon="publish" onClick={createFiles} />
      </div>
      <FileViewer
        file={file}
        open={showFile}
        onClose={() => setShowFile(false)}
        preview
        allFieldValues={allFieldValues}
      />
    </div>
  );
};

Dropzone.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    tradingPartners: getTradingPartners(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (payload) => {
      dispatch(actionCreateFile(constants.CREATE_FILE, payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dropzone));
