import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';
import Admin from './components/Admin';

const reducers = {
  reducer,
};

const components = {
  Admin,
};

export default { actions, constants, reducers, sagas, selectors, api, components };