import orders from '../../modules/orders';
import rfqs from '../rfqs';
import trades from '../../modules/trades';
import fxrates from '../../modules/fxrates';
import users from '../../modules/users';

const socketConfig = {
  url: `${process.env.REACT_APP_API_URL}/serverupdates`,
  types: {
    order: {
      name: 'Order',
      insert(payload) {
        return orders.actions.insertOrders(orders.constants.INSERT_ORDERS_FROM_SOCKET, payload);
      },
      update(payload) {
        return orders.actions.updateOrders(orders.constants.UPDATE_ORDERS_FROM_SOCKET, payload);
      },
    },
    rfq: {
      name: 'Rfq',
      insert(payload) {
        return rfqs.actions.insertRFQs(rfqs.constants.INSERT_RFQS_FROM_SOCKET, payload);
      },
      update(payload) {
        return rfqs.actions.updateRFQs(rfqs.constants.UPDATE_RFQS_FROM_SOCKET, payload);
      },
    },
    trade: {
      name: 'Trade',
      insert(payload) {
        return trades.actions.insertTrades(trades.constants.INSERT_TRADES_FROM_SOCKET, payload);
      },
      update(payload) {
        return trades.actions.updateTrades(trades.constants.UPDATE_TRADES_FROM_SOCKET, payload);
      },
    },
    fxrate: {
      name: 'FxRate',
      insert(payload) {
        return fxrates.actions.initFxrate(fxrates.constants.INSERT_FXRATES_FROM_SOCKET, payload);
      },
      update(payload) {
        return fxrates.actions.updateFxrate(fxrates.constants.UPDATE_FXRATES_FROM_SOCKET, payload);
      },
    },
    user: {
      name: 'User',
      insert(payload) {
        return users.actions.insertUsers(users.constants.INSERT_USERS_FROM_SOCKET, payload);
      },
      update(payload) {
        return users.actions.updateUsers(users.constants.UPDATE_USERS_FROM_SOCKET, payload);
      },
    },
  },
  events: [
    'AdminMessage',
    'OrderCreated',
    'OrderAmended',
    'RfqCreated',
    'RfqAmended',
    'TradeCreated',
    'TradeAmended',
    'FxRateUpdate',
    'UserStatus',
    'UserCreated',
  ],
  /*  events: [
    'AdminMessage',
    'OrderCreated',
    'OrderAmended',
    'RfqCreated',
    'RfqAmended',
    'TradeCreated',
    'TradeUpdated',
    'FxRateUpdate',
    'UserStatus',
    'UserCreated',
  ], */
  RECONNECT_TIMEOUT: 2000,
  SERVER_TIMEOUT: 10000,
};

export default socketConfig;
