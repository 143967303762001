import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Toolbar, AppBar, Tab, Tabs, Tooltip, IconButton, Icon } from '@material-ui/core';
const styles = (theme) => ({
  tab: {
    minWidth: '150px',
  },
  appBar: {
    flex: 1,
    height: '50px',
    maxHeight: '50px',
    minHeight: '50px',
    backgroundColor: theme.palette.background.blottersMenuBar,
  },
  icon: {
    color: theme.palette.common.white,
  },
});

const MenuBar = (props) => {
  const { classes, allFieldValues = {} } = props;
  //const _myOrders = allFieldValues.myOrders ? allFieldValues.myOrders.displayName : 'My Orders';
  const _ordersSentToMe = allFieldValues.ordersSentToMe
    ? allFieldValues.ordersSentToMe.displayName
    : 'Orders Sent To Me';
  const _myTrades = allFieldValues.myTrades ? allFieldValues.myTrades.displayName : 'My Trades';
  const _chooseColumns = allFieldValues.chooseColumns
    ? allFieldValues.chooseColumns.displayName
    : 'Choose Columns';
  const _clearFilters = allFieldValues.clearFilters
    ? allFieldValues.clearFilters.displayName
    : 'Clear Filters';
  const _saveCurrentSettings = allFieldValues.saveCurrentSettings
    ? allFieldValues.saveCurrentSettings.displayName
    : 'Save Current Settings';
  const _chooseSetting = allFieldValues.chooseSetting
    ? allFieldValues.chooseSetting.displayName
    : 'Choose Setting';
  const _download = allFieldValues.download ? allFieldValues.download.displayName : 'Download';

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ minHeight: '0px' }}>
        <Tabs value={props.tabValue} onChange={props.handleTabChange} style={{ flexGrow: 1 }}>
          {/*  <Tab className={classes.tab} label={_myOrders} /> */}
          {/* <Tab className={classes.tab} label={_ordersSentToMe} /> */}
          <Tab className={classes.tab} label={_myTrades} />
        </Tabs>
        <Tooltip title={_chooseColumns} aria-label={_chooseColumns}>
          <IconButton onClick={props.handleColumnPickerOpen}>
            <Icon className={classes.icon}>view_column</Icon>
          </IconButton>
        </Tooltip>

        {/* <Tooltip title="Show Filters" aria-label="ShowFilters">
              <IconButton onClick={this.showFloatingFilter}>
                <Icon>filter_list</Icon>
              </IconButton>
            </Tooltip> */}
        <Tooltip title={_clearFilters} aria-label={_clearFilters}>
          <IconButton onClick={props.clearFilters}>
            <Icon className={classes.icon}>clear_all</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={_saveCurrentSettings} aria-label={_saveCurrentSettings}>
          <IconButton onClick={props.handleSaveSettingsOpen}>
            <Icon className={classes.icon}>save</Icon>
          </IconButton>
        </Tooltip>

        <Tooltip title={_chooseSetting} aria-label={_chooseSetting}>
          <IconButton
            onClick={(e) => {
              props.handleChooseSettings(e);
            }}
          >
            <Icon className={classes.icon}>list</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={_download} aria-label={_download}>
          <IconButton onClick={props.printAllDisplayedRows}>
            <Icon className={classes.icon}>get_app</Icon>
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuBar);
