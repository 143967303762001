import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  Icon,
  FormControl,
  Menu,
  MenuItem,
  Card,
  Snackbar,
  Divider,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import {
  CancelButton,
  ConfirmButton,
  ResetButton,
  SaveButton,
  ClearButton,
} from '../../../../commons/components/buttons';
import { checkVolume } from '../../../../commons/config/formatters';
import SelectField from '../../../../commons/components/formFields/SelectField';
import SelectFieldOrgs from '../../../../commons/components/formFields/SelectFieldOrgs';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import DateField from '../../../../commons/components/formFields/dateField';
import MultipleSelectField from '../../../../commons/components/formFields/multipleSelectField';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';

import TextFieldFilled from '../../../../commons/components/formFields/textFieldFilled';
import FormRow from './FormRow';
import CheckCommentsPrompt from '../CheckCommentsPrompt';
import SaveRfqForm from './SaveRfqForm';

import CustomText from '../../../../commons/components/formFields/customText';

import { idGenerator } from '../../../../commons/config/functions';
import { getContractWeek } from '../../../contracts/utils/deliveryWeeks';

import {
  getActiveContract,
  isOpenFormCreate,
  getOrganisationsObject,
  getRfqFormOrder,
  getActiveUser,
  getRfqFormFields,
  getEnumerations,
  getRfqOrderRows,
  getTradingPartners,
  getContractType,
  getSavedRfqPresets,
  getMyOrganisation,
} from '../../selectors';

import { updateForm as actionUpdateForm, createRFQ as actionCreateRFQ } from '../../actions';
import { UPDATE_FORM, CREATE_RFQ } from '../../constants';
import { isArray } from 'lodash';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';

const FIELD_WIDTH = '152px';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(0.5),
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),

    alignItems: 'center',
  },
  subsectionWrap: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),

    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },
  title: {},
  filesEmpty: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(),
    margin: theme.spacing(),
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    color: theme.palette.text.disabled,
  },
});

const ERROR_VOLUME = 'Volume must be an integer > 0.';

// TODO: Extract all functionality to saga ans store and constants in config
class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorVolume: { message: '', value: false },
      errorPrice: { message: '', value: false },
      errorSelectedOrganisations: { message: '', value: false },
      promptOpen: false,
      saveFormOpen: false,
      anchorEl: null,
      snackbarSuccessOpen: false,
      snakcBarErrorOpen: false,
    };
  }

  getErrors = () => {
    const order = this.props.order || {};
    let errorVolume = checkVolume(order.volume)
      ? { message: '', value: false }
      : { message: ERROR_VOLUME, value: true };
    let errorPrice = { message: '', value: false };
    let errorSelectedOrganisations = { message: '', value: false };
    if (order.visibility === 'SELECTED_ORGANISATIONS') {
      if (order.selectedOrganisationsIds.length === 0) {
        errorSelectedOrganisations = { message: 'atLeastOneClient', value: true };
      }
    }
    return { errorVolume, errorPrice, errorSelectedOrganisations };
  };
  addOrderRow = () => {
    const { subSegment } = this.props.contractType;
    const subs = this.props.enumerations[subSegment];
    const order = this.props.order;
    const totalVolume = order.volume;
    const orderRowsOld = this.props.orderRows;
    const usedSubSegments = orderRowsOld.map((o) => o[subSegment]);

    const increment = parseInt(totalVolume / (orderRowsOld.length + 1));
    const finalIncrement =
      orderRowsOld.length === 0
        ? totalVolume
        : parseInt(totalVolume - increment * orderRowsOld.length);

    const row = {
      key: idGenerator(),
    };
    const fields = this.props.fields.breakDownRowFields;
    fields.forEach((field) => {
      let orderValue = field.defaultValue;
      if (order[field.accessor]) {
        if (order[field.accessor] !== null && order[field.accessor] !== undefined) {
          if (isArray(order[field.accessor])) {
            orderValue = order[field.accessor][0];
          } else {
            orderValue = order[field.accessor];
          }
        }
      }
      row[field.accessor] = orderValue;
    });
    row[subSegment] = (function (subs) {
      for (let sub in subs) {
        if (!usedSubSegments.includes(subs[sub])) {
          return subs[sub];
        } else {
          //console.log(('sub included,', sub));
        }
      }
      return subs[0];
    })(subs);
    //row.weightClass = order[subSegment][0] || '1-2';
    const orderRows = [...orderRowsOld, row];
    for (let i = 0; i < orderRows.length; i++) {
      if (i === orderRows.length - 1) {
        orderRows[i].volume = finalIncrement;
      } else {
        orderRows[i].volume = increment;
      }
    }
    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };

  deleteOrderRow = (key) => {
    const orderRowsOld = [...this.props.orderRows];
    const index = orderRowsOld.findIndex((i) => i.key === key);
    orderRowsOld.splice(index, 1);
    const totalVolume = this.props.order.volume;
    const increment = parseInt(totalVolume / orderRowsOld.length);
    const finalIncrement =
      orderRowsOld.length === 0
        ? totalVolume
        : parseInt(totalVolume - increment * (orderRowsOld.length - 1));
    const orderRows = [...orderRowsOld];
    for (let i = 0; i < orderRows.length; i++) {
      if (i === orderRows.length - 1) {
        orderRows[i].volume = finalIncrement;
      } else {
        orderRows[i].volume = increment;
      }
    }
    let totals = {};
    if (orderRows.length !== 0) {
      totals = this.updateTotals(orderRows);
    }

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };

  copyOrderRow = (key) => {
    const orderRows = [...this.props.orderRows];
    const index = orderRows.findIndex((i) => i.key === key);

    if (index > -1) {
      const foundRow = orderRows[index];
      const newRow = Object.assign({}, foundRow);
      newRow.key = idGenerator();
      orderRows.splice(index, 0, newRow);
    }

    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };
  updateOrderRow = (row) => {
    const orderRows = [...this.props.orderRows];
    const index = orderRows.findIndex((i) => i.key === row.key);
    orderRows[index] = row;
    const totals = this.updateTotals(orderRows);

    const payload = {
      type: 'create',
      action: 'updateOrderRows',
      value: { orderRows, totals },
      name: 'orderRows',
    };
    this.props.updateForm(payload);
  };
  updateTotals = (rows) => {
    let allWeights = [];
    let allPresentation = [];
    let allQuality = [];
    let volume = 0;
    rows.forEach((r) => {
      allWeights.push(r.weightClass);

      volume += Number(r.volume);
      if (r.presentation) allPresentation.push(r.presentation);
      if (r.quality) allQuality.push(r.quality);
    });
    const weightClass = [...new Set(allWeights)];
    let presentation = [...new Set(allPresentation)];
    let quality = [...new Set(allQuality)];
    if (presentation.includes('ANY')) {
      presentation = ['ANY'];
    }
    if (quality.includes('ANY')) {
      quality = ['ANY'];
    }
    const { subSegment } = this.props.contractType;
    const values = this.props.enumerations[subSegment];

    weightClass.sort((a, b) => values.indexOf(a) - values.indexOf(b));

    return { weightClass, presentation, quality, volume };
  };

  adjustOrderQuantities = (totalVolume) => {
    const { orderRows } = this.props;

    if (orderRows.length > 0) {
      const increment = parseInt(totalVolume / orderRows.length);
      const finalIncrement =
        orderRows.length === 0
          ? totalVolume
          : parseInt(totalVolume - increment * (orderRows.length - 1));
      for (let i = 0; i < orderRows.length; i++) {
        let volume = Number(orderRows[i].volume);
        if (i === orderRows.length - 1) {
          volume = finalIncrement;
        } else {
          volume = increment;
        }
        orderRows[i].volume = volume;
      }
    }

    return orderRows;
  };

  handleChange = (name) => (event) => {
    let payload = {
      type: 'create',
      action: 'update',
      value: event.target.value,
      name,
    };

    switch (name) {
      case 'week':
        payload.action = 'updateDates';
        break;

      case 'selectedOrganisationsIds':
        payload.value =
          event.target.value.length > 1
            ? event.target.value.splice(event.target.value.length - 1, 1)
            : event.target.value;
        break;
      case 'validity':
        const value =
          this.props.order.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
        payload.action = 'updateValidity';
        payload.value = value;
        payload.name = 'timeInForce';
        break;
      case 'volume':
        const orderRows = this.adjustOrderQuantities(payload.value);
        const { rfqSubSegment } = this.props.contractType;
        const weightClass = this.props.order[rfqSubSegment];
        const volume = Number(event.target.value);
        payload = {
          type: 'create',
          action: 'updateOrderRows',
          value: { orderRows, totals: { weightClass, volume: volume } },
          name,
        };

        break;
      default:
    }

    this.props.updateForm(payload);
  };

  handleChangeMultiple = (name) => (event) => {
    let value;
    const { rfqSubSegment } = this.props.contractType;
    const oldValue = this.props.order[name] || [];
    if (name === rfqSubSegment) {
      value = event.target.value;

      this.props.orderRows.forEach((row) => {
        if (!value.includes(row.weightClass)) {
          value.push(row.weightClass);
        } else {
        }
      });
      const values = this.props.enumerations[rfqSubSegment];
      value.sort((a, b) => values.indexOf(a) - values.indexOf(b));
    }
    if (name === 'quality' || name === 'presentation') {
      value = [...event.target.value];
      this.props.orderRows.forEach((row) => {
        if (!value.includes(row[name])) {
          value.push(row[name]);
        } else {
        }
      });
      const values = this.props.enumerations[name];
      value.sort((a, b) => values.indexOf(a) - values.indexOf(b));
    }
    if (event.target.value.indexOf('ANY') === -1) {
      if (event.target.value.length === 0) {
        value = ['ANY'];
      } else {
        value = event.target.value;
      }
    } else if (oldValue.indexOf('ANY') === -1) {
      value = ['ANY'];
    } else {
      value = event.target.value;
      const removed = value.splice(value.indexOf('ANY'), 1);
    }

    const payload = {
      type: 'create',
      action: 'update',
      value: value,
      name,
    };
    this.props.updateForm(payload);
  };

  handleDateChange = (name) => (dat) => {
    const date = dat.format('YYYY-MM-DD');
    let {
      minHarvestDate,
      maxHarvestDate,
      minPackingDate,
      maxPackingDate,
      minDeliveryDate,
      maxDeliveryDate,
      minFreezeDate,
      maxFreezeDate,
      processingDate,
      useByDate,
      week,
      states,
    } = this.props.order;

    const fresh = states === undefined ? (states === 'FRESH' ? 'FRESH' : 'FROZEN') : 'FRESH';
    if (name === 'minHarvestDate') {
      if (date > moment(minDeliveryDate)) {
        minDeliveryDate = date;
        if (fresh === 'FROZEN') {
          week = getContractWeek(new Date(date));
        }
      }

      if (date > moment(maxDeliveryDate)) {
        maxDeliveryDate = date;
      }
      if (fresh !== 'FROZEN') {
        if (date > moment(maxHarvestDate)) {
          maxHarvestDate = date;
        }
        maxHarvestDate = date;
        week = getContractWeek(new Date(date));
      }

      minHarvestDate = date;
      if (date > moment(minFreezeDate)) {
        minFreezeDate = date;
      }
    } else if (name === 'minPackingDate') {
      if (date > moment(minDeliveryDate)) {
        minDeliveryDate = date;
        maxDeliveryDate = date;
      }
      minPackingDate = date;
      maxPackingDate = date;
    } else if (name === 'maxPackingDate') {
      maxPackingDate = date;
    } else if (name === 'minDeliveryDate') {
      if (fresh === 'FROZEN') {
        week = getContractWeek(new Date(date));
      }
      minDeliveryDate = date;
      maxDeliveryDate = date;
    } else if (name === 'maxHarvestDate') {
      maxHarvestDate = date;
    } else if (name === 'maxDeliveryDate') {
      maxDeliveryDate = date;
      if (date < moment(maxHarvestDate)) {
        maxHarvestDate = date;
      }
      if (date < moment(maxFreezeDate)) {
        maxFreezeDate = date;
      }
      if (fresh === 'FROZEN') {
        maxHarvestDate = date;
        maxFreezeDate = date;
      }
    } else if (name === 'minFreezeDate') {
      minFreezeDate = date;
      if (fresh !== 'FROZEN') {
        if (date > moment(maxFreezeDate)) {
          maxFreezeDate = date;
        }

        maxFreezeDate = date;
      }
      if (date < moment(minHarvestDate)) {
        minHarvestDate = date;
      }
    } else if (name === 'maxFreezeDate') {
      maxFreezeDate = date;
    } else if (name === 'useByDate') {
      useByDate = date;
    } else if (name === 'processingDate') {
      processingDate = date;
    }

    const newValues = {
      minHarvestDate,
      maxHarvestDate,
      minPackingDate,
      maxPackingDate,
      minDeliveryDate,
      maxDeliveryDate,
      minFreezeDate,
      maxFreezeDate,
      useByDate,
      processingDate,
      week,
    };

    const payload = {
      type: 'create',
      action: 'changeDates',
      value: newValues,
    };

    this.props.updateForm(payload);
  };
  handleStartDateChange = (name) => (date) => {
    const newDate = date.format('YYYY-MM-DD');
    const payload = {
      type: 'create',
      action: 'update',
      value: newDate,
      name,
    };

    this.props.updateForm(payload);
  };

  createRFQ = () => {
    const errors = this.getErrors();
    const { errorVolume, errorSelectedOrganisations } = errors;
    const { order } = this.props;
    if (errorVolume.value === false && errorSelectedOrganisations.value === false) {
      if (order.rfqType === 'CONTRACT' && order.textMessage === '') {
        this.setState({ promptOpen: true });
      } else {
        this.sendRfq();
      }
    }
  };

  sendRfq = () => {
    const { orderRows } = this.props;
    const payload = {
      orderRows,
      callback: this.handleSend,
    };
    this.props.createRFQ(payload);
    this.setState({ promptOpen: false });
    this.closeForm();
  };

  handleSend = (success) => {
    if (success) {
      this.setState({ snackbarSuccessOpen: true });

      this.setState({ promptOpen: false });
      this.closeForm();
    } else {
      this.setState({ snackbarErrorOpen: true });
    }
  };

  getMinDate = (field) => {
    const { order } = this.props;

    switch (field) {
      case 'minHarvestDate':
        return moment().add(-730, 'days');
      case 'maxHarvestDate':
        return order.minHarvestDate;
      case 'minDeliveryDate':
        return moment().add(-7, 'days');
      case 'maxDeliveryDate':
        return order.minDeliveryDate;
      case 'minFreezeDate':
        return moment().add(-730, 'days');
      case 'maxFreezeDate':
        return order.minHarvestDate;
      case 'processingDate':
        return order.states === 'FRESH' ? moment().add(-14, 'days') : moment().add(-730, 'days');
      default:
        return moment().add(-14, 'days');
    }
  };

  getMaxDate = (field) => {
    const { order } = this.props;
    switch (field) {
      case 'minFreezeDate':
        return order.maxDeliveryDate;
      case 'maxFreezeDate':
        return order.maxDeliveryDate;
      default:
        return new Date(2100, 1, 1);
    }
  };

  closeForm = () => {
    const payload = {
      type: 'create',
      action: 'close',
    };

    this.props.updateForm(payload);
  };
  resetForm = () => {
    const payload = {
      type: 'create',
      action: 'reset',
    };

    this.props.updateForm(payload);
  };
  saveForm = () => {
    this.setState({ saveFormOpen: true });
  };
  saveRfqPreset = (title) => {
    const next = [...this.props.savedRfqPresets];
    const index = next.findIndex((item) => item.name === title);
    const nextRfq = JSON.parse(JSON.stringify(this.props.order));
    const nextOrderRows = JSON.parse(JSON.stringify(this.props.orderRows));
    nextRfq.orderRows = nextOrderRows;
    if (index === -1) {
      const newpreset = {
        name: title,
        id: idGenerator(),
        data: nextRfq,
      };
      next.push(newpreset);
    } else {
      next[index].data = nextRfq;
    }
    const payload = {
      type: 'create',
      action: 'savePresets',
      item: next,
    };

    this.props.updateForm(payload);
    this.setState({ saveFormOpen: false });
  };
  chooseRfqPreset = (preset) => {
    const beneficiariesIds = this.props.myOrganisation.beneficiariesIds || [];
    if (!beneficiariesIds.some((id) => id === preset.data.beneficiaryOrganisationId)) {
      preset.data.beneficiaryOrganisationId = this.props.myOrganisation.id;
    }
    const payload = {
      type: 'create',
      action: 'choosePreset',
      item: preset,
    };

    this.props.updateForm(payload);
    this.setState({ anchorEl: null });
  };

  chooseLastRfq = () => {
    const payload = {
      type: 'create',
      action: 'chooseLastRfq',
    };

    this.props.updateForm(payload);
    this.setState({ anchorEl: null });
  };

  openPreset = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  getFieldComponent = (field, order, enumerations) => {
    switch (field.component) {
      case 'select':
        return (
          <React.Fragment key={field.accessor}>
            <SelectField
              accessor={field.accessor}
              value={order[field.accessor]}
              values={enumerations[field.accessor]}
              handleChange={this.handleChange}
              width={FIELD_WIDTH}
            />
          </React.Fragment>
        );

      case 'date':
        return (
          <React.Fragment key={field.accessor}>
            <DateField
              accessor={field.accessor}
              value={order[field.accessor]}
              disablePast={field.disablePast}
              handleDateChange={this.handleDateChange}
              //minDate={this.getMinDate(field.accessor)}
              //maxDate={this.getMaxDate(field.accessor)}
              width={FIELD_WIDTH}
              clearable={field.clearable}
            />
          </React.Fragment>
        );
      case 'text':
        return (
          <React.Fragment key={field.accessor}>
            <TextFieldFilled
              accessor={field.accessor}
              value={order[field.accessor]}
              handleChange={this.handleChange}
              width={FIELD_WIDTH}
            ></TextFieldFilled>
          </React.Fragment>
        );
      case 'multiSelect':
        return (
          <React.Fragment key={field.accessor}>
            <MultipleSelectField
              accessor={field.accessor}
              value={order[field.accessor]}
              values={enumerations[field.accessor] || []}
              handleChange={this.handleChangeMultiple}
              any
              width={FIELD_WIDTH}
            />
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  render() {
    const { classes, fields, order, open, savedRfqPresets, enumerations } = this.props;
    const formFields = fields.formFields || [];
    const formFieldsTop = formFields.filter((f) => f.rfqFormTopSection);
    const formFieldsLeft = formFields.filter((f) => f.rfqFormLeftSection);
    const formFieldsRight = formFields.filter((f) => f.rfqFormRightSection);
    const formFieldsBottom = formFields.filter((f) => f.rfqFormBottomSection);

    const requestor = this.props.organisationsObject[this.props.user.organisationId]
      ? this.props.organisationsObject[this.props.user.organisationId].shortName
      : '';
    const breakDownRowFields = fields.breakDownRowFields || [];

    const errors = this.getErrors();
    const { errorSelectedOrganisations } = errors;
    const periods =
      order.contractFrequency === 'MONTHLY' ? 12 : order.contractFrequency === 'QUARTERLY' ? 4 : 52;
    const periodsEnum = [];
    let i;
    for (i = 1; i <= periods; i++) {
      periodsEnum.push(i);
    }

    const beneficiariesIds = this.props.myOrganisation.beneficiariesIds || [];

    const mainForm = () => {
      return (
        <React.Fragment>
          <Modal open={open} onClose={this.closeForm}>
            <div style={getModalStyle()} className={classes.paper}>
              <Typography className={classes.title}>
                <CustomText value="rfqRequest" upperCase />
              </Typography>
              <ToolbarBottom>
                <>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ClearButton title={'preset'} onClick={this.openPreset} />
                    <SaveButton title={'save'} onClick={this.saveForm} />
                    <ResetButton title={'reset'} onClick={this.resetForm} />
                  </div>
                </>
              </ToolbarBottom>
              <div className={classes.container}>
                {true && (
                  <div className={classes.section}>
                    <div className={classes.subsectionWrap}>
                      <DirectionField
                        accessor="direction"
                        displayName="Direction"
                        value={order.direction}
                        values={enumerations.direction}
                        handleChange={this.handleChange}
                      />

                      {/*   <SelectField
                      accessor="visibility"
                      displayName="Visibility"
                      value={order.visibility}
                      values={visibilities}
                      handleChange={this.handleChange}
                      fullWidth
                    /> */}

                      {order.visibility === 'SELECTED_ORGANISATIONS' && (
                        <MultipleSelectFieldOrgs
                          key="selectedOrganisationsIds"
                          accessor="selectedOrganisationsIds"
                          displayName={<CustomText value="client" />}
                          value={order.selectedOrganisationsIds}
                          values={this.props.tradingPartners}
                          handleChange={this.handleChange}
                          errorMessage={errorSelectedOrganisations.message}
                          errorValue={errorSelectedOrganisations.value}
                          width="360px"
                        />
                      )}
                      {beneficiariesIds.length > 0 && (
                        <SelectFieldOrgs
                          accessor="beneficiaryOrganisationId"
                          displayName="beneficiary"
                          value={order.beneficiaryOrganisationId || {}}
                          values={[...beneficiariesIds, this.props.myOrganisation.id]}
                          handleChange={this.handleChange}
                          orgs
                          width="360px"
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className={classes.section}>
                  <Typography className={classes.banner}>
                    <CustomText value="company" upperCase />
                  </Typography>
                  <Card style={{ margin: '0px 4px 4px', padding: '4px' }}>
                    <ReadOnlyField
                      displayName={'company'}
                      value={requestor}
                      width={FIELD_WIDTH}
                    ></ReadOnlyField>
                    {formFieldsTop.map((field) => {
                      return this.getFieldComponent(field, order, enumerations);
                    })}
                  </Card>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className={classes.section}>
                    <Typography className={classes.banner}>
                      {' '}
                      <CustomText value="product" upperCase />
                    </Typography>
                    <Card style={{ margin: '0px 4px 4px', padding: '4px' }}>
                      {formFieldsLeft.map((field) => {
                        return this.getFieldComponent(field, order, enumerations);
                      })}
                    </Card>
                  </div>
                  <div className={classes.section}>
                    <Typography className={classes.banner}>
                      {' '}
                      <CustomText value="freight" upperCase />
                    </Typography>
                    <Card style={{ margin: '0px 4px 4px', padding: '4px' }}>
                      {formFieldsRight.map((field) => {
                        return this.getFieldComponent(field, order, enumerations);
                      })}
                    </Card>
                  </div>
                </div>

                <div className={classes.section}>
                  <Typography className={classes.banner}>
                    {' '}
                    <CustomText value="offer" upperCase />
                  </Typography>
                  <Card style={{ margin: '0px 4px 4px', padding: '4px' }}>
                    {formFieldsBottom.map((field) => {
                      return this.getFieldComponent(field, order, enumerations);
                    })}
                  </Card>
                  <FormControl>
                    <ClearButton
                      icon="add"
                      title={'addItem'}
                      onClick={this.addOrderRow}
                      //style={{ justifyContent: 'center', backgroundColor: '#092e37' }}
                    />
                  </FormControl>
                </div>

                <div className={classes.sectionFlex}>
                  {this.props.orderRows.map((row, index) => (
                    <Card
                      key={index}
                      style={{ margin: '4px', display: 'flex', flexDirection: 'row' }}
                    >
                      <FormRow
                        key={row.key}
                        orderRow={row}
                        copyOrderRow={() => this.copyOrderRow(row.key)}
                        deleteOrderRow={() => this.deleteOrderRow(row.key)}
                        updateOrderRow={this.updateOrderRow}
                        index={index}
                        enumerations={enumerations}
                        formFields={breakDownRowFields}
                        adornment={{ volume: order.unit, price: order.currency }}
                      />
                    </Card>
                  ))}
                </div>
              </div>

              <ToolbarBottom>
                <React.Fragment>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CancelButton title={'cancel'} onClick={this.closeForm} />
                  </div>
                  <ConfirmButton title={'send'} onClick={this.createRFQ} />
                </React.Fragment>
              </ToolbarBottom>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.setState({ anchorEl: null })}
                MenuListProps={{ style: { minWidth: '240px' } }}
              >
                {savedRfqPresets.length === 0 && (
                  <div className={classes.filesEmpty}>
                    <div>No Saved Presets</div>
                    <Icon>filter_none</Icon>
                  </div>
                )}
                {savedRfqPresets.map((preset) => {
                  return (
                    <MenuItem key={preset.id} onClick={() => this.chooseRfqPreset(preset)}>
                      {preset.name}
                    </MenuItem>
                  );
                })}
                <Divider />
                <MenuItem key={'lastRfq'} onClick={() => this.chooseLastRfq()}>
                  <CustomText value="lastRfq" upperCase></CustomText>
                </MenuItem>
              </Menu>
            </div>
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={this.state.snackbarSuccessOpen}
            autoHideDuration={6000}
            onClose={() => this.setState({ snackbarSuccessOpen: false })}
          >
            <Alert
              onClose={() => this.setState({ snackbarSuccessOpen: false })}
              severity="info"
              sx={{ width: '100%' }}
            >
              <CustomText value="orderRequestSuccess" upperCase />
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={this.state.snackbarErrorOpen}
            autoHideDuration={6000}
            onClose={() => this.setState({ snackbarErrorOpen: false })}
          >
            <Alert
              onClose={() => this.setState({ snackbarErrorOpen: false })}
              severity="error"
              sx={{ width: '100%' }}
            >
              <CustomText value="orderRequestFail" upperCase />
            </Alert>
          </Snackbar>
        </React.Fragment>
      );
    };

    if (this.state.promptOpen) {
      return (
        <CheckCommentsPrompt
          open={this.state.promptOpen}
          onClose={() => this.setState({ promptOpen: false })}
          continue={this.sendRfq}
        />
      );
    } else if (this.state.saveFormOpen) {
      return (
        <SaveRfqForm
          open={this.state.saveFormOpen}
          onClose={() => this.setState({ saveFormOpen: false })}
          savedRfqPresets={savedRfqPresets}
          saveRfqPreset={this.saveRfqPreset}
        />
      );
    } else {
      return mainForm();
    }
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const matchIds = []; // state.client.orderEntryMatchIds,

const mapStateToProps = (state) => {
  return {
    organisationsObject: getOrganisationsObject(state),
    user: getActiveUser(state),
    tradingPartners: getTradingPartners(state),
    contract: getActiveContract(state),
    contractType: getContractType(state),
    open: isOpenFormCreate(state),
    matchIds,
    order: getRfqFormOrder(state),
    fields: getRfqFormFields(state),
    enumerations: getEnumerations(state),
    orderRows: getRfqOrderRows(state),
    savedRfqPresets: getSavedRfqPresets(state) || [],
    myOrganisation: getMyOrganisation(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => {
      dispatch(actionUpdateForm(UPDATE_FORM, payload));
    },
    createRFQ: (payload) => {
      dispatch(actionCreateRFQ(CREATE_RFQ, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Form));
