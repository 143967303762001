//import coreApi from '../../../core/services/api';
import fetch from 'cross-fetch';
import HTTPError from '../../../commons/api/HTTPError';
import defaultData from '../config/defaultData';

class Api {
  constructor() {
    this.baseUrl = `https://resource.lfex.matchtech.cloud`;
    this.getPriceData = this.getPriceData.bind(this);
    this.defaultOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: null,
    };
  }

  create(url) {
    const requestOptions = {};

    requestOptions.method = this.defaultOptions.method;
    requestOptions.credentials = this.defaultOptions.credentials;
    requestOptions.body = this.defaultOptions.body;

    const requestUrl = url;
    const request = fetch(requestUrl, requestOptions);
    return request;
  }

  async getResponse(name) {
    const url = `${this.baseUrl}/priceData/${name}.json`;
    const response = await this.create(url);
    let data = defaultData[name];
    let lastModified = '';
    if (response.ok) {
      try {
        data = await response.json();
        lastModified = response.headers.map['last-modified'];
      } catch {
        console.log('Could not parse chart Json Data');
      }
      return { data, lastModified };
    }

    throw new HTTPError(response.status, response.statusText);
  }

  async getPriceData(name) {
    return this.getResponse(name);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
