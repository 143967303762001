import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import Chart from './components/Chart';
import LfexPrices from './components/LfexPrices';
import api from './api';

const reducers = {
  reducer,
};

const components = {
  Chart,
  LfexPrices,
};

export default { actions, constants, reducers, sagas, selectors, components, api };
