import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';

import { Modal, Typography, InputBase } from '@material-ui/core';

import { directions, visibilities } from '../../../../commons/models/constants';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import { checkVolume, checkPrice } from '../../../../commons/config/formatters';

import { updateForm as actionUpdateForm, createOrder as actionCreateOrder } from '../../actions';
import { UPDATE_FORM, CREATE_ORDER } from '../../constants';
import {
  isFormCreateOpen,
  getOrderForm,
  getEnumerations,
  getFormFields,
  getActiveContract,
  getTradingPartners,
  getContractType,
} from '../../selectors';

import SelectField from '../../../../commons/components/formFields/SelectField';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import DateField from '../../../../commons/components/formFields/dateField';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';
import DateTimeField from '../../../../commons/components/formFields/dateTimeField';
import NumberFieldFilled from '../../../../commons/components/formFields/numberFieldFilled';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(139),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: 300,
    color: 'white',
  },
  selector: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: 300,
    color: 'white',
  },
  textFieldSell: {
    color: teal[300],
  },
  textFieldBuy: {
    color: lightBlue[500],
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 400,
  },
  container: { marginTop: '10px' },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.type === 'light' ? theme.palette.text.faded_75 : theme.palette.text.faded,
  },
  title: { fontSize: theme.fontSize * 1.5 },
  formControl: {
    marginLeft: 0,
    marginTop: 0,
    width: 300,
  },
  formControlLabel: {
    margin: theme.spacing(),
    marginTop: theme.spacing(2),
    width: 300,
  },
  iconButton: {
    paddingLeft: '0px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '4px',
    padding: '2px',
    height: 'auto',
  },
});

// TODO: Extract to config
const ERROR_VOLUME = 'Volume must be an integer > 0.';
const ERROR_PRICE = 'Price must be a decimal( max 3 places ) > 0.';
const ERROR_RECIPIENT = 'At least one organisation must be selected.';

class CreateOrder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorVolume: { message: '', value: false },
      errorPrice: { message: '', value: false },
      errorRecipients: { message: '', value: false },
      validity: 'GTC',
    };
  }

  handleChange = (name) => (event) => {
    let payload = {
      type: 'create',
      action: 'update',
      updatedField: name,
      updatedValue: event.target.value,
    };
    this.props.updateForm(payload);

    switch (name) {
      case 'price':
        if (checkPrice(event.target.value)) {
          this.setState({
            errorPrice: { message: '', value: false },
          });
        } else {
          this.setState({
            errorPrice: { message: ERROR_PRICE, value: true },
          });
        }
        break;
      case 'validity':
        const value =
          this.props.order.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
        payload.updatedValue = value;
        payload.updatedField = 'timeInForce';
        break;
      case 'volume':
        if (checkVolume(event.target.value)) {
          this.setState({
            errorVolume: { message: '', value: false },
          });
        } else {
          this.setState({
            errorVolume: { message: ERROR_VOLUME, value: true },
          });
        }
        break;

      case 'selectedOrganisationsIds':
        let errorRecipients = {};

        if (event.target.value.length === 0) {
          errorRecipients = { message: ERROR_RECIPIENT, value: true };
        } else {
          errorRecipients = { message: '', value: false };
        }
        this.setState({
          errorRecipients,
        });
        break;

      case 'visibility':
        errorRecipients = { message: '', value: false };
        if (event.target.value === 'SELECTED_ORGANISATIONS') {
          errorRecipients = { message: ERROR_RECIPIENT, value: true };
        }
        this.setState({
          errorRecipients,
        });
        break;

      default:
    }
    this.props.updateForm(payload);
  };

  handleGtc = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (name) => (date) => {
    let payload = {
      type: 'create',
      action: 'update',
      updatedField: name,
      updatedValue: date,
    };
    this.props.updateForm(payload);
  };

  submit = () => {
    const { errorPrice, errorVolume, errorRecipients } = this.state;

    if (
      errorPrice.value === false &&
      errorVolume.value === false &&
      errorRecipients.value === false
    ) {
      this.sendOrder();
    }
  };

  sendOrder = () => {
    const { contract } = this.props;

    const payload = {
      data: {},
      contract,
    };

    this.props.createOrder(payload);

    this.closeForm();
  };

  closeForm = () => {
    const payload = {
      type: 'create',
      action: 'close',
    };
    this.props.updateForm(payload);
  };

  render() {
    const { classes, enumerations, order, fields, contract, contractType, open, tradingPartners } =
      this.props;
    const subsegment = fields.find((f) => f.accessor === contractType.subSegment) || {};

    return (
      <div>
        <Modal open={open} onClose={this.closeForm}>
          <div style={getModalStyle()} className={classes.paper}>
            <Typography className={classes.title}>MARKET ORDER</Typography>
            <div className={classes.container}>
              <Typography className={classes.banner}>CONTRACT</Typography>
              <div className={classes.containerItems}>
                {fields.map((field, index) => {
                  if (
                    field.accessor !== contractType.subSegment &&
                    field.marketViewOrder === true
                  ) {
                    switch (field.component) {
                      case 'select':
                        return (
                          <React.Fragment key={index}>
                            <SelectField
                              accessor={field.accessor}
                              displayName={field.displayName}
                              value={order[field.accessor]}
                              values={enumerations[field.accessor]}
                              handleChange={this.handleChange}
                            />
                          </React.Fragment>
                        );

                      case 'date':
                        return (
                          <React.Fragment key={index}>
                            <DateField
                              accessor={field.accessor}
                              displayName={field.displayName}
                              value={order[field.accessor]}
                              disablePast={field.disablePast}
                              handleDateChange={this.handleDateChange}
                              clearable
                            />
                          </React.Fragment>
                        );
                      /* 
                      case 'multi-select':
                        return (
                          <React.Fragment key={index}>
                            <MultipleSelectField
                              accessor={field.accessor}
                              displayName={field.displayName}
                              value={order[field.accessor]}
                              values={enumerations[field.accessor]}
                              handleChange={this.handleChange}
                              errorRecipientsMessage={this.state.errorRecipients.message}
                            />
                          </React.Fragment>
                        ); */

                      default:
                        return null;
                    }
                  }
                  return null;
                })}
              </div>
            </div>
            <div className={classes.container}>
              <Typography className={classes.banner}>ORDER TYPE</Typography>
              <div className={classes.containerItems}>
                <SelectField
                  accessor="validity"
                  displayName="Validity"
                  value={order.timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                  values={['GTC', 'TIME IN FORCE']}
                  handleChange={this.handleChange}
                />
                {order.timeInForce !== null && (
                  <DateTimeField
                    accessor="timeInForce"
                    displayName="Time In Force"
                    value={order.timeInForce}
                    handleChange={this.handleChange}
                  />
                )}
                <SelectField
                  accessor="visibility"
                  displayName="Visibility"
                  value={order.visibility}
                  values={visibilities}
                  handleChange={this.handleChange}
                />

                {order.visibility === visibilities.SELECTED_ORGANISATIONS && (
                  <MultipleSelectFieldOrgs
                    accessor="selectedOrganisationsIds"
                    displayName="Visible To:"
                    value={order.selectedOrganisationsIds}
                    values={tradingPartners}
                    handleChange={this.handleChange}
                    errorRecipientsMessage={this.state.errorRecipients.message}
                    errorValue={this.state.errorRecipients.value}
                  />
                )}
              </div>
            </div>
            <div className={classes.container}>
              <Typography className={classes.banner}>Comments</Typography>
              <div className={classes.containerItems}>
                <InputBase
                  value={this.state.textMessage}
                  type="text"
                  label="Comments"
                  placeholder="Comments"
                  multiline
                  fullWidth
                  onChange={(e) => {
                    this.setState({ textMessage: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className={classes.container}>
              <Typography className={classes.banner}>ORDER</Typography>
              <div className={classes.containerItems}>
                <DirectionField
                  accessor="direction"
                  displayName="Direction"
                  value={order.direction}
                  values={Object.keys(directions)}
                  handleChange={this.handleChange}
                />

                <NumberFieldFilled
                  accessor="volume"
                  displayName="Volume"
                  value={order.volume}
                  handleChange={this.handleChange}
                  error={this.state.errorVolume}
                  adornment={order.unit}
                />
                <NumberFieldFilled
                  accessor="price"
                  displayName="Price"
                  value={order.price}
                  handleChange={this.handleChange}
                  error={this.state.errorPrice}
                  adornment={`${this.state.currency || order.currency}/KG`}
                  step="0.1"
                />
                <SelectField
                  accessor={contract.subSegment}
                  displayName={subsegment.displayName || ''}
                  defaultValue={order[contract.subSegment]}
                  value={order[contractType.subSegment]}
                  values={enumerations[contractType.subSegment]}
                  handleChange={this.handleChange}
                  errorRecipientsMessage={this.state.errorRecipients.message}
                />
              </div>
            </div>

            <ToolbarBottom>
              <>
                <CancelButton onClick={this.closeForm} />
                <ConfirmButton onClick={this.submit} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
      </div>
    );
  }
}

CreateOrder.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    open: isFormCreateOpen(state),
    contract: getActiveContract(state),
    order: getOrderForm(state), // state.client.orderFormOrder, BUTTON NEW ORDER SAVE TO STORE orderFormOrder for ORDER FORM CREATE AND SELECT THIS STATE
    enumerations: getEnumerations(state),
    tradingPartners: getTradingPartners(state),
    fields: getFormFields(state),
    contractType: getContractType(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => {
      dispatch(actionUpdateForm(UPDATE_FORM, payload));
    },
    createOrder: (payload) => {
      dispatch(actionCreateOrder(CREATE_ORDER, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateOrder));
