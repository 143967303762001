import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';

const styles = (theme) => ({});

const ChooseSettingsMenu = (props) => {
  const { items, anchorEl, onClick, onClose } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={anchorEl !== null}
      onClose={onClose}
    >
      {items.map((w) => (
        <MenuItem key={w.name} onClick={() => onClick(w.id)} style={{ minWidth: '250px' }}>
          {w.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

ChooseSettingsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
};
export default withStyles(styles)(ChooseSettingsMenu);
