const NAME = 'fileUpload';

const INIT_FILE_UPLOAD = 'fileUpload/init';
const INIT_FILE_UPLOAD_SUCCESS = 'fileUpload/init/success';
const INIT_FILE_UPLOAD_ERROR = 'fileUpload/init/error';

const DESTROY_FILE_UPLOAD = 'fileUpload/destroy';
const DESTROY_FILE_UPLOAD_SUCCESS = 'fileUpload/destroy/success';
const DESTROY_FILE_UPLOAD_ERROR = 'fileUpload/destroy/error';

const UPDATE_FILE_UPLOAD = 'fileUpload/update';
const UPDATE_FILE_UPLOAD_SUCCESS = 'fileUpload/update/success';
const UPDATE_FILE_UPLOAD_ERROR = 'fileUpload/update/error';

const CREATE_FILE = 'fileUpload/createFile';
const CREATE_FILE_SUCCESS = 'fileUpload/createFile/success';
const CREATE_FILE_ERROR = 'fileUpload/createFile/error';

const GET_FILE = 'fileUpload/getFile';
const GET_FILE_SUCCESS = 'fileUpload/getFile/success';
const GET_FILE_ERROR = 'fileUpload/getFile/error';

const GET_FILE_CONTENT = 'fileUpload/getFileContent';
const GET_FILE_CONTENT_SUCCESS = 'fileUpload/getFileContent/success';
const GET_FILE_CONTENT_ERROR = 'fileUpload/getFileContent/error';

const GET_FILES = 'fileUpload/getFiles';
const GET_FILES_SUCCESS = 'fileUpload/getFiles/success';
const GET_FILES_ERROR = 'fileUpload/getFiles/error';

const DELETE_FILE = 'fileUpload/deleteFile';
const DELETE_FILE_SUCCESS = 'fileUpload/deleteFile/success';
const DELETE_FILE_ERROR = 'fileUpload/deleteFile/error';

const UPDATE_FILE_META = 'fileUpload/updateFileMeta';
const UPDATE_FILE_META_SUCCESS = 'fileUpload/updateFileMeta/success';
const UPDATE_FILE_META_ERROR = 'fileUpload/updateFileMeta/error';

export default {
  NAME,
  INIT_FILE_UPLOAD,
  INIT_FILE_UPLOAD_SUCCESS,
  INIT_FILE_UPLOAD_ERROR,
  DESTROY_FILE_UPLOAD,
  DESTROY_FILE_UPLOAD_SUCCESS,
  DESTROY_FILE_UPLOAD_ERROR,
  UPDATE_FILE_UPLOAD,
  UPDATE_FILE_UPLOAD_SUCCESS,
  UPDATE_FILE_UPLOAD_ERROR,
  CREATE_FILE,
  CREATE_FILE_SUCCESS,
  CREATE_FILE_ERROR,
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  GET_FILE_CONTENT,
  GET_FILE_CONTENT_SUCCESS,
  GET_FILE_CONTENT_ERROR,
  GET_FILES,
  GET_FILES_SUCCESS,
  GET_FILES_ERROR,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  UPDATE_FILE_META,
  UPDATE_FILE_META_SUCCESS,
  UPDATE_FILE_META_ERROR,
};
