import { takeEvery, put, call } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';

import orderModel from '../models/order_model.json';
//import orderAssetsModel from '../models/orderAssets_model.json';
//import orderContractModel from '../models/orderContract_model.json';
//import orderContractUnderlyingModel from '../models/orderContract_underlying_model.json';
import rfqModel from '../models/rfq_model.json';
//import rfqAssetsModel from '../models/rfqAssets_model.json';
//import rfqContractModel from '../models/rfqContract_model.json';
//import rfqContractUnderlyingModel from '../models/rfqContract_underlying_model.json';
import translationModel from '../models/translation_model.json';
import { createAction } from '../../../commons/actions';
import { getFromLS, saveToLS } from '../../../commons/localStorage';
import api from '../api';
import auth from '../../auth';
import { useLanguage } from '../../../commons/contexts/LanguageProvider';

/**
 *
 * @param {*} action
 */
function* initSchemas(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    console.log('saga:initSchemas:action', action);
    const options = {
      token,
      params: {},
    };

    const models = yield call(api.get, options);
    const language = getFromLS('language') || {
      name: 'GB',
      fullName: 'United Kingdom',
      language: 'English',
      langaugeIso: 'EN',
    };
    const languageString = language.language.toLowerCase();

    const allFieldValues = createAllFieldValues(language, translationModel);

    let payload = {
      orderModel,
      rfqModel,
      translationModel,
      allFieldValues,
      languageString,
    };
    models.forEach((m) => (payload[m.name] = m));

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.INIT_SCHEMAS_SUCCESS, payload, meta));
  } catch (error) {
    console.log('saga:schemas:init:error', error);
    yield put(createAction(actionTypes.INIT_SCHEMAS_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* createFieldValue(action) {
  try {
    const token = yield call(auth.selectors.getToken);

    const data = {
      body: action.payload.body,
      type: action.payload.type,
      token,
    };

    const response = yield call(api.createFieldValue, data);

    const payload = {
      type: action.payload.type,
      item: response,
    };

    yield put(
      createAction(actionTypes.CREATE_FIELD_VALUE_SUCCESS, payload, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      createAction(actionTypes.CREATE_FIELD_VALUE_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

/**
 *
 * @param {*} action
 */

function* deleteFieldValue(action) {
  try {
    const token = yield call(auth.selectors.getToken);

    const data = {
      value: action.payload.value,
      type: action.payload.type,
      token,
    };

    const response = yield call(api.deleteFieldValue, data);

    const payload = {
      type: action.payload.type,
      item: action.payload.value,
    };

    yield put(
      createAction(actionTypes.DELETE_FIELD_VALUE_SUCCESS, payload, { receivedAt: new Date() }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      createAction(actionTypes.DELETE_FIELD_VALUE_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

/**
 *
 * @param {*} action
 */
function* initLanguage() {
  const payload = {
    language: getFromLS('language') || {
      name: 'GB',
      fullName: 'United Kingdom',
      language: 'English',
      langaugeIso: 'EN',
    },
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(createAction(actionTypes.INIT_LANGUAGE_SUCCESS, payload, meta));
}

/**
 *
 * @param {*} action
 */

function* changeLanguage(action) {
  const { language } = action.payload;

  if (language) {
    saveToLS('language', language);

    const allFieldValues = createAllFieldValues(language, translationModel);

    const payload = {
      language: action.payload.language,
      allFieldValues,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.CHANGE_LANGUAGE_SUCCESS, payload, meta));
  }
}

export function* watchInitSchemas() {
  yield takeEvery(actionTypes.INIT_SCHEMAS, initSchemas);
}

export function* watchCreateFieldValue() {
  yield takeEvery(actionTypes.CREATE_FIELD_VALUE, createFieldValue);
}

export function* watchDeleteFieldValue() {
  yield takeEvery(actionTypes.DELETE_FIELD_VALUE, deleteFieldValue);
}

export function* watchInitLanguage() {
  yield takeEvery(actionTypes.INIT_LANGUAGE, initLanguage);
}

export function* watchChangeLanguage() {
  yield takeEvery(actionTypes.CHANGE_LANGUAGE, changeLanguage);
}

function createAllFieldValues(lang, model) {
  const language = lang.language.toLowerCase();

  let items = {};
  if (model.fields) {
    model.fields.forEach((val) => {
      items[val.enum] = {
        ...val,
        displayName: val[language],
        displayFields: [{ displayName: val[language] }],
      };
    });
  }
  return items;
}
