import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';

import CreateOrder from './components/forms/CreateOrder';
import AmendMultipleOrderForm from './components/forms/AmendMultipleOrderForm';
import AmendOrderForm from './components/forms/AmendOrderForm';

import Manager from './components/Manager';
import ManageOrderLists from './components/Manager/ManageOrderLists';
import OrderDetail from './components/OrderDetail';

import OrderAmend from './models/OrderAmend';
import OrderAmendStatus from './models/OrderAmendStatus';

const components = {
  forms: {
    CreateOrder,
    AmendMultipleOrderForm,
    AmendOrderForm,
  },
  Manager,
  ManageOrderLists,
  OrderDetail,
};

const reducers = {
  reducer,
};

const models = {
  OrderAmend,
  OrderAmendStatus,
};

export default { actions, constants, reducers, sagas, selectors, api, components, models };
