import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Modal, Typography } from '@material-ui/core';
import TotalsRow from './TotalsRow';
import ToolbarBottom from '../../../commons/components/toolbarBottom';

import { CancelButton } from '../../../commons/components/buttons';
import ReadOnlyField from '../../../commons/components/formFields/readOnlyField';
import { getOrganisationFullNameFromId } from '../../../commons/utils/functions';

import { getOrganisations, getOrganisationsObject, getActiveUser } from '../selectors';
import { getKeyValuePairsFromOrder, getFieldsArray } from '../../contracts/utils/modelFunctions';

import CustomText from '../../../commons/components/formFields/customText';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //height: '70vh',
    //maxHeight: '70vh',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  sectionTitleContainer: {
    padding: '0px 16px 0px',
  },
  sectionContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 0px 16px 0px',
  },
  assetRowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 8px 0px 0px',
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },

  title: { fontSize: theme.fontSize.lg },
});

class ResponseDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, interest, open, user } = this.props;
    const beneficiary =
      this.props.organisationsObject[interest.fullOrder.beneficiaryOrganisationId];

    const userData = {
      user: interest.user.userName,
      organisation: interest.org.name,
      organisationShortName: interest.org.shortName,
      beneficiary: beneficiary.name,
      beneficiaryShortName: beneficiary.shortName,
    };
    //const orderRows = this.props.orderGroup.assets || [];
    const order = interest.fullOrder || {};
    let orderRows = [];
    if (order.assets) {
      orderRows = getKeyValuePairsFromOrder(order);
    }
    const orderRow = orderRows[0] || [];

    const iWeightClass =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'weightClass') : 0;
    const iPrice =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'price') : 0;
    const iVolume =
      orderRows.length > 0 ? orderRows[0].findIndex((item) => item.key === 'volume') : 0;

    const list = order.selectedOrganisationsIds || [];

    const formFields = getFieldsArray('order', ['responseForm', 'spot', 'fresh']);
    const fieldKeys = formFields ? Object.keys(formFields) : [];
    const formFieldsTop = fieldKeys.filter((f) => formFields[f].formTopSection);
    const formFieldsBottom = fieldKeys.filter((f) => formFields[f].formBottomSection);
    const formFieldsRight = fieldKeys.filter((f) => formFields[f].formRightSection);
    const formFieldsLeft = fieldKeys.filter((f) => formFields[f].formLeftSection);
    return (
      <Modal open={open} onClose={this.closeForm}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>
            {<CustomText value="responseDetails" upperCase />}
          </Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <List open classes={{ root: classes.list }}>
                {Object.keys(userData).map((item, index) => (
                  <ListItem key={index} button classes={{ root: classes.listItem }}>
                    <ListItemText primary={<CustomText value={item} />} className={classes.key} />
                    <ListItemText primary={userData[item]} align="right" />
                  </ListItem>
                ))}
                {order.organisationId === user.organisationId && (
                  <ListItem key="recipients" button classes={{ root: classes.listItem }}>
                    <ListItemText
                      primary={<CustomText value="orderSentTo" upperCase />}
                      className={classes.key}
                    />
                    <ListItemText
                      primary={
                        <>
                          {list.map((v, index) => {
                            let name;
                            if (v.name) {
                              name = v.name;
                            } else {
                              name = getOrganisationFullNameFromId(v, this.props.orgs);
                            }

                            return <div key={name}>{name}</div>;
                          })}
                        </>
                      }
                      align="right"
                    />
                  </ListItem>
                )}

                <ListItem key="textMessage" button classes={{ root: classes.listItem }}>
                  <ListItemText primary={<CustomText value="comments" />} className={classes.key} />
                  <ListItemText primary={order.textMessage} align="right" />
                </ListItem>
              </List>
            </div>

            <div className={classes.section}>
              <TotalsRow rows={order.assets || []} />
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="order" upperCase />}
                  className={classes.key}
                />
                {orderRows.map((row, index) => {
                  return (
                    <div className={classes.assetRowContainer}>
                      <ReadOnlyField
                        accessor={'weightClass'}
                        displayName={'weightClass'}
                        value={row[iWeightClass].value || ''}
                      />
                      <ReadOnlyField
                        accessor={'volume'}
                        displayName={'volume'}
                        value={row[iVolume].value || ''}
                        adornment={'cartons'}
                      />
                      <ReadOnlyField
                        accessor="price"
                        displayName={'price'}
                        value={row[iPrice].value || ''}
                        adornment={order.currency || ''}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="company" upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  order.assets &&
                  formFieldsTop.map((field, index) => {
                    if (formFields[field]) {
                      let value = order.assets[0];

                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                      }

                      return (
                        <React.Fragment key={index}>
                          <ReadOnlyField
                            accessor={formFields[field].accessor}
                            displayName={formFields[field].accessor}
                            value={value}
                            key={index}
                          />
                        </React.Fragment>
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="product" upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  order.assets &&
                  formFieldsLeft.map((field, index) => {
                    if (formFields[field]) {
                      let value = order.assets[0];

                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                      }

                      return (
                        <React.Fragment key={index}>
                          <ReadOnlyField
                            accessor={formFields[field].accessor}
                            displayName={formFields[field].accessor}
                            value={value}
                            key={index}
                          />
                        </React.Fragment>
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="freight" upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  order.assets &&
                  formFieldsRight.map((field, index) => {
                    if (formFields[field]) {
                      let value = order.assets[0];

                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                      }

                      return (
                        <React.Fragment key={index}>
                          <ReadOnlyField
                            accessor={formFields[field].accessor}
                            displayName={formFields[field].accessor}
                            value={value}
                            key={index}
                          />
                        </React.Fragment>
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="offer" upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {formFields &&
                  order.assets &&
                  formFieldsBottom.map((field, index) => {
                    if (formFields[field]) {
                      let value = order.assets[0];

                      const fullAccessor = formFields[field].fullAccessor.split('.');
                      for (let i = 0; i < fullAccessor.length; i++) {
                        value = value[fullAccessor[i]];
                      }

                      return (
                        <React.Fragment key={index}>
                          <ReadOnlyField
                            accessor={formFields[field].accessor}
                            displayName={formFields[field].accessor}
                            value={value}
                            key={index}
                          />
                        </React.Fragment>
                      );
                    } else return null;
                  })}
              </div>
            </div>

            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <ListItemText
                  primary={<CustomText value="details" upperCase />}
                  className={classes.key}
                />
              </div>
              <div className={classes.sectionContentContainer}>
                {['direction', 'createdTime', 'updatedTime', 'status', 'timeInForce', 'id'].map(
                  (name, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ReadOnlyField
                          accessor={name}
                          displayName={name}
                          value={order.assets ? order.assets[0][name] : ''}
                          key={index}
                        />
                      </React.Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>

          <ToolbarBottom>
            <>
              <CancelButton onClick={this.props.onClose} title={'close'} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ResponseDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    orgs: getOrganisations(state), // state.rooms.organisations,
    organisationsObject: getOrganisationsObject(state), // state.rooms.organisations,
    user: getActiveUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // onClose: payload => {
    //   dispatch(groupListToggle(payload));
    // }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseDetail));
