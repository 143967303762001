export const required = (value) => (value || typeof value === 'number' ? undefined : 'Required');

export const passwordValidation = (value) => {
  const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  return regEx.test(value);
};

export const phoneValidation = (value) => {
  const regEx = new RegExp('^[+][0-9]{8,12}?$');
  return regEx.test(value) ? undefined : "Phone should begin with '+' and include country code";
};

export const passwordValidationMessage = (value) => {
  const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  return regEx.test(value)
    ? null
    : 'Password must be at least 8 characters long and contain at least: 1 number, 1 special character, 1 upper case character and 1 lower case character.';
};
