const defaultData = {
  nasdaq_alt: [
    {
      Yr: 2019,
      Week: 1,
      '3-4KG': 1.0,
      '4-5KG': 1.0,
      '5-6KG': 1.0,
    },
  ],
  lfexIndex: [
    {
      Yr: 2019,
      Week: 1,
      Date: '1/1/2019',
      '3-4KG': 1.0,
      '4-5KG': 1.0,
      '5-6KG': 1.0,
      Index: 1.0,
    },
  ],
  lfexIndex_4w: [
    {
      Yr: 2019,
      Week: 1,
      Date: '1/1/2019',
      '3-4KG': 1.0,
      '4-5KG': 1.0,
      '5-6KG': 1.0,
      Index: 1.0,
    },
  ],
  fishpool: [
    {
      Yr: 2019,
      Week: 1,
      Date: '1/1/2019',
      'Fish Pool NOK': 1.0,
      'SSB Oslo': 1.0,
    },
  ],
  eurnok: [
    {
      Yr: 2019,
      Week: 1,
      'EUR/NOK': 1.0,
    },
  ],
  eurnok_4w: [
    {
      Yr: 2019,
      Week: 1,
      'EUR/NOK': 1.0,
    },
  ],
  gbpnok: [
    {
      Yr: 2019,
      Week: 1,
      'EUR/NOK': 1.0,
    },
  ],
  fresh_frozen: [
    {
      Yr: 2019,
      Week: 1,
      fresh: 1.0,
      frozen: 1.0,
    },
  ],
};

export default defaultData;
