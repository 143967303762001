import { createAction } from '../../../commons/actions';

export const initBlotter = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const destroyBlotter = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateBlotter = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateBlotterPresets = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
