import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/organisations`;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
  }

  async getAll(data = {}) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(organisationId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(organisationId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
