import {
  GET_RFQS,
  GET_RFQS_SUCCESS,
  GET_RFQS_ERROR,
  GET_RFQ,
  GET_RFQ_SUCCESS,
  GET_RFQ_ERROR,
  CREATE_RFQ,
  CREATE_RFQ_SUCCESS,
  CREATE_RFQ_ERROR,
  UPDATE_RFQS,
  UPDATE_RFQS_SUCCESS,
  UPDATE_RFQS_ERROR,
  RESET_RFQS,
  UPDATE_RFQS_FROM_SOCKET_SUCCESS,
  UPDATE_RFQS_FROM_SOCKET_ERROR,
  UPDATE_RFQS_FROM_SOCKET,
  INSERT_RFQS_FROM_SOCKET,
  INSERT_RFQS_FROM_SOCKET_SUCCESS,
  INSERT_RFQS_FROM_SOCKET_ERROR,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  UPDATE_RFQ_VIEWS,
  UPDATE_RFQ_VIEWS_SUCCESS,
  UPDATE_RFQ_VIEWS_ERROR,
  UPDATE_RFQ_DISMISSED,
  UPDATE_RFQ_DISMISSED_SUCCESS,
  UPDATE_RFQ_DISMISSED_ERROR,
  UPDATE_RESPONSE_FORM,
  UPDATE_RESPONSE_FORM_SUCCESS,
  UPDATE_RESPONSE_FORM_ERROR,
} from '../constants';

import { isObject, isBoolean, insertItems, updateItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  forms: {
    create: {
      open: false,
      item: {},
      enumerations: [],
      formFields: {},
      orderRows: [],
      status: null,
      error: null,
      receivedAt: null,
    },
    response: {
      open: false,
      rfq: {},
      orders: [],
      orderToCancel: null,
      defaultOrders: [],
      enumerations: [],
      formFields: {},
      status: null,
      error: null,
      receivedAt: null,
    },
    execute: {
      open: false,
      rfq: {},
      orders: [],
      orderToCancel: null,
      enumerations: [],
      formFields: [],
      status: null,
      error: null,
      receivedAt: null,
    },
    amend: {
      open: false,
      rfq: {},
      orders: [],
      formFields: [],
      status: null,
      error: null,
      receivedAt: null,
    },
  },
  views: {
    items: [],
    status: null,
    error: null,
    receivedAt: null,
  },
  dismissed: {
    items: [],
    status: null,
    error: null,
    receivedAt: null,
  },
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case GET_RFQS:
      return {
        ...state,
        status: 'pending',
      };
    case GET_RFQS_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_RFQS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case GET_RFQ:
      return {
        ...state,
        status: 'pending',
      };
    case GET_RFQ_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_RFQ_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case CREATE_RFQ:
      return {
        ...state,
        status: 'pending',
      };
    case CREATE_RFQ_SUCCESS:
      nextState = {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return nextState;
    case CREATE_RFQ_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case UPDATE_RFQS_FROM_SOCKET:
    case UPDATE_RFQS:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_RFQS_FROM_SOCKET_SUCCESS:
    case UPDATE_RFQS_SUCCESS:
      return {
        ...state,
        ...updateItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_RFQS_FROM_SOCKET_ERROR:
    case UPDATE_RFQS_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case INSERT_RFQS_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case INSERT_RFQS_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case INSERT_RFQS_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_RFQS:
      return {
        ...initialState,
      };
    case UPDATE_RFQ_VIEWS:
      nextState = { ...state };

      nextState.views = {
        ...nextState.views,
        status: 'pending',
      };
      return nextState;
    case UPDATE_RFQ_VIEWS_SUCCESS:
      nextState = { ...state };

      nextState.views = {
        ...nextState.views,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (Array.isArray(action.payload.items)) {
        nextState.views.items = [...action.payload.items]; // DUPLICATE COPY. FIRST COPY IN SAGA UPDATE_RFQS. REVIEW AND UPDATE
      }
      return nextState;
    case UPDATE_RFQ_VIEWS_ERROR:
      nextState = { ...state };

      nextState.views = {
        ...nextState.views,
        status: 'error',
        error: action.payload.error,
      };
      return nextState;
    case UPDATE_RFQ_DISMISSED:
      nextState = { ...state };

      nextState.views = {
        ...nextState.views,
        status: 'pending',
      };
      return nextState;
    case UPDATE_RFQ_DISMISSED_SUCCESS:
      nextState = { ...state };

      nextState.dismissed = {
        ...nextState.dismissed,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (Array.isArray(action.payload.items)) {
        nextState.dismissed.items = [...action.payload.items]; // DUPLICATE COPY. FIRST COPY IN SAGA UPDATE_RFQS. REVIEW AND UPDATE
      }
      return nextState;
    case UPDATE_RFQ_DISMISSED_ERROR:
      nextState = { ...state };

      nextState.dismissed = {
        ...nextState.dismissed,
        status: 'error',
        error: action.payload.error,
      };
      return nextState;
    case UPDATE_RESPONSE_FORM:
    case UPDATE_FORM:
      nextState = { ...state };

      nextState.forms[action.payload.type] = {
        ...nextState.forms[action.payload.type],
        status: 'pending',
      };
      return nextState;
    case UPDATE_RESPONSE_FORM_SUCCESS:
    case UPDATE_FORM_SUCCESS:
      nextState = { ...state };
      if (isObject(nextState.forms[action.payload.type])) {
        nextState.forms[action.payload.type] = {
          ...nextState.forms[action.payload.type],
          status: 'success',
          error: null,
          receivedAt: action.meta.receivedAt,
        };

        if (isObject(action.payload.item)) {
          nextState.forms[action.payload.type].item = { ...action.payload.item };
        }
        if (isObject(action.payload.lastRfq)) {
          nextState.forms[action.payload.type].lastRfq = { ...action.payload.lastRfq };
        }
        if (Array.isArray(action.payload.savedRfqPresets)) {
          nextState.forms[action.payload.type].savedRfqPresets = [
            ...action.payload.savedRfqPresets,
          ];
        }
        if (action.payload.orderToCancel) {
          nextState.forms[action.payload.type].orderToCancel = action.payload.orderToCancel;
        }

        if (Array.isArray(action.payload.orders)) {
          nextState.forms[action.payload.type].orders = [...action.payload.orders];
        }
        if (Array.isArray(action.payload.defaultOrders)) {
          nextState.forms[action.payload.type].defaultOrders = [...action.payload.defaultOrders];
        }

        if (isObject(action.payload.rfq)) {
          nextState.forms[action.payload.type].rfq = { ...action.payload.rfq };
        }

        if (isObject(action.payload.enumerations)) {
          nextState.forms[action.payload.type].enumerations = {
            ...action.payload.enumerations,
          };
        }
        if (isObject(action.payload.contractType)) {
          nextState.forms[action.payload.type].contractType = {
            ...action.payload.contractType,
          };
        }
        if (isObject(action.payload.formFields)) {
          nextState.forms[action.payload.type].formFields = {
            ...action.payload.formFields,
          };
        }
        if (Array.isArray(action.payload.formFields)) {
          nextState.forms[action.payload.type].formFields = [...action.payload.formFields];
        }
        if (Array.isArray(action.payload.orderRows)) {
          nextState.forms[action.payload.type].orderRows = [...action.payload.orderRows];
        }

        if (isBoolean(action.payload.open)) {
          nextState.forms[action.payload.type].open = action.payload.open;

          if (action.payload.open === false) {
            if (action.payload.type === 'create') {
              nextState.forms[action.payload.type].item = {};
              nextState.forms[action.payload.type].underlyingOptions = {
                underlyingValues: {},
              };
            } else if (action.payload.type === 'response') {
              nextState.forms[action.payload.type].rfq = {};
              nextState.forms[action.payload.type].orders = [];
            }
          }
        }
      }

      return nextState;
    case UPDATE_RESPONSE_FORM_ERROR:
    case UPDATE_FORM_ERROR:
      nextState = { ...state };

      nextState.forms[action.payload.type] = {
        ...nextState.forms[action.payload.type],
        status: 'error',
        error: action.payload.error,
      };
      return nextState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
