import {
  GET_TRADES,
  GET_TRADES_SUCCESS,
  GET_TRADES_ERROR,
  GET_TRADE,
  GET_TRADE_SUCCESS,
  GET_TRADE_ERROR,
  RESET_TRADES,
  INSERT_TRADES_FROM_SOCKET,
  INSERT_TRADES_FROM_SOCKET_SUCCESS,
  INSERT_TRADES_FROM_SOCKET_ERROR,
  TRADE_DETAILS_OPEN,
  TRADE_DETAILS_OPEN_SUCCESS,
  TRADE_DETAILS_OPEN_ERROR,
  POST_TRADE_FORM_OPEN,
  POST_TRADE_FORM_OPEN_SUCCESS,
  POST_TRADE_FORM_OPEN_ERROR,
  UPDATE_TRADES_FROM_SOCKET,
  UPDATE_TRADES_FROM_SOCKET_SUCCESS,
  UPDATE_TRADES_FROM_SOCKET_ERROR,
  UPDATE_END_RFQ_PROMPT,
  UPDATE_END_RFQ_PROMPT_SUCCESS,
  UPDATE_END_RFQ_PROMPT_ERROR,
  AMEND_TRADE_FORM_OPEN,
  AMEND_TRADE_FORM_OPEN_SUCCESS,
  AMEND_TRADE_FORM_OPEN_ERROR,
  NEW_TRADES_CLEAR,
} from '../constants';

import { insertItems, updateItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  status: null,
  error: null,
  receivedAt: null,
  newTrades: {
    open: false,
    title: 'newTrades',
    tradeIds: [],
    status: 'pending',
    error: null,
  },
  tradeDetails: {
    open: false,
    title: 'TRADE DETAILS',
    tradeIds: [],
    status: 'pending',
    error: null,
  },
  postTradeForm: {
    open: false,
    title: 'POST TRADE INFORMATION',
    formType: 'buyer',
    data: {},
    tradeId: null,
    status: 'pending',
    error: null,
  },
  amendTradeForm: {
    open: false,
    tradeId: null,
    status: 'pending',
    error: null,
  },
  endRfqPrompt: {
    open: false,
    rfqId: null,
    status: 'pending',
    error: null,
  },
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRADES:
      return {
        ...state,
        status: 'pending',
      };
    case GET_TRADES_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_TRADES_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case GET_TRADE:
      return {
        ...state,
        status: 'pending',
      };
    case GET_TRADE_SUCCESS:
      return {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case GET_TRADE_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case NEW_TRADES_CLEAR:
      const meta = {
        receivedAt: new Date(),
      };
      let nextNewTrades = { ...state.newTrades };
      let nextTradeIds = [];
      nextNewTrades.tradeIds = nextTradeIds;
      return {
        ...state,
        newTrades: nextNewTrades,
        status: 'success',
        error: null,
        receivedAt: action.meta ? action.meta.receivedAt : meta,
      };

    case INSERT_TRADES_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case INSERT_TRADES_FROM_SOCKET_SUCCESS:
      nextNewTrades = { ...state.newTrades };
      nextTradeIds = [...state.newTrades.tradeIds];

      action.payload.items.forEach((item) => {
        console.log(item.id);
        nextTradeIds.push(item.id);
      });
      nextNewTrades.open = true;
      nextNewTrades.tradeIds = nextTradeIds;
      console.log('nextNewTrades.tradeIds.length', nextNewTrades.tradeIds.length);

      return {
        ...state,
        ...insertItems(action.payload, state),
        newTrades: nextNewTrades,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case INSERT_TRADES_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case UPDATE_TRADES_FROM_SOCKET:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_TRADES_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        ...updateItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_TRADES_FROM_SOCKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };

    case RESET_TRADES:
      return {
        ...initialState,
      };

    case TRADE_DETAILS_OPEN:
      let next = { ...state.tradeDetails };
      next.status = 'pending';
      return {
        ...state,
        tradeDetails: next,
      };
    case TRADE_DETAILS_OPEN_SUCCESS:
      next = { ...state.tradeDetails };
      next.status = 'success';
      next.open = action.payload.open;
      if (action.payload.tradeIds) {
        next.tradeIds = [...action.payload.tradeIds];
      }
      if (action.payload.open === false) {
        next.tradeIds = [];
        next.title = 'TRADE DETAILS';
      }
      return {
        ...state,
        tradeDetails: next,
      };
    case TRADE_DETAILS_OPEN_ERROR:
      next = { ...state.tradeDetails };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        tradeDetails: next,
      };

    case POST_TRADE_FORM_OPEN:
      next = { ...state.postTradeForm };
      next.status = 'pending';
      return {
        ...state,
        postTradeForm: next,
      };
    case POST_TRADE_FORM_OPEN_SUCCESS:
      console.log('POST_TRADE_FORM_OPEN_SUCCESS', action.payload);
      next = { ...state.postTradeForm, ...action.payload };
      next.status = 'success';

      return {
        ...state,
        postTradeForm: next,
      };
    case POST_TRADE_FORM_OPEN_ERROR:
      next = { ...state.postTradeForm };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        postTradeForm: next,
      };
    case AMEND_TRADE_FORM_OPEN:
      next = { ...state.amendTradeForm };
      next.status = 'pending';
      return {
        ...state,
        amendTradeForm: next,
      };
    case AMEND_TRADE_FORM_OPEN_SUCCESS:
      next = { ...state.postAmendForm, ...action.payload };
      next.status = 'success';

      return {
        ...state,
        amendTradeForm: next,
      };
    case AMEND_TRADE_FORM_OPEN_ERROR:
      next = { ...state.amendTradeForm };
      next.status = 'error';
      next.error = action.payload;
      return {
        ...state,
        amendTradeForm: next,
      };
    case UPDATE_END_RFQ_PROMPT:
      next = { ...state.endRfqPrompt };
      next.status = 'pending';
      return {
        ...state,
        endRfqPrompt: next,
      };

    case UPDATE_END_RFQ_PROMPT_SUCCESS:
      return {
        ...state,
        endRfqPrompt: {
          open: false,
          rfqId: null,
          status: 'success',
          error: null,
        },
      };

    case UPDATE_END_RFQ_PROMPT_ERROR:
      next = { ...state.endRfqPrompt };
      next.status = 'pending';
      next.error = action.payload;
      return {
        ...state,
        endRfqPrompt: next,
      };
    case auth.constants.SIGN_OUT_SUCCESS:
      let newState = { ...initialState };
      newState.tradeDetails = {
        open: false,
        title: 'TRADE DETAILS',
        tradeIds: [],
        status: 'pending',
        error: null,
      };
      newState.postTradeForm = {
        open: false,
        title: 'POST TRADE INFORMATION',
        tradeId: null,
        status: 'pending',
        error: null,
      };
      newState.endRfqPrompt = {
        open: false,
        rfqId: null,
        status: 'pending',
        error: null,
      };
      return newState;
    default: {
      return state;
    }
  }
};

export default reducer;
