import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';

import { formatPrice } from '../../../commons/config/formatters';

const styles = (theme) => ({
  columnBuy: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'transparent',
    '&:hover $price': { fontSize: theme.fontSize.md, color: lightBlue[100] },
    '&:hover $subPrice': { fontSize: theme.fontSize.md, color: teal[100] },
    '&:hover $text': { fontSize: 0 },
    '&:hover $price2': { color: lightBlue[300], fontSize: theme.fontSize.md },
    '&:hover $rfq': { fontSize: theme.fontSize.lg },
    '&:hover ': {
      borderRadius: '5px',
      backgroundColor: lightBlue[300],
      border: '1px solid',
      borderColor: lightBlue[800],
      boxShadow: '1px 1px 1px rgba(0, 0, 0, .2)',
      cursor: 'pointer',
    },
  },
  columnSell: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'transparent',
    '&:hover $price': { fontSize: theme.fontSize.md, color: teal[100] },
    '&:hover $subPrice': { fontSize: theme.fontSize.md, color: teal[100] },
    '&:hover $text': { fontSize: 0 },
    '&:hover $price2': { color: teal[300], fontSize: theme.fontSize.md },
    '&:hover $rfq': { fontSize: theme.fontSize.lg },
    '&:hover ': {
      borderRadius: '5px',
      backgroundColor: teal[300],
      border: '1px solid',
      borderColor: teal[800],
      boxShadow: '1px 1px 1px rgba(0, 0, 0, .2)',
      cursor: 'pointer',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'transparent',
  },

  price: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.lg,
  },
  price2: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.lg,
  },
  text: {
    color: theme.palette.text.subtitle,
    fontSize: theme.fontSize.md,
  },
  subPrice: {
    color: theme.palette.text.subtitle,
    fontSize: theme.fontSize.md,
  },
  rfq: {
    fontSize: 0,
  },
});

const PriceCell = (props) => {
  const { classes, refPrice, price, currency, sell, buy, showDisplayCurrency } = props;

  return showDisplayCurrency ? (
    <div
      className={sell ? classes.columnBuy : buy ? classes.columnSell : classes.column}
      onClick={props.onClick}
    >
      <div className={classes.price2}>{formatPrice(refPrice)}</div>
      <div className={classes.subPrice}>{formatPrice(price)}</div>
      <div className={classes.text}>{currency}</div>
      <div className={classes.rfq}>RFQ</div>
    </div>
  ) : (
    <div
      className={sell ? classes.columnBuy : buy ? classes.columnSell : classes.column}
      onClick={props.onClick}
    >
      <div className={classes.price}>{formatPrice(price)}</div>
      <div className={classes.text}>{currency}</div>
      <div className={classes.rfq}>RFQ</div>
    </div>
  );
};
PriceCell.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PriceCell);
