export const NAME = 'users';

export const GET_USERS = 'users/get';
export const GET_USERS_SUCCESS = 'users/get/success';
export const GET_USERS_ERROR = 'users/get/error';

export const GET_USER = 'user/get';
export const GET_USER_SUCCESS = 'user/get/success';
export const GET_USER_ERROR = 'user/get/error';

export const GET_PROFILE = 'profile/get';
export const GET_PROFILE_SUCCESS = 'profile/get/success';
export const GET_PROFILE_ERROR = 'profile/get/error';

export const UPDATE_USER = 'user/update';
export const UPDATE_USER_SUCCESS = 'user/update/success';
export const UPDATE_USER_ERROR = 'user/update/error';

export const RESET_USERS = 'users/reset';
export const RESET_USERS_SUCCESS = 'users/reset/success';
export const RESET_USERS_ERROR = 'users/reset/error';

export const INSERT_USERS_FROM_SOCKET = 'users/insert/from/socket';
export const INSERT_USERS_FROM_SOCKET_SUCCESS = 'users/insert/from/socket/success';
export const INSERT_USERS_FROM_SOCKET_ERROR = 'users/insert/from/socket/error';

export const UPDATE_USERS_FROM_SOCKET = 'users/update/from/socket';
export const UPDATE_USERS_FROM_SOCKET_SUCCESS = 'users/update/from/socket/success';
export const UPDATE_USERS_FROM_SOCKET_ERROR = 'users/update/from/socket/error';
