import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';
import Statistics from './components/Statistics';

const reducers = {
  reducer,
};

const components = {
  Statistics,
};

export default { actionTypes, api, constants, reducers, sagas, selectors, components };
