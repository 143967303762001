import { takeEvery, put, select, fork } from 'redux-saga/effects';

import {
  RESET_CALCULATORS,
  INIT_CALCULATORS_SETTINGS,
  INIT_CALCULATORS_SETTINGS_SUCCESS,
  UPDATE_CALCULATOR,
  UPDATE_CALCULATOR_SUCCESS,
  INIT_CALCULATORS,
  INIT_CALCULATORS_SUCCESS,
  UPDATE_CALCULATORS,
  UPDATE_CALCULATORS_SUCCESS,
  INIT_CALCULATOR_SUCCESS,
  DESTROY_CALCULATOR_SUCCESS,
  DESTROY_CALCULATOR,
  INIT_CALCULATOR,
  CALCULATOR_MANAGER,
  CALCULATOR_MANAGER_SUCCESS,
  CHANGE_STATE_CALCULATORS,
} from '../constants';

import {
  initCalculatorsSettings as actionInitCalculatorsSettings,
  initCalculators as actionInitCalculators,
  initCalculator as actionInitCalculator,
  destroyCalculator as actionDestroyCalculator,
  updateCalculators as actionUpdateCalculators,
  updateCalculator as actionUpdateCalculator,
  calculatorManager as actionCalculatorManager,
  changeStateCalculators,
} from '../actions';

import { getFromLS, saveToLS, removeFromLS } from '../../../commons/localStorage';

import { newCalculator, calcFinalPrice } from '../config/calculators';
import {
  getLocalCalculator,
  getCalculatorsFromSettings,
  getAllLocalCalculators,
} from '../selectors';

import { isObject } from '../../../commons/utils/functions';

import workspaces from '../../workspaces';

import auth from '../../auth';

function* initCalculators() {
  const calculator = newCalculator();
  calculator.active = true;
  const items = getFromLS('calculators') || [calculator];

  yield put(actionInitCalculators(INIT_CALCULATORS_SUCCESS, { items }, { receivedAt: new Date() }));

  yield put(
    changeStateCalculators(
      CHANGE_STATE_CALCULATORS,
      { source: 'calculators' },
      { receivedAt: new Date() },
    ),
  );
}

function* initCalculator(action) {
  const {
    payload: {
      id,
      widget,
      localPrice = 1,
      finalPrice = 1,
      defaultCalculatorId,
      usePrice = false,
      quantityUnit,
    },
  } = action;

  let { quantity } = action.payload;

  if (quantity) {
    const unit = quantityUnit || 'KG';
    const factor = unit === 'KG' ? 1 : 20;
    quantity *= factor;
  } else {
    quantity = 19000;
  }

  const payload = {
    id,
    localPrice,
    finalPrice,
    quantity,
    usePrice,
  };

  const meta = { receivedAt: new Date() };

  const calculators = yield select(getCalculatorsFromSettings);

  let calcIndex = -1;

  if (isObject(widget) && isObject(widget.savedState)) {
    if (widget.savedState.localPrice) {
      payload.localPrice = widget.savedState.localPrice;
    }
    if (widget.savedState.finalPrice) {
      payload.finalPrice = widget.savedState.finalPrice;
    }

    if (widget.savedState.quantity) {
      payload.quantity = widget.savedState.quantity;
    }

    const { calculatorId } = widget.savedState;

    if (Array.isArray(calculators) && widget.savedState.calculatorId) {
      calcIndex = calculators.findIndex((w) => w.id === calculatorId);
    }

    payload.widget = widget;
  }

  if (Array.isArray(calculators)) {
    if (calcIndex === -1) {
      if (defaultCalculatorId) {
        calcIndex = calculators.findIndex((c) => c.id === defaultCalculatorId);

        if (defaultCalculatorId && calcIndex !== -1) {
          payload.defaultCalculatorId = defaultCalculatorId;
        }
      } else {
        calcIndex = calculators.findIndex((c) => c.active === true);
      }

      if (calcIndex === -1) {
        calcIndex = 0;
      }
    }

    let calculator = {};

    if (calculators[calcIndex]) {
      calculator = JSON.parse(JSON.stringify(calculators[calcIndex]));
    }

    payload.calculator = calculator;
  }

  yield put(actionInitCalculator(INIT_CALCULATOR_SUCCESS, payload, meta));
}

function* destroyCalculator(action) {
  const payload = {
    id: action.payload.id,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionDestroyCalculator(DESTROY_CALCULATOR_SUCCESS, payload, meta));
}

function* initCalculatorsSettings() {
  const calculator = newCalculator();
  calculator.active = true;
  const items = getFromLS('calculators') || [calculator];

  const payload = {
    items,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionInitCalculatorsSettings(INIT_CALCULATORS_SETTINGS_SUCCESS, payload, meta));

  yield fork(processCalculators);

  yield put(
    changeStateCalculators(
      CHANGE_STATE_CALCULATORS,
      { source: 'calculators' },
      { receivedAt: new Date() },
    ),
  );
}

function* updateCalculators(action) {
  try {
    const payload = {};
    const meta = { receivedAt: new Date() };

    const {
      payload: { id, name, value, errors, fxrates, widget, action: actionCalculators },
    } = action;

    let { items } = action.payload;
    if (!Array.isArray(items) && id) {
      let { calculator, localPrice, finalPrice, quantity, usePrice } = yield select(
        getLocalCalculator,
        id,
      );
      calculator = JSON.parse(JSON.stringify(calculator));

      calculator.calculatorState[name] = value;
      calculator.calculatorState.errors = errors;

      if (name === 'localCurrency' || name === 'finalCurrency') {
        const curr = updateCurrencyRate(calculator, name, value, fxrates);
        calculator.calculatorState.currencyRate = curr;
        calculator.calculatorState.feeCurrencyRate = 1;
      } else if (name === 'currencyRate') {
        calculator.calculatorState.feeCurrencyRate = 1;
      } else if (name === 'fxRateInverted') {
        let curr = calculator.calculatorState.currencyRate;
        curr = 1 / parseFloat(curr);
        calculator.calculatorState.currencyRate = numberFormat(curr);
        calculator.calculatorState.feeCurrencyRate = 1;
      }

      const sCalculator = {
        localPrice,
        finalPrice,
        quantity,
        calculator: { calculatorState: calculator.calculatorState },
      };

      const sPrice = calcPrice('localPrice', sCalculator);

      const stateCalculator = {
        usePrice,
        localPrice,
        finalPrice,
        quantity,
        calculator,
        ...sPrice,
      };

      items = [calculator];

      payload.id = id;
      payload.stateCalculator = stateCalculator;

      // TODO: REFACTOR: NEED REMOVE/ USE TEMPORARY FOR DEMO. THIS ISSUE WORK FOR CALCULATOR INTO RFQ RESPONSE FORM ROW
      if (usePrice && action.payload.callbacks && action.payload.callbacks.updatePrice) {
        action.payload.callbacks.updatePrice(finalPrice);
      }
    }
    if (Array.isArray(items)) {
      payload.settings = {};

      if (actionCalculators === 'onlySave') {
        payload.settings.items = items;
      } else {
        const calculators = JSON.parse(JSON.stringify(yield select(getCalculatorsFromSettings))); // TODO: Temporary. USE diff array and merge result!

        items.forEach((item) => {
          const index = calculators.findIndex((i) => i.id === item.id);

          if (index !== -1) {
            calculators[index] = item;
          }
        });

        payload.settings.items = calculators;
      }

      saveToLS('calculators', payload.settings.items);
    }
    if (isObject(payload.stateCalculator) && isObject(widget)) {
      widget.savedState = {
        calculatorId: payload.stateCalculator.calculator.id,
        localPrice: payload.stateCalculator.localPrice,
        finalPrice: payload.stateCalculator.finalPrice,
        quantity: payload.stateCalculator.quantity,
      };

      yield put(
        workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: widget }),
      );
    }

    yield put(actionUpdateCalculators(UPDATE_CALCULATORS_SUCCESS, payload, meta));
  } catch (e) {
    console.log('Calculators:SAGA:updateCalculators:e:', e);
  }
}

function* updateCalculator(action) {
  const {
    payload: { id, widget, value, calculatorId },
  } = action;

  let {
    payload: { name },
  } = action;

  const stateCalculator = yield select(getLocalCalculator, id);
  const { localPrice, finalPrice, quantity, calculator } = stateCalculator;

  const sCalculator = {
    localPrice,
    finalPrice,
    quantity,
    calculator,
  };

  if (calculatorId) {
    const calculators = yield select(getCalculatorsFromSettings);
    const calculator = calculators.find((c) => c.id === calculatorId);
    sCalculator.calculator = JSON.parse(JSON.stringify(calculator));
    name = 'localPrice';
  }

  if (typeof value !== 'undefined') {
    sCalculator[name] = value;
  }

  const statePrice = calcPrice(name, sCalculator);

  const nextState = {
    ...stateCalculator,
    ...sCalculator,
    ...statePrice,
  };

  if (typeof value !== 'undefined') {
    nextState[name] = value;
  }

  const payload = {
    id,
    ...nextState,
  };
  const meta = { receivedAt: new Date() };

  if (isObject(widget)) {
    widget.savedState = {
      calculatorId: nextState.calculator.id,
      localPrice: nextState.localPrice,
      finalPrice: nextState.finalPrice,
      quantity: nextState.quantity,
    };

    yield put(
      workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: widget }),
    );
  }

  // TODO: REFACTOR: NEED REMOVE/ USE TEMPORARY FOR DEMO. THIS ISSUE WORK FOR CALCULATOR INTO RFQ RESPONSE FORM ROW
  if (nextState.usePrice && action.payload.callbacks && action.payload.callbacks.updatePrice) {
    action.payload.callbacks.updatePrice(nextState.finalPrice);
  }

  yield put(actionUpdateCalculator(UPDATE_CALCULATOR_SUCCESS, payload, meta));
}

function* processCalculatorManager(action) {
  const {
    payload: { action: actioManager },
  } = action;

  const payload = {};

  if (actioManager === 'openManager') {
    payload.open = true;
  } else if (actioManager === 'closeManager') {
    payload.open = false;
  }

  const meta = { receivedAt: new Date() };

  yield put(actionCalculatorManager(CALCULATOR_MANAGER_SUCCESS, payload, meta));
}

export function* watchInitCalculators() {
  yield takeEvery(INIT_CALCULATORS, initCalculators);
}

export function* watchInitCalculatorsSettings() {
  yield takeEvery(INIT_CALCULATORS_SETTINGS, initCalculatorsSettings);
}

export function* watchInitCalculator() {
  yield takeEvery(INIT_CALCULATOR, initCalculator);
}

export function* watchDestroyCalculator() {
  yield takeEvery(DESTROY_CALCULATOR, destroyCalculator);
}

export function* watchUpdateCalculators() {
  yield takeEvery(UPDATE_CALCULATORS, updateCalculators);
}

export function* watchUpdateCalculator() {
  yield takeEvery(UPDATE_CALCULATOR, updateCalculator);
}

export function* watchCalculatorManager() {
  yield takeEvery(CALCULATOR_MANAGER, processCalculatorManager);
}

export function* watchResetCalculators() {
  yield takeEvery(RESET_CALCULATORS);
}

function calcPrice(
  changedField,
  { localPrice, finalPrice, quantity, calculator: { calculatorState } },
) {
  const state = {};

  let price;

  if (changedField === 'localPrice' || changedField === 'quantity') {
    price = calcFinalPrice(calculatorState, parseFloat(localPrice), quantity);
    state.finalPrice = price;
  } else {
    price = calcFinalPrice(calculatorState, parseFloat(finalPrice), quantity, true);
    state.localPrice = price;
  }

  return state;
}

// RUPLICATE: CALCULATORS & ORDER MANAGER/ EXATRACT IN SINGLW FUNCTION
function updateCurrencyRate(calculator, name, value, fxrates = {}) {
  const localCurrency = name === 'localCurrency' ? value : calculator.calculatorState.localCurrency;
  const finalCurrency = name === 'finalCurrency' ? value : calculator.calculatorState.finalCurrency;

  let rates = fxrates[localCurrency] ? fxrates[localCurrency].rates : [];
  let fx = rates.find((r) => r.quoteCurrency === finalCurrency);

  let rate = fx ? fx.rate : null;

  let currencyRate = null;
  if (!rate) {
    rates = fxrates[finalCurrency] ? fxrates[finalCurrency].rates : [];
    fx = rates.find((r) => r.quoteCurrency === localCurrency);
    rate = fx ? fx.rate : null;

    currencyRate = rate ? 1 / rate || 1 : 1;
  } else {
    currencyRate = rate ? rate || 1 : 1;
  }

  if (calculator.calculatorState.fxRateInverted) {
    currencyRate = 1 / currencyRate;
  }

  return numberFormat(currencyRate);
}

function numberFormat(number) {
  return Math.round(number * 10000) / 10000;
}

function* processCalculators() {
  try {
    const calculators = yield select(getCalculatorsFromSettings);

    if (Array.isArray(calculators)) {
      const locaCalculators = yield select(getAllLocalCalculators);

      for (let i = 0; i < locaCalculators.length; i += 1) {
        const localCalculator = locaCalculators[i];

        const { widget, defaultCalculatorId } = localCalculator;

        let calcIndex = -1;

        if (isObject(widget) && isObject(widget.savedState)) {
          const { calculatorId } = widget.savedState;

          if (widget.savedState.calculatorId) {
            calcIndex = calculators.findIndex((w) => w.id === calculatorId);
          }
        }

        if (calcIndex === -1) {
          if (defaultCalculatorId) {
            calcIndex = calculators.findIndex((c) => c.id === defaultCalculatorId);
          } else {
            calcIndex = calculators.findIndex((c) => c.active === true);
          }

          if (calcIndex === -1) {
            calcIndex = 0;
          }
        }

        let calculator = {};

        if (calculators[calcIndex]) {
          calculator = JSON.parse(JSON.stringify(calculators[calcIndex]));
          const payload = { id: localCalculator.id, calculator };
          const meta = { receivedAt: new Date() };
          yield put(actionUpdateCalculator(UPDATE_CALCULATOR_SUCCESS, payload, meta));
        }
      }
    }
  } catch (error) {
    yield put(actionUpdateCalculator(UPDATE_CALCULATOR, { error }, { receivedAt: new Date() }));
  }
}

function signOutSuccess() {
  removeFromLS('calculators');
}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
