class Config {
  constructor() {
    this.version = 'v1';
    this.chatUrl = 'chatupdates';

    this.base_path = `${process.env.REACT_APP_API_URL}/api/${this.version}`;

    this.socket = `${process.env.REACT_APP_API_URL}`;
    this.chatSocket = `${process.env.REACT_APP_API_URL}/${this.chatUrl}`;
  }
}

const config = new Config();
Object.freeze(config);

export default config;
