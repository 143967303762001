import { createAction } from '../../../commons/actions';

export const initFileUpload = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const destroyFileUpload = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateFileUpload = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const createFile = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const getFile = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const getFiles = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateFileMeta = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
