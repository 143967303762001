import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomText from '../formFields/customText';

const styles = (theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(),
  },
  row: {
    paddingTop: theme.spacing(0.5),
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  caption: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    //fontSize: theme.fontSize.md,
    color: theme.palette.common.white,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.text.secondary,
  },
  key: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    //fontSize: theme.fontSize.sm,
    color: theme.palette.faded_75_white,
  },
  value: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    //fontSize: theme.fontSize.sm,
    color: theme.palette.common.white,
  },
});

const UserInfo = (props) => {
  const { classes } = props;

  return (
    <div className={classes.grid}>
      <div className={classes.caption}>
        <CustomText value="clickForMoreDetail" upperCase />
      </div>
      <div className={classes.row}>
        <div className={classes.key}>
          <CustomText value="user" upperCase />
        </div>
        <div className={classes.value}>{props.userName}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.key}>
          <CustomText value="organisation" upperCase />:
        </div>
        <div className={classes.value}>{props.organisationName}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.key}>
          <CustomText value="organisationShortName" upperCase />:
        </div>
        <div className={classes.value}>{props.organisationShortName}</div>
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserInfo);
