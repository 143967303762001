import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';

import LoginForm from './components/LoginForm';
import RequireNewPassword from './components/RequireNewPassword';
import withAuth from './components/withAuth';

Amplify.configure(awsconfig);

const components = {
  LoginForm,
  RequireNewPassword,
  withAuth,
};

const reducers = {
  reducer,
};

export default { actions, constants, reducers, sagas, selectors, components, api };
