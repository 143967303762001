import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar, IconButton, Icon } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
// import red from "@material-ui/core/colors/red";
// import amber from "@material-ui/core/colors/amber";

const styles = (theme) => ({
  snackbar: {
    position: 'absolute',
  },
  snackbarContent: {
    height: 100,
    width: 500,
    backgroundColor: teal[300],
    color: '#fff',
    fontSize: theme.fontSize.md,
  },
  close: {
    padding: theme.spacing(0.5),
  },
});

const SnackbarMessage = (props) => {
  const { classes } = props;
  const snackbarColor = props.color ? props.color : teal[300];
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.open}
      autoHideDuration={4000}
      onClose={props.close}
      ContentProps={{
        'aria-describedby': 'snackbar-fab-message-id',
        className: classes.snackbarContent,
        style: { backgroundColor: snackbarColor },
      }}
      message={<span id="snackbar-fab-message-id">{props.message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={props.close}
        >
          <Icon>close</Icon>
        </IconButton>
      }
      className={classes.snackbar}
    />
  );
};

SnackbarMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SnackbarMessage);
