import React from 'react';
import PropTypes from 'prop-types';

import { Menu, Divider, MenuItem, ListItemIcon, Icon, ListItemText } from '@material-ui/core';

const CalculatorsMenu = ({ anchorEl, open, onClose, calculators, onClick, showEditor }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      {calculators.map((w) => (
        <MenuItem key={w.name} onClick={() => onClick(w.id)}>
          {w.name}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        onClick={() => {
          showEditor(true);
          onClose();
        }}
      >
        <ListItemIcon>
          <Icon>edit</Icon>
        </ListItemIcon>
        <ListItemText primary="Calculators Editor" />
      </MenuItem>
    </Menu>
  );
};

CalculatorsMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  calculators: PropTypes.array,
  onClick: PropTypes.func,
  showEditor: PropTypes.func,
};

export default CalculatorsMenu;
