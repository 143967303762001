import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import UserTab from './UserTab';

const styles = () => ({
  contactList: {
    overflowY: 'scroll',
  },
});

const ChatsList = ({ classes, rooms = [], selectedChatId, onRoomClick }) => {

  return (
    <div className={classes.contactList}>
      {rooms.map((room) => {
        return (
          <UserTab
            roomInfo={room}
            selected={room.id === selectedChatId}
            onRoomClick={onRoomClick}
            key={room.id}
          />
        );
      })}
    </div>
  );
};

ChatsList.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedChatId: PropTypes.string,
  rooms: PropTypes.array.isRequired,
  onRoomClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChatsList);
