import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip, Button, Icon } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

const styles = (theme) => ({
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

const TrafficLights = (props) => {
  const { classes } = props;

  return (
    <div className={classes.buttonDiv}>
      <Tooltip title="Cancel All" aria-label="Cancel All">
        <FiberManualRecordIcon style={{ color: red[500] }} onClick={props.cancelAll} />
      </Tooltip>
      <Tooltip title="Suspend All" aria-label="Suspend All">
        <FiberManualRecordIcon style={{ color: amber[500] }} onClick={props.suspendAll} />
      </Tooltip>
      <Tooltip title="Activate All" aria-label="Activate All">
        <FiberManualRecordIcon style={{ color: teal['300'] }} onClick={props.activateAll} />
      </Tooltip>
      {!props.hideOrderButton && (
        <div style={{ paddingLeft: '10px' }}>
          <Button variant="outlined" fullWidth onClick={props.orderClick}>
            <Icon>{props.edit ? 'edit' : 'add'}</Icon>
            {props.edit ? 'Amend Order' : 'New Order'}
          </Button>
        </div>
      )}
    </div>
  );
};

TrafficLights.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrafficLights);
