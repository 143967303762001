import * as actions from './actions';
import constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import Dropzone from './components/Dropzone';
import FileViewer from './components/FileViewer';
import FileManager from './components/FileManager';
import api from './api';

const reducers = {
  reducer,
};

const components = {
  Dropzone,
  FileViewer,
  FileManager,
};

export default { actions, constants, reducers, sagas, selectors, components, api };
