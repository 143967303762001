import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
});

// TODO:Refactor
class CheckCommentsPrompt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes,  open } = this.props;
    
    return (
      <PopUp
        open={open}
        title="MISSING COMMENTS?"
        onClose={this.props.onClose}
      >
        <div className={classes.container}>
          <div className={classes.listContainer}>
            <div style={{ padding: '16px' }}>
              <Typography align="center" variant="h5">
                Your request is missing comments?
              </Typography>
              <Typography align="center" variant="h5">
                Click 'Continue' to submit without comments.
              </Typography>
            </div>
          </div>

          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton title="Cancel" onClick={this.props.onClose} />
                <ConfirmButton title="Continue" onClick={this.props.continue} />
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
CheckCommentsPrompt.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
  
  };
}

function mapDispatchToProps(dispatch) {
  return {
   
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckCommentsPrompt));
