import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = ``;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
  }

  async getAll(data = {}) {
    const type = data.type || 'organisations';
    const url = `${this.baseUrl}/${type}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(itemId = null, data = {}) {
    const type = data.type || 'organisations';
    const url = `${this.baseUrl}/${type}/${encodeURIComponent(itemId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async create(data) {
    const type = data.type || 'organisations';
    const url = `${this.baseUrl}/${type}`;

    const params = data.params || {};
    console.log('admin:api:create:databody', JSON.stringify(data.body));
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async update(data = {}) {
    const type = data.type || 'organisations';
    const url = `${this.baseUrl}/${type}/${encodeURIComponent(data.id)}`;

    const params = data.params || {};

    const { orderId, ...body } = data.body;
    console.log('admin:api:update:databody', JSON.stringify(data.body));
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async getBrokers(data = {}) {
    const url = `/beneficiariestobrokers`;

    const params = data.params || {};

    console.log('admin:api:update:databody', JSON.stringify(data.body));
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async createBrokers(data = {}) {
    const url = `/beneficiariestobrokers`;

    const params = data.params || {};

    const { body } = data;
    console.log('admin:api:update:databody', JSON.stringify(data.body));
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async updateBrokers(data = {}) {
    const url = `/beneficiariestobrokers`;

    const params = data.params || {};

    const { body } = data;
    console.log('admin:api:update:databody', JSON.stringify(data.body));
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
