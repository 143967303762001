export const NAME = 'user-settings';

export const TRADING_PARTNERS_LIST_OPEN = 'tradingPartnersList/open';
export const TRADING_PARTNERS_LIST_OPEN_SUCCESS = 'tradingPartnersList/open/success';
export const TRADING_PARTNERS_LIST_OPEN_ERROR = 'tradingPartnersList/open/error';

export const NOTIFICATION_SETTINGS_OPEN = 'notificationSettings/open';
export const NOTIFICATION_SETTINGS_OPEN_SUCCESS = 'notificationSettings/open/success';
export const NOTIFICATION_SETTINGS_OPEN_ERROR = 'notificationsSettings/open/error';

export const GENERAL_SETTINGS_OPEN = 'generalSettings/open';
export const GENERAL_SETTINGS_OPEN_SUCCESS = 'generalSettings/open/success';
export const GENERAL_SETTINGS_OPEN_ERROR = 'generalSettings/open/error';

export const GET_NOTIFICATION_TYPES = 'notificationTypes/get';
export const GET_NOTIFICATION_TYPES_SUCCESS = 'notificationTypes/get/success';
export const GET_NOTIFICATION_TYPES_ERROR = 'notificationsTypes/get/error';

export const GENERAL_SETTINGS_UPDATE = 'generalSettings/update';
export const GENERAL_SETTINGS_UPDATE_SUCCESS = 'generalSettings/update/success';
export const GENERAL_SETTINGS_UPDATE_ERROR = 'generalSettings/update/error';

export const BLOTTER_PRESETS_OPEN = 'blotterPresets/open';
export const BLOTTER_PRESETS_OPEN_SUCCESS = 'blotterPresets/open/success';
export const BLOTTER_PRESETS_OPEN_ERROR = 'blotterPresets/open/error';

export const BLOTTER_PRESETS_UPDATE = 'blotterPresets/update';
export const BLOTTER_PRESETS_UPDATE_SUCCESS = 'blotterPresets/update/success';
export const BLOTTER_PRESETS_UPDATE_ERROR = 'blotterPresets/update/error';
