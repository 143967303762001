import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/users`;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.update = this.update.bind(this);
  }

  async getAll(data = {}) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(userId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(userId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async getMy(userId = null, data = {}) {
    const url = `${this.baseUrl}/me`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async getProfile(data = {}) {
    const url = `${this.baseUrl}/profile`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async update(payload) {
    const { userId, token, body } = payload;
    let url = '';
    if (userId) {
      url = `${this.baseUrl}/${encodeURIComponent(userId)}`;
    } else {
      url = `${this.baseUrl}/me`;
    }

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'PATCH',
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
