import { createAction } from '../../../commons/actions';

export const getAdmins = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const getAdmin = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const insertAdmins = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateAdmins = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const resetAdmins = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const initAdmin = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const destroyAdmin = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateAdmin = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const createAdminItem = (type, payload, meta) => {
  return createAction(type, payload, meta);
};

export const updateAdminItem = (type, payload, meta) => {
  return createAction(type, payload, meta);
};
