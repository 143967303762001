import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Modal, Typography, Divider } from '@material-ui/core';
import CustomText from '../../../commons/components/formFields/customText';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import TradeListRow from './TradeListRow';
import { ClearButton, CancelButton } from '../../../commons/components/buttons';
import { createAction } from '../../../commons/actions';
import { TRADE_DETAILS_OPEN, NEW_TRADES_CLEAR } from '../constants';
import { UPDATE_RFQS, RFQ_STATUS_ENDED } from '../../rfqs/constants';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '50vw',
    maxWidth: '1000px',
    minWidth: '750px',
    maxHeight: '50vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',

    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    //backgroundColor: theme.palette.primary.row,
  },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  itemTitle: {
    backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.subtitle,
  },
  key: {
    color: theme.palette.text.subtitle,
  },
  sectionFlexRow: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    alignItems: 'center',
  },
  subsectionNoFlex: {
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
    //overFlowY: "scroll"
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: "scroll"
  },

  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.fontSize.sm,
  },
  title: { fontSize: theme.fontSize.lg },
  postTradeData: {
    flex: 1,
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.tableBorder}`,
    },
  },
  executed: {
    backgroundColor: theme.palette.teal,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },
  cancelled: {
    backgroundColor: theme.palette.red,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },
  amended: {
    backgroundColor: theme.palette.amber,
    borderRadius: '2px',
    color: '#fff',
    maxWidth: '160px',
    textAlign: 'center',
  },

  cancelRequest: {
    color: theme.palette.amber_700,
    fontWeight: '700',
  },
  none: {},
  toolbarRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sectionTitleContainer: {
    padding: '0px 16px 0px',
  },
  sectionContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 0px 16px 0px',
  },
  assetRowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    padding: ' 8px 0px 0px',
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const TradeNotification = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const trades = useSelector((state) => state.trades.byId);
  const rfqs = useSelector((state) => state.rfqs.byId);
  const user = useSelector((state) => state.auth.server.user);
  const allTradeIds = useSelector((state) => state.trades.allIds);
  const numberOfTrades = 5;
  const demoTrades = (function (num) {
    const t = [];
    if (allTradeIds.length > num) {
      for (let i = 0; i < num - 1; i++) {
        t.push(trades[allTradeIds[i]]);
      }
    }
    return t;
  })(numberOfTrades);

  const newTradeIds = useSelector((state) => state.trades.newTrades.tradeIds);
  //const isSuperAdmin = useSelector((state) => state.trades.byId);

  const onTradeClick = (id) => {
    const payload = { open: true, tradeIds: [id] };
    dispatch(createAction(TRADE_DETAILS_OPEN, payload));
  };
  const onClose = () => {
    newTradeIds.forEach((id) => {
      const trade = trades[id] || {};
      const rfq = rfqs[trade.rfqId] || {};
      endRfq(rfq);
    });
    const payload = {};
    dispatch(createAction(NEW_TRADES_CLEAR, payload));
  };
  const endRfq = (rfq) => {
    if (user.id === rfq.createdByUserId) {
      const payload = {
        userId: user.id,
        status: RFQ_STATUS_ENDED,
        rfqs: [rfq],
      };
      dispatch(createAction(UPDATE_RFQS, payload));
    }
  };
  return (
    <Modal open={true} onClose={() => {}}>
      <div style={getModalStyle()} className={classes.paper}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography className={classes.title}>
            <CustomText value="newTrade" upperCase />
          </Typography>
          <div style={{ paddingRight: '4px' }}>
            <Typography className={classes.title}>{`( ${newTradeIds.length} )`}</Typography>
          </div>
        </div>

        <div className={classes.container}>
          {newTradeIds.map((id, index) => {
            const trade = trades[id] || {};
            const rfq = rfqs[trade.rfqId] || {};
            return (
              <div style={{ paddingTop: '8px', paddingBottom: '4px' }}>
                <TradeListRow
                  key={id}
                  index={id}
                  onTradePress={() => onTradeClick(trade.id)}
                  trade={trade}
                ></TradeListRow>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    padding: '0px 16px 16px',
                  }}
                >
                  {/*   {user.id === rfq.createdByUserId && (
                    <CancelButton
                      onClick={() => endRfq(trade.rfqId)}
                      title="endRfq"
                      icon="cancel"
                    />
                  )} */}

                  <ClearButton
                    onClick={() => onTradeClick(trade.id)}
                    title="details"
                    icon="visibility"
                  />
                </div>
                <Divider />
              </div>
            );
          })}
          {/*  {demoTrades.map((trade, index) => {
            const id = trade.id || index;
            const rfq = rfqs[trade.rfqId] || {};

            return (
              <React.Fragment>
                <div style={{ paddingTop: '8px' }}>
                  <TradeListRow
                    key={id}
                    index={id}
                    onTradePress={() => onTradeClick(trade.id)}
                    trade={trade}
                    //colors={props.colors}
                  ></TradeListRow>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    padding: '0px 16px 8px',
                  }}
                >
                  {user.id === rfq.createdByUserId}
                  <CancelButton onClick={() => endRfq(trade.rfqId)} title="endRfq" icon="cancel" />
                  <ClearButton
                    onClick={() => onTradeClick(trade.id)}
                    title="details"
                    icon="visibility"
                  />
                </div>
                {index < demoTrades.length - 1 && <Divider />}
              </React.Fragment>
            );
          })} */}
        </div>

        <ToolbarBottom>
          <React.Fragment>
            <CancelButton onClick={() => onClose()} title={'close'} />
            <div className={classes.toolbarRight}></div>
          </React.Fragment>
        </ToolbarBottom>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(TradeNotification);
