import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@material-ui/core';

const CustomTooltip = ({ children, title }) => {
  const [parentRef, setParentRef] = useState(null);
  const message = () => {
    if (parentRef && parentRef.scrollWidth > parentRef.offsetWidth) {
      return title;
    }
    return '';
  };
  return (
    <Tooltip
      ref={(el) => {
        setParentRef(el);
      }}
      title={message()}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

export default CustomTooltip;
