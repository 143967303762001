import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TextField, FormControl } from '@material-ui/core';
import { textField } from './formFieldStyles';

const styles = (theme) => ({
  textField: textField(theme),
  darkBackground: {
    background: theme.palette.background.darkRow,
  },
  warningBackground: {
    background: theme.palette.amber,
  },
});

const DateTimeField = (props) => {
  const { classes, accessor, displayName, value } = props;
  return (
    <FormControl className={classes.textField} fullWidth={props.fullWidth}>
      <TextField
        id={accessor}
        label={displayName}
        type="datetime-local"
        style={props.width !== null ? { width: props.width ? props.width : '250px' } : {}}
        size="small"
        value={value}
        variant="filled"
        onChange={props.handleChange(accessor)}
        /* inputProps={{
          min: '2019-16-08T11:12Z',
          max: '2022-06-14T00:00Z',
        }} */
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: true,
          className: clsx(props.dark && classes.darkBackground),
        }}
      />
    </FormControl>
  );
};

DateTimeField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateTimeField);
