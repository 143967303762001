import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import ReactCountryFlag from 'react-country-flag';

import { useLanguage } from '../../contexts/LanguageProvider';

const getLanguages = (language) => {
  let languages = [
    {
      name: 'CN',
      fullName: 'China',
      language: 'Mandarin',
      langaugeIso: 'ZH',
    },
    {
      name: 'GB',
      fullName: 'United Kingdom',
      language: 'English',
      langaugeIso: 'EN',
    },
  ];
  let index = languages.findIndex((l) => l.name.toLowerCase() === language.name.toLowerCase());
  if (index > -1) {
    languages.splice(index, 1);
  }
  languages.push(language);
  return languages;
};

const styles = (theme) => ({});
const LanguageMenu = (props) => {
  const { language } = props;
  const languages = getLanguages(language);
  const [open, setOpen] = React.useState(false);
  const { changeLanguage } = useLanguage();
  const handleChange = (event) => {
    const item = event.target.value.language.toLowerCase();
    props.changeLanguage({ language: event.target.value });
    changeLanguage(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={language}
      name="language"
      onChange={handleChange}
      disableUnderline={true}
      sx={{
        color: 'white',

        '.MuiSvgIcon-root ': {
          fill: 'white !important',
        },
      }}
    >
      {languages.map((option, key) => (
        <MenuItem value={option} key={key}>
          <ReactCountryFlag
            svg
            countryCode={option.name}
            style={{
              fontSize: '2em',
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

LanguageMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LanguageMenu);
