import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { INIT_TABLE, DESTROY_TABLE, UPDATE_TABLE } from '../constants';
import {
  initTable as actionInitTable,
  destroyTable as actionDestroyTable,
  updateTable as actionUpdateTable,
} from '../actions';
import { getTable } from '../selectors';
import LfexRow from './LfexRow';
const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(),
    color: theme.palette.text.hint,
    '&:hover': {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },
  },
});

class LfexPrices extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    const { id, initTable, widget } = this.props;

    const payload = {
      id,
      widget,
    };

    initTable(payload);
  };

  componentWillUnmount() {
    const { id, destroyTable } = this.props;

    const payload = { id };
    destroyTable(payload);
  }
  refresh = () => {
    const { id, updateTable, widget } = this.props;
    const payload = {
      id,
      widget,
    };
    updateTable(payload);
  };

  render() {
    const { table, classes } = this.props;
    const data = table.rows || [];
    return (
      <div className={` ${classes.container} undraggable`}>
        <div className={classes.ratesContainer}>
          {data.map((item) => {
            return <LfexRow key={item.title} item={item} />;
          })}
        </div>
        <div className={classes.footer} onClick={() => this.refresh()}>
          <span className="material-icons">refresh</span>
          <span>REFRESH</span>
        </div>
      </div>
    );
  }
}

LfexPrices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;

  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    table: getTable(state, cId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initTable: (payload) => {
      dispatch(actionInitTable(INIT_TABLE, payload));
    },
    destroyTable: (payload) => {
      dispatch(actionDestroyTable(DESTROY_TABLE, payload));
    },
    updateTable: (payload) => {
      dispatch(actionUpdateTable(UPDATE_TABLE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LfexPrices));
