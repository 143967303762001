import React from 'react';

import ViewListIcon from '@material-ui/icons/ViewList';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/FormatQuote';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import GroupsIcon from '@material-ui/icons/Group';
import TableChartIcon from '@material-ui/icons/TableChart';

//const MARKETVIEWEXPANDED = 'MARKETVIEWEXPANDED';
const BLOTTERS = 'BLOTTERS';
const CHAT = 'CHAT';
//const ORDERMANAGER = 'ORDERMANAGER';
//const CHART = 'CHART';
const RFQ = 'RFQ';
//const FXRATES = 'FXRATES';
//const LFEXPRICES = 'LFEXPRICES';
//const CALCULATOR = 'CALCULATOR';
const ADMIN = 'ADMIN';
//const FILE_UPLOAD = 'FILEUPLOAD';
//const INVENTORY = 'INVENTORY';
const STATISTICS = 'STATISTICS';
const TRADE_LIST = 'TRADE_LIST';
export const widgets = [
  {
    type: RFQ,
    id: 'rfq',
    title: 'Offers',
    name: 'offers',
    icon: <BusinessIcon />,
    w: 32,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: TRADE_LIST,
    id: 'tradeList',
    title: 'Trade List',
    name: 'tradeList',
    icon: <ViewListIcon />,
    w: 12,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },

  {
    type: CHAT,
    id: 'chat',
    title: 'Chat',
    name: 'chat',
    icon: <ChatBubbleIcon />,
    w: 24,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  /*  {
    type: MARKETVIEWEXPANDED,
    id: 'market-view-expanded',
    title: 'Market View',
    name: 'marketView',
    icon: <TabIcon />,
    w: 8,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */

  /*  {
    type: ORDERMANAGER,
    id: 'orderManager',
    title: 'Order Manager',
    icon: <PlaylistAddIcon />,
    w: 16,
    h: 24,
    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */ {
    type: STATISTICS,
    id: 'statistics',
    title: 'Statistics',
    name: 'statistics',
    icon: <InsertChartIcon />,
    w: 32,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  /* {
    type: CHART,
    id: 'chart',
    title: 'Chart',
    name: 'chart',
    icon: <BarChartIcon />,
    w: 32,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
 */
  /*  {
    type: INVENTORY,
    id: 'inventory',
    title: 'Inventory',
    name: 'inventory',
    icon: <InventoryIcon />,
    w: 16,
    h: 16,
    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */

  /*  {
    type: FXRATES,
    id: 'fx',
    title: 'FX Rates',
    name: 'fxRates',
    icon: <EuroSymbolIcon />,
    w: 12,
    h: 12,

    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */
  /*   {
    type: LFEXPRICES,
    id: 'lfex',
    title: 'LFEX Prices',
    name: 'lfexPrices',
    icon: <WavesIcon />,
    w: 6,
    h: 12,

    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */
  /*  {
    type: CALCULATOR,
    id: 'calculator',
    title: 'Calculator',
    icon: <TableChartIcon />,
    w: 12,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */
  /* {
    type: FILE_UPLOAD,
    id: 'file_upload',
    title: 'Files',
    name: 'files',
    icon: <CloudUploadIcon />,
    w: 24,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  }, */
  {
    type: ADMIN,
    id: 'admin',
    title: 'Organisations & Users',
    name: 'organisationsAndUsers',
    icon: <GroupsIcon />,
    w: 24,
    h: 12,
    hasSearch: false,
    component: <div />,
    savedState: {},
  },
  {
    type: BLOTTERS,
    id: 'blotter',
    title: 'Order and Trade Blotters',
    name: 'orderAndTradeBlotters',
    icon: <TableChartIcon />,
    w: 32,
    h: 16,
    hasSearch: true,
    component: <div />,
    savedState: {},
  },
];

export const widgetComponent = (widgetType) => {
  const widget = widgets.find((item) => item.type === widgetType);
  return widget.component;
};

export const widgetTypes = {
  // MARKETVIEW,
  //MARKETVIEWEXPANDED,
  // SINGLEMARKET,
  BLOTTERS,
  CHAT,
  //ORDERMANAGER,
  STATISTICS,
  //CHART,
  RFQ,
  //FXRATES,
  //LFEXPRICES,
  //INVENTORY,
  //CALCULATOR,
  ADMIN,
  //FILE_UPLOAD,
  TRADE_LIST,
};
