import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Icon, Collapse } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import PriceCell from './PriceCell';
import DetailCell from './DetailCell';

import UserAvatar from '../../../commons/components/userAvatar';
import { avatarSize } from '../../../commons/models/constants';
import { getOrganisationShortNameFromId } from '../../../commons/utils/functions';
import { formatDateTime } from '../../../commons/config/formatters';

import orders from '../../orders';

import { visibilities } from '../../../commons/models/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.row,
    borderBottomColor: theme.palette.divider,
    borderBottom: '.1em solid',
  },
  columnSmall: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnLarge: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: '4px',
  },
  rowDark: {
    backgroundColor: darken(theme.palette.background.row, 0.15),
  },
  rowLight: {
    backgroundColor: darken(theme.palette.background.row, 0.1),
  },
  cellCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  subtitleColor: {
    color: theme.palette.text.subtitle,
  },
  timestamp: {
    color: theme.palette.text.subtitle,
    fontSize: theme.fontSize.xs,
  },

  recipients: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    fontSize: theme.fontSize.xs,
  },
  borderRight: {
    borderRightColor: theme.palette.divider,
    borderRight: '.1em solid',
  },
  expand: {
    color: theme.palette.text.subtitle,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(90deg)',
  },
  opacity: {
    backgroundColor: 'green',
    opacity: 0.8,
  },
});

const getRecipients = (order, organisations, organisationId) => {
  const selectedOrganisationsIds =
    order !== null
      ? order.organisationId === organisationId
        ? order.selectedOrganisationsIds
        : []
      : [];
  const visibility =
    order !== null ? (order.organisationId === organisationId ? order.visibility : '') : '';
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationShortNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return visibility;
  }

  list.sort();
  const listString = list.join(', ');
  return listString;
};

const isTradingPartner = (orgId, tradingPartners) => {
  return tradingPartners.some((t) => t.id === orgId);
};

const Row = (props) => {
  const { row, classes, user } = props;
  const [bidOpen, setBidOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const { title } = row;

  const buyer = row.bid !== null ? row.bid.organisationId : null; // TODO: REPLACE row.bid.organisation.shortName : null;
  const seller = row.offer !== null ? row.offer.organisationId : null; // row.offer.organisation.shortName : null;
  const bidOrder = row.bid !== null ? row.bid : null;
  const offerOrder = row.offer !== null ? row.offer : null;
  const bidIsCompanyOrder = row.bid === null || row.bid.organisationId === user.organisationId; //row.bid.isCompanyOrder : true;
  const offerIsCompanyOrder =
    row.offer === null || row.offer.organisationId === user.organisationId;
  const bidIsTradingPartner =
    row.bid !== null ? isTradingPartner(row.bid.organisationId, props.tradingPartners) : false;
  const offerIsTradingPartner =
    row.offer !== null ? isTradingPartner(row.offer.organisationId, props.tradingPartners) : false;
  const bid = row.bid !== null ? row.bid.assets[0].price : '';
  const offer = row.offer !== null ? row.offer.assets[0].price : '';
  const bidRefPrice = row.bid !== null ? row.bid.refPrice : '';
  const offerRefPrice = row.offer !== null ? row.offer.refPrice : '';
  const bidCurrency = row.bid !== null ? row.bid.assets[0].contract.underlying.currency : null;
  const offerCurrency =
    row.offer !== null ? row.offer.assets[0].contract.underlying.currency : null;
  const bidIncoTerms = row.bid !== null ? row.bid.assets[0].contract.underlying.incoTerms : null;
  const offerIncoTerms =
    row.offer !== null ? row.offer.assets[0].contract.underlying.incoTerms : null;
  const bidWeek = row.bid !== null ? row.bid.assets[0].contract.week : null;
  const offerWeek = row.offer !== null ? row.offer.assets[0].contract.week : null;
  const bidTimeStamp =
    row.bid !== null
      ? row.bid.updatedTime
        ? formatDateTime(row.bid.updatedTime)
        : formatDateTime(row.bid.createdTime)
      : null;
  const offerTimeStamp =
    row.offer !== null
      ? row.offer.updatedTime
        ? formatDateTime(row.offer.updatedTime)
        : formatDateTime(row.offer.createdTime)
      : null;
  // const bidComments = row.bid !==null ? row.bid.textMessage : null;
  // const offerComments =
  //   row.offer ? row.offer.textMessage : null;
  const bidRecipients = getRecipients(row.bid, props.organisations, props.user.organisationId);
  const offerRecipients = getRecipients(row.offer, props.organisations, props.user.organisationId);

  return (
    <div className={classes.container}>
      <div className={`${classes.columnSmall} ${classes.borderRight}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {props.mainRow && (
            <>
              <Icon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: props.expanded,
                })}
                onClick={() => props.setExpanded(!props.expanded)}
              >
                arrow_right
              </Icon>
              <div className={classes.subtitleColor}>{title}</div>
            </>
          )}
        </div>
      </div>
      <div className={`${classes.columnSmall} ${classes.borderRight}`}>
        {buyer !== null && (
          <div onClick={() => setBidOpen(true)} style={{ cursor: 'pointer' }}>
            <UserAvatar organisationId={buyer} size={avatarSize.SMALL} />{' '}
          </div>
        )}
      </div>
      <div className={`${classes.columnLarge} ${classes.borderRight}`}>
        <div className={classes.row}>
          <div className={classes.cellCentered}>
            <DetailCell incoTerms={bidIncoTerms} week={bidWeek} />
          </div>

          <div className={classes.cellCentered}>
            {bidIsCompanyOrder || bidIsTradingPartner === false ? (
              <PriceCell
                price={bid}
                currency={bidCurrency}
                refPrice={bidRefPrice}
                showDisplayCurrency={props.showDisplayCurrency}
              />
            ) : (
              <PriceCell
                price={bid}
                currency={bidCurrency}
                refPrice={bidRefPrice}
                showDisplayCurrency={props.showDisplayCurrency}
                buy
                onClick={() => props.createRfq(bidOrder)}
              />
            )}
          </div>
        </div>

        <div className={`${classes.row} ${classes.rowLight}`}>
          <div className={classes.timestamp}>{bidTimeStamp}</div>
        </div>
        <div className={`${classes.row} ${classes.rowDark}`}>
          <div className={classes.cell}>
            <div className={classes.recipients}>{bidRecipients}</div>
          </div>
        </div>
      </div>
      <div className={`${classes.columnLarge} ${classes.borderRight}`}>
        <div className={classes.row}>
          <div className={classes.cellCentered}>
            {offerIsCompanyOrder || offerIsTradingPartner === false ? (
              <PriceCell
                price={offer}
                currency={offerCurrency}
                refPrice={offerRefPrice}
                showDisplayCurrency={props.showDisplayCurrency}
              />
            ) : (
              <PriceCell
                price={offer}
                currency={offerCurrency}
                refPrice={offerRefPrice}
                showDisplayCurrency={props.showDisplayCurrency}
                sell
                onClick={() => props.createRfq(offerOrder)}
              />
            )}
          </div>

          <div className={classes.cellCentered}>
            <DetailCell incoTerms={offerIncoTerms} week={offerWeek} />
          </div>
        </div>

        <div
          className={`${classes.row} ${classes.rowLight}`}
          style={{ justifyContent: 'flex-end' }}
        >
          <div className={classes.timestamp} style={{ textAlign: 'right' }}>
            {offerTimeStamp}
          </div>
        </div>
        <div className={`${classes.row} ${classes.rowDark}`} style={{ justifyContent: 'flex-end' }}>
          <div className={classes.cell} style={{ alignItems: 'flex-end' }}>
            <div className={classes.recipients}>{offerRecipients}</div>
          </div>
        </div>
      </div>
      <div className={`${classes.columnSmall} ${classes.borderRight}`}>
        {seller !== null && (
          <div onClick={() => setOfferOpen(true)} style={{ cursor: 'pointer' }}>
            <UserAvatar organisationId={seller} size={avatarSize.SMALL} />
          </div>
        )}
      </div>
      {bidOrder !== null && bidOpen && (
        <orders.components.OrderDetail
          open={bidOpen}
          onClose={() => setBidOpen(false)}
          order={bidOrder}
        />
      )}

      {offerOrder !== null && offerOpen && (
        <orders.components.OrderDetail
          open={offerOpen}
          onClose={() => setOfferOpen(false)}
          order={offerOrder}
        />
      )}
    </div>
  );
};

const MarketViewRow = (props) => {
  const { item, classes } = props;

  const [expanded, setExpanded] = useState(false);
  const { subsegment } = item;
  const bidsLength = item.bids.length;
  const offersLength = item.offers.length;
  const numberOfRows = Math.max(bidsLength, offersLength);
  const orders = numberOfRows === bidsLength ? item.bids : item.offers;

  if (bidsLength === 0 && offersLength === 0) {
    return null;
  }
  const title = `${subsegment} (${numberOfRows})`;
  const bid = item.bids.length > 0 ? item.bids[0] : null;
  const offer = item.offers.length > 0 ? item.offers[0] : null;

  const row = { title, bid, offer };
  return (
    <div>
      <Row
        row={row}
        expanded={expanded}
        setExpanded={setExpanded}
        classes={classes}
        mainRow
        organisations={props.organisations}
        user={props.user}
        createRfq={props.createRfq}
        showDisplayCurrency={props.showDisplayCurrency}
        tradingPartners={props.tradingPartners}
      />
      <Collapse classes={{ container: classes.opacity }} in={expanded}>
        {orders.map((o, index) => {
          if (index === 0) {
            return null;
          }
          const dataRow = {
            title: '',
            bid: item.bids[index] || null,
            offer: item.offers[index] || null,
          };
          return (
            <Row
              key={o.id}
              row={dataRow}
              classes={classes}
              organisations={props.organisations}
              user={props.user}
              createRfq={props.createRfq}
              showDisplayCurrency={props.showDisplayCurrency}
              tradingPartners={props.tradingPartners}
            />
          );
        })}
      </Collapse>
    </div>
  );
};
MarketViewRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketViewRow);
