import moment from 'moment';

export const getWeek = function (dt) {
  const date = new Date(dt.valueOf());
  date.setDate(dt.getDate());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
  );
};

// Returns the four-digit year corresponding to the ISO week of the date.
export const getWeekYear = function (dt) {
  const date = new Date(dt.valueOf());
  date.setDate(dt.getDate());
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  return date.getFullYear();
};

export const getContractWeek = (dt) => {
  let week = getWeek(dt);
  const year = getWeekYear(dt);
  if (week < 10) {
    week = `0${week}`;
  }
  return `${week}_${year}`;
};

export const getAllContractWeeks = (pastWeeks = false) => {
  const obj = {};
  const values = [];
  const weeks = [];
  let numberOfPastWeeks = pastWeeks === false ? -2 : -2;
  let i;
  for (i = numberOfPastWeeks; i < 17; i++) {
    weeks.push(i);
  }

  weeks.forEach((week) => {
    const value = moment().add(week, 'week');
    const contract = getContractWeek(new Date(value));
    values.push(contract);
  });
  obj.value = getContractWeek(new Date());
  obj.values = values;

  return obj;
};

export const getWeekNumberFromWeekString = (week) => {
  const weekparts = week.split('_');
  const weekNumber = Number(weekparts[0]);
  return weekNumber;
};

export const getWeekPartsFromWeekString = (week) => {
  const weekparts = week.split('_');
  const weekNumber = Number(weekparts[0]);
  const weekYear = Number(weekparts[1]);
  return { week: weekNumber, year: weekYear };
};

export const getDatesFromWeek = (week, fresh) => {
  const parts = getWeekPartsFromWeekString(week);
  const weekNumber = parts.week;
  const yearNumber = parts.year;
  const today = moment();

  let minDelDate;
  let maxDelDate;
  let minHarvestDate;
  let maxHarvestDate;
  let minPackingDate;
  let maxPackingDate;
  let minFreezeDate;
  let maxFreezeDate;
  let processingDate;
  let useByDate;
  let minDate;

  if (fresh && fresh !== 'FRESH') {
    minDelDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .startOf('isoWeek')
      .add(2, 'days');
    maxDelDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .endOf('isoWeek')
      .add(2, 'days');
    minHarvestDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    maxHarvestDate =
      today > maxDelDate ? today.format('YYYY-MM-DD') : maxDelDate.format('YYYY-MM-DD');
    minFreezeDate = minHarvestDate;
    processingDate = minHarvestDate;
    useByDate = moment(minHarvestDate).add(7, 'days');
    maxFreezeDate =
      today > maxDelDate ? today.format('YYYY-MM-DD') : maxDelDate.format('YYYY-MM-DD');
    minDate = minHarvestDate;
    minPackingDate = minHarvestDate;
    maxPackingDate = maxHarvestDate;
  } else {
    minDelDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .startOf('isoWeek')
      .add(2, 'days');
    maxDelDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .endOf('isoWeek')
      .add(2, 'days');
    minHarvestDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    maxHarvestDate = moment()
      .set('year', yearNumber)
      .isoWeek(weekNumber)
      .endOf('isoWeek')
      .format('YYYY-MM-DD');

    minFreezeDate = minHarvestDate;
    maxFreezeDate = maxHarvestDate;
    processingDate = maxHarvestDate;
    useByDate = moment(maxHarvestDate).add(30, 'days').format('YYYY-MM-DD');
    minDate = minHarvestDate;
    minPackingDate = minHarvestDate;
    maxPackingDate = maxHarvestDate;
  }

  const minDeliveryDate =
    today > minDelDate ? today.format('YYYY-MM-DD') : minDelDate.format('YYYY-MM-DD');
  const maxDeliveryDate =
    today > maxDelDate ? today.format('YYYY-MM-DD') : maxDelDate.format('YYYY-MM-DD');

  return {
    minHarvestDate,
    maxHarvestDate,
    minPackingDate,
    maxPackingDate,
    minDeliveryDate,
    maxDeliveryDate,
    minFreezeDate,
    maxFreezeDate,
    processingDate,
    useByDate,
    minDate,
    week,
  };
};

export const getDatesThisWeek = () => {
  const week = getAllContractWeeks().value;
  const dates = getDatesFromWeek(week);
  return dates;
};
