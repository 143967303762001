import { idGenerator } from '../../../commons/config/functions';
import { PRICE_DECIMALS } from '../../../commons/models/constants';

export const newCalculator = (name) => {
  return {
    name: name === undefined || name === null ? 'Calculator' : name,
    id: idGenerator(),
    active: false,
    calculatorState: defaultCalculatorState(),
  };
};

export const defaultCalculatorState = () => ({
  collapseOpen: true,
  ddpPrice: true,
  fxRateInverted: false,
  quantity: 0,
  finalPrice: 0,
  finalCurrency: 'EUR',
  localCurrency: 'EUR',
  feeCurrency: 'EUR',
  currencyRate: 1,
  feeCurrencyRate: 1,

  price: 0,
  importRate: 2.0,
  exportRate: 0.6,
  creditInsuranceRate: 0.17,
  transportInsuranceRate: 0.03,
  rebateRate: 0,
  transportRate: 0.22,
  terminalFees: 0,
  miscRate: 0,

  cmr: 0,
  notification: 0,
  invoiceFee: 0,
  bankFee: 0,
  otherFee: 0,

  errors: {
    price: { message: '', value: false },
    importRate: { message: '', value: false },
    exportRate: { message: '', value: false },
    invoiceRate: { message: '', value: false },
    creditInsuranceRate: { message: '', value: false },
    transportInsuranceRate: { message: '', value: false },
    transportRate: { message: '', value: false },
    terminalFees: { message: '', value: false },
    feeCurrencyRate: { message: '', value: false },
  },
});

export const calcFinalPrice = (calculatorState, _price, _quantity, _reverse) => {
  const state =
    calculatorState === null || calculatorState === undefined
      ? defaultCalculatorState()
      : calculatorState;
  const price = _price === null || _price === undefined ? 0 : _price;
  const quantity = _quantity === null || _quantity === undefined ? 1 : _quantity;

  const reverse = _reverse === null || _reverse === undefined ? false : _reverse;

  const importRate = parseFloat(state.importRate / 100);
  const exportRate = parseFloat(state.exportRate / 100);
  const creditInsuranceRate = state.creditInsuranceRate
    ? parseFloat(state.creditInsuranceRate / 100)
    : 0;
  const transportInsuranceRate = state.transportInsuranceRate
    ? parseFloat(state.transportInsuranceRate / 100)
    : 0;
  const rebateRate = parseFloat(state.rebateRate / 100);
  const miscRate = parseFloat(state.miscRate / 100);
  let transportRate = parseFloat(state.transportRate);
  let terminalFees = state.terminalFees ? parseFloat(state.terminalFees) : 0;
  const { feeCurrencyRate } = state;
  transportRate = parseFloat(transportRate * feeCurrencyRate);
  terminalFees = parseFloat(terminalFees * feeCurrencyRate);
  const currencyRate = state.fxRateInverted ? 1 / state.currencyRate : state.currencyRate;

  const cmr = quantity === 0 ? 0 : parseFloat((state.cmr * feeCurrencyRate) / quantity);
  const notification =
    quantity === 0 ? 0 : parseFloat((state.notification * feeCurrencyRate) / quantity);
  const invoiceFee =
    quantity === 0 ? 0 : parseFloat((state.invoiceFee * feeCurrencyRate) / quantity);
  const bankFee = quantity === 0 ? 0 : parseFloat((state.bankFee * feeCurrencyRate) / quantity);
  const otherFee = quantity === 0 ? 0 : parseFloat((state.otherFee * feeCurrencyRate) / quantity);

  const fixedFees =
    cmr + notification + invoiceFee + bankFee + otherFee + transportRate + terminalFees;
  const variableFees = creditInsuranceRate + transportInsuranceRate + rebateRate + miscRate;

  let finalPrice;

  if (!reverse) {
    if (state.ddpPrice) {
      finalPrice = fixedFees + price * (1 + variableFees);
      finalPrice *= 1 + importRate;
      finalPrice += exportRate * price;
      finalPrice = currencyRate * finalPrice;
    } else {
      finalPrice = price * currencyRate;
    }
  } else if (state.ddpPrice) {
    finalPrice = price / currencyRate;
    finalPrice /= 1 + importRate;
    finalPrice -= fixedFees;
    finalPrice /= exportRate + 1 + variableFees;
  } else {
    finalPrice = price / currencyRate;
  }

  finalPrice = finalPrice.toFixed(PRICE_DECIMALS);

  return finalPrice;
};
