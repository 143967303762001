import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/dictionaries/models`;
    this.get = this.get.bind(this);
  }

  async get(data = {}) {
    const url = `${this.baseUrl}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async createFieldValue(data = {}) {
    const type = data.type || 'brand';
    const url = `/admin/dictionaries/models/organisationDetails/fields/${type}/values`;

    const params = data.params || {};

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };

    return coreApi.getResponse(url, params, options);
  }

  async deleteFieldValue(data = {}) {
    const type = data.type || 'brand';
    const value = data.value;
    const url = `/admin/dictionaries/models/organisationDetails/fields/${type}/values/${value}`;

    let params = {};

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: null,
    };

    return coreApi.getResponseDelete(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
