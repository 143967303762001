import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Badge, Icon } from '@material-ui/core';

const styles = (theme) => ({
  icon: { color: theme.palette.text.primary, fontSize: '20px' },
  iconEmpty: { color: theme.palette.text.faded, fontSize: '20px' },
});

const getRoom = (rfqId, rfqs, chats, user) => {
  const rfq = rfqs[rfqId];
  let foundRoom = null;
  if (rfq) {
    const counterpartyOrgId =
      rfq.createdByUserId === user.id ? rfq.selectedOrganisationsIds[0] : rfq.brokerOrganisationId;

    foundRoom = chats.find((item) => {
      return item.rfqId === rfq.id && item.organisationIds.includes(counterpartyOrgId);
    });
  }
  return foundRoom;
};

const ChatNotification = (props) => {
  const { rfqId, classes } = props;
  const rfqs = useSelector((state) => state.rfqs.byId);
  const chats = useSelector((state) => state.chat.chats);
  const user = useSelector((state) => state.auth.server.user);
  const room = useMemo(() => getRoom(rfqId, rfqs, chats, user), [chats]);
  const hasMessages = room ? room.messages.length > 0 : false;

  return (
    <Badge badgeContent={room ? room.newMessages : null} color="secondary">
      <Icon className={hasMessages ? classes.icon : classes.iconEmpty}>{'chat_bubble'}</Icon>
    </Badge>
  );
};

export default withStyles(styles)(ChatNotification);
