import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';

import { updateRFQs } from '../actions';
import { UPDATE_RFQS, RFQ_STATUS_ENDED } from '../constants';

import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: { flex: 1, display: 'flex', flexDirection: 'column' },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
});

class EndAllRfqs extends React.PureComponent {
  endAllRfqs = () => {
    const { userId } = this.props;
    const rfqs = this.props.rfqs;

    const payload = {
      userId,
      status: RFQ_STATUS_ENDED,
      rfqs,
    };

    this.props.endRFQs(payload);

    this.props.close();
  };

  render() {
    const { classes, open, close } = this.props;

    return (
      <PopUp open={open} title={<CustomText value="endAllMyRfqs" />}>
        <div className={classes.container}>
          <div className={classes.listContainer} />
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <React.Fragment>
                <CancelButton title={'cancel'} onClick={close} />
                <ConfirmButton title={'endAllMyRfqs'} onClick={this.endAllRfqs} />
              </React.Fragment>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
EndAllRfqs.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    endRFQs: (payload) => {
      dispatch(updateRFQs(UPDATE_RFQS, payload));
    },
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(EndAllRfqs));
