import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SIGN_IN_NEW_PASSWORD_SENT } from '../../../modules/auth/constants';
import { createAction } from '../../../commons/actions';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextBox from '../textBox';
import Button from '../button';
import {
  getStatus,
  getError,
  getRequiredAttributes,
  getThemeType,
} from '../../../modules/auth/selectors';

import { passwordValidation, passwordValidationMessage } from '../validators';
import clsx from 'clsx';
const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  errorMessageContainer: {
    height: '50px',
    margin: '8px auto',
    maxWidth: '216px',
  },
  errorHidden: {
    display: 'none',
  },
  errorVisible: {
    display: 'inline',
    overflowWrap: 'normal',
    fontFamily: 'sans-serif',
    color: 'var(--text-error-color)',
  },
});

const RequireNewPassword = (props) => {
  const { classes, backToSignIn } = props;
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [focus, setFocus] = useState(null);
  const [inputError, setInputError] = useState(null);

  const handleSubmit = () => {
    if (
      passwordValidation(password) &&
      passwordValidation(confirmPassword) &&
      password === confirmPassword
    ) {
      const payload = {
        password: password,
      };
      props.createNewPassword(payload);
    } else {
      if (inputError === null) {
        setInputError('Passwords do not match');
      }
    }
  };

  const handleChange = (id) => (event) => {
    console.log(id, event.target.value);
    switch (id) {
      case 'password':
        setPassword(event.target.value);
        setInputError(passwordValidationMessage(event.target.value));
        break;

      default:
        setConfirmPassword(event.target.value);
        setInputError(passwordValidationMessage(event.target.value));
    }
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.loginBackground}>
          <Typography>Choose a New Password</Typography>
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <TextBox
              icon="lock"
              id="password"
              placeholder="Password"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              visibility
              autocomplete="new-password"
            ></TextBox>
            <TextBox
              icon="lock"
              id="confirmPassword"
              placeholder="ConfirmPassword"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              visibility
              autocomplete="new-password"
            ></TextBox>
            <Button onClick={handleSubmit} title="SUBMIT" />
            <div className={classes.errorMessageContainer}>
              <div
                className={clsx({
                  [classes.errorVisible]: true,
                  [classes.errorHidden]: inputError,
                })}
              >
                {inputError ? inputError : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    status: getStatus(state),
    requiredAttributes: getRequiredAttributes(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewPassword: (payload) => dispatch(createAction(SIGN_IN_NEW_PASSWORD_SENT, payload)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RequireNewPassword));
