import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { formatDateTime } from '../../../commons/config/formatters';
import UserAvatar from '../../../commons/components/userAvatar';
import TotalsRow from './TotalsRowNew';

import { directions, status } from '../../../commons/models/constants';
import ChatNotification from '../../chat/components/ChatNotification';
const styles = (theme) => ({
  container: {
    flex: 1,
    //backgroundColor: theme.color.background.primary,
  },
  view: {
    padding: 8,
    flex: 1,

    justifyContent: 'center',
    alignItems: 'center',
  },

  timeOfUpdateContainer: {
    display: 'flex',
    flexDirection: 'row',
    //alignItems: 'flex-end',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  timeOfUpdateText: {
    color: theme.palette.common.white,
    fontSize: theme.fontSize.small,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textAlign: 'center',
    minWidth: '200px',
  },
  invoiceTitleText: {
    color: theme.palette.text.faded_75_white,
    marginRight: theme.spacing(0.5),
  },
});
const getCounterpartyId = (trade, myOrganisation) => {
  if (trade) {
    const counterpartyId =
      trade.buyBrokerOrganisationId === myOrganisation.id ||
      trade.buyBeneficiaryOrganisationId === myOrganisation.id
        ? trade.sellBrokerOrganisationId
        : trade.buyBrokerOrganisationId;

    return counterpartyId;
  } else {
    return null;
  }
};

const TradeListRow = (props) => {
  const { classes, trade } = props;
  const myOrganisation = useSelector((state) => state.organisations.myOrganisation);
  const theme = useSelector((state) => state.themes.theme);
  const timeStamp = trade ? (props.trade.updatedTime ? formatDateTime(trade.updatedTime) : '') : '';
  const invoiceNumber =
    trade.sellerData && trade.sellerData.invoiceNumber ? trade.sellerData.invoiceNumber : null;
  const referenceNumber =
    trade.sellerData && trade.sellerData.trackingNumber ? trade.sellerData.trackingNumber : null;
  const getColors = (totals, theme) => {
    const direction = totals.direction || 'SELL';
    let borderColor =
      direction === directions.SELL ? theme.color.green.primary : theme.color.lightBlue.primary;
    let tileBackground =
      direction === directions.SELL ? theme.color.green.dark : theme.color.lightBlue.dark;
    let toolbarBackground =
      direction === directions.SELL ? theme.color.green.primary : theme.color.lightBlue.primary;
    let timestampBackground = 'transparent';
    let priceColor =
      direction === directions.SELL ? theme.color.green.light : theme.color.lightBlue.light;
    let volumeColor =
      direction === directions.SELL ? theme.color.green.light : theme.color.lightBlue.light;
    if (totals.status === status.SUSPENDED || totals.cancellationStatus === 'REQUESTED') {
      borderColor = theme.color.amber.primary;
      tileBackground = theme.color.amber.dark;
      toolbarBackground = theme.color.amber.primary;
      timestampBackground = theme.color.amber.primary;
      priceColor = theme.color.amber.light;
      volumeColor = theme.color.amber.light;
    }
    if (totals.status === status.CANCELLED) {
      borderColor = theme.color.red.primary;
      tileBackground = theme.color.red.primary;
      toolbarBackground = theme.color.red.primary;
      timestampBackground = theme.color.red.primary;
      priceColor = theme.color.red.light;
      volumeColor = theme.color.red.light;
    }
    /*  if (totals.status === status.FILLED) {
      borderColor = theme.color.border.primary;
      tileBackground = 'transparent';
      toolbarBackground = 'transparent';
      timestampBackground = 'transparent';
      priceColor = theme.color.text.primary;
      volumeColor = theme.color.text.primary;
    } */

    return {
      borderColor,
      tileBackground,
      toolbarBackground,
      timestampBackground,
      priceColor,
      volumeColor,
    };
  };
  let counterpartyId = useMemo(() => {
    return getCounterpartyId(props.trade, myOrganisation);
  }, [props.trade, myOrganisation]);
  const getDirection = (trade) => {
    const direction =
      trade.buyBrokerOrganisationId === myOrganisation.id ||
      trade.buyBeneficiaryOrganisationId === myOrganisation.id
        ? directions.BUY
        : directions.SELL;
    return direction;
  };
  const totals = {
    direction: getDirection(trade),
    status: trade.status,
    cancellationStatus: trade.cancellationStatus,
  };
  const colors = getColors(totals, theme);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
      }}
      key={props.index}
    >
      <div style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
        <UserAvatar organisationId={counterpartyId} size={32}></UserAvatar>
      </div>
      <div
        style={{
          flex: 1,
          border: 'solid',
          borderWidth: '4px',
          borderRadius: '8px',
          borderColor: colors.borderColor,
        }}
      >
        <TotalsRow orderTradeObject={props.trade} orgId={props.myOrganisationId} noColor />

        <div
          className={classes.timeOfUpdateContainer}
          style={{
            backgroundColor: colors.timestampBackground,
          }}
          onClick={props.onTradePress}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '16px',
              paddingRight: '16px',
              flex: 1,
            }}
          >
            {invoiceNumber && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',

                  marginBottom: '2px',
                }}
              >
                <div className={classes.invoiceTitleText}>INV:</div>
                <div>{invoiceNumber}</div>
              </div>
            )}
            <div></div>
            <div style={{}}>
              <ChatNotification rfqId={trade.rfqId} />
            </div>
          </div>

          <div
            className={classes.timeOfUpdateText}
            textAlign="center"
            style={{
              backgroundColor: colors.toolbarBackground,
              borderTopLeftRadius: '6px',
            }}
          >
            {referenceNumber || timeStamp}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TradeListRow);
