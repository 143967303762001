import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import CalcTool from './CalcTool';

import NumberFieldFilled from '../../../commons/components/formFields/numberFieldFilled';
import SwitchField from '../../../commons/components/formFields/switchField';

import { getCalculatorsFromSettings, getLocalCalculator, getFxratesObject } from '../selectors';

import {
  INIT_CALCULATOR,
  DESTROY_CALCULATOR,
  UPDATE_CALCULATOR,
  UPDATE_CALCULATORS,
} from '../constants';

import { initCalculator, destroyCalculator, updateCalculator, updateCalculators } from '../actions';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    overflowY: 'scroll',
  },
  row: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
  },

  containerInputs: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  gridContainer: {
    alignItems: 'center',
  },

  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textAlign: 'left',
  },
});

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElCalculator: null,
      menuOpenCalculator: false,
    };
  }

  componentDidMount() {
    const { widget, quantityUnit, quantity, defaultCalculatorId, id } = this.props;

    const payload = {
      id,
      widget,
      quantityUnit,
      quantity,
      usePrice: false,
      defaultCalculatorId,
    };

    this.props.initCalculator(payload);
  }

  componentWillUnmount() {
    const payload = {
      id: this.props.id,
    };
    this.props.destroyCalculator(payload);
  }

  handlePriceQuantityChange = (name) => (event) => {
    const { widget, id } = this.props;

    const payload = {
      id,
      widget: widget,
      name,
      value: event.target.value,
    };

    // TODO: CRITICAL! REFACTOR. ONLY FOR DEMO. NOW USE IN RFQ RESPONSE FORM ROW AND UPDATE FOR IT
    if (this.props.updatePrice) {
      payload.callbacks = {
        updatePrice: this.props.updatePrice,
      };
    }
    this.props.updateCalculator(payload);
  };

  handleCalculatorChange = (name, value, errors) => {
    const { id, widget, fxrates } = this.props;

    const payload = {
      id,
      widget,
      name,
      value,
      errors,
      fxrates,
    };

    // TODO: CRITICAL! REFACTOR. ONLY FOR DEMO. NOW USE IN RFQ RESPONSE FORM ROW AND UPDATE FOR IT
    if (this.props.updatePrice) {
      payload.callbacks = {
        updatePrice: this.props.updatePrice,
      };
    }

    this.props.updateCalculators(payload);
  };

  handleUsePriceChanged = (event) => {
    const { widget, id } = this.props;

    const payload = {
      id,
      widget,
      name: 'usePrice',
      value: event.target.checked,
    };

    // TODO: CRITICAL! REFACTOR. ONLY FOR DEMO. NOW USE IN RFQ RESPONSE FORM ROW AND UPDATE FOR IT
    if (this.props.updatePrice) {
      payload.callbacks = {
        updatePrice: this.props.updatePrice,
      };
    }

    this.props.updateCalculator(payload);
  };

  changeCalculator = (calculatorId) => {
    const { widget, id } = this.props;

    const payload = {
      id,
      widget,
      calculatorId,
    };

    // TODO: CRITICAL! REFACTOR. ONLY FOR DEMO. NOW USE IN RFQ RESPONSE FORM ROW AND UPDATE FOR IT
    if (this.props.updateDefaultCalculatorId) {
      this.props.updateDefaultCalculatorId(calculatorId); // TODOD: use in rfq now. refactor. use in saga.
    }

    // TODO: CRITICAL! REFACTOR. ONLY FOR DEMO. NOW USE IN RFQ RESPONSE FORM ROW AND UPDATE FOR IT
    if (this.props.updatePrice) {
      payload.callbacks = {
        updatePrice: this.props.updatePrice,
      };
    }

    this.props.updateCalculator(payload);
  };

  saveWidgetState = () => {
    if (this.props.widget) {
      const { widget } = this.props;
      widget.savedState = {
        calculatorId: this.state.calculator.id,
        localPrice: this.state.localPrice,
        finalPrice: this.state.finalPrice,
        quantity: this.state.quantity,
      };
      this.props.saveWidgetState(widget);
    }
  };

  render() {
    const { classes, isRfq, stateCalculator } = this.props;
    let { calculator, localPrice, finalPrice, quantity, usePrice } = stateCalculator;

    if (!calculator) {
      calculator = {};
    }

    const calculatorState = calculator.calculatorState || {};

    return (
      <div className="undraggable">
        <div className={classes.containerInputs}>
          <div className={classes.root}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={2} className={classes.gridItem}>
                <NumberFieldFilled
                  accessor="localPrice"
                  displayName="Local Price"
                  value={localPrice}
                  handleChange={this.handlePriceQuantityChange}
                  fullWidth
                  width={null}
                  adornment={calculatorState.localCurrency}
                  step="0.1"
                />
              </Grid>
              <Grid item xs={2} className={classes.gridItem}>
                <NumberFieldFilled
                  accessor="finalPrice"
                  displayName="Final Price"
                  value={finalPrice}
                  handleChange={this.handlePriceQuantityChange}
                  fullWidth
                  width={null}
                  adornment={calculatorState.finalCurrency}
                  step="0.1"
                />
              </Grid>
              {!isRfq && (
                <Grid item xs={2} className={classes.gridItem}>
                  <NumberFieldFilled
                    accessor="quantity"
                    displayName="Quantity"
                    value={quantity}
                    handleChange={this.handlePriceQuantityChange}
                    fullWidth
                    width={null}
                    adornment="KG"
                    step="100"
                  />
                </Grid>
              )}
              {isRfq && (
                <SwitchField
                  checked={usePrice}
                  value={usePrice}
                  handleChecked={this.handleUsePriceChanged}
                  onLabel="Export Final Price"
                  offLabel=""
                />
              )}

              <Grid item xs={4} className={classes.gridItem} />
            </Grid>
          </div>
        </div>
        <div className={`${classes.container} undraggable`}>
          <CalcTool
            handleChange={this.handleCalculatorChange}
            calculator={calculator}
            calculatorState={calculatorState}
            calculatorClick={this.calculatorClick}
            {...calculatorState}
            changeCalculator={this.changeCalculator}
          />
        </div>
      </div>
    );
  }
}

Calculator.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const { id, widgetId, widget } = props;

  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    fxrates: getFxratesObject(state),
    calculators: getCalculatorsFromSettings(state), // state.userSettings.calculators,
    stateCalculator: getLocalCalculator(state, cId),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initCalculator: (payload) => {
      dispatch(initCalculator(INIT_CALCULATOR, payload));
    },
    destroyCalculator: (payload) => {
      dispatch(destroyCalculator(DESTROY_CALCULATOR, payload));
    },
    updateCalculator: (payload) => {
      dispatch(updateCalculator(UPDATE_CALCULATOR, payload));
    },
    updateCalculators: (payload) => {
      dispatch(updateCalculators(UPDATE_CALCULATORS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Calculator));
