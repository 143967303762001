import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { FormControl, MenuItem, InputLabel, Select, Checkbox, Chip } from '@material-ui/core';

import { textField } from './formFieldStyles';
import { isObject } from '../../../commons/utils/functions';
import CustomText from './customText';
const styles = (theme) => ({
  textField: textField(theme),

  any: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.primary,
  },
  option: {},
  'label + .MuiInput-formControl': {
    marginTop: '32px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px 2px',
    fontSize: theme.fontSize.xs,
    // padding: "0px 2px",
    // height: "auto"
  },
});

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MAX_ITEMS = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * MAX_ITEMS + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectFieldOrgs = (props) => {
  const { classes, accessor, displayName, value, values, errorMessage, errorValue } = props;
  let object = value;
  if (value.length > 0) {
    if (isObject(value[0])) {
      if (value[0].hasOwnProperty('id')) {
        object = values.filter((org) => value.some((val) => val.id === org.id));
      }
    }
  }

  return (
    <FormControl
      className={classes.textField}
      fullWidth={props.fullWidth}
      style={props.style}
      size="small"
    >
      <InputLabel htmlFor={accessor}>
        <CustomText value={displayName} />
      </InputLabel>
      <Select
        multiple
        variant="filled"
        value={object}
        onChange={props.handleChange(accessor)}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        MenuProps={MenuProps}
        disableUnderline={true}
        renderValue={(selected) => `${selected[0].name} | ${selected[0].shortName}`}
      >
        {values.map((option) => (
          <MenuItem key={option.id} value={option}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>{option.name}</div>
              <div>{option.shortName}</div>
            </div>
          </MenuItem>
        ))}
      </Select>

      {/* <FormHelperText>{errorRecipientsMessage}</FormHelperText> */}
    </FormControl>
  );
};

MultipleSelectFieldOrgs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultipleSelectFieldOrgs);
