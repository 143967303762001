import { takeEvery, put } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import { THEME_TYPES } from '../constants';
import { getFromLS, saveToLS } from '../../../commons/localStorage';

import { createAction } from '../../../commons/actions';

function* initTheme() {
  const payload = {
    type: getFromLS('theme') || THEME_TYPES.dark,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(createAction(actionTypes.INIT_THEME_SUCCESS, payload, meta));
}

function* changeTheme(action) {
  const { type } = action.payload;

  if (type) {
    saveToLS('theme', type);

    const payload = {
      type: action.payload.type,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.CHANGE_THEME_SUCCESS, payload, meta));
  }
}

export function* watchInitTheme() {
  yield takeEvery(actionTypes.INIT_THEME, initTheme);
}

export function* watchChangeTheme() {
  yield takeEvery(actionTypes.CHANGE_THEME, changeTheme);
}
