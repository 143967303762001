import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';
import * as functions from './utils/functions';
import { getMarketSegments } from './utils/modelFunctions';
import { getEnumerations } from './utils/modelFunctions';
import { getCartonNetWeightEnums } from './utils/modelFunctions';
const reducers = {
  reducer,
};
const utils = {
  ...functions,
  getMarketSegments,
  getEnumerations,
  getCartonNetWeightEnums,
};

export default { actions, constants, reducers, sagas, selectors, api, utils };
