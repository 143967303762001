import coreApi from '../../../commons/api';

class Api {
  constructor() {
    this.baseUrl = `/rfqs`;

    this.getAll = this.getAll.bind(this);
    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
  }

  async getAll(data = {}) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async get(rfqId = null, data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(rfqId)}`;

    const params = {};

    const options = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };

    return coreApi.getResponse(url, params, options);
  }

  async create(data) {
    const url = `${this.baseUrl}`;

    const params = data.params || {};

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(data.body),
    };
    return coreApi.getResponse(url, params, options);
  }

  async update(data = {}) {
    const url = `${this.baseUrl}/${encodeURIComponent(data.body.rfqId)}`;

    const params = data.params || {};

    const { rfqId, ...body } = data.body;

    const options = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      body: JSON.stringify(body),
    };

    return coreApi.getResponse(url, params, options);
  }
}

const api = new Api();
Object.freeze(api);

export default api;
