import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Card,
} from '@material-ui/core';

import SelectField from '../../../../commons/components/formFields/SelectField';
import TextInputField from '../../../../commons/components/formFields/TextInputField';
import MultipleSelectField from '../../../../commons/components/formFields/multipleSelectField';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';

import {
  getOrganisations,
  getOrganisationsObject,
  getMyOrganisation,
  getBrandsAndFactories,
  getBrokers,
} from '../../selectors';
import { createAdminItem, updateAdminItem } from '../../actions';
import {
  CREATE_ADMIN_ITEM,
  UPDATE_ADMIN_ITEM,
  UPDATE_BROKERS,
  CREATE_BROKERS,
} from '../../constants';
import logos from '../../../logos';
import auth from '../../../auth';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    maxHeight: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.primary,
  },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  card: {
    margin: '0px 4px 4px',
    padding: '4px',
  },
  section: {
    marginTop: theme.spacing(0.5),
  },
  /*   banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  }, */
  title: {},
  banner: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },
  subheader: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.5),
  },
});
const initialState = {
  name: null,
  shortName: null,
  type: null,
  tradingPartnersIds: [],
  beneficiariesIds: [],
  brokersIds: [],
  address1: null,
  address2: null,
  address3: null,
  city: null,
  postcode: null,
  locality: null,
  country: null,
  brand: null,
  processingPoints: null,
  species: null,
  description: null,
  nameError: { value: false, message: '' },
  shortNameError: { value: false, message: '' },
};

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
const UpdateOrganisation = (props) => {
  const [formState, setFormState] = useState({});
  const { classes, open, organisations, isSuperAdmin = false } = props;
  const brands = Object.keys(props.brandsAndFactories.brand);
  const factoryNumbers = Object.keys(props.brandsAndFactories.factoryNumber);
  const { organisationId } = props;
  const organisation = props.organisationsObject[organisationId];

  const {
    name,
    shortName,
    tradingPartnersIds,
    address,
    details,
    description,
    type,
    beneficiariesIds,
    brokersIds,
  } = organisation;
  const { address1, address2, address3, city, postcode, locality, country } = address || {};
  const { brand, factoryNumber, species } = details || [];

  useEffect(() => {
    setFormState({ ...formState, tradingPartnersIds, beneficiariesIds, brokersIds });
  }, [tradingPartnersIds, beneficiariesIds, brokersIds]);

  const handleChange = (name) => (event) => {
    const value = event.target.value;

    let error = { value: false, message: '' };
    if (value === '' || value === null || value === undefined) {
      error = { value: true, message: 'Value is required' };
    }
    setFormState({ ...formState, [name]: event.target.value, [`${name}Error`]: error });
  };

  const handleChecked = (id, name) => () => {
    const checked = formState[name];
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFormState({ ...formState, [name]: newChecked });
  };

  const handleMultiCheck = (eventType, name) => {
    const newChecked = [];
    switch (eventType) {
      case 'all':
        props.organisations.forEach((org) => {
          newChecked.push(org.id);
        });
        break;
      case 'buyer':
      case 'seller':
        props.organisations.forEach((org) => {
          if (org.type.toLowerCase() === eventType) {
            newChecked.push(org.id);
          }
        });
        break;
      default:
    }
    setFormState({ ...formState, [name]: newChecked });
  };

  const send = () => {
    const address = {
      address1: formState.address1 || address1,
      address2: formState.address2 || address2,
      address3: formState.address3 || address3,
      city: formState.city || city,
      postcode: formState.postcode || postcode,
      locality: formState.locality || locality,
      country: formState.country || country,
    };
    const details = {
      species: formState.species || species,
      brand: formState.brand || brand,
      factoryNumber: formState.factoryNumber || factoryNumber,
      product: [],
      country: [],
      region: [],
    };
    const payload = {
      type: 'organisations',
      body: {
        name: formState.name || name,
        shortName: formState.shortName || shortName,
        type: formState.type || type,
        address,
        tradingPartnersIds: formState.tradingPartnersIds,
        beneficiariesIds: formState.beneficiariesIds,
        brokersIds: formState.brokersIds,
        details,
        description: formState.description || description,
      },
      id: props.organisationId,
    };

    props.update(payload);
    props.closeForm();
    setFormState({});
  };

  return (
    <div>
      <Modal open={open} onClose={props.closeForm}>
        <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
          <Typography className={classes.title}>EDIT ORGANISATION</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <Typography className={classes.banner}>ORGANISATION</Typography>
              <Card className={classes.card}>
                <TextInputField
                  accessor="name"
                  displayName="Organisation Full Name"
                  value={formState.name || name}
                  handleChange={handleChange}
                  error={formState.nameError}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="shortName"
                  displayName="Organisation Short Name"
                  value={formState.shortName || shortName}
                  handleChange={handleChange}
                  error={formState.shortNameError}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <SelectField
                  accessor="type"
                  displayName="Organisation Type"
                  value={formState.type || type}
                  values={['BUYER', 'SELLER', 'AGENT']}
                  handleChange={(name) => (e) => {
                    setFormState({
                      ...formState,
                      [name]: e.target.value,
                      typeError: { value: false, message: '' },
                    });
                  }}
                  error={formState.typeError}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
              </Card>
            </div>
            <div className={classes.section}>
              <Typography className={classes.banner}>ADDRESS</Typography>
              <Card className={classes.card}>
                <TextInputField
                  accessor="address1"
                  displayName="Address 1"
                  value={formState.address1 || address1}
                  handleChange={handleChange}
                  error={formState.address1Error}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="address2"
                  displayName="Address 2"
                  value={formState.address2 || address2}
                  handleChange={handleChange}
                  error={formState.address2Error}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="address3"
                  displayName="Address 3"
                  value={formState.address3 || address3}
                  handleChange={handleChange}
                  error={formState.address3Error}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="city"
                  displayName="city"
                  value={formState.city || city}
                  handleChange={handleChange}
                  error={formState.cityError}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="postcode"
                  displayName="Postcode"
                  value={formState.postcode || postcode}
                  handleChange={handleChange}
                  error={formState.postCodeError}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="locality"
                  displayName="Locality"
                  value={formState.locality || locality}
                  handleChange={handleChange}
                  error={formState.locality}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
                <TextInputField
                  accessor="country"
                  displayName="Country"
                  value={formState.country || country}
                  handleChange={handleChange}
                  error={formState.country}
                  fullWidth
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
              </Card>
            </div>
            <div className={classes.section}>
              <Typography className={classes.banner}>PRODUCT</Typography>

              <Card className={classes.card}>
                <MultipleSelectField
                  accessor="brand"
                  key="user_update_brand"
                  displayName="Brand"
                  value={formState.brand || brand}
                  values={brands || []}
                  handleChange={handleChange}
                  fullWidth
                  disabled={!isSuperAdmin}
                />

                <MultipleSelectField
                  accessor="factoryNumber"
                  key="user_update_factoryNumber"
                  displayName="FactoryNumber"
                  value={formState.factoryNumber || factoryNumber}
                  values={factoryNumbers || []}
                  handleChange={handleChange}
                  fullWidth
                  disabled={!isSuperAdmin}
                />

                <MultipleSelectField
                  accessor="species"
                  key="user_update_species"
                  displayName="Species"
                  value={formState.species || species}
                  values={props.species}
                  handleChange={handleChange}
                  fullWidth
                />
              </Card>
            </div>

            <div className={classes.section}>
              <Typography className={classes.banner}>DESCRIPTION</Typography>
              <Card className={classes.card}>
                <TextInputField
                  accessor="description"
                  displayName="Description"
                  value={formState.description || description}
                  handleChange={handleChange}
                  error={formState.description}
                  fullWidth
                  multiline
                  maxRows={5}
                  style={{ marginRight: 0, marginLeft: 0 }}
                />
              </Card>
            </div>
            <div className={classes.section}>
              <Typography className={classes.banner}>LOGO</Typography>
              <Card className={classes.card}>
                <logos.components.logoUpload
                  organisationId={props.organisationId}
                  organisationShortName={formState.shortName || shortName}
                  description="Drag and Drop .PNG or .JPEG files"
                ></logos.components.logoUpload>
              </Card>
            </div>
            {isSuperAdmin && (
              <div className={classes.section}>
                <Typography className={classes.banner}>TRADING PARTNERS</Typography>
                <Card className={classes.card}>
                  <div style={{ display: 'flex' }}>
                    <ClearButton
                      title="Select All"
                      onClick={() => handleMultiCheck('all', 'tradingPartnersIds')}
                      icon="check_box"
                    ></ClearButton>
                    <ClearButton
                      title="Select None"
                      onClick={() => handleMultiCheck('none', 'tradingPartnersIds')}
                      icon="check_box_outline_blank"
                    ></ClearButton>
                    <ClearButton
                      title="Select Buyers"
                      onClick={() => handleMultiCheck('buyer', 'tradingPartnersIds')}
                      icon="check"
                    ></ClearButton>
                    <ClearButton
                      title="Select Sellers"
                      onClick={() => handleMultiCheck('seller', 'tradingPartnersIds')}
                      icon="check"
                    ></ClearButton>
                  </div>
                  {organisations.map((org) => {
                    const id = org.id;
                    const labelId = `checkbox-list-label-${id}`;
                    const labelId2 = `checkbox-list-label2-${id}`;
                    const labelId3 = `checkbox-list-label3-${id}`;
                    const list = formState.tradingPartnersIds || tradingPartnersIds || [];
                    return (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={handleChecked(id, 'tradingPartnersIds')}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={list.indexOf(org.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId2} primary={`${org.name} (${org.shortName})`} />

                        <ListItemText
                          id={labelId3}
                          primary={org.type}
                          style={{ textAlign: 'right' }}
                        />
                      </ListItem>
                    );
                  })}
                </Card>
              </div>
            )}

            {isSuperAdmin && (
              <div className={classes.section}>
                <Typography className={classes.banner}>BENFICIARIES</Typography>
                <Card className={classes.card}>
                  <div style={{ display: 'flex' }}>
                    <ClearButton
                      title="Select All"
                      onClick={() => handleMultiCheck('all', 'beneficiariesIds')}
                      icon="check_box"
                    ></ClearButton>
                    <ClearButton
                      title="Select None"
                      onClick={() => handleMultiCheck('none', 'beneficiariesIds')}
                      icon="check_box_outline_blank"
                    ></ClearButton>
                    <ClearButton
                      title="Select Buyers"
                      onClick={() => handleMultiCheck('buyer', 'beneficiariesIds')}
                      icon="check"
                    ></ClearButton>
                    <ClearButton
                      title="Select Sellers"
                      onClick={() => handleMultiCheck('seller', 'beneficiariesIds')}
                      icon="check"
                    ></ClearButton>
                  </div>
                  {organisations.map((org) => {
                    const id = org.id;
                    const labelId = `checkbox-list-label-${id}`;
                    const labelId2 = `checkbox-list-label2-${id}`;
                    const labelId3 = `checkbox-list-label3-${id}`;
                    const list = formState.beneficiariesIds || beneficiariesIds || [];
                    return (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={handleChecked(id, 'beneficiariesIds')}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={list.indexOf(org.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId2} primary={`${org.name} (${org.shortName})`} />

                        <ListItemText
                          id={labelId3}
                          primary={org.type}
                          style={{ textAlign: 'right' }}
                        />
                      </ListItem>
                    );
                  })}
                </Card>
              </div>
            )}

            {isSuperAdmin && (
              <div className={classes.section}>
                <Typography className={classes.banner}>BROKERS</Typography>
                <Card className={classes.card}>
                  {/*    <div style={{ display: 'flex' }}>
                  <ClearButton
                    title="Select All"
                    onClick={() => handleMultiCheck('all', 'brokers')}
                    icon="check_box"
                  ></ClearButton>
                  <ClearButton
                    title="Select None"
                    onClick={() => handleMultiCheck('none', 'brokers')}
                    icon="check_box_outline_blank"
                  ></ClearButton>
                  <ClearButton
                    title="Select Buyers"
                    onClick={() => handleMultiCheck('buyer', 'brokers')}
                    icon="check"
                  ></ClearButton>
                  <ClearButton
                    title="Select Sellers"
                    onClick={() => handleMultiCheck('seller', 'brokers')}
                    icon="check"
                  ></ClearButton>
                </div> */}
                  {organisations.map((org) => {
                    const id = org.id;
                    const labelId = `checkbox-list-label-${id}`;
                    const labelId2 = `checkbox-list-label2-${id}`;
                    const labelId3 = `checkbox-list-label3-${id}`;
                    const list = formState.brokersIds || brokersIds || [];
                    return (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={handleChecked(id, 'brokersIds')}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={list.indexOf(org.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId2} primary={`${org.name} (${org.shortName})`} />

                        <ListItemText
                          id={labelId3}
                          primary={org.type}
                          style={{ textAlign: 'right' }}
                        />
                      </ListItem>
                    );
                  })}
                </Card>
              </div>
            )}
          </div>
          <ToolbarBottom>
            <>
              <CancelButton onClick={props.closeForm} />
              <ConfirmButton onClick={send} title="Update" />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    </div>
  );
};

UpdateOrganisation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    myOrganisation: getMyOrganisation(state),
    brokersArray: getBrokers(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    brandsAndFactories: getBrandsAndFactories(state),
    isSuperAdmin: auth.selectors.isSuperAdmin(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    update: (payload) => {
      dispatch(updateAdminItem(UPDATE_ADMIN_ITEM, payload));
    },
    create: (payload) => {
      dispatch(createAdminItem(CREATE_ADMIN_ITEM, payload));
    },
    updateBrokers: (payload) => {
      dispatch(updateAdminItem(UPDATE_BROKERS, payload));
    },
    createBrokers: (payload) => {
      dispatch(updateAdminItem(CREATE_BROKERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateOrganisation));
