import { createSelector } from 'reselect';
import constants from '../constants';
import auth from '../../auth';
import organisations from '../../organisations';
import users from '../../users';
import trades from '../../trades';
import orders from '../../orders';
import rfqsModule from '../../rfqs';

import { isObject } from '../../../commons/utils/functions';
const NAME = constants.NAME;

const getAllObjectProp = (state) => {
  const items = state[NAME].items;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => {
  return items;
});
const getPresetsProp = (state) => {
  const items = state[NAME].presets;
  return items;
};
export const getPresets = createSelector(getPresetsProp, (items) => items);

const getSavedPresetsProp = (state) => {
  const items = state[NAME].presets;
  return items.saved;
};
export const getSavedPresets = createSelector(getSavedPresetsProp, (item) => item);

const getDefaultPresetsProp = createSelector(getPresets, (item) => item.default);
export const getDefaultPresets = createSelector(getDefaultPresetsProp, (item) => item);

const getBlotterProp = (state, id) => {
  const items = state[NAME].items;
  const item = items[id] || {};
  return item;
};

export const getBlotter = createSelector(getBlotterProp, (item) => item);

const getBlotterColumnsProp = (state) => state[NAME].columnDefs;
export const getBlotterColumns = createSelector(getBlotterColumnsProp, (items) => items);

const getBlotterTabValueProp = createSelector(getBlotter, (item) => item.tabValue || 0);
export const getBlotterTabValue = createSelector(getBlotterTabValueProp, (item) => item);

const getBlotterColumnStateProp = createSelector(getBlotter, (item) => item.columnState || []);
export const getBlotterColumnState = createSelector(getBlotterColumnStateProp, (item) => item);

const getBlotterFilterStateProp = createSelector(getBlotter, (item) => item.filterState || {});
export const getBlotterFilterState = createSelector(getBlotterFilterStateProp, (item) => item);

const getBlotterSortStateProp = createSelector(getBlotter, (item) => item.sortState || []);
export const getBlotterSortState = createSelector(getBlotterSortStateProp, (item) => item);

export const getBlotterAllOrders = createSelector(
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,
  orders.selectors.getAllObject,
  orders.selectors.getIds,
  rfqsModule.selectors.getAllObject,
  (user, users, organisations, byId, ids, rfqsById) => {
    const orders = {
      my: [],
      others: [],
    };

    const existUserOrganisation =
      typeof user.organisationId !== 'undefined' && user.organisationId !== null;

    ids.forEach((id) => {
      const order = byId[id];
      //const data = byId[id].assets[0];

      order.assets.forEach((data) => {
        const organisation =
          typeof organisations[order.organisationId] !== 'undefined'
            ? organisations[order.organisationId].shortName
            : '';
        const organisationId =
          typeof organisations[order.organisationId] !== 'undefined'
            ? organisations[order.organisationId].id
            : '';
        const toUser =
          typeof users[order.userId] !== 'undefined'
            ? `${users[order.userId].firstName} ${users[order.userId].lastName}`
            : '';
        const orderGroupId = byId[id].assets.length > 1 ? id : null;

        const rfqId = order.rfqId;

        let rfqType = 'SPOT';
        if (rfqId !== null) {
          if (isObject(rfqsById[rfqId])) {
            //const rfq = rfqsById[rfqId];
            /* const extensions = JSON.parse(rfq.extensions);
            if (isObject(extensions)) {
              if (extensions.rfqType) {
                rfqType = extensions.rfqType;
              }
            } */
          }
        }
        const formatedOrder = {
          id,
          contract: data.contract,
          status: order.status,
          direction: data.direction,
          volume: data.volume,
          originalVolume: data.originalVolume,
          price: data.price,
          recipientList:
            order.visibility === 'SELECTED_ORGANISATIONS'
              ? order.selectedOrganisationsIds.map((orgId) => organisations[orgId])
              : [],
          textMessage: order.textMessage,
          organisation,
          organisationId,
          rfqId: order.rfqId,
          rfqType,
          user: toUser,
          orderGroupId,
          // autoClose: order.timeInForce !== null ? 'Yes' : 'No',
          // actingUser: 'actingUser',
          // actingUserName: 'actingUserName',
          // actingUserId: 'actingUserId',
          visibility: order.visibility,
          timeInForce: order.timeInForce,
          createdTime: order.createdTime,
          updatedTime: order.updatedTime,
          tradeId: null,
        };

        if (existUserOrganisation && user.organisationId === formatedOrder.organisationId) {
          orders.my.push(formatedOrder);
        } else {
          orders.others.push(formatedOrder);
        }
      });
    });

    return orders;
  },
);

export const getBlotterMyTrades = createSelector(
  trades.selectors.getAll,
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,
  orders.selectors.getAllObject,
  rfqsModule.selectors.getAllObject,
  (trades, user, users, orgs, ordersById, rfqsById) => {
    const myOrgId = user.organisationId;
    const formattedTrades = [];

    const getDirection = (data, trade, user) => {
      const buyBroker = trade.buyBrokerOrganisationId;
      const buyBeneficiary = trade.buyBeneficiaryOrganisationId;

      if (buyBroker === user.organisationId || buyBeneficiary === user.organisationId) {
        return 'BUY';
      } else {
        return 'SELL';
      }
    };

    const getFullOrg = (id) => {
      const organisation = {};
      if (typeof orgs[id] !== 'undefined') {
        organisation.id = id;
        organisation.name = orgs[id].name;
        organisation.shortName = orgs[id].shortName;
      }
      return organisation;
    };

    const getFullUser = (id) => {
      const fulluser = {};
      if (typeof users[id] !== 'undefined') {
        fulluser.id = id;
        fulluser.name = `${users[id].firstName} ${users[id].lastName}`;
      }
      return fulluser;
    };

    const getCounterparty = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId === myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    const getOwn = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId !== myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };
    const getComments = (buyOrgId, buyOrderId, sellOrderId, ownComments) => {
      if (ownComments) {
        if (buyOrgId === myOrgId) {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        } else {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        }
      } else {
        if (buyOrgId === myOrgId) {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        } else {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        }
      }
    };
    const getData = (buyOrgId, ownData, trade) => {
      if (ownData) {
        if (buyOrgId === myOrgId) {
          return trade.buyerData;
        } else {
          return trade.sellerData;
        }
      } else {
        if (buyOrgId === myOrgId) {
          return trade.sellerData;
        } else {
          return trade.buyerData;
        }
      }
    };
    const getRfqType = (rfqId) => {
      let value = 'SPOT';
      if (isObject(rfqsById[rfqId])) {
        const rfq = rfqsById[rfqId];
        if (!rfq.extensions === '') {
          const extensions = JSON.parse(rfq.extensions);
          if (isObject(extensions)) {
            if (extensions.rfqType) {
              value = extensions.rfqType;
            }
          }
        }
      }
      return value;
    };
    const volumePerUnit = (unit, product, cartonNetWeight = null) => {
      let value = 1;
      if (unit === 'BOX') {
        if (product === 'SALMON') {
          value = 20;
        } else {
          value = 22.5;
        }
      }
      switch (cartonNetWeight) {
        case 'fifteen':
          value = 15;
          break;
        case 'twelveAndHalf':
        case 'tweleveAndHalf':
          value = 12.5;
          break;
        case 'twelve':
          value = 12;
          break;
        default:
          value = 1;
      }

      return value;
    };
    Object.values(trades).forEach((trade) => {
      const buyBrokerOrganisation = getFullOrg(trade.buyBrokerOrganisationId);

      const buyBrokerUser = getFullUser(trade.buyBrokerUserId);
      const buyBeneficiaryOrganisation = getFullOrg(trade.buyBeneficiaryOrganisationId);
      const buyBeneficiaryUser = getFullUser(trade.buyBeneficiaryUserId);

      const sellBrokerOrganisation = getFullOrg(trade.sellBrokerOrganisationId);

      const sellBrokerUser = getFullUser(trade.sellBrokerUserId);
      const sellBeneficiaryOrganisation = getFullOrg(trade.sellBeneficiaryOrganisationId);
      const sellBeneficiaryUser = getFullUser(trade.sellBeneficiaryUserId);

      const rfqId = trade.rfqId;
      const rfqComments = isObject(rfqsById[rfqId]) ? rfqsById[rfqId].textMessage : '';
      const rfqType = getRfqType(rfqId);

      trade.assets.forEach((data) => {
        const unit = data.contract.underlying ? data.contract.underlying.unit : 'KG';
        const cartonNetWeight = data.contract.underlying
          ? data.contract.underlying.cartonNetWeight
          : null;
        const product = data.contract.underlying ? data.contract.underlying.product : 'SALMON';
        const volPerUnit = volumePerUnit(unit, product, cartonNetWeight);
        const nominal = parseInt(data.price * data.volume * volPerUnit, 10);
        const cancellationInitiatedByOrgId = trade.cancellationInitiatedByOrgId;
        const cancellationRequestorShortName = cancellationInitiatedByOrgId
          ? orgs[cancellationInitiatedByOrgId]
            ? orgs[cancellationInitiatedByOrgId].shortName
            : ''
          : '';
        const formattedTrade = {
          id: data.id,
          tradeId: trade.id,
          status: trade.status,
          cancellationStatus: trade.cancellationStatus,
          cancellationInitiatedByOrgId: trade.cancellationInitiatedByOrgId,
          cancellationRequestorShortName,
          contract: data.contract,
          direction: getDirection(data, trade, user),
          volume: data.volume,
          price: data.price,

          buyBrokerOrganisationId: buyBrokerOrganisation.id,
          buyBrokerOrganisationName: buyBrokerOrganisation.name,
          buyBrokerOrganisationShortName: buyBrokerOrganisation.shortName,

          buyBeneficiaryOrganisationId: buyBeneficiaryOrganisation.id,
          buyBeneficiaryOrganisationName: buyBeneficiaryOrganisation.name,
          buyBeneficiaryOrganisationShortName: buyBeneficiaryOrganisation.shortName,

          sellBrokerOrganisationId: sellBrokerOrganisation.id,
          sellBrokerOrganisationName: sellBrokerOrganisation.name,
          sellBrokerOrganisationShortName: sellBrokerOrganisation.shortName,

          sellBeneficiaryOrganisationId: sellBeneficiaryOrganisation.id,
          sellBeneficiaryOrganisationName: sellBeneficiaryOrganisation.name,
          sellBeneficiaryOrganisationShortName: sellBeneficiaryOrganisation.shortName,

          buyBrokerUserId: buyBrokerUser.id,
          buyBrokerUserName: buyBrokerUser.name,

          buyBeneficiaryUserId: buyBeneficiaryUser.id,
          buyBeneficiaryUserName: buyBeneficiaryUser.name,

          sellBrokerUserId: sellBrokerUser.id,
          sellBrokerUserName: sellBrokerUser.name,

          sellBeneficiaryUserId: sellBeneficiaryUser.id,
          sellBeneficiaryUserName: sellBeneficiaryUser.name,

          buyerData: trade.buyerData,
          sellerData: trade.sellerData,

          tradeGroupId: trade.id,
          rfqId: trade.rfqId,
          rfqType,
          createdTime: trade.createdTime,
          updatedTime: trade.updatedTime,
          nominal,

          rfqComments,
        };
        formattedTrades.push(formattedTrade);
      });
    });

    return formattedTrades;

    /*  return formattedTrades.filter(
      ({ buyOrganisation, sellOrganisation }) =>
        buyOrganisation === myOrgId || sellOrganisation === myOrgId,
    ); */
  },
);

const getThemesProp = (state) => state.themes;
export const getThemes = createSelector(getThemesProp, (themes) => themes);

const getThemeProp = (state) => state.themes.theme;
export const getTheme = createSelector(getThemeProp, (theme) => theme);

const getThemeTypeProp = (state) => state.themes.type;
export const getThemeType = createSelector(getThemeTypeProp, (type) => type);

const getBlotterPresetsProp = (state) => state[NAME].presets;
export const getBlotterPresets = createSelector(getBlotterPresetsProp, (item) => item);

const getSavedBlotterPresetsProp = (state) => state[NAME].presets.saved;
export const getSavedBlotterPresets = createSelector(getSavedBlotterPresetsProp, (item) => item);

const getInitialBlotterPresetsProp = createSelector(getBlotterPresets, (item) => item.initial);
export const getInitialBlotterPresets = createSelector(
  getInitialBlotterPresetsProp,
  (item) => item,
);

const getDefaultBlotterPresetsProp = createSelector(getBlotterPresets, (item) => item.default);
export const getDefaultBlotterPresets = createSelector(
  getDefaultBlotterPresetsProp,
  (items) => items,
);

export const getUserFullInfo = createSelector(
  auth.selectors.getServerUser,
  auth.selectors.isConnected,
  organisations.selectors.getAllObject,
  (user, connected, orgs) => {
    const { id: userId, firstName, lastName, organisationId } = user;

    let organisation = null;

    if (Object.prototype.toString.call(orgs[organisationId]) === '[object Object]') {
      const { id, name, shortName, tradingPartnersIds } = orgs[organisationId];

      organisation = {
        id,
        name,
        shortName,
        fullName: name,
        tradingPartnersIds,
      };
    } else {
      organisation = {};
    }

    const userInfo = {
      userId,
      userName: `${firstName} ${lastName}`,
      fullName: `${firstName} ${lastName}`,
      connected,
      organisation,
    };

    return userInfo;
  },
);
