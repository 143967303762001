export const NAME = 'market';

export const INIT_MARKET = 'market/init';
export const INIT_MARKET_SUCCESS = 'market/init/success';
export const INIT_MARKET_ERROR = 'market/init/error';

export const DESTROY_MARKET = 'market/destroy';
export const DESTROY_MARKET_SUCCESS = 'market/destroy/success';
export const DESTROY_MARKET_ERROR = 'market/destroy/error';

export const UPDATE_MARKET = 'market/update';
export const UPDATE_MARKET_SUCCESS = 'market/update/success';
export const UPDATE_MARKET_ERROR = 'market/update/error';

export const GET_MARKET = 'market/get';
export const GET_MARKET_SUCCESS = 'market/get/success';
export const GET_MARKET_ERROR = 'market/get/error';

export const RESET_MARKET = 'market/reset';
export const RESET_MARKET_SUCCESS = 'market/reset/success';
export const RESET_MARKET_ERROR = 'market/reset/error';
