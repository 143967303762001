import {
  DESTROY_CHART_SUCCESS,
  UPDATE_CHART_SUCCESS,
  INIT_CHART_SUCCESS,
  DESTROY_TABLE_SUCCESS,
  UPDATE_TABLE_SUCCESS,
  INIT_TABLE_SUCCESS,
} from '../constants';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  config: {},
  default: {
    contractName: 'nasdaq',
    nasdaq: [
      {
        Yr: 2019,
        Week: 1,
        '3-4KG': 1.0,
        '4-5KG': 1.0,
        '5-6KG': 1.0,
      },
    ],
    lfex: [
      {
        Yr: 2019,
        Week: 1,
        Date: '1/1/2019',
        '3-4KG': 1.0,
        '4-5KG': 1.0,
        '5-6KG': 1.0,
        Index: 1.0,
      },
    ],
    fishpool: [
      {
        Yr: 2019,
        Week: 1,
        Date: '1/1/2019',
        'Fish Pool NOK': 1.0,
        'SSB Oslo': 1.0,
      },
    ],
    fishpool_forwards: [
      {
        Yr: 2019,
        Week: 1,
        Month: '2021_01',
        NOK: 1.0,
      },
    ],
    eurnok: [
      {
        Yr: 2019,
        Week: 1,
        'EUR/NOK': 1.0,
      },
    ],
    currency: 'EUR',
  },
  items: {},
  tables: {},
  status: null,
  error: null,
  receivedAt: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case INIT_CHART_SUCCESS:
    case UPDATE_CHART_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInitChart, items, config, ...otherNextInitChartProps } = action.payload;

      if (config) {
        nextState.config = {
          ...nextState.config,
          ...config,
        };
      }

      if (typeof idInitChart !== 'undefined') {
        let newItem = null;

        if (nextState.items[idInitChart]) {
          newItem = {
            [idInitChart]: {
              ...nextState.items[idInitChart],
              ...otherNextInitChartProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        } else {
          newItem = {
            [idInitChart]: {
              id: idInitChart,
              ...otherNextInitChartProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        }

        nextState.items = {
          ...nextState.items,
          ...newItem,
        };

        nextState.status = 'success';
        nextState.receivedAt = action.meta.receivedAt;
      }
      return nextState;
    case DESTROY_CHART_SUCCESS:
      nextState = {
        ...state,
      };

      const { id } = action.payload;

      if (typeof id !== 'undefined') {
        if (nextState.items[id]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[id];
        }

        if (Object.keys(nextState.items).length === 0) {
          nextState.config = {};
          nextState.status = null;
          nextState.error = null;
          nextState.receivedAt = null;
        }
      }
      return nextState;

    case INIT_TABLE_SUCCESS:
    case UPDATE_TABLE_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInitTable, ...otherNextInitTableProps } = action.payload;

      if (typeof idInitTable !== 'undefined') {
        let newItem = null;

        if (nextState.tables[idInitTable]) {
          newItem = {
            [idInitTable]: {
              ...nextState.tables[idInitTable],
              ...otherNextInitTableProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        } else {
          newItem = {
            [idInitTable]: {
              id: idInitChart,
              ...otherNextInitTableProps,
              status: 'success',
              receivedAt: action.meta.receivedAt,
            },
          };
        }

        nextState.tables = {
          ...nextState.tables,
          ...newItem,
        };

        nextState.status = 'success';
        nextState.receivedAt = action.meta.receivedAt;
      }
      return nextState;
    case DESTROY_TABLE_SUCCESS:
      nextState = {
        ...state,
      };

      const { id2 } = action.payload;

      if (typeof id2 !== 'undefined') {
        if (nextState.tables[id2]) {
          nextState.tables = {
            ...nextState.tables,
          };

          delete nextState.tables[id2];
        }
      }
      return nextState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
