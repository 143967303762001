import React, { useState } from 'react';

import { connect } from 'react-redux';
import { SIGN_IN_PASSWORD_RESET_SEND, BACK_TO_SIGN_IN } from '../../../modules/auth/constants';
import { createAction } from '../../../commons/actions';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextBox from '../textBox';
import Button from '../button';
import { getStatus, getError } from '../../../modules/auth/selectors';
import clsx from 'clsx';
const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  forgotPasswordButton: {
    color: 'var(--alternate-dark-color)',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessageContainer: {
    height: '50px',
    margin: '8px auto',
    maxWidth: '216px',
  },
  errorHidden: {
    display: 'none',
  },
  errorVisible: {
    display: 'inline',
    overflowWrap: 'normal',
    fontFamily: 'sans-serif',
    color: 'var(--text-error-color)',
  },
});

const RequireNewPasswordValidation = (props) => {
  const { classes } = props;
  const [username, setUsername] = useState(null);
  const [focus, setFocus] = useState(null);
  const [inputError, setInputError] = useState(null);

  const handleSubmit = () => {
    const payload = { username };
    props.sendValidationCode(payload);
  };

  const handleChange = (id) => (event) => {
    setUsername(event.target.value);
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.loginBackground}>
          <Typography>Enter Email to receive validation code by email</Typography>
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <TextBox
              icon="person"
              id="username"
              placeholder="Email"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              autocomplete="new-password"
            ></TextBox>
            <Button onClick={handleSubmit} title="SUBMIT" />
            <button className={classes.forgotPasswordButton} onClick={props.signIn}>
              Back to Login
            </button>
            <div className={classes.errorMessageContainer}>
              <div
                className={clsx({
                  [classes.errorVisible]: true,
                  [classes.errorHidden]: inputError,
                })}
              >
                {inputError ? inputError : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    status: getStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendValidationCode: (payload) => dispatch(createAction(SIGN_IN_PASSWORD_RESET_SEND, payload)),
  signIn: () => dispatch(createAction(BACK_TO_SIGN_IN)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RequireNewPasswordValidation),
);
