import * as actionTypes from './actionTypes';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
//import api from './api';
import Inventory from './components/Inventory';

const reducers = {
  reducer,
};

const components = {
  Inventory,
};

export default { actionTypes, constants, reducers, sagas, selectors, components };
