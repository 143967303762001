import React, { useMemo } from 'react';
import { useLanguage } from '../../contexts/LanguageProvider';

const translationModel = require('../../../modules/schemas/models/translation_model');

const CustomText = (props) => {
  const { language } = useLanguage();
  const translateValue = (value) => {
    let returnValue = value;
    if (translationModel) {
      if (translationModel.fields) {
        const field = translationModel.fields.find((f) => f.enum === value);

        if (field) {
          returnValue = field[language];
        }
      }
    }
    returnValue = props.upperCase && returnValue ? returnValue.toUpperCase() : returnValue;
    return returnValue;
  };
  let value = useMemo(() => translateValue(props.value), [props.value, language]) || '';

  return value;
};

export default CustomText;
