import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

const styles = (theme) => ({
  div: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2px',
  },
  icon: {
    color: theme.palette.text.disabled,
    fontSize: '24px',
    height: '.5em',
    lineHeight: '.5em',
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
});

const UpDown = (props) => {
  const { classes } = props;
  const name = props.name.toLowerCase();

  return (
    <div className={classes.div}>
      <Icon className={classes.icon} onClick={() => props.onClick(1, name)}>
        arrow_drop_up
      </Icon>
      <Icon className={classes.icon} onClick={() => props.onClick(-1, name)}>
        arrow_drop_down
      </Icon>
    </div>
  );
};

UpDown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpDown);
