import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, Icon } from '@material-ui/core';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import PopUp from '../../../commons/components/popUp';
import { CancelButton, ConfirmButton, ClearButton } from '../../../commons/components/buttons';
import CustomText from '../../../commons/components/formFields/customText';
import MultipleSelectField from '../../../commons/components/formFields/multipleSelectField';
import { NOTIFICATION_SOURCES } from '../../../commons/models/constants';
import { notificationSettingsOpen } from '../actions';
import { NOTIFICATION_SETTINGS_OPEN } from '../constants';
import { isNotificationSettingsOpen, getNotificationSettings } from '../selectors';
import users from '../../users';
import auth from '../../auth';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  listContainer: {
    overflowY: 'scroll',
    maxHeight: '60vh',
    flex: 1,
    backgroundColor: theme.palette.background.primary,
  },
  toolbarContainer: { flex: 1 },
  listItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  list: { flex: 1 },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.toolbarBottom,
  },
});

const values = Object.values(NOTIFICATION_SOURCES);

class NotificationSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { settings } = this.props;

    if (prevProps.settings !== settings || !Object.keys(this.state).length) {
      const obj = {};
      settings.forEach((setting) => {
        obj[setting.id] = setting.value;
      });

      this.setState({ ...obj });
    }
  }

  fields = () => {
    return Object.keys(this.state);
  };

  apply = () => {
    const notificationsSettings = [];
    const fields = this.fields();

    fields.forEach((field) => {
      const setting = {
        notificationTypeId: field,
        channels: {},
      };
      Object.values(NOTIFICATION_SOURCES).forEach((item) => {
        setting.channels[item] = this.state[field].includes(item);
      });

      notificationsSettings.push(setting);
    });
    const { userId } = this.props;
    const body = { notificationsSettings };
    const payload = { body, userId };
    this.props.updateUser(payload);
    this.props.onClose();
  };

  handleChange = (name) => (event) => {
    const value = event.target.value.sort();
    this.setState({ [name]: value });
  };

  selectAll = () => {
    const fields = this.fields();
    fields.forEach((field) => {
      this.setState({ [field]: values });
    });
  };

  deselectAll = () => {
    const fields = this.fields();
    fields.forEach((field) => {
      this.setState({ [field]: [] });
    });
  };

  render() {
    const { classes, open } = this.props;
    const fields = this.fields();

    return (
      <PopUp
        open={open}
        title={<CustomText value="notificationSettings" upperCase />}
        onClose={this.props.onClose}
      >
        <div className={classes.container}>
          <div className={classes.buttonRow}>
            <ClearButton title={'selectAll'} onClick={this.selectAll} icon="add" />
            <ClearButton title={'deselectAll'} onClick={this.deselectAll} icon="remove" />
          </div>
          <div className={classes.listContainer}>
            <List open classes={{ root: classes.list }}>
              {fields.map((field) => (
                <ListItem key={field} button classes={{ root: classes.listItem }}>
                  <MultipleSelectField
                    accessor={field}
                    displayName={field}
                    value={this.state[field]}
                    values={values}
                    handleChange={this.handleChange}
                    fullWidth
                    disableUnderline
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <div className={classes.toolbarContainer}>
            <ToolbarBottom>
              <>
                <CancelButton title={'cancel'} onClick={this.props.onClose} />
                <ConfirmButton title={'apply'} onClick={this.apply} />
              </>
            </ToolbarBottom>
          </div>
        </div>
      </PopUp>
    );
  }
}
NotificationSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  settings: PropTypes.array,
  userId: PropTypes.string,
  onClose: PropTypes.func,
  updateUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    open: isNotificationSettingsOpen(state),
    settings: getNotificationSettings(state),
    userId: auth.selectors.getUserId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(notificationSettingsOpen(NOTIFICATION_SETTINGS_OPEN, { open: false }));
    },
    updateUser: (payload) => {
      dispatch(users.actions.updateUser(users.constants.UPDATE_USER, payload));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationSettings));
