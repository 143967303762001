export const NAME = 'calculators';

export const INIT_CALCULATORS = 'calculators/init';
export const INIT_CALCULATORS_SUCCESS = 'calculators/init/success';
export const INIT_CALCULATORS_ERROR = 'calculators/init/error';

export const INIT_CALCULATOR = 'calculator/init';
export const INIT_CALCULATOR_SUCCESS = 'calculator/init/success';
export const INIT_CALCULATOR_ERROR = 'calculator/init/error';

export const DESTROY_CALCULATOR = 'calculator/destroy';
export const DESTROY_CALCULATOR_SUCCESS = 'calculator/destroy/success';
export const DESTROY_CALCULATOR_ERROR = 'calculator/destroy/error';

export const INIT_CALCULATORS_SETTINGS = 'calculators/settings/init';
export const INIT_CALCULATORS_SETTINGS_SUCCESS = 'calculators/settings/init/success';
export const INIT_CALCULATORS_SETTINGS_ERROR = 'calculators/settings/init/error';

export const UPDATE_CALCULATORS = 'calculators/update';
export const UPDATE_CALCULATORS_SUCCESS = 'calculators/update/success';
export const UPDATE_CALCULATORS_ERROR = 'calculators/update/error';

export const RESET_CALCULATORS = 'calculators/reset';

export const UPDATE_CALCULATOR = 'calculator/update';
export const UPDATE_CALCULATOR_SUCCESS = 'calculator/update/success';
export const UPDATE_CALCULATOR_ERROR = 'calculator/update/error';

export const SAVE_CALCULATOR = 'calculators/save';
export const SAVE_CALCULATOR_SUCCESS = 'calculators/save/success';
export const SAVE_CALCULATOR_ERROR = 'calculators/save/error';

export const CALCULATOR_MANAGER = 'calculator/manager';
export const CALCULATOR_MANAGER_SUCCESS = 'calculator/manager/success';
export const CALCULATOR_MANAGER_ERROR = 'calculator/manager/error';

export const CHANGE_STATE_CALCULATORS = 'calculators/state/change';
