import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import api from './api';

import TradingPartnersList from './components/TradingPartnersList';
import NotificationSettings from './components/NotificationSettings';
import ManageRfqPresets from './components/ManageRfqPresets';
import ManageBlotterPresets from './components/ManageBlotterPresets';
const components = {
  TradingPartnersList,
  NotificationSettings,
  ManageRfqPresets,
  ManageBlotterPresets,
};

const reducers = {
  reducer,
};

export default { actions, constants, reducers, sagas, selectors, api, components };
