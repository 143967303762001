import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormControlLabel, FormControl, Checkbox } from '@material-ui/core';
import CustomText from './customText';

const styles = (theme) => ({
  textField: {},
  formControlLabel: {
    margin: theme.spacing(2),
    marginTop: 0,
    color: theme.palette.text.primary,
  },
  iconButton: {
    paddingLeft: '0px',
    paddingRight: '16px',
  },
});

const CheckboxField = (props) => {
  const { classes, accessor, displayName, value } = props;

  return (
    <FormControl
      className={classes.textField}
      fullWidth={props.fullWidth}
      style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
    >
      <FormControlLabel
        className={classes.formControlLabel}
        label={<CustomText value={displayName} />}
        control={
          <Checkbox
            classes={{ root: classes.iconButton }}
            checked={value}
            onChange={props.handleChecked(accessor)}
          />
        }
      />
    </FormControl>
  );
};

CheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxField);
