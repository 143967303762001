import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { FormControl, MenuItem, InputLabel, Select, Checkbox, Chip } from '@material-ui/core';

import { textField } from './formFieldStyles';
import CustomText from './customText';
const styles = (theme) => ({
  textField: textField(theme),

  any: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.primary,
  },
  option: {},
  'label + .MuiInput-formControl': {
    marginTop: '32px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px 2px',
    fontSize: theme.fontSize.xs,
    // padding: "0px 2px",
    // height: "auto"
  },
});

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MAX_ITEMS = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * MAX_ITEMS + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectFieldFilled = (props) => {
  const {
    classes,
    accessor,
    displayName,
    value,
    values,
    defaultValue,
    allFieldValues = {},
  } = props;
  if (props.any) {
    const index = values.indexOf('ANY');
    if (index === -1) {
      values.splice(0, 0, 'ANY');
    }
  }

  let selectedValues = [];

  if (Array.isArray(value) && value.length > 0) {
    selectedValues = value;
  } else if (Array.isArray(defaultValue) && defaultValue.length > 0) {
    selectedValues = defaultValue;
  }

  return (
    <FormControl
      className={classes.textField}
      fullWidth={props.fullWidth}
      style={props.style}
      size="small"
    >
      <InputLabel htmlFor={accessor}>
        <CustomText value={accessor} />
      </InputLabel>
      <Select
        multiple
        variant="filled"
        value={selectedValues}
        onChange={props.handleChange(accessor)}
        style={props.fullWidth ? {} : { width: props.width ? props.width : '250px' }}
        MenuProps={MenuProps}
        disableUnderline={props.disableUnderline}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selectedValues.map((val) => {
              return (
                <Chip
                  key={val}
                  label={<CustomText value={val} upperCase />}
                  className={classes.chip}
                />
              );
            })}
          </div>
        )}
      >
        {values.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option}
              className={option === 'ANY' ? classes.any : classes.options}
            >
              <Checkbox checked={[...selectedValues].indexOf(option) > -1} />
              <CustomText value={option} upperCase />
            </MenuItem>
          );
        })}
      </Select>

      {/* <FormHelperText>{errorRecipientsMessage}</FormHelperText> */}
    </FormControl>
  );
};

MultipleSelectFieldFilled.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultipleSelectFieldFilled);
