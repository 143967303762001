import * as actionTypes from '../actionTypes';

const initialState = {
  orderModel: {},
  orderAssetsContract: {},
  orderContract: {},
  orderAssetsContractUnderlying: {},
  rfqModel: {},
  rfqAssetsContract: {},
  rfqContract: {},
  rfqContractUnderlying: {},
  userDetails: {},
  organisationDetails: {},
  translationModel: {},
  allFieldValues: {},
  language: {
    name: 'GB',
    fullName: 'United Kingdom',
    language: 'English',
    langaugeIso: 'EN',
  },
  languageString: null,
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case actionTypes.INIT_SCHEMAS:
      nextState = {
        ...state,
        status: 'pending',
      };
      return nextState;

    case actionTypes.INIT_SCHEMAS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.INIT_SCHEMAS_ERROR:
      nextState = {
        ...state,
        status: 'error',
        error: action.payload,
      };
      return nextState;

    case actionTypes.CREATE_FIELD_VALUE:
      nextState = {
        ...state,
        status: 'pending',
      };
      return nextState;

    case actionTypes.CREATE_FIELD_VALUE_SUCCESS:
      let { type, item } = action.payload;
      let nextOrganisationDetails = { ...state.organisationDetails };
      let index = nextOrganisationDetails.fields.findIndex((f) => f.name === type);

      if (index > -1) {
        nextOrganisationDetails.fields[index].values.push(item);
      }

      return {
        ...state,
        nextOrganisationDetails,
      };

    case actionTypes.CREATE_FIELD_VALUE_ERROR:
      nextState = {
        ...state,
        status: 'error',
        error: action.payload,
      };
      return nextState;

    case actionTypes.DELETE_FIELD_VALUE:
      nextState = {
        ...state,
        status: 'pending',
      };
      return nextState;

    case actionTypes.DELETE_FIELD_VALUE_SUCCESS:
      type = action.payload.type;
      item = action.payload.item;

      nextOrganisationDetails = { ...state.organisationDetails };
      let indexField = nextOrganisationDetails.fields.findIndex((f) => f.name === type);
      if (indexField > -1) {
        let indexValue = nextOrganisationDetails.fields[indexField].values.findIndex(
          (f) => f.name === item,
        );
        if (indexValue > -1) {
          nextOrganisationDetails.fields[indexField].values.splice(indexValue, 1);
        }
      }

      return {
        ...state,
        nextOrganisationDetails,
      };

    case actionTypes.DELETE_FIELD_VALUE_ERROR:
      nextState = {
        ...state,
        status: 'error',
        error: action.payload,
      };
      return nextState;
    case actionTypes.INIT_LANGUAGE:
    case actionTypes.CHANGE_LANGUAGE:
      nextState = {
        ...state,
        status: 'pending',
      };
      return nextState;

    case actionTypes.INIT_LANGUAGE_SUCCESS:
    case actionTypes.CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.INIT_LANGUAGE_ERROR:
    case actionTypes.CHANGE_LANGUAGE_ERROR:
      nextState = {
        ...state,
        status: 'error',
        error: action.payload,
      };
      return nextState;
    default:
      return state;
  }
};

export default reducer;
