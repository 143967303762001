import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

import { directions, status, PRICE_DECIMALS } from '../../../commons/models/constants';
import { getTotals } from '../../../commons/utils/functions';

import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  totalsContainerBuy: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: lightBlue[300],
    //padding: theme.spacing(.5),
    backgroundColor: lightBlue[500],
  },
  totalsContainerSell: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: teal[300],
    //padding: theme.spacing(.5),
    backgroundColor: teal[500],
  },

  totalsContainerCancelled: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: red[300],
    //padding: theme.spacing(.5),
    backgroundColor: red[500],
  },
  totalsContainerSuspended: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: amber[300],
    //padding: theme.spacing(.5),
    backgroundColor: amber[500],
  },
  totalsContainerFilled: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    border: '1px solid',
    borderColor: theme.palette.divider,
    //padding: theme.spacing(.5),
    backgroundColor: 'transparent',
  },

  title: {
    color: theme.palette.text.faded_75_white,
    textAlign: 'center',
  },

  volumeNominal: {
    color: theme.palette.common.white,
    fontSize: theme.fontSize.lg,
    textAlign: 'right',
  },
  units: {
    color: theme.palette.text.faded_75_white,
    fontSize: theme.fontSize.md,
    paddingLeft: theme.spacing(),
  },
  titleFilled: {
    color: theme.palette.text.faded_75,
    textAlign: 'center',
  },

  volumeNominalFilled: {
    color: theme.palette.text.primary,
    fontSize: theme.fontSize.lg,
    textAlign: 'right',
  },
  unitsFilled: {
    color: theme.palette.text.faded_75,
    fontSize: theme.fontSize.md,
    paddingLeft: theme.spacing(),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const TotalsItem = (props) => {
  let titleClass = props.classes.title;
  let volumeClass = props.classes.volumeNominal;
  let unitsClass = props.classes.units;

  if (props.status === status.FILLED || props.noColor) {
    titleClass = props.classes.titleFilled;
    volumeClass = props.classes.volumeNominalFilled;
    unitsClass = props.classes.unitsFilled;
  }

  return (
    <div className={props.classes.gridItem}>
      <div className={props.classes.itemsContainer}>
        <div className={titleClass}>
          <CustomText value={props.title} />:
        </div>
        <div className={props.classes.priceRow}>
          <span className={volumeClass}>{props.value}</span>
          <span className={unitsClass}>
            <CustomText value={props.unit} />
          </span>
        </div>
      </div>
    </div>
  );
};

const TotalsRow = (props) => {
  const { classes } = props;

  const total = props.totals ? props.totals : getTotals(props.rows);
  const direction = props.direction ? props.direction : total.direction;
  let containerStyle =
    direction === directions.BUY ? classes.totalsContainerBuy : classes.totalsContainerSell;
  if (total.status === status.SUSPENDED || total.status === status.IN_PROGRESS) {
    containerStyle = classes.totalsContainerSuspended;
  }
  if (total.status === status.CANCELLED) {
    containerStyle = classes.totalsContainerCancelled;
  }
  if (total.status === status.FILLED || props.noColor) {
    containerStyle = classes.totalsContainerFilled;
  }

  return (
    <div className={containerStyle}>
      <TotalsItem
        title={'totalVolume'}
        value={total.volume}
        unit={'cartons'}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
      <TotalsItem
        title={'avgPrice'}
        value={total.avgPrice.toFixed(PRICE_DECIMALS)}
        unit={total.currency}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
      <TotalsItem
        title={'nominal'}
        value={total.nominal.toFixed(0)}
        unit={total.currency}
        classes={classes}
        status={total.status}
        noColor={props.noColor}
      />
    </div>
  );
};

TotalsRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TotalsRow);
