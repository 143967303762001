import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { red } from '@material-ui/core/colors';
import { Icon, IconButton } from '@material-ui/core';
import CustomText from '../../../commons/components/formFields/customText';

const style = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(),
    borderRadius: '2px',
    overflow: 'hidden',
  },
  itemRight: {
    //flex: 1,
    padding: '0px 2px',
    textAlign: 'right',
  },
  itemCenter: {
    flex: 1,
    padding: '0px 4px',
    textAlign: 'center',
  },
  itemFile: {
    flex: 2,

    padding: '0px 2px',
  },
  typeAndSize: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemFileName: {
    fontSize: '16px',
  },
  itemFileType: {
    color: theme.palette.text.secondary,
  },
  itemFileSize: {
    color: theme.palette.text.disabled,
    padding: '0 4px',
  },

  thumbContainer: {
    height: '50px',
    width: '50px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
  thumb: {
    height: '90px',
  },
  pdfIcon: {
    fontSize: '48px',
    color: theme.palette.red,
  },
  imageIcon: {
    fontSize: '48px',
    color: theme.palette.lightBlue,
  },
  deleteIcon: {
    cursor: 'pointer',
    padding: theme.spacing(),
  },
});

const FileRow2 = (props) => {
  const { classes, file, removeFile, showFile } = props;

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <div className={classes.thumbContainer}>
          {file.filename.includes('.pdf') ? (
            <i className={`fa fa-file-pdf-o ${classes.pdfIcon}`}></i>
          ) : (
            <i className={`fa fa-image ${classes.pdfIcon}`}></i>
          )}
        </div>
      </div>

      <div className={classes.itemCenter}>
        <CustomText value={file.documentType ? file.documentType : file.type} />
      </div>
      <div className={classes.itemFile}>
        <div className={classes.itemFileName}>{file.filename}</div>
        <div className={classes.typeAndSize}>
          <div className={classes.itemFileType}>
            {file.filename.includes('.pdf') ? 'application/pdf' : 'application/image'}{' '}
          </div>
        </div>
      </div>
      <div className={classes.itemRight}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <IconButton onClick={showFile} aria-label="Visibility">
            <Icon>visibility</Icon>
          </IconButton>
          <IconButton onClick={removeFile} aria-label="Remove">
            <Icon>delete</Icon>
          </IconButton>
        </div>
      </div>
    </div>
  );
};
FileRow2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(FileRow2);
