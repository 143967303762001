import * as actionTypes from '../actionTypes';
import { createAction } from '../../../commons/actions';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { isObject } from '../../../commons/utils/functions';
import auth from '../../auth';
import workspaces from '../../workspaces';
import api from '../api';
import * as selectors from '../selectors';
import marketSegments from '../../contracts/models/marketSegments';
import moment from 'moment';

function* init(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    let product = 'spinyLobster';
    let species = 'southernRock';
    let deliveryCountry = 'ANY';
    let dateFilter = 'tradeDate';
    let deliveryDate = moment().format('YYYY-MM-DD');

    if (isObject(widget)) {
      payload.widget = widget;
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'product')
      ) {
        product = widget.savedState.product;
      }
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'species')
      ) {
        species = widget.savedState.species;
      }
      if (
        Object.prototype.hasOwnProperty.call(widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(widget.savedState, 'deliveryCountry')
      ) {
        deliveryCountry = widget.savedState.deliveryCountry;
      }
      if (
        Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'dateFilter')
      ) {
        dateFilter = payload.widget.savedState.dateFilter;
      }
      if (
        Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
        Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'deliveryDate')
      ) {
        deliveryDate = payload.widget.savedState.deliveryDate;
      }
    }
    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      params: {},
    };
    if (dateFilter === 'tradeDate') {
      const tradeDates = getDates();
      data.params.createdDateFrom = tradeDates.createdDateFromUTC;
      data.params.createdDateTo = tradeDates.createdDateToUTC;
    } else {
      const newDates = getDates(-60, 1);
      data.params.createdDateFrom = newDates.createdDateFromUTC;
      data.params.createdDateTo = newDates.createdDateToUTC;
    }

    payload.species = species;
    payload.product = product;
    payload.deliveryCountry = deliveryCountry;
    payload.deliveryDate = deliveryDate;
    payload.dateFilter = dateFilter;

    const orders = yield call(api.get, 'ordersVolume', data);
    const trades = yield call(api.get, 'tradesVolume', data);
    console.log('trades', trades);
    let stats = [];

    if (dateFilter === 'tradeDate') {
      stats = getStats(orders, trades, species, deliveryCountry, null);
    } else {
      stats = getStats(orders, trades, species, deliveryCountry, deliveryDate);
    }
    payload.orders = orders;
    payload.trades = trades;
    payload.stats = stats;

    yield put(createAction(actionTypes.INIT_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    console.log('statistics/init/error:', error);
    yield put(
      createAction(actionTypes.INIT_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

function* destroyStatistics(action) {
  try {
    const payload = {
      id: action.payload.id,
    };

    const meta = { receivedAt: new Date() };

    yield put(createAction(actionTypes.DESTROY_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.DESTROY_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

function* update(action) {
  try {
    const {
      payload: { id, action: actionStats, name, value, tradeDates },
    } = action;

    let payload = {
      id,
    };
    const meta = { receivedAt: new Date() };
    const item = yield select(selectors.getLocalStatisticsById, id);

    const { widget } = item;
    payload.widget = widget;

    if (actionStats === 'update') {
      if (isObject(widget)) {
        const newWidget = {
          ...widget,
          savedState: {
            ...widget.savedState,
            [name]: value,
          },
        };

        yield put(
          workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
        );
        payload.widget = newWidget;
      } else {
        console.log('widget is not object', widget);
      }
    }

    let product = 'spinyLobster';
    let species = 'southernRock';
    let deliveryCountry = 'ANY';
    let dateFilter = 'tradeDate';
    let deliveryDate = action.payload.deliveryDate || moment().format('YYYY-MM-DD');
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'product')
    ) {
      product = payload.widget.savedState.product;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'species')
    ) {
      species = payload.widget.savedState.species;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'deliveryCountry')
    ) {
      deliveryCountry = payload.widget.savedState.deliveryCountry;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'dateFilter')
    ) {
      dateFilter = payload.widget.savedState.dateFilter;
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.widget, 'savedState') &&
      Object.prototype.hasOwnProperty.call(payload.widget.savedState, 'deliveryDate')
    ) {
      deliveryDate = payload.widget.savedState.deliveryDate;
    }

    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      params: {},
    };
    payload.species = species;
    payload.product = product;
    payload.deliveryCountry = deliveryCountry;
    payload.dateFilter = dateFilter;
    payload.deliveryDate = deliveryDate;

    if (dateFilter === 'tradeDate') {
      const newDates = tradeDates ? { ...tradeDates } : getDates();
      data.params.createdDateFrom = newDates.createdDateFromUTC;
      data.params.createdDateTo = newDates.createdDateToUTC;
    } else {
      const newDates = getDates(-60, 1);
      data.params.createdDateFrom = newDates.createdDateFromUTC;
      data.params.createdDateTo = newDates.createdDateToUTC;
    }

    const orders = yield call(api.get, 'ordersVolume', data);
    const trades = yield call(api.get, 'tradesVolume', data);
    console.log('all server orders', orders);
    console.log('all server trades', trades);

    let stats = [];

    if (dateFilter === 'tradeDate') {
      stats = getStats(orders, trades, species, deliveryCountry, null);
    } else {
      stats = getStats(orders, trades, species, deliveryCountry, deliveryDate);
    }
    console.log('fileteredStats', stats);

    payload.orders = orders;
    payload.trades = trades;
    payload.stats = stats;

    yield put(createAction(actionTypes.UPDATE_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    console.error('UPDATE STATISTICS ERROR', error);
    /*  yield put(
      createAction(actionTypes.UPDATE_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    ); */
  }
}

export function* watchInitStatistics() {
  yield takeEvery(actionTypes.INIT_STATISTICS, init);
}

export function* watchDestroyStatistics() {
  yield takeEvery(actionTypes.DESTROY_STATISTICS, destroyStatistics);
}

export function* watchUpdateStatistics() {
  yield takeEvery(actionTypes.UPDATE_STATISTICS, update);
}

function getStats(orders, trades, species, deliveryCountry = 'ANY', deliveryDateString = null) {
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );
  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];
  const stats = [];
  weightClasses.forEach((wgt) => {
    const filteredOrders = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'ACTIVE',
      )
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .filter((o) => {
        if (deliveryDateString === null) {
          return true;
        } else {
          const deliveryDate = moment(o.deliveryDate).format('YYYY-MM-DD');
          return moment(deliveryDateString).format('YYYY-MM-DD') === deliveryDate;
        }
      })
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredIndicative = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'SUSPENDED',
      )
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .filter((o) => {
        if (deliveryDateString === null) {
          return true;
        } else {
          const deliveryDate = moment(o.deliveryDate).add(2, 'hours').format('YYYY-MM-DD');
          return moment(deliveryDateString).format('YYYY-MM-DD') === deliveryDate;
        }
      })
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredTrades = [...trades]
      .filter((t) => t.species === species && t.weightClass === wgt)
      .filter((o) => {
        if (deliveryCountry === 'ANY') {
          return true;
        } else {
          return deliveryCountry === o.deliveryCountry;
        }
      })
      .filter((o) => {
        if (deliveryDateString === null) {
          return true;
        } else {
          const deliveryDate = moment(o.deliveryDate).format('YYYY-MM-DD');
          return moment(deliveryDateString).format('YYYY-MM-DD') === deliveryDate;
        }
      })
      .filter((t) => t.status !== 'CANCELLED')
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const obj = {
      name: wgt,
      Offers: filteredOrders ? filteredOrders : 0,
      Indicative: filteredIndicative ? filteredIndicative : 0,
      Trades: filteredTrades ? filteredTrades : 0,
    };
    stats.push(obj);
  });

  return stats;
}

function getDates(offsetStart = 0, offsetEnd = 1) {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() + offsetStart);
  endDate.setDate(endDate.getDate() + offsetEnd);
  let day = startDate.getDate();
  let month = startDate.getMonth() + 1;
  let year = startDate.getFullYear();

  let day2 = endDate.getDate();
  let month2 = endDate.getMonth() + 1;
  let year2 = endDate.getFullYear();
  const createdDateFrom = `${year}-${month}-${day}`;
  const createdDateTo = `${year2}-${month2}-${day2}`;
  const createdDateFromUTC = moment(createdDateFrom).toISOString();
  const createdDateToUTC = moment(createdDateTo).toISOString();

  return {
    createdDateFrom,
    createdDateTo,
    createdDateFromUTC,
    createdDateToUTC,
  };
}
