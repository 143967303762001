import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Menu, Divider, MenuItem, ListItemIcon, Icon, ListItemText } from '@material-ui/core';

const styles = (theme) => ({
  responseHeaders: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.hint,
    padding: '14px 0px',
  },
  cellHeader: {
    padding: theme.spacing(),
    textAlign: 'center',
    color: theme.palette.text.hint,
    fontWeight: 500,
    fontSize: theme.fontSize.sm,
    borderBottomWidth: '0px',
    flex: 1,
  },
  tableCellContents: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerName: {
    margin: 'auto',
  },
});

const OrderListsMenu = (props) => {
  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.open}
      onClose={props.onClose}
    >
      {props.orderLists.map((w) => (
        <MenuItem key={w.name} onClick={() => props.onClick(w.id)}>
          {w.name}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        onClick={() => {
          props.showEditor(true);
          props.onClose();
        }}
      >
        <ListItemIcon>
          <Icon>edit</Icon>
        </ListItemIcon>
        <ListItemText primary="Lists Editor" />
      </MenuItem>
    </Menu>
  );
};

OrderListsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderListsMenu);
