import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './assets/css/index.css';
import './assets/css/reactGrid/index.css';

import { Provider } from 'react-redux';
import * as serviceWorker from './app/serviceWorker';

import store from './commons/store';

import App from './app';

ReactDOM.render(
  // <React.Profiler id="APP" onRender={onRenderCallback}>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.Profiler>,

  document.getElementById('root'),
);

serviceWorker.unregister();

/* function onRenderCallback(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions, // the Set of interactions belonging to this update
) {} */
