import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';

const styles = (theme) => ({
  buttonContainer: {
    textAlign: 'center',
    margin: '16px 0px',
  },
  loginButton: {
    border: 'none',
    borderRadius: '8px',
    backgroundColor: 'var(--alternate-dark-color)',
    color: 'var(--text-light-color)',
    padding: '16px',
    width: '100%',
    fontSize: '24px',
    letterSpacing: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.7s, color 0.7s',
    '&:hover': {
      backgroundColor: 'var(--alternate-light-color)',
      color: 'var(--text-white-color)',
      transition: 'background-color 0.7s, color 0.7s',
    },
  },
  errorContainer: {
    height: '50px',
    margin: '8px 0px',
    maxWidth: '216px',
  },
  errorText: {
    border: 'none',
    fontSize: '16px',
    color: 'var(--text-dark-color)',
    outline: 'none !important',
    width: '300px',
  },
  icon: {
    color: 'var(--alternate-dark-color)',
  },
});

const Button = (props) => {
  const { classes, title } = props;

  return (
    <div className={classes.buttonContainer}>
      <MuiButton id="login-button" className={classes.loginButton} onClick={props.onClick}>
        {title}
      </MuiButton>
    </div>
  );
};

Button.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Button);
