export const INIT_SCHEMAS = 'schemas/init';
export const INIT_SCHEMAS_SUCCESS = 'schemas/init/success';
export const INIT_SCHEMAS_ERROR = 'schemas/init/error';

export const CREATE_FIELD_VALUE = 'admin/create/field/value';
export const CREATE_FIELD_VALUE_SUCCESS = 'admin/create/field/value/success';
export const CREATE_FIELD_VALUE_ERROR = 'admin/create/field/value/error';

export const DELETE_FIELD_VALUE = 'admin/delete/field/value';
export const DELETE_FIELD_VALUE_SUCCESS = 'admin/delete/field/value/success';
export const DELETE_FIELD_VALUE_ERROR = 'admin/delete/field/value/error';

export const INIT_LANGUAGE = 'language/init';
export const INIT_LANGUAGE_SUCCESS = 'language/init/success';
export const INIT_LANGUAGE_ERROR = 'language/init/error';

export const CHANGE_LANGUAGE = 'language/change';
export const CHANGE_LANGUAGE_SUCCESS = 'language/change/success';
export const CHANGE_LANGUAGE_ERROR = 'language/change/error';
