import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getOrderModelProp = (state) => {
  const items = state[NAME].orderModel;
  return items;
};
const getOrderAssetsContractProp = (state) => {
  const items = state[NAME].orderAssetsContract;
  return items;
};
const getOrderContractProp = (state) => {
  const items = state[NAME].orderContract;
  return items;
};
const getOrderAssetsContractUnderlyingProp = (state) => {
  const items = state[NAME].orderAssetsContractUnderlying;
  return items;
};
const getRfqModelProp = (state) => {
  const items = state[NAME].rfqModel;
  return items;
};
const getRfqAssetsContractProp = (state) => {
  const items = state[NAME].rfqAssetsContract;
  return items;
};
const getRfqContractProp = (state) => {
  const items = state[NAME].rfqContract;
  return items;
};
const getRfqContractUnderlyingProp = (state) => {
  const items = state[NAME].rfqContractUnderlying;
  return items;
};
export const getAllSchemas = createSelector(
  getOrderModelProp,
  getOrderAssetsContractProp,
  getOrderContractProp,
  getOrderAssetsContractUnderlyingProp,
  getRfqModelProp,
  getRfqAssetsContractProp,
  getRfqContractProp,
  getRfqContractUnderlyingProp,
  (
    orderModel,
    orderAssetsContract,
    orderContract,
    orderAssetsContractUnderlying,
    rfqModel,
    rfqAssetsContract,
    rfqContract,
    rfqContractUnderlying,
  ) => {
    return {
      orderModel,
      orderAssetsContract,
      orderContract,
      orderAssetsContractUnderlying,
      rfqModel,
      rfqAssetsContract,
      rfqContract,
      rfqContractUnderlying,
    };
  },
);

const getTranslationModelProp = (state) => {
  const items = state[NAME].translationModel;
  return items;
};

export const getTranslationModel = createSelector(getTranslationModelProp, (items) => items);

const getAllFieldValuesProp = (state) => {
  /* const language = state[NAME].language.language.toLowerCase();
  const model = state[NAME].translationModel;
  let items = {};
  if (model.fields) {
    model.fields.forEach((val) => {
      items[val.enum] = {
        ...val,
        displayName: val[language],
        displayFields: [{ displayName: val[language] }],
      };
    });
  } 

  return items;*/
  return state[NAME].allFieldValues;
};
export const getAllFieldValues = createSelector(getAllFieldValuesProp, (items) => items);

const getLanguageStringProp = (state) => {
  return state[NAME].languageString;
};
export const getLanguageString = createSelector(getLanguageStringProp, (items) => items);

const getBrandsProp = (state) => {
  const model = state[NAME].organisationDetails;
  let items = {};
  if (model.fields) {
    model.fields.forEach((field) => {
      if (field.name === 'brand') {
        field.values.forEach((val) => {
          items[val.name] = val;
        });
      }
    });
  }
  return items;
};
const getFactoriesProp = (state) => {
  const model = state[NAME].organisationDetails;
  let items = {};
  if (model.fields) {
    model.fields.forEach((field) => {
      if (field.name === 'factoryNumber') {
        field.values.forEach((val) => {
          items[val.name] = val;
        });
      }
    });
  }
  return items;
};
export const getBrandsAndFactories = createSelector(
  getBrandsProp,
  getFactoriesProp,
  (brands, factories) => {
    return { brand: brands, factoryNumber: factories };
  },
);

const getLanguageProp = (state) => {
  const items = state[NAME].language;
  return items;
};

export const getLanguage = createSelector(getLanguageProp, (item) => item);
