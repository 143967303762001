export const NAME = 'workspaces';

export const INIT_WORKSPACE = 'workspace/init';
export const INIT_WORKSPACE_SUCCESS = 'workspace/init/success';
export const INIT_WORKSPACE_ERROR = 'workspace/init/error';

export const ACTIVATE_WORKSPACE = 'activate/workspace';
export const ACTIVATE_WORKSPACE_SUCCESS = 'activate/workspace/success';
export const ACTIVATE_WORKSPACE_ERROR = 'activate/workspace/error';

export const RESET_WORKSPACE = 'reset/workspace';
export const RESET_WORKSPACE_SUCCESS = 'reset/workspace/success';
export const RESET_WORKSPACE_ERROR = 'reset/workspace/error';

export const CLEAR_WORKSPACE = 'clear/workspace';
export const CLEAR_WORKSPACE_SUCCESS = 'clear/workspace/success';
export const CLEAR_WORKSPACE_ERROR = 'clear/workspace/error';

export const ADD_COMPONENT = 'add/component';
export const ADD_COMPONENT_SUCCESS = 'add/component/success';
export const ADD_COMPONENT_ERROR = 'add/component/error';

export const UPDATE_WORKSPACE = 'workspace/update';
export const UPDATE_WORKSPACE_SUCCESS = 'workspace/update/success';
export const UPDATE_WORKSPACE_ERROR = 'workspace/update';

export const REMOVE_LAYOUT_WIDGET = 'remove/layout/widget';
export const REMOVE_LAYOUT_WIDGET_SUCCESS = 'remove/layout/widget/success';
export const REMOVE_LAYOUT_WIDGET_ERROR = 'remove/layout/widget/error';

export const REMOVE_WIDGET = 'remove/widget';
export const REMOVE_WIDGET_SUCCESS = 'remove/widget/success';
export const REMOVE_WIDGET_ERROR = 'remove/widget/error';

export const UPDATE_WIDGET = 'update/widget';
export const UPDATE_WIDGET_SUCCESS = 'update/widget/success';
export const UPDATE_WIDGET_ERROR = 'update/widget/error';

export const UPDATE_WORKSPACES = 'workspaces/update';
export const UPDATE_WORKSPACES_SUCCESS = 'workspaces/update/success';
export const UPDATE_WORKSPACES_ERROR = 'workspaces/update';

export const UPDATE_WORKSPACE_LAYOUTS = 'workspace/layouts/update';
export const UPDATE_WORKSPACE_LAYOUTS_SUCCESS = 'workspace/layouts/update/success';
export const UPDATE_WORKSPACE_LAYOUTS_ERROR = 'workspace/layouts/update/error';

export const ADD_LAYOUT_WIDGET = 'add/layout/widget';
export const ADD_LAYOUT_WIDGET_SUCCESS = 'add/layout/widget/success';
export const ADD_LAYOUT_WIDGET_ERROR = 'add/layout/widget/error';

export const UPDATE_MANAGE_WORKSPACES = 'workspace/manage/update';
export const UPDATE_MANAGE_WORKSPACES_SUCCESS = 'workspace/manage/update/success';
export const UPDATE_MANAGE_WORKSPACES_ERROR = 'workspace/manage/update/error';

export const SAVE_WORKSPACES = 'workspace/save';
export const SET_MANAGE_ACTIVE_WORKSPACE = 'workspace/manage/set_active';
export const ADD_MANAGE_WORKSPACE = 'workspace/manage/add';
export const COPY_MANAGE_WORKSPACE = 'workspace/manage/copy';
export const EDIT_MANAGE_WORKSPACE_NAME = 'workspace/manage/edit_name';
export const DELETE_MANAGE_WORKSPACE = 'workspace/manage/delete';
