import { fieldMap, fields } from '../config';

export const createPostTradeFormData = (trade, formType) => {
  const data = trade[formType];
  const nextData = {};
  if (data) {
    Object.keys(data).forEach((d) => {
      if (d !== 'files') {
        nextData[d] = data[d];
      } else {
        const files = data.files || [];
        nextData.files = files;
      }
    });
  } else {
    const formFields = fields[formType];

    Object.keys(formFields).forEach((field) => {
      if (field.type !== 'file') {
        nextData[field] = formFields[field].defaultValue;
      }
    });
  }
  return nextData;
};
