export const NAME = 'contracts';

export const GET_CONTRACTS = 'contracts/get';
export const GET_CONTRACTS_SUCCESS = 'contracts/get/success';
export const GET_CONTRACTS_ERROR = 'contracts/get/error';

export const GET_CONTRACT = 'contract/get';
export const GET_CONTRACT_SUCCESS = 'contract/get/success';
export const GET_CONTRACT_ERROR = 'contract/get/error';

export const RESET_CONTRACTS = 'contracts/reset';
export const RESET_CONTRACTS_SUCCESS = 'contracts/reset/success';
export const RESET_CONTRACTS_ERROR = 'contracts/reset/error';

export const CHANGE_STATE_CONTRACT = 'contract/state/change';
