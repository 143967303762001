import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Login from './Login';
import RequireNewPassword from './RequireNewPassword';
import RequireNewPasswordValidation from './RequireNewPasswordValidation';
import RequestPasswordReset from './RequestPasswordReset';
import { getThemes, getAuthStatus } from '../../commons/selectors';
import * as authConstants from '../../modules/auth/constants';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--background-color)',
    height: '100vh',
  },

  textContainer: {
    border: '1px solid var(--text-border-color)',
    borderRadius: '3px',
    margin: '16px 0px',
    padding: '16px 8px',
    transition: 'border 0.7s',
    '&:hover': {
      border: '1px solid var(--text-border-color-hover)',
      transition: 'border 0.7s',
    },
    '&:focus': {
      border: '1px solid var(--text-border-color-focus)',
      transition: 'border 0.7s',
    },
  },
  companyPart1: {
    color: 'var(--text-light-color)',
    fontSize: '32px',
  },
  companyPart2: {
    color: 'var(--alternate-dark-color)',
    fontSize: '32px',
  },
  loginBackground: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  contactContainer: {
    padding: '8px',
  },
  contactText: {
    color: 'rgba(255,255,255,.5)',
    paddingTop: '4px',
    paddingBottom: '4px',
    textAlign: 'center',
  },
});

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.redirect = {
      whenAuthed: true,
      url: authConstants.CLIENT_PAGE_URL,
    };
  }

  render() {
    const { status } = this.props;
    if (status === authConstants.SIGN_IN_NEW_PASSWORD_REQUIRED) {
      return <RequireNewPassword />;
    }
    if (status === authConstants.SIGN_IN_NEW_PASSWORD_VALIDATION_REQUIRED) {
      return <RequireNewPasswordValidation />;
    }
    if (status === authConstants.SIGN_IN_PASSWORD_RESET_OPEN) {
      return <RequestPasswordReset />;
    }

    return <Login redirect={this.redirect} />;
  }
}

const mapStateToProps = (state) => ({
  theme: getThemes(state),
  status: getAuthStatus(state),
});

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string,
};

export default connect(mapStateToProps)(withStyles(styles)(LoginPage));
