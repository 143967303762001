import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';

import api from '../api';

import { createAction } from '../../../commons/actions';

import auth from '../../auth';
import organisations from '../../organisations';

import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };
    const orgs = yield call(organisations.api.getAll, options);
    const payload = {
      items: [],
    };

    if (orgs) {
      const tasks = [];
      orgs.forEach((org) => {
        const organisationId = org.id;
        tasks.push(call(api.get, organisationId, options));
      });
      if (tasks.length > 0) {
        const logos = yield all(tasks);
        payload.items = logos;
      }
      console.log('logos:saga:payload', payload);
      /* const organisationId = '482103cc-4cf3-4767-9d3f-92e51ae26907';
      const logo = yield call(api.get, organisationId, options);
      console.log('logo', logo);
      payload.items = []; */
    }

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.GET_LOGOS_SUCCESS, payload, meta));
  } catch (error) {
    console.log('LOGO ERROR');
    console.error(error);
    yield put(createAction(actionTypes.GET_LOGOS_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* get(action) {
  try {
    const organisationId = action.payload.organisationId;

    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const logo = yield call(api.get, organisationId, options);

    const payload = {
      items: [logo],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(createAction(actionTypes.GET_LOGO_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);

    yield put(createAction(actionTypes.GET_LOGO_ERROR, { error }));
  }
}

function* update(action) {
  try {
    const organisationId = action.payload.organisationId;

    const token = yield call(auth.selectors.getToken);

    const data = {
      token,
      body: { ...action.payload.body },
    };

    const logo = yield call(api.update, organisationId, data);

    const payload = {
      items: [logo],
    };
    console.log('logos:saga:getresposne;payload:', payload);

    const meta = {
      receivedAt: new Date(),
    };
    action.payload.callback(true);

    yield put(createAction(actionTypes.GET_LOGO_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    action.payload.callback(false);

    yield put(createAction(actionTypes.GET_LOGO_ERROR, { error }));
  }
}

export function* watchGetLogos() {
  yield takeEvery(actionTypes.GET_LOGOS, getAll);
}

export function* watchGetLogo() {
  yield takeEvery(actionTypes.GET_LOGO, get);
}

export function* watchUpdateLogo() {
  yield takeEvery(actionTypes.UPDATE_LOGO, update);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
