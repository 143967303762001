import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CustomText from './formFields/customText';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const SnackbarCustom = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.onClose}
    >
      <Alert
        onClose={props.onClose}
        severity={props.messageType === 'success' ? 'info' : 'error'}
        sx={{ width: '100%' }}
      >
        <CustomText value={props.message} />
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;
