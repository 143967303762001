import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, Snackbar, IconButton, Icon, Button } from '@material-ui/core';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';
import { ConfirmButton } from '../../../../commons/components/buttons';

import OrderManagerHeaders from './OrderManagerHeaders';

import OrderRow from './OrderRow';

import {
  getOrganisations,
  getOrdersFromOrderList,
  getGlobalsFromManagerOrder,
  getOthersFromManagerOrder,
  getTradingPartners
} from '../../selectors';

import { ordersManager as actionOrdersManager } from '../../actions';

import { ORDERS_MANAGER_TABLE_ADD_ROW } from '../../constants';

const styles = (theme) => ({
  tableWrapper: {
    flex: 1,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    overflow: 'auto',
  },
  tableBody: {
    overflowY: 'scroll !important',
    flex: 1,
  },
});

class OrderTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const headers = [
      { name: 'ACTIVE', label: 'ACTIVE', key: 'active', upDown: false },

      { name: 'PRODUCT', label: 'PRODUCT', key: 'product', upDown: false },
      { name: 'DIRECTION', label: 'DIRECTION', key: 'direction', upDown: false },
      { name: 'VOLUME', label: 'VOLUME', key: 'volume', upDown: true },
      { name: 'MID', label: 'TARGET', key: 'mid', upDown: true },
      { name: 'SPREAD', label: 'MARGIN', key: 'spread', upDown: true },
      { name: 'PRICE', label: 'PRICE', key: 'price', upDown: true },

      {
        name: 'VISIBILITY',
        label: 'VISIBILITY',
        key: 'visibility',
        upDown: false,
      },
      {
        name: 'RECIPIENTS',
        label: 'RECIPIENTS',
        key: 'recipients',
        upDown: false,
      },
      { name: 'REMOVE', label: 'REMOVE', key: 'remove', upDown: false },
    ];

    this.state = {
      headers,
    };
  }

  addRow = () => {
    this.props.addRow();
  };

  sendOrders = () => {
    this.props.handleSendOrders();
  };

  handleSnackbarClose = () => {
    this.props.handleSnackbarClose();
  };

  render() {
    const {
      orders,
      handleSpinnerClick,
      handleGlobalsChange,
      handleApplyGlobals,
      handleGlobalsCheckedChange,
      globals,
      organisations,
      classes,
      onProductTableUpdate,
      handleRowDel,
      filterText,
      activeCell,
      textInput,
      localCurrency,
      finalCurrency,
      contract,
      settings,
      tradingPartners
    } = this.props;
    const { headers } = this.state;
    const { snackbarOpen, ordersSent } = settings;
    
    return (
      <div className={classes.tableWrapper}>
        <Table>
          <OrderManagerHeaders
            headers={headers}
            textAlign="left"
            handleSpinnerClick={handleSpinnerClick}
            handleGlobalsChange={handleGlobalsChange}
            handleApplyGlobals={handleApplyGlobals}
            handleGlobalsCheckedChange={handleGlobalsCheckedChange}
            globals={globals}
            organisations={organisations}
            tradingPartners={tradingPartners}
          />
          <TableBody className={classes.tableBody}>
            {orders.map((order) => {
              return (
                <OrderRow
                  id={order.id}
                  headers={headers}
                  onProductTableUpdate={onProductTableUpdate}
                  order={order}
                  handleRowDel={handleRowDel}
                  key={order.id}
                  filterText={filterText}
                  activeCell={activeCell}
                  textInput={textInput}
                  organisations={organisations}
                  localCurrency={localCurrency}
                  finalCurrency={finalCurrency}
                  contract={contract}
                  tradingPartners={tradingPartners}
                />
              );
            })}
          </TableBody>
        </Table>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'snackbar-fab-message-id',
            className: classes.snackbarContent,
          }}
          message={<span id="snackbar-fab-message-id">Orders sent: {ordersSent}</span>}
          action={
            <IconButton
              key="snackbar-close-button"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackbarClose}
            >
              <Icon>close</Icon>
            </IconButton>
          }
          className={classes.snackbar}
        />
        <ToolbarBottom>
          <>
            <Button onClick={this.addRow}>+ Row</Button>
            <ConfirmButton onClick={this.sendOrders} />
          </>
        </ToolbarBottom>
      </div>
    );
  }
}

OrderTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state),
    orders: getOrdersFromOrderList(state),
    globals: getGlobalsFromManagerOrder(state),
    settings: getOthersFromManagerOrder(state),
    tradingPartners: getTradingPartners(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRow: (payload) => {
      dispatch(actionOrdersManager(ORDERS_MANAGER_TABLE_ADD_ROW, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderTable));
