import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip, Card } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import SelectField from '../../../../commons/components/formFields/SelectField';
import NumberFieldCustom from '../../../../commons/components/formFields/numberFieldCustom';

import { errorMessages } from '../../../../commons/models/constants';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import { getContractWeek } from '../../../contracts/utils/deliveryWeeks';
import { getFresh } from '../../../contracts/utils/modelFunctions';
import contractType from '../../../contracts/models/contractType';
import orderUnderlying from '../../../contracts/models/orderUnderlying';
import CustomText from '../../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.1)',
  },
  containerOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //alignItems: "center",
    backgroundColor: 'rgba(0,0,0,.2)',
  },

  rowNotVisible: {
    height: '1px',
  },
  rowMargin: {
    marginBottom: theme.spacing(),
  },
  small: {
    width: theme.spacing(2.4),
    height: theme.spacing(2.4),
    backgroundColor: theme.palette.text.primary,
  },
  week: {
    color: theme.palette.secondary.main,
    width: '75px',
  },
});

// TODO: Extract all functionality to saga ans store
class ResponseFormRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  updatePrice = (price) => {
    this.setState({ price });
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: 'price',
      value: price,
    };

    this.props.updateOrderRow(payload);
  };

  checkValid = (key, value) => {
    const returnValue = { message: '', value: false };
    switch (key) {
      case 'price':
        const price = checkPrice(value);
        returnValue.value = !price;
        returnValue.message = price ? '' : errorMessages.price;
        break;
      case 'volume':
        const volume = checkVolume(value);

        returnValue.value = !volume;
        returnValue.message = volume ? '' : errorMessages.volume;
        break;
      case 'targetOrganisations':
        returnValue.value = value.length !== 0;
        returnValue.message = errorMessages.targetOrganisations;
        break;
      default:
    }
    return returnValue;
  };

  handleChange = (name) => (event) => {
    const { errors } = this.state;
    const valid = this.checkValid(name, event.target.value);

    errors[name] = valid;

    this.setState({
      errors,
    });
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: event.target.value,
    };

    this.props.updateOrderRow(payload);
  };

  handleDateChange = (name) => (date) => {
    const payload = {
      type: 'response',
      action: 'updateOrderRow',
      key: this.props.orderRow.key,
      field: name,
      value: date,
    };

    this.props.updateOrderRow(payload);
  };

  getErrors = (key) => {
    if (this.state.errors[key]) {
      return this.state.errors[key];
    }
    return { message: '', value: false };
  };

  render() {
    const { classes, activeRfq, enumerations, formFields } = this.props;
    const orderRow = this.props.orderRow || {};
    const subSegment = orderUnderlying.fields[contractType.subSegment];
    const FIELD_WIDTH = '150px';

    return (
      <React.Fragment>
        <Card style={{ margin: '4px', padding: '8px', display: 'flex', flexDirection: 'row' }}>
          <div className={classes.containerItems} style={{ flex: 1 }}>
            <SelectField
              accessor={subSegment.accessor}
              value={orderRow[subSegment.accessor]}
              values={enumerations[subSegment.accessor]}
              handleChange={this.handleChange}
              width={FIELD_WIDTH}
            />{' '}
            <NumberFieldCustom
              accessor="volume"
              key={`${'volume'}-${orderRow.key}`}
              displayName={'volume'}
              value={orderRow['volume']}
              handleChange={this.handleChange}
              error={this.getErrors('volume')}
              adornment={'cartons'}
              width={FIELD_WIDTH}
              step={formFields['volume'].step}
            />
            <NumberFieldCustom
              accessor="price"
              key={`${'price'}-${orderRow.key}`}
              displayName={'price'}
              value={orderRow.price}
              handleChange={this.handleChange}
              error={this.getErrors('price')}
              adornment={orderRow[formFields['price'].adornment]}
              width={FIELD_WIDTH}
              step={formFields['price'].step}
            />
          </div>
          <div className={classes.containerItems}>
            <Tooltip
              title={<CustomText value="deleteOrder" />}
              aria-label={<CustomText value="deleteOrder" />}
            >
              <IconButton onClick={this.props.deleteOrderRow}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

ResponseFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResponseFormRow);
