import marketSegments from '../models/marketSegments';
import order from '../models/order';
import orderContract from '../models/orderContract';
import rfq from '../models/rfq';
import rfqContract from '../models/rfqContract';
import rfqUnderlying from '../models/rfqUnderlying';
import rfqExtensions from '../models/rfqExtensions';
import orderUnderlying from '../models/orderUnderlying';
import orderData from '../models/orderData';
import trade from '../models/trade';
import {
  getAllContractWeeks,
  getDatesThisWeek,
  getDatesFromWeek,
  getContractWeek,
} from './deliveryWeeks';
import contractType from '../models/contractType';
import { formatDate, formatDateTime } from '../../../commons/config/formatters';
import { createId } from '../../../commons/utils/functions';
import { getPostTradeBlotterItems } from '../../trades/config';

import moment from 'moment';
import { isNull } from 'lodash';

const get = (obj, fieldsToCheck = []) => {
  let fields = {};
  Object.keys(obj)
    .filter((key) => {
      let val = true;
      fieldsToCheck.forEach((f) => {
        if (obj[key].hasOwnProperty(f)) {
          if (obj[key][f] === false) {
            val = false;
          }
        } else {
          val = false;
        }
      });
      return val;
    })
    .forEach((key) => (fields[key] = obj[key]));
  return fields;
};

export const getContractType = () => {
  return contractType;
};

export const getFieldsByStructure = (type = 'order', fieldsToCheck = ['request']) => {
  switch (type) {
    case 'order':
      return {
        contract: get(orderContract.fields, fieldsToCheck),
        underlying: get(orderUnderlying.fields, fieldsToCheck),
        order: get(order.fields, fieldsToCheck),
        orderData: get(orderData.fields, fieldsToCheck),
      };
    case 'rfq':
      return {
        contract: get(rfqContract.fields, fieldsToCheck),
        underlying: get(rfqUnderlying.fields, fieldsToCheck),
        order: get(rfq.fields, fieldsToCheck),
        extensions: get(rfqExtensions.fields, fieldsToCheck),
      };
    default:
      return {};
  }
};

export const getFieldsObject = (type = 'order', fieldsToCheck = ['request']) => {
  switch (type) {
    case 'order':
      return {
        ...get(orderContract.fields, fieldsToCheck),
        ...get(orderUnderlying.fields, fieldsToCheck),
        ...get(order.fields, fieldsToCheck),
        ...get(orderData.fields, fieldsToCheck),
        ...get(rfqExtensions.fields, fieldsToCheck),
      };
    case 'rfq':
      return {
        ...get(rfqContract.fields, fieldsToCheck),
        ...get(rfqUnderlying.fields, fieldsToCheck),
        ...get(rfq.fields, fieldsToCheck),
        ...get(rfqExtensions.fields, fieldsToCheck),
      };
    case 'trade':
      const tradeFields = { ...trade.fields, ...getPostTradeBlotterItems() };

      return {
        ...get(orderContract.fields, fieldsToCheck),
        ...get(orderUnderlying.fields, fieldsToCheck),
        ...get(order.fields, fieldsToCheck),
        ...get(orderData.fields, fieldsToCheck),
        ...get(rfqExtensions.fields, fieldsToCheck),
        ...get(tradeFields, fieldsToCheck),
      };
    default:
      return {};
  }
};

export const getFieldsArray = (type = 'order', fieldsToCheck = ['request']) => {
  const obj = getFieldsObject(type, fieldsToCheck);
  if (type === 'trade') {
  }

  const arr = [];
  Object.keys(obj).forEach((key) => arr.push(obj[key]));
  return arr;
};

export const getEnumerations = (
  type = 'order',
  order = {},
  myOrganisation = {},
  myBeneficiary = {},
) => {
  const map = getMapRfqtoOrder();
  const market =
    type === 'rfq'
      ? order[map[contractType.market]] || contractType.defaultValues.market
      : order[contractType.market] || contractType.defaultValues.market;
  const segment =
    type === 'rfq'
      ? order[map.location] || contractType.defaultValues.segment
      : order.location || contractType.defaultValues.segment;
  const incoTerms = type === 'rfq' ? order[map.incoTerms] || 'FCA' : order.incoTerms || 'FCA';
  const unit = type === 'rfq' ? order[map.unit] || 'KG' : order.unit || 'KG';
  const presentation =
    type === 'rfq'
      ? order[map.presentation] || [contractType.defaultValues.presentation]
      : order.presentation || contractType.defaultValues.presentation;
  const presentationType = getPresentation(presentation);
  const deliveryCountry =
    type === 'rfq' ? order[map.deliveryCountry] || 'ANY' : order.deliveryCountry || 'ANY';
  const unitType = unit.toLowerCase();

  let freshFrozen = type === 'rfq' ? order[map.state] || 'FRESH' : order.state || 'FRESH';
  if (freshFrozen !== 'FROZEN') freshFrozen = 'FRESH';
  let spotvap = order.rfqType ? order.rfqType.toLowerCase() : 'spot';
  if (spotvap === 'contract') spotvap = 'spot';
  if (spotvap === 'vapcontract') spotvap = 'vap';
  const frequency = order.frequency || 'MONTHLY';

  const values = {};
  const fields = getFieldsObject(type);

  const fieldKeys = Object.keys(fields);
  fieldKeys.forEach((key) => {
    if (fields[key].enum) {
      if (key === 'week') {
        if (freshFrozen === 'FRESH') {
          values[key] = getAllContractWeeks().values;
        } else {
          values[key] = getAllContractWeeks(true).values;
        }
      } else if (key === 'deliveryPoint') {
        let enums;
        if (deliveryCountry === 'ANY') {
          enums = Object.entries(fields[key].enum).map((item) => item[1].name);
        } else {
          enums = Object.entries(fields[key].enum)
            .filter((item) => item[1].country === deliveryCountry)
            .map((item) => item[1].name);
        }
        values[key] = enums;
      } else if (key === 'brandName') {
        if (myBeneficiary.details) {
          if (myBeneficiary.details.brand) {
            values[key] = ['ANY', ...myBeneficiary.details.brand];
          }
        } else {
          values[key] = ['ANY'];
        }
      } else if (key === 'processingPoint') {
        if (myBeneficiary.details) {
          if (myBeneficiary.details.factoryNumber) {
            values[key] = ['ANY', ...myBeneficiary.details.factoryNumber];
          }
        } else {
          values[key] = ['ANY'];
        }
      } else {
        let enums = Object.entries(fields[key].enum)
          .filter((item) => {
            let val = item[1][spotvap];
            if (key === 'size') {
              if (presentationType !== null) {
                if (item[1][presentationType] === false) val = false;
              }
              if (item[1][unitType] === false) val = false;
            }
            return val;
          })
          .map((item) => item[1].name);

        values[key] = enums;
      }
    }
  });

  const marketSegment = findMarketSegment(market, segment);

  if (marketSegment.enums) {
    for (var k in marketSegment.enums) {
      const fieldName = type === 'order' ? k : map[k];
      switch (k) {
        case 'deliveryPoint':
        case 'deliveryPoints':
          if (incoTerms === 'FCA' || incoTerms === 'FOB' || incoTerms === 'EXW') {
            values[fieldName] = Object.keys(marketSegment.enums[k]);
          }
          break;
        case 'size':
          let enums = Object.entries(marketSegment.enums[k])
            .filter((item) => {
              let val = item[1][spotvap];

              if (presentationType !== null) {
                if (item[1][presentationType] === false) val = false;
              }
              if (item[1][unitType] === false) val = false;

              return val;
            })
            .map((item) => item[1].name);

          values[fieldName] = enums;
          break;
        case 'presentation':
          enums = Object.entries(marketSegment.enums[k])
            .filter((item) => {
              let val = item[1][spotvap];

              return val;
            })
            .map((item) => item[1].name);

          values[fieldName] = enums;
          break;
        default:
          values[fieldName] = Object.keys(marketSegment.enums[k]);
      }
    }
  }
  const periods = frequency === 'MONTHLY' ? 12 : frequency === 'QUARTERLY' ? 4 : 78;
  const numberOfPeriods = [];
  let i;
  for (i = 1; i <= periods; i++) {
    numberOfPeriods.push(i);
  }
  values.numberOfPeriods = numberOfPeriods;
  let direction = [];
  if (myOrganisation.type === 'BUYER') {
    direction.push('BUY');
  } else if (myOrganisation.type === 'SELLER') {
    direction.push('SELL');
  } else {
    direction.push('BUY');
    direction.push('SELL');
  }
  values.direction = direction;
  return values;
};

export const getRfqRespsonseEnumerations = (rfq, flex = false, myOrganisation = null) => {
  const product = rfq.contract.underlying.product || 'SALMON';
  const location = rfq.contract.underlying.locations
    ? rfq.contract.underlying.locations[0]
    : rfq.contract.underlying.location
    ? rfq.contract.underlying.location
    : 'NORWAY';
  const species = rfq.contract.underlying.species || null;
  const incoTerms = rfq.contract.underlying.incoTerms || 'FCA';
  const state = getFresh(rfq.contract.underlying);
  const extensions = parseExtensions(rfq);
  const rfqType = extensions.rfqType ? extensions.rfqType : 'SPOT';
  //const presentation = rfq.contract.underlying.product || ['ANY'];
  const deliveryCountry = rfq.contract.underlying.deliveryCountry || ['ANY'];
  //const presentationType = getPresentation(presentation);
  const order = {
    product,
    location,
    incoTerms,
    state,
    rfqType,
    species,
    deliveryCountry,
  };
  const map = getMapRfqtoOrder();

  const defaultEnumerations = getEnumerations('order', order);

  let enumerations = { ...defaultEnumerations };

  /*   Object.keys(rfq.contract.underlying).forEach((key) => {
    const field = rfq.contract.underlying[key];
    const accessor = map[key];
    if (Array.isArray(field)) {
      if (!field.includes('ANY')) {
        enumerations[accessor] = [...field];
      }
    } else {
      enumerations[accessor] = [field];
    }
  }); */
  const days = rfqExtensions.fields
    ? rfqExtensions.fields.harvestDay
      ? Object.keys(rfqExtensions.fields.harvestDay.enum)
      : []
    : [];
  //const days = rfqExtensions.fields ? Object.keys(rfqExtensions.fields.harvestDay.enum) : [];
  /*   if (flex) {
    enumerations.weightClass = defaultEnumerations.weightClass;
    enumerations.deliveryPoint = defaultEnumerations.deliveryPoint;
    enumerations.incoTerms = defaultEnumerations.incoTerms;
    enumerations.packaging = defaultEnumerations.packaging;
    enumerations.certification = defaultEnumerations.certification;
     enumerations.colour = defaultEnumerations.colour;
    enumerations.mortalityRateAllowance = defaultEnumerations.mortalityRateAllowance;
    enumerations.weightAllowance = defaultEnumerations.weightAllowance; 
    enumerations.paymentTerms = defaultEnumerations.paymentTerms;
  } 
  */

  enumerations.harvestDay = days;
  enumerations.deliveryDay = days;
  enumerations.maxFreezePeriod = rfqExtensions.fields
    ? rfqExtensions.fields.maxFreezePeriod
      ? Object.keys(rfqExtensions.fields.maxFreezePeriod.enum)
      : []
    : [];
  /* enumerations.maxFreezePeriod = rfqExtensions.fields
    ? Object.keys(rfqExtensions.fields.maxFreezePeriod.enum)
    : []; */
  if (myOrganisation) {
    if (myOrganisation.details) {
      if (myOrganisation.details.brand) {
        enumerations['brandName'] = ['ANY', ...myOrganisation.details.brand];
      } else {
        enumerations['brandName'] = ['ANY'];
      }
      if (myOrganisation.details.factoryNumber) {
        enumerations['processingPoint'] = ['ANY', ...myOrganisation.details.factoryNumber];
      } else {
        enumerations['processingPoint'] = ['ANY'];
      }
    } else {
      enumerations['brandName'] = ['ANY'];
      enumerations['processingPoint'] = ['ANY'];
    }
  } else {
    enumerations['brandName'] = ['ANY'];
    enumerations['processingPoint'] = ['ANY'];
  }
  return enumerations;
};
export const getCartonNetWeightEnums = (order) => {
  const species = order.species;
  const location = order.location;
  const fields = getFieldsObject('order');
  const marketSegment = findMarketSegment(species, location);
  const enums = marketSegment ? marketSegment.enums.cartonNetWeight || {} : {};
  return { ...fields.cartonNetWeight.enum, ...enums };
};
export const getTradeAmendEnumerations = (trade, myOrganisation = null) => {
  const order = {
    product: trade.assets[0].contract.underlying.product,
    location: trade.assets[0].contract.underlying.location,
    incoTerms: trade.assets[0].contract.underlying.incoTerms,
    state: 'fresh',
    rfqType: 'spot',
    species: trade.assets[0].contract.underlying.species,
    deliveryCountry: trade.assets[0].contract.underlying.deliveryCountry,
  };

  const defaultEnumerations = getEnumerations('order', order);

  let enumerations = { ...defaultEnumerations };

  if (myOrganisation) {
    if (myOrganisation.details) {
      if (myOrganisation.details.brand) {
        enumerations['brandName'] = ['ANY', ...myOrganisation.details.brand];
      } else {
        enumerations['brandName'] = ['ANY'];
      }
      if (myOrganisation.details.factoryNumber) {
        enumerations['processingPoint'] = ['ANY', ...myOrganisation.details.factoryNumber];
      } else {
        enumerations['processingPoint'] = ['ANY'];
      }
    } else {
      enumerations['brandName'] = ['ANY'];
      enumerations['processingPoint'] = ['ANY'];
    }
  } else {
    enumerations['brandName'] = ['ANY'];
    enumerations['processingPoint'] = ['ANY'];
  }

  return enumerations;
};

export const getMapRfqtoOrder = () => {
  const fields = get(orderUnderlying.fields, ['request']);
  const map = {};
  Object.keys(fields).forEach((f) => {
    if (fields[f].rfqAccessor) {
      map[fields[f].rfqAccessor] = f;
      map[f] = fields[f].rfqAccessor;
    } else {
      map[f] = f;
    }
  });
  return map;
};

export const getDefaultFormState = (type = 'order', order = {}, exchange = 'lfex') => {
  const map = getMapRfqtoOrder();
  const market =
    type === 'rfq'
      ? order[map[contractType.market]] || contractType.defaultValues.market
      : order[contractType.market] || contractType.defaultValues.market;
  const segment =
    type === 'rfq'
      ? order[map.location] || contractType.defaultValues.segment
      : order.location || contractType.defaultValues.segment;
  const incoTerms = type === 'rfq' ? order[map.incoTerms] || 'FCA' : order.incoTerms || 'FCA';

  //const freshFrozen = type === "rfq" ? order[map.state] || "FRESH" : order.state || "FRESH"
  const values = {};
  const fields = getFieldsObject(type);
  const fieldKeys = Object.keys(fields);

  fieldKeys.forEach((key) => {
    if (fields[key].hasOwnProperty('defaultValue')) {
      if (key === 'week') {
        values[key] = order[key] || getAllContractWeeks().value;
      } else {
        if (fields[key].type === 'array' && fields[key].component === 'multiSelect') {
          if (Array.isArray(fields[key].defaultValue)) {
            values[key] = order[key] || fields[key].defaultValue;
          } else {
            values[key] = order[key] || [fields[key].defaultValue];
          }
        } else {
          values[key] = order[key] || fields[key].defaultValue;
        }
      }
    }
    if (fields[key].hasOwnProperty('enum')) {
      let enums = Object.keys(fields[key].enum);
      if (Array.isArray(values[key])) {
        if (values[key].every((v) => enums.includes(v))) {
          values[key] = [fields[key].defaultValue];
        } else {
        }
      } else {
        if (enums.some((en) => en === values[key])) {
        } else {
          values[key] = fields[key].defaultValue;
        }
      }
    }
  });

  const marketSegment = findMarketSegment(market, segment);

  if (marketSegment.defaultValues) {
    for (var k in marketSegment.defaultValues) {
      const fieldName = type === 'order' ? k : map[k];
      switch (k) {
        case 'deliveryPoint':
        case 'deliveryPoints':
          if (incoTerms === 'FCA' || incoTerms === 'FOB' || incoTerms === 'EXW') {
            values[fieldName] = marketSegment.defaultValues[k];
          }
          break;
        default:
          if (fields[fieldName].type === 'array' && fields[fieldName].component === 'multiSelect') {
            if (Array.isArray(marketSegment.defaultValues[k])) {
              values[fieldName] = marketSegment.defaultValues[k];
            } else {
              values[fieldName] = [marketSegment.defaultValues[k]];
            }
          } else {
            values[fieldName] = marketSegment.defaultValues[k];
          }
      }
    }
  }

  const allDates = getDatesThisWeek();

  const filteredDates = getFilteredDates(allDates, values);

  if (type === 'rfq') {
    Object.keys(filteredDates).forEach((item) => {
      if (values.hasOwnProperty(item)) {
        values[item] = allDates[item];
      }
    });
  } else {
    values.week = allDates.week;
    values.deliveryDate = new Date();
    values.packingDate = new Date();
  }
  if ((exchange = 'oox')) {
    values.minPackingDate = moment().format('YYYY-MM-DD');
    values.maxPackingDate = moment().format('YYYY-MM-DD');
    values.minDeliveryDate = moment().add(2, 'days').format('YYYY-MM-DD');
    values.maxDeliveryDate = moment().add(2, 'days').format('YYYY-MM-DD');
  }
  values.startDate = new Date();

  return values;
};

export const checkRfqStateAgainstEnums = (order, enumerations) => {
  Object.keys(order).forEach((key) => {
    if (enumerations[key]) {
      if (enumerations[key].length > 0) {
        if (Array.isArray(order[key])) {
          order[key] = order[key].filter((o) => enumerations[key].includes(o));
          if (order[key].length === 0) order[key].push(enumerations[key][0]);
        } else {
          if (!enumerations[key].includes(order[key])) {
            order[key] = enumerations[key][0];
          }
        }
      }
    }
  });
  return order;
};
export const checkRfqOrderRowsStateAgainstEnums = (orderRows, enumerations) => {
  orderRows.forEach((order) => {
    Object.keys(order).forEach((key) => {
      if (enumerations[key]) {
        if (enumerations[key].length > 0) {
          if (Array.isArray(order[key])) {
            order[key] = order[key].filter((o) => enumerations[key].includes(o));
            if (order[key].length === 0) order[key].push(enumerations[key][0]);
          } else {
            if (!enumerations[key].includes(order[key])) {
              order[key] = enumerations[key][0];
            }
          }
        }
      }
    });
  });

  return orderRows;
};

export const getDefaultRfqFromOrder = (order) => {
  const map = getMapRfqtoOrder();
  const restingOrder = getDestructuredOrder(order)[0];
  const values = {};
  const fields = getFieldsObject('rfq');
  const fieldKeys = Object.keys(fields);

  fieldKeys.forEach((key) => {
    const mapKey = map[key] ? map[key] : key;

    let newValue;
    if (restingOrder[mapKey] !== null && restingOrder[mapKey] !== undefined) {
      newValue = restingOrder[mapKey];
    } else {
      newValue = fields[key].defaultValue;
    }
    if (fields[key].type === 'array' && fields[key].component === 'multiSelect') {
      values[key] = [newValue];
    } else {
      values[key] = newValue;
    }
  });
  values.direction = order.direction === 'BUY' ? 'SELL' : 'BUY';
  values.visibility = 'SELECTED_ORGANISATIONS';
  values.selectedOrganisationsIds = [restingOrder.organisationId];
  values.startDate = new Date();
  const week = values.week;
  let allDates;
  if (week === null || week === undefined) {
    allDates = getDatesFromWeek(week);
  } else {
    allDates = getDatesThisWeek();
  }
  Object.keys(allDates).forEach((item) => {
    if (values.hasOwnProperty(item)) {
      values[item] = allDates[item];
    }
  });
  return values;
};
export const getUpdatedRfqFormState = (updatedField, oldOrder, rfqType, fresh) => {
  const allFields = getFieldsObject('rfq', ['rfqForm']);
  const fields = getFieldsObject('rfq', ['rfqForm', rfqType, fresh]);
  const defaultOrder = getDefaultFormState('rfq', oldOrder);

  let newOrder = { ...oldOrder };
  switch (updatedField) {
    case 'rfqType':
    case 'product':
    case 'incoTerms':
    case 'states':
      Object.keys(allFields).forEach((field) => {
        if (!fields.hasOwnProperty(field)) {
          if (allFields[field].type === 'array') {
            newOrder[field] = [allFields[field].defaultValue];
          } else {
            newOrder[field] = allFields[field].defaultValue;
          }
        } else {
          if (newOrder[field] === null) {
            newOrder[field] = defaultOrder[field];
          }
        }
      });
      return newOrder;
    case 'species':
      Object.keys(allFields).forEach((field) => {
        if (!fields.hasOwnProperty(field)) {
          if (allFields[field].type === 'array') {
            newOrder[field] = [allFields[field].defaultValue];
          } else {
            newOrder[field] = allFields[field].defaultValue;
          }
        } else {
          if (newOrder[field] === null) {
            newOrder[field] = defaultOrder[field];
          }
        }
      });

      newOrder.product = defaultOrder.product;
      return newOrder;
    case 'location':
    case 'locations':
      Object.keys(allFields).forEach((field) => {
        if (!fields.hasOwnProperty(field)) {
          if (allFields[field].type === 'array') {
            newOrder[field] = [allFields[field].defaultValue];
          } else {
            newOrder[field] = allFields[field].defaultValue;
          }
        } else {
          if (newOrder[field] === null) {
            newOrder[field] = defaultOrder[field];
          }
        }
      });
      newOrder.currency = defaultOrder.currency ? defaultOrder.currency : newOrder.currency;
      return newOrder;
    case 'visibility':
      newOrder.selectedOrganisationsIds = [];
      return newOrder;
    default:
      return newOrder;
  }
};

export const getUpdatedFormState = (payload, oldOrder, type = 'order') => {
  const field = payload.updatedField;
  const value = payload.updatedValue;
  oldOrder[field] = value;
  let newOrder = { ...oldOrder };
  switch (field) {
    case 'rfqType':
    case 'product':
    case 'location':
    case 'locations':
    case 'incoTerms':
      newOrder = getDefaultFormState(type, oldOrder);
      newOrder[field] = value;
      return newOrder;
    case 'visibility':
      newOrder[field] = value;
      newOrder.selectedOrganisationsIds = [];
      return newOrder;
    default:
      newOrder[field] = value;
      return newOrder;
  }
};
export const checkRfqState = (rfqState, defaultRfqState) => {
  const newState = {};
  Object.keys(defaultRfqState).forEach((key) => {
    if (rfqState[key]) {
      if (typeof rfqState[key] === typeof defaultRfqState[key] || defaultRfqState[key] === null) {
        newState[key] = rfqState[key];
      } else {
        newState[key] = defaultRfqState[key];
      }
    } else {
      newState[key] = defaultRfqState[key];
    }
  });
  return newState;
};

export const createApiOrder = (datas) => {
  const structure = getFieldsByStructure();
  const order = {};
  const orderData = [];
  let currency = null;
  datas.forEach((data) => {
    const underlying = {};
    const contract = {};
    const orderDataObject = {};

    for (var key in structure.underlying) {
      if (structure.underlying[key].type === 'string') {
        underlying[key] = data[key] || structure.underlying[key].defaultValue || '';
      } else {
        underlying[key] = data[key] || structure.underlying[key].defaultValue || null;
      }
    }
    currency = underlying.currency;
    for (key in structure.contract) {
      if (structure.contract[key].type === 'date') {
        contract[key] =
          moment(data[key]).format('YYYY-MM-DD') || structure.contract[key].defaultValue || null;
      } else if (key === 'packingWeight') {
        const volume = Number(data.volume);
        const enums = getCartonNetWeightEnums(data);
        const cartonNetWeight = data.cartonNetWeight;
        const netWeight = Number(enums[cartonNetWeight].numericalValue);
        contract[key] = String(volume * netWeight);
      } else {
        contract[key] = data[key] || structure.contract[key].defaultValue || null;
      }
    }
    contract.underlying = underlying;
    for (key in structure.orderData) {
      orderDataObject[key] = data[key] || structure.orderData[key].defaultValue || null;
    }
    if (underlying.state === 'FROZEN' || contract.harvestDate === null) {
      if (contract.deliveryDate !== null && contract.deliveryDate !== undefined) {
        const dt = new Date(contract.deliveryDate);
        const week = getContractWeek(dt);
        if (week !== null && week !== undefined) {
          contract.week = week;
        }
      }
    }
    currency = contract.underlying ? contract.underlying.currency : currency;
    orderDataObject.contract = contract;
    orderData.push(orderDataObject);
    for (key in structure.order) {
      if (key === 'selectedOrganisationsIds') {
        order[key] = data[key].map(({ id }) => id);
      } else {
        order[key] = data[key] || structure.order[key].defaultValue || null;
      }
    }
    order.extensions = '';
    order.timeInForce = data.timeInForce ? moment.utc(moment(data.timeInForce)).format() : null;
    order.contract = {};
  });
  order.assets = orderData;
  order.currency = currency;
  return order;
};
export const createApiRfq = (data) => {
  const structure = getFieldsByStructure('rfq');
  const order = {};
  const extensionsObject = {};
  let assets = [];
  const underlying = {};
  const fresh = data.states === 'FRESH' ? true : false;
  let timeInForce = null;
  const defaultDate = {
    to: null,
    from: null,
  };
  const contract = {};
  for (var key in structure.underlying) {
    const value = data[key] || structure.underlying[key].defaultValue || null;
    if (structure.underlying[key].type === 'array') {
      if (Array.isArray(value)) {
        underlying[key] = value;
      } else {
        underlying[key] = [value];
      }
    } else if (structure.underlying[key].type === 'string') {
      underlying[key] = value || '';
    } else {
      underlying[key] = value;
    }
  }

  for (key in structure.contract) {
    switch (key) {
      case 'minFreezeDate':
        /* if(data.states === "FROZEN"){
                    contract.freezeDate.from = data[key] 
                } */
        break;
      case 'maxFreezeDate':
        /*  if(data.states === "FROZEN"){
                 contract.freezeDate.to = data[key] 
             } */
        break;
      case 'minHarvestDate':
        if (!contract.harvestDate) contract.harvestDate = { ...defaultDate };
        contract.harvestDate.from = data[key];
        break;
      case 'maxHarvestDate':
        if (!contract.harvestDate) contract.harvestDate = { ...defaultDate };
        contract.harvestDate.to = data[key];
        break;
      case 'minPackingDate':
        if (!contract.packingDate) contract.packingDate = { ...defaultDate };
        contract.packingDate.from = data[key];
        break;
      case 'maxPackingDate':
        if (!contract.packingDate) contract.packingDate = { ...defaultDate };
        contract.packingDate.to = data[key];
        break;
      case 'minDeliveryDate':
        if (!contract.deliveryDate) contract.deliveryDate = { ...defaultDate };
        contract.deliveryDate.from = data[key];
        break;
      case 'maxDeliveryDate':
        if (!contract.deliveryDate) contract.deliveryDate = { ...defaultDate };
        contract.deliveryDate.to = data[key];
        //const newDate1 = new Date(data[key]);
        const newDate2 = new Date(data[key]);
        newDate2.setDate(newDate2.getDate() + 2);
        timeInForce = moment.utc(moment(newDate2)).format();
        break;
      case 'processingDate':
      case 'useByDate':
        if (!contract[key]) contract[key] = { ...defaultDate };
        contract[key].from = data[key];
        let toDate = data[key];
        if (toDate) {
          toDate = moment(data[key]).add(1, 'y').format('YYYY-MM-DD');
        }
        contract[key].to = toDate;
        break;
      default:
        contract[key] = data[key] || structure.contract[key].defaultValue || null;
    }
  }
  if (data.rfqType === 'CONTRACT' || data.rfqType === 'VAPCONTRACT') {
    const numberOfPeriods = data.numberOfPeriods;

    const maxFrozen = data.maxFreezePeriod || '1 MONTH';
    const periods = Number(maxFrozen.split(' ')[0]) * -1;
    const startDate = moment(data.startDate);
    timeInForce = startDate
      .clone()
      .add(2, 'days')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toJSON();
    const harvestDay = data.harvestDay;
    const deliveryDay = data.deliveryDay;
    const fromDates = getDatesFromDayofWeek(startDate, 0, 'W', harvestDay, deliveryDay);
    const toDates = getDatesFromDayofWeek(startDate, numberOfPeriods, 'W', harvestDay, deliveryDay);
    contract.harvestDate.from = fromDates.harvestDate;
    contract.harvestDate.to = toDates.harvestDate;
    contract.deliveryDate.from = fromDates.deliveryDate;
    contract.deliveryDate.to = toDates.deliveryDate;
    contract.week = getContractWeek(fromDates.harvestDate.toDate());
    if (fresh) {
      contract.freezeDate = {
        from: null,
        to: null,
      };
    } else {
      if (data.rfqType === 'CONTRACT') {
        contract.freezeDate = {
          from: fromDates.deliveryDate.clone().add(periods, 'months'),
          to: toDates.deliveryDate.clone(),
        };
      }
      contract.harvestDate = {
        from: fromDates.deliveryDate.clone().add(periods, 'months').add(-1, 'days'),
        to: toDates.deliveryDate.clone(),
      };
    }
  } else {
    if (fresh) {
      contract.freezeDate = {
        from: null,
        to: null,
      };
    } else {
      if (data.rfqType === 'SPOT') {
        if (structure.contract.minFreezeDate) {
          contract.freezeDate = {
            from: data.minFreezeDate,
            to: data.maxFreezeDate,
          };
        }
      }
    }
    if (data.rfqType === 'VAP') {
      //const allDates = getDatesFromWeek(contract.week);
      contract.harvestDate.from = null;
      contract.harvestDate.to = null;
    }
  }

  contract.underlying = underlying;

  for (key in structure.order) {
    if (key === 'selectedOrganisationsIds') {
      order[key] = [];
      if (Array.isArray(data[key])) {
        if (data[key].length > 0) {
          order[key] = data[key].map(({ id }) => id);
        }
      }
    } else {
      order[key] = data[key] || structure.order[key].defaultValue || null;
    }
  }
  const rfqType = data.rfqType || 'SPOT';
  for (key in structure.extensions) {
    if (rfqType === 'SPOT' || rfqType === 'VAP') {
      extensionsObject[key] = null;
    } else {
      extensionsObject[key] = data[key] || null;
    }
  }
  assets = [...data.orderRows];
  assets.forEach((asset) => {
    asset.contract = { weightClass: asset.weightClass };
    asset.direction = order.direction;
    delete asset.key;
    delete asset.weightClass;
  });

  extensionsObject.rfqType = rfqType;
  extensionsObject.orderRows = data.orderRows || [];
  extensionsObject.marketViewId = data.marketViewId || null;
  //const extensions = JSON.stringify(extensionsObject);
  order.extensions = '';
  order.assets = assets;
  order.contract = contract;
  order.currency = contract.underlying.currency;
  order.timeInForce = data.timeInForce === null ? timeInForce : data.timeInForce;
  return order;
};

export const getFresh = (underlying) => {
  if (underlying.state) {
    return underlying.state.toLowerCase();
  } else if (underlying.states) {
    return underlying.states[0];
  } else {
    return 'fresh';
  }
};

export const parseExtensions = (rfq) => {
  return rfq.extensions ? (rfq.extensions === '' ? {} : JSON.parse(rfq.extensions)) : {};
};

export const getKeyValuePairsFromOrder = (data) => {
  let fresh = getFresh(data.assets[0].contract.underlying);
  const fieldsToCheck = ['responseDetail', fresh];
  const structure = {
    ...get(orderData.fields, fieldsToCheck),
    ...get(orderContract.fields, fieldsToCheck),
    ...get(orderUnderlying.fields, fieldsToCheck),
    ...get(order.fields, fieldsToCheck),

    ...get(rfqExtensions.fields, fieldsToCheck),
  };
  const _order = [];

  data.assets.forEach((orderData) => {
    const orderRow = [];
    for (var k in structure) {
      const { accessor: key, type } = structure[k];
      const val =
        orderData[k] || orderData.contract[k] || orderData.contract.underlying[k] || data[k];
      let value;
      if (type === 'date' && val !== null && val !== undefined) {
        value = formatDate(val);
      } else if (type === 'dateTime' && val !== null && val !== undefined) {
        value = formatDateTime(val);
      } else {
        value = val || '';
      }
      orderRow.push({ key, value });
    }

    _order.push(orderRow);
  });

  return _order;
};

export const getKeyValuePairsFromTrade = (data) => {
  let fresh = getFresh(data[0].contract.underlying);
  const fieldsToCheck = ['responseDetail', fresh];
  const structure = {
    ...get(orderData.fields, fieldsToCheck),
    ...get(orderContract.fields, fieldsToCheck),
    ...get(orderUnderlying.fields, fieldsToCheck),
    ...get(order.fields, fieldsToCheck),

    ...get(rfqExtensions.fields, fieldsToCheck),
  };
  const _order = [];

  data.forEach((orderData) => {
    const orderRow = [];
    for (var k in structure) {
      const { accessor: key, type } = structure[k];
      const val =
        orderData[k] || orderData.contract[k] || orderData.contract.underlying[k] || data[k];
      let value;
      if (type === 'date' && val !== null && val !== undefined) {
        value = formatDate(val);
      } else if (type === 'dateTime' && val !== null && val !== undefined) {
        value = formatDateTime(val);
      } else {
        value = val || '';
      }
      orderRow.push({ key, value });
    }

    _order.push(orderRow);
  });
  return _order;
};

export const getKeyValuePairsFromRfq = (data) => {
  let fresh = getFresh(data.contract.underlying);
  const fieldsToCheck = ['rfqDetail', fresh];
  const structure = {
    ...get(rfqContract.fields, fieldsToCheck),
    ...get(rfqUnderlying.fields, fieldsToCheck),
    ...get(rfq.fields, fieldsToCheck),
  };
  const order = [];
  for (var k in structure) {
    const { accessor: key, type } = structure[k];
    let val;
    let value;
    switch (k) {
      case 'minFreezeDate':
        val = data.contract.freezeDate ? data.contract.freezeDate.from : null;
        break;
      case 'maxFreezeDate':
        val = data.contract.freezeDate ? data.contract.freezeDate.to : null;
        break;
      case 'minHarvestDate':
        val = data.contract.harvestDate ? data.contract.harvestDate.from : null;
        break;
      case 'maxHarvestDate':
        val = data.contract.harvestDate ? data.contract.harvestDate.to : null;
        break;
      case 'minPackingDate':
        val = data.contract.packingDate ? data.contract.packingDate.from : null;
        break;
      case 'maxPackingDate':
        val = data.contract.packingDate ? data.contract.packingDate.from : null;
        break;
      case 'minDeliveryDate':
        val = data.contract.deliveryDate ? data.contract.deliveryDate.from : null;
        break;
      case 'maxDeliveryDate':
        val = data.contract.deliveryDate ? data.contract.deliveryDate.to : null;
        break;
      case 'useByDate':
        val = data.contract.useByDate ? data.contract.useByDate.from : null;
        break;
      case 'processingDate':
        val = data.contract.processingDate ? data.contract.processingDate.from : null;
        break;
      default:
        val = data[k] || data.contract[k] || data.contract.underlying[k];
    }

    if (type === 'date' && val !== null) {
      value = formatDate(val);
    } else if (type === 'dateTime' && val !== null) {
      value = formatDateTime(val);
    } else if (Array.isArray(val)) {
      value = val.join(' , ');
    } else {
      value = val || '';
    }
    order.push({ key, value });
  }

  const extensions = [];
  const extensionFields = get(rfqExtensions.fields, ['rfqDetail', fresh]);
  for (k in extensionFields) {
    const { displayName: key } = extensionFields[k];
    const rfqExtensions = parseExtensions(data);

    const value = rfqExtensions[k] || extensionFields[k].rfqDetailsDefault;

    extensions.push({ key, value });
  }
  const assets = data.assets;

  return { order, extensions, assets };
};

export const getDestructuredOrder = (data) => {
  const structure = getFieldsByStructure('order', ['response']);
  const order = [];
  data.orderData.forEach((orderData) => {
    const orderRow = {};
    for (var k in structure.underlying) {
      const { accessor: key } = structure.underlying[k];
      const value = orderData.contract.underlying[k];
      orderRow[key] = value;
    }
    for (k in structure.contract) {
      const { accessor: key } = structure.contract[k];
      const value = orderData.contract[k];
      orderRow[key] = value;
    }
    for (k in structure.orderData) {
      const { accessor: key } = structure.orderData[k];
      const value = orderData[k];
      orderRow[key] = value;
    }

    for (k in structure.order) {
      const { accessor: key } = structure.order[k];
      const value = data[k];
      orderRow[key] = value;
    }
    order.push(orderRow);
  });

  return order;
};

export const getRfqResponseFromOrder = (
  data,
  direction,
  trade = false,
  counterpartyContract = null,
) => {
  const structure = getFieldsByStructure('order', ['request']);

  const order = [];
  const formatDate = (date) => {
    if (date === null || date === undefined) {
      return null;
    } else {
      return moment(date).format('YYYY-MM-DD');
    }
  };
  data.assets.forEach((orderData) => {
    const orderRow = {};
    for (var k in structure.underlying) {
      const { accessor: key } = structure.underlying[k];
      let value;
      if (counterpartyContract !== null && key !== 'weightClass') {
        value = counterpartyContract.underlying[k];
      } else {
        value = orderData.contract.underlying[k];
      }
      orderRow[key] = value;
    }
    for (k in structure.contract) {
      const { accessor: key, type } = structure.contract[k];
      let value;
      if (counterpartyContract !== null && key !== 'weightClass') {
        value = type === 'date' ? formatDate(counterpartyContract[k]) : counterpartyContract[k];
      } else {
        value = type === 'date' ? formatDate(orderData.contract[k]) : orderData.contract[k];
      }
      orderRow[key] = value;
    }
    for (k in structure.orderData) {
      const { accessor: key } = structure.orderData[k];
      const value = orderData[k];
      orderRow[key] = value;
    }

    for (k in structure.order) {
      const { accessor: key } = structure.order[k];
      const value = data[k];
      orderRow[key] = value;
    }
    orderRow.key = createId();
    orderRow.direction = direction;
    orderRow.id = orderData.id;
    if (trade === true) {
      orderRow.targetIds = [data.id];
    }
    order.push(orderRow);
  });

  return order;
};

export const getRfqResponseItem = (rfq, direction, defaultOrders) => {
  const getValue = (item) => {
    let val;
    if (Array.isArray(item)) {
      val = item[0];
    } else {
      val = item;
    }
    return val;
  };
  const Rows = [];
  const rfqSubSegment = contractType.rfqSubSegment;
  const weightClass = rfq.contract.underlying[rfqSubSegment];
  const extensions = parseExtensions(rfq);
  let assetRows = extensions.orderRows || [];
  let assets = rfq.assets || [];
  if (assets.length > 0) {
    assetRows = [];
    assets.forEach((asset) => {
      let newAsset = {
        ...asset,
        ...asset.contract,
      };
      delete newAsset.contract;
      assetRows.push(newAsset);
    });
  }
  const rfqType = extensions.rfqType ? extensions.rfqType.toLowerCase() : 'spot';
  const structure = getFieldsByStructure('order', ['request', rfqType]);
  const orderRows = [...defaultOrders] || assetRows || [];

  const number = weightClass.length;
  const volume = parseInt(rfq.volume / number, 10);
  const finalVolume = rfq.volume - (number - 1) * volume;

  if (rfqType === 'spot' || rfqType === 'vap') {
    if (orderRows.length > 0) {
      orderRows.forEach((row, index) => {
        const orderRow = {};

        for (var k in structure.underlying) {
          const { accessor: key } = structure.underlying[k];
          const rfqAccessor = structure.underlying[k].rfqAccessor || key;
          const value = getValue(rfq.contract.underlying[rfqAccessor]);
          orderRow[key] = row[key] || value;
        }
        for (k in structure.contract) {
          const { accessor: key } = structure.contract[k];
          const value = rfq.contract[k];
          orderRow[key] = row[key] || value;
        }
        const orderRowHarvestDate = rfq.contract.harvestDate ? rfq.contract.harvestDate.from : null;
        orderRow.freezeDate = rfq.contract.freezeDate ? rfq.contract.freezeDate.from : null;
        orderRow.harvestDate = orderRowHarvestDate;
        orderRow.deliveryDate = rfq.contract.deliveryDate ? rfq.contract.deliveryDate.from : null;
        orderRow.packingDate = rfq.contract.packingDate ? rfq.contract.packingDate.from : null;
        orderRow.processingDate = rfq.contract.processingDate
          ? rfq.contract.processingDate.from
          : null;
        orderRow.useByDate = rfq.contract.useByDate ? rfq.contract.useByDate.from : null;
        const hDate = orderRowHarvestDate === null ? new Date() : new Date(orderRowHarvestDate);

        orderRow.week = getContractWeek(hDate);
        for (k in structure.orderData) {
          const { accessor: key } = structure.orderData[k];
          const value = rfq[k] || structure.orderData[k].defaultValue;
          orderRow[key] = row[k] || value;
        }

        for (k in structure.order) {
          const { accessor: key } = structure.order[k];
          const value = rfq[k];
          orderRow[key] = row[k] || value;
        }
        orderRow.key = createId();
        orderRow.rfqId = rfq.id;
        orderRow.volume = row.volume;

        orderRow.direction = direction;
        orderRow.weightClass = row.weightClass;
        Rows.push(orderRow);
      });
    } else {
      weightClass.forEach((wgt, index) => {
        const orderRow = {};

        for (var k in structure.underlying) {
          const { accessor: key } = structure.underlying[k];
          const rfqAccessor = structure.underlying[k].rfqAccessor || key;
          const value = getValue(rfq.contract.underlying[rfqAccessor]);
          orderRow[key] = value;
        }
        for (k in structure.contract) {
          const { accessor: key } = structure.contract[k];
          const value = rfq.contract[k];
          orderRow[key] = value;
        }
        const orderRowHarvestDate = rfq.contract.harvestDate ? rfq.contract.harvestDate.from : null;
        orderRow.freezeDate = rfq.contract.freezeDate ? rfq.contract.freezeDate.from : null;
        orderRow.harvestDate = orderRowHarvestDate;
        orderRow.deliveryDate = rfq.contract.deliveryDate ? rfq.contract.deliveryDate.from : null;
        const hDate = orderRowHarvestDate === null ? new Date() : new Date(orderRowHarvestDate);

        orderRow.week = getContractWeek(hDate);
        for (k in structure.orderData) {
          const { accessor: key } = structure.orderData[k];
          const value = rfq[k] || structure.orderData[k].defaultValue;
          orderRow[key] = value;
        }

        for (k in structure.order) {
          const { accessor: key } = structure.order[k];
          const value = rfq[k];
          orderRow[key] = value;
        }
        orderRow.key = createId();
        orderRow.rfqId = rfq.id;
        orderRow.volume = index + 1 === number ? finalVolume : volume;
        orderRow.direction = direction;
        orderRow.weightClass = wgt;
        Rows.push(orderRow);
      });
    }
  } else {
    const { numberOfPeriods, frequency } = extensions;
    let { harvestDay, deliveryDay, processingDay } = extensions;
    const frequencyInitial = frequency === 'QUARTERLY' ? 'M' : frequency.charAt(0);
    for (let i = 0; i <= numberOfPeriods - 1; i++) {
      const periodsToAdd = frequency === 'QUARTERLY' ? 3 * i : i;
      orderRows.forEach((row, index) => {
        const startDate = moment(extensions.startDate);
        if (row.harvestDay) {
          harvestDay = row.harvestDay;
        }
        if (row.deliveryDay) {
          deliveryDay = row.deliveryDay;
        }
        if (row.processingDay) {
          processingDay = row.processingDay;
        }
        const dates = getDatesFromDayofWeek(
          startDate,
          periodsToAdd,
          frequencyInitial,
          harvestDay,
          deliveryDay,
          processingDay,
        );
        const { deliveryDate, useByDate } = dates;
        let { harvestDate, processingDate } = dates;

        let freezeDate = null;
        if (row.state !== 'FRESH') {
          if (row.freezeDate !== null) {
            freezeDate = row.freezeDate;
            harvestDate = freezeDate.clone().add(-1, 'd');
            processingDate = freezeDate.clone().add(-1, 'd');
          } else {
            const months = Number(row.maxFreezePeriod.split(' ')[0]) * -1 || -1;
            processingDate = deliveryDate.clone().add(months, 'M').add(-1, 'd');
            harvestDate = deliveryDate.clone().add(months, 'M').add(-1, 'd');
            freezeDate = deliveryDate.clone().add(months, 'M');
          }
        }

        const week = getContractWeek(harvestDate.toDate());
        const orderRow = {};

        for (var k in structure.underlying) {
          const { accessor: key } = structure.underlying[k];
          const rfqAccessor = structure.underlying[k].rfqAccessor || key;
          const value = getValue(rfq.contract.underlying[rfqAccessor]);
          orderRow[key] = row[k] || value;
        }
        for (k in structure.contract) {
          const { accessor: key } = structure.contract[k];
          const value = rfq.contract[k];
          orderRow[key] = row[k] || value;
        }
        orderRow.freezeDate = freezeDate;
        orderRow.harvestDate = harvestDate;
        orderRow.deliveryDate = deliveryDate;
        orderRow.useByDate = useByDate;
        orderRow.processingDate = processingDate;
        orderRow.week = week;
        for (k in structure.orderData) {
          const { accessor: key } = structure.orderData[k];
          const value = rfq[k] || structure.orderData[k].defaultValue;
          orderRow[key] = row[k] || value;
        }

        for (k in structure.order) {
          const { accessor: key } = structure.order[k];
          const value = rfq[k];
          orderRow[key] = value;
        }
        orderRow.key = createId();
        orderRow.rfqId = rfq.id;
        orderRow.volume = row.volume;
        orderRow.direction = direction;
        orderRow.weightClass = row.weightClass;
        Rows.push(orderRow);
      });
    }
  }
  return Rows;
};

export const getRfqResponseDefaultItem = (rfq, direction, orders) => {
  const structure = getFieldsByStructure('order', ['request']);
  const getValue = (item) => {
    let val;
    if (Array.isArray(item)) {
      val = item[0];
    } else {
      val = item;
    }
    return val;
  };

  const Rows = [];
  let defaultOrders = [];
  if (orders !== null && orders !== undefined) {
    if (orders.extensions) {
      let ext = JSON.parse(orders.extensions);
      if (ext.defaultOrders) {
        if (ext.defaultOrders.length > 0) {
          defaultOrders = ext.defaultOrders;
        }
      }
    }
  }

  const subSegment = contractType.subSegment;
  const weightClass = rfq.contract.underlying[subSegment];
  const extensions = parseExtensions(rfq);
  //const { rfqType, startDate, } = extensions
  let orderRows = defaultOrders.length > 0 ? defaultOrders : extensions.orderRows || [];
  let assets = rfq.assets;
  if (assets.length > 0) {
    orderRows = [];
    assets.forEach((asset) => {
      let newAsset = {
        ...asset,
        ...asset.contract,
      };
      delete newAsset.contract;
      orderRows.push(newAsset);
    });
  }

  const number = weightClass.length;
  const volume = parseInt(rfq.volume / number, 10);
  const finalVolume = rfq.volume - (number - 1) * volume;

  const { harvestDay, deliveryDay, maxFreezePeriod, processingDay } = extensions;

  const orderRowsLength = orderRows.length > 0 ? orderRows.length : number;

  for (let i = 0; i < orderRowsLength; i++) {
    const orderRow = {};
    const row = orderRows[i] || {};

    for (var k in structure.underlying) {
      const { accessor: key } = structure.underlying[k];
      const rfqAccessor = structure.underlying[k].rfqAccessor || key;
      const value = getValue(rfq.contract.underlying[rfqAccessor]);
      orderRow[key] = row[key] || value;
    }
    for (k in structure.contract) {
      const { accessor: key } = structure.contract[k];
      const value = rfq.contract[k];
      orderRow[key] = row[key] || value;
    }
    orderRow.freezeDate = null;
    orderRow.harvestDay = row.harvestDay || harvestDay;
    orderRow.deliveryDay = row.deliveryDay || deliveryDay;

    orderRow.processingDay = row.processingDay || processingDay;
    orderRow.maxFreezePeriod = row.maxFreezePeriod || maxFreezePeriod;
    orderRow.harvestDate = null;
    orderRow.deliveryDate = null;
    for (k in structure.orderData) {
      const { accessor: key } = structure.orderData[k];

      let value = rfq[k] || structure.orderData[k].defaultValue;
      if (k === 'volume') {
        if (row[key]) {
          value = row[key];
        } else {
          value = i === number - 1 ? finalVolume : volume;
        }
      }

      orderRow[key] = row[key] || value;
    }

    for (k in structure.order) {
      const { accessor: key } = structure.order[k];
      const value = rfq[k];
      orderRow[key] = row[key] || value;
    }
    orderRow.key = createId();
    orderRow.rfqId = rfq.id;
    orderRow.direction = direction;
    orderRow.weightClass = row.weightClass || rfq.contract.underlying.weightClass[i] || '1-2';
    Rows.push(orderRow);
  }

  return Rows;
};

const getDatesFromDayofWeek = (
  startDate,
  periodsToAdd,
  frequencyInitial,
  harvestDay,
  deliveryDay,
  processingDay,
) => {
  let nextHarvestDate = moment(startDate).add(periodsToAdd, frequencyInitial);
  let nextDeliveryDate = moment(startDate).add(periodsToAdd, frequencyInitial);
  let nextProcessingDate = moment(startDate).add(periodsToAdd, frequencyInitial);
  let harvestDayInteger = mapDayToInteger(harvestDay);
  let deliveryDayInteger = mapDayToInteger(deliveryDay);
  let processingDayInteger = mapDayToInteger(processingDay);
  if (startDate.day() > deliveryDayInteger) {
    harvestDayInteger += 7;
    deliveryDayInteger += 7;
  }
  if (harvestDayInteger > deliveryDayInteger) {
    harvestDayInteger -= 7;
  }
  if (processingDayInteger > deliveryDayInteger) {
    processingDayInteger -= 7;
  }

  const harvestDate = nextHarvestDate.day(harvestDayInteger);
  const deliveryDate = nextDeliveryDate.day(deliveryDayInteger);
  const processingDate = nextProcessingDate.day(processingDayInteger);
  const useByDate = harvestDate.clone().add(7, 'd');
  return { harvestDate, deliveryDate, processingDate, useByDate };
};

const findMarketSegment = (market, segment) => {
  let marketSegmentName = `${market}_${segment}`;
  let marketSegment = marketSegments[marketSegmentName];
  if (marketSegment === null || marketSegment === undefined) {
    const markets = [];
    Object.keys(marketSegments).map((m) => {
      if (marketSegments[m][contractType.market] === market) {
        markets.push(marketSegments[m]);
      }
    });
    marketSegment = markets[0];
  }
  return marketSegment;
};

export const getMarketSegments = () => {
  return Object.keys(marketSegments).map((key) => marketSegments[key].name);
};

export const mapDayToInteger = (day) => {
  switch (day) {
    case 'SUNDAY':
      return 0;
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
    default:
  }
};

export const getFilteredDates = (dates, values) => {
  const nextDates = { ...dates };
  if (values.rfqType === 'SPOT' || values.rfqType === 'CONTRACT') {
    dates.processingDate = null;
    dates.useByDate = null;
    if (values.state === 'FRESH' || values.states === 'FRESH') {
      //dates.useByDate = null;
    }
  }
  return nextDates;
};

const getPresentation = (presentation) => {
  let filets = false;
  let portions = false;
  let burgers = false;
  let returnValue = null;
  const isFilet = (element) => element.includes('TRIM') || element.includes('ANY');
  const isPortion = (element) =>
    !element.includes('TRIM') &&
    !element.includes('HG') &&
    !element.includes('HON') &&
    !element.includes('HOG') &&
    !element.includes('BURGERS');
  const isBurger = (element) => element.includes('BURGERS');
  if (Array.isArray(presentation)) {
    if (presentation.some(isFilet)) {
      filets = true;
    }
    if (presentation.some(isPortion)) {
      portions = true;
    }
    if (presentation.some(isBurger)) {
      burgers = true;
    }
  }

  if (!portions && !burgers && filets) {
    returnValue = 'filets';
  } else if (portions && !burgers && !filets) {
    returnValue = 'portions';
  } else if (!portions && burgers && !filets) {
    returnValue = 'burgers';
  }

  return returnValue;
};
