import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducers';
import * as sagas from './sagas';
import * as selectors from './selectors';
import * as config from './config';
import api from './api';

import TradeDetails from './components/TradeDetails';
import PostTradeForm from './components/PostTradeForm';
import EndRfqPrompt from './components/EndRfqPrompt';
import TradeAmendment from './components/TradeAmendment';
import TradeList from './components/TradeList';
import TradeNotification from './components/TradeNotification';
const reducers = {
  reducer,
};

const components = {
  PostTradeForm,
  TradeDetails,
  EndRfqPrompt,
  TradeAmendment,
  TradeList,
  TradeNotification,
};

export default { actions, constants, reducers, sagas, selectors, config, api, components };
