import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import SelectField from '../../../../commons/components/formFields/SelectField';
import DateField from '../../../../commons/components/formFields/dateField';
import SwitchField from '../../../../commons/components/formFields/switchField';

import { getAllContractWeeks } from '../../../contracts/utils/deliveryWeeks';

const weeks = getAllContractWeeks();

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },

  gridContainer: {
    alignItems: 'center',
  },

  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textAlign: 'left',
  },
});

const width = 175;

const GlobalSettings = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} className={classes.gridItem}>
            <SelectField
              accessor="harvestWeek"
              displayName="HarvestWeek"
              value={props.harvestWeek}
              values={weeks.values}
              handleChange={props.handleChange}
              width={width}
              className={classes.select}
              //   errorRecipientsMessage={this.state.errorRecipients.message}
            />
          </Grid>

          {props.checked && (
            <Grid item xs={2} className={classes.gridItem}>
              <DateField
                accessor="harvestDate"
                displayName="Harvest Date"
                value={props.harvestDate}
                disablePast={false}
                handleDateChange={props.handleChange}
                width={width}
                minDate={props.minHarvestDate}
                maxDate={props.maxHarvestDate}
                clearable
              />
            </Grid>
          )}
          <Grid item xs={4} className={classes.gridItem}>
            <SwitchField
              checked={props.checked}
              value={props.checked}
              handleChecked={props.handleChecked}
              onLabel="Specify Harvest Date"
              offLabel=""
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

GlobalSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GlobalSettings);
