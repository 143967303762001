//import * as actionTypes from '../actionTypes';

//import { insertItems, updateItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: [],
  allIds: [],
  items: {},
  segmentValues: [],
  segmentValue: '',
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  //let nextState = null;

  switch (action.type) {
    case auth.constants.INIT_INVENTORY_SUCCESS:
      const newState = { ...initialState };
      newState.segmentValues = action.payload.segmentValues;
      newState.segmentValue = action.payload.segmentValue;
      return newState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
