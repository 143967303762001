import React, { useState, useContext, createContext } from 'react';

const languages = { english: 'english', mandarin: 'mandarin' };

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  console.log('rending language provider');
  const [language, setLanguage] = useState(languages.english);
  const changeLanguage = (language) => {
    console.log('changingLanguage', language);
    setLanguage(language);
  };
  return (
    <LanguageContext.Provider value={{ language, changeLanguage, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageProvider;
