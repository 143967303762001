import { createSelector } from 'reselect';
import { NAME } from '../constants';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyContract = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyContract;
};
export const getById = createSelector(getByIdProp, (item) => item);

const emptyActiveContract = {};
const getActiveContractProp = (state) => {
  const id = 1;
  const item = state[NAME].byId[id];
  return item || emptyActiveContract;
};
export const getActiveContract = createSelector(getActiveContractProp, (item) => {
  return item;
});
