import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CHECK_AUTH } from '../../constants';
import { checkAuth as actionCheckAuth } from '../../actions';

import { isAuthed } from '../../selectors';

export default function withAuth(Component) {
  class WithAuth extends React.PureComponent {
    componentDidMount() {
      const { redirect } = this.props;

      if (this.props.authed !== true) {
        const payload = {
          redirect,
        };

        setTimeout(() => {
          this.props.checkAuth(payload);
        }, 0);
      }
    }

    render() {
      const { authed, show, ...otherProps } = this.props;
      if ((show && show.whenAuthed === false) || authed === true) {
        return (
          <React.Fragment>
            <Component {...otherProps} />
          </React.Fragment>
        );
      }

      return null;
    }
  }

  const displayName = Component.displayName || Component.name || 'Component';

  WithAuth.displayName = `${WithAuth}(${displayName})`;

  WithAuth.defaultProps = {
    authed: false,
    show: null,
  };

  WithAuth.propTypes = {
    authed: PropTypes.bool,
    show: PropTypes.object,
    checkAuth: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => ({
    authed: isAuthed(state),
  });

  //const checkAuth = (payload) => dispatch(actionCheckAuth(CHECK_AUTH, payload));

  const mapDispatchToProps = (dispatch) => ({
    checkAuth: (payload) => dispatch(actionCheckAuth(CHECK_AUTH, payload)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuth);
}
