import { createSelector } from 'reselect';
import constants from '../constants';

import organisations from '../../organisations';
import { getOrgId } from '../../auth/selectors';
import moment from 'moment';
const NAME = constants.NAME;

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => {
  return items;
});
export const getAll = createSelector(getAllObject, (items) => {
  return Object.values(items);
});

export const getAllByType = createSelector(getAllObjectProp, getOrgId, (items, orgId) => {
  const documentTypes = [
    'INVOICE',
    'PACKING_LIST',
    'PRODUCT_SPECIFICATION',
    'FISH_CV',
    'ORDER_SPECIFICATION',
    'AIRWAY_BILL',
    'HEALTH_CERTIFICATE',
    'CERTIFICATE_OF_ORIGIN',
    'FULL_PACK',
    'INSPECTION_REPORT',
    'OTHER',
  ];
  const values = Object.values(items);
  const filteredList = {};
  documentTypes.forEach((docType) => {
    const filtered = values
      .filter((val) => val.type === docType)
      .filter((val) => val.organisationId === orgId);
    filteredList[docType] = filtered;
  });
  return filteredList;
});

export const getAllMyFiles = createSelector(getAllObjectProp, getOrgId, (items, orgId) => {
  const values = Object.values(items);
  const filteredList = values.filter((val) => val.organisationId === orgId);
  filteredList.sort((a, b) => {
    a = moment(a.updatedTime);
    b = moment(b.updatedTime);
    return a > b ? -1 : a < b ? 1 : 0;
  });
  return filteredList;
});

export const getAllSharedFiles = createSelector(getAllObjectProp, getOrgId, (items, orgId) => {
  const values = Object.values(items);
  const filteredList = values.filter((val) => val.organisationId !== orgId);
  filteredList.sort((a, b) => {
    a = moment(a.updatedTime);
    b = moment(b.updatedTime);
    return a > b ? -1 : a < b ? 1 : 0;
  });
  return filteredList;
});

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyCalculator = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyCalculator;
};
export const getById = createSelector(getByIdProp, (item) => item);
/* 
export const getTradingPartners = createSelector(settings.selectors.getTradingPartners, (items) => {
  return items;
}); */
export const getTradingPartners = createSelector(
  organisations.selectors.getMyOrganisation,
  organisations.selectors.getAllObject,

  (myOrganisation, organisations) => {
    const tradingPartners = [];
    const tradingPartnersIds = myOrganisation ? myOrganisation.tradingPartnersIds || [] : [];

    tradingPartnersIds.forEach((id) => {
      const tradingPartner = organisations[id];
      if (tradingPartner !== null && tradingPartner !== undefined) {
        tradingPartners.push(tradingPartner);
      }
    });

    tradingPartners.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return tradingPartners;
  },
);
export const getOrganisationsObject = createSelector(
  organisations.selectors.getAllObject,
  (items) => {
    return items;
  },
);

const getFileContentProp = (state) => {
  const items = state[NAME].content;
  return items;
};
export const getFileContent = createSelector(getFileContentProp, (items) => items);
