import {
  GET_MARKET,
  GET_MARKET_SUCCESS,
  GET_MARKET_ERROR,
  RESET_MARKET,
  INIT_MARKET_SUCCESS,
  UPDATE_MARKET_SUCCESS,
  DESTROY_MARKET_SUCCESS,
} from '../constants';

import { insertItems } from '../../../commons/utils/functions';

import auth from '../../auth';

const initialState = {
  byId: {},
  allIds: [],
  items: {},
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;

  switch (action.type) {
    case INIT_MARKET_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idInitMarket, ...otherNextMarketProps } = action.payload;

      if (typeof idInitMarket !== 'undefined') {
        if (!nextState.items[idInitMarket]) {
          nextState.items = {
            ...nextState.items,
            [idInitMarket]: {
              id: idInitMarket,
              ...otherNextMarketProps,
            },
          };
        }
      }
      return nextState;
    case DESTROY_MARKET_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idDestroyMarket } = action.payload;

      if (typeof idDestroyMarket !== 'undefined') {
        if (nextState.items[idDestroyMarket]) {
          nextState.items = {
            ...nextState.items,
          };

          delete nextState.items[idDestroyMarket];
        }
      }
      return nextState;
    case UPDATE_MARKET_SUCCESS:
      nextState = {
        ...state,
      };

      const { id: idUpdateMarket, ...otherNextUpdateCalculatorProps } = action.payload;

      if (typeof idUpdateMarket !== 'undefined') {
        if (nextState.items[idUpdateMarket]) {
          nextState.items = {
            ...nextState.items,
            [idUpdateMarket]: {
              ...nextState.items[idUpdateMarket],
              ...otherNextUpdateCalculatorProps,
            },
          };

          if (typeof action.payload.filters !== 'undefined') {
            nextState.items[idUpdateMarket].filters = {
              ...state.items[idUpdateMarket].filters,
              ...action.payload.filters,
            };
          }
        }
      }
      return nextState;
    case GET_MARKET:
      return {
        ...state,
        status: 'pending',
      };
    case GET_MARKET_SUCCESS:
      nextState = {
        ...state,
        ...insertItems(action.payload, state),
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };
      return nextState;
    case GET_MARKET_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_MARKET:
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
