import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import moment from 'moment';
import auth from '../../../auth';
import users from '../../../users';

import CustomTooltip from './CustomTooltip';

const styles = (theme) => ({
  flexEnd: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexStart: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  item: {
    minWidth: '200px',
    maxWidth: '70%',
    position: 'relative',
    padding: '10px',
    color: theme.palette.common.black,
    borderRadius: '20px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    fontSize: '14px',
  },
  userName: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    paddingBottom: '5px',

    color: theme.color.text.subtitle,
  },
  timestamp: {
    position: 'relative',
    color: theme.color.text.subtitle,
    fontSize: '12px',
  },
  messageText: {
    overflowWrap: 'break-word',
    display: 'block',
    wordBreak: 'break-word',
    minHeight: '1.43em',
    color: '#fff',
    textAlign: 'left',
    fontWeight: '700',
  },
});

const getTime = (isoTime) => {
  return moment(isoTime).format('HH:mm');
};

const isOwnMessage = (msg, user) => {
  return msg.createdBy === user.id;
};

const getUserName = (message, usersObject) => {
  const user = usersObject[message.createdBy];
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
};

const MessageItem = ({ classes, message, user, usersObject }) => {
  return (
    <Grid container className={isOwnMessage(message, user) ? classes.flexEnd : classes.flexStart}>
      <Grid
        item
        className={classes.item}
        style={{ backgroundColor: isOwnMessage(message, user) ? teal[500] : lightBlue[500] }}
      >
        <CustomTooltip title={getUserName(message, usersObject)}>
          <div className={classes.userName}>{getUserName(message, usersObject)}</div>
        </CustomTooltip>
        <div className={classes.messageText}>{message.text}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={classes.timestamp}>{getTime(message.createdTime)}</div>
        </div>
      </Grid>
    </Grid>
  );
};

MessageItem.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  usersObject: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: auth.selectors.getServerUser(state),
    usersObject: users.selectors.getAllObject(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(MessageItem));
