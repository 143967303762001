import { darken } from '@material-ui/core/styles/colorManipulator';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

export const agGridTheme = (theme) => {
  const obj = {
    '* .ag-theme-material': {
      color: theme.palette.text.secondary,

      backgroundColor: 'rgba(0,0,0,.05) ',
    },
    '* .ag-theme-material .ag-root-wrapper': {
      backgroundColor: 'transparent',
    },
    '* .ag-theme-material .ag-cell,.ag-theme-material .ag-header-cell': {
      padding: '0 4px',
    },
    '* .ag-theme-material .ag-floating-filter-body input': {
      color: theme.palette.text.primary,
      borderBottomColor: theme.palette.divider,
    },
    ".ag-theme-material input[class^='ag-'][type='text']": {
      color: theme.palette.text.primary,
      borderBottomColor: theme.palette.divider,
    },
    '* .ag-theme-material .ag-floating-filter-body input:focus': {
      borderBottomColor: theme.palette.secondary.main,
    },
    "* .ag-theme-material .ag-input-wrapper input[type='date']": {
      color: theme.palette.text.primary,
      borderBottomColor: theme.palette.divider,
    },
    "* .ag-theme-material .ag-input-wrapper input[type='date']:focus": {
      borderBottomColor: theme.palette.secondary.main,
    },
    ".ag-theme-material input:focus[class^='ag-'][type='text']": {
      borderBottomColor: theme.palette.secondary.main,
    },
    '* .ag-theme-material .ag-header': {
      backgroundColor: theme.palette.background.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    '* .ag-theme-material .ag-header-cell': {
      backgroundColor: theme.palette.background.primary,
      borderColor: theme.palette.divider,
    },
    '.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell': {
      borderColor: theme.palette.divider,
    },
    '* .ag-theme-material .ag-icon-filter:before': {
      color: theme.palette.text.faded,
    },
    '* .ag-theme-material .ag-header-row:first-child .ag-header-cell': {
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.text.primary,
      fontWeight: 400,
      //fontSize: theme.typography.fontSize,
    },
    '* .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover, \
      .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing, \
      .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover, \
      .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing, \
      .ag-theme-material .ag-header-group-cell:first-of-type:hover, \
      .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing, \
      .ag-theme-material .ag-header-cell:first-of-type:hover, \
      .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing ': {
      backgroundColor: 'red',
      //backgroundColor: fade(theme.palette.background.primary, 0.95),
      //   color: theme.palette.background.primary
    },

    '.ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-header-cell-moving):hover, .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing, \
    .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-header-cell-moving):hover,\
    .ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,\
    .ag-theme-material .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,\
    .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing,\
    .ag-theme-material .ag-header-cell:first-of-type:not(.ag-header-cell-moving):hover,\
    .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing': {
      backgroundColor: darken(theme.palette.background.primary, 0.1),
    },

    '*   .ag-theme-material .ag-row': {
      backgroundColor: theme.palette.background.light,
      borderColor: theme.palette.widgetBorder,
      color: theme.palette.text.secondary,
      //fontSize: theme.typography.fontSize,
    },
    '* .ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '*.ag-theme-material .ag-tab-header': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '* .ag-theme-material .ag-tab-header .ag-icon-filter:before, .ag-theme-material .ag-tab-header .ag-icon-menu:before, .ag-theme-material .ag-tab-header .ag-icon-columns:before':
      {
        color: theme.palette.text.primary,
      },
    '* .ag-theme-material .ag-icon-checkbox-checked': {
      color: theme.palette.secondary.main,
    },
    "* .ag-theme-material .ag-input-wrapper input[type='text']": {
      borderBottomColor: theme.palette.divider,
      color: theme.palette.text.secondary,
    },
    '* .ag-theme-material .ag-header-icon .ag-header-cell-menu-button  .ag-icon-menu:before': {
      color: theme.palette.text.subtitle,
    },
    '* .ag-theme-material .ag-icon-menu:before, .ag-theme-material .ag-icon-asc:before, .ag-theme-material .ag-icon-desc:before':
      {
        color: theme.palette.text.subtitle,
      },

    '* .ag-theme-material .ag-filter-body-wrapper': {
      backgroundColor: theme.palette.background.paper,
    },

    '*.ag-theme-material .ag-input-wrapper input:not([type])': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      //fontSize: theme.typography.fontSize,
    },
    '*.ag-theme-material .ag-input-wrapper input:not([type]):focus': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      // color:theme.palette.text.secondary
    },
    "* .ag-theme-material .ag-input-wrapper input[type='text']::placeholder": {
      color: theme.palette.text.secondary,
    },
    '* .ag-theme-material .ag-filter-select': {
      height: '36px',
      border: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },

    '* .ag-theme-material .ag-filter-select option': {
      height: '36px',
      border: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
    },
    '* .ag-theme-material .ag-row-suspended': {
      color: amber[300],
      backgroundColor: darken(amber[500], 0.2),
      border: `1px solid ${amber[300]}`,
      // backgroundColor: "rgba(255,193,7,.2)",
    },

    '* .ag-theme-material .ag-row-filled': {
      color: lightBlue[300],
      backgroundColor: darken(lightBlue[500], 0.2),
      border: `1px solid ${lightBlue[300]}`,
      // backgroundColor: "rgba(3,169,244,.3)",
    },
    '* .ag-theme-material .ag-row-cancelled': {
      color: red[300],
      backgroundColor: darken(red[500], 0.2),
      border: `1px solid ${red[300]}`,
      // backgroundColor: "rgba(244,67,44,.2)",
    },
    '* ag-row ag-row-no-focus ag-row-even ag-row-level-0 ag-row-position-absolute ag-row-first': {
      color: 'inherit',
    },
    '.ag-body-horizontal-scroll-viewport': {
      backgroundColor: theme.palette.background.default,
    },
    '.ag-horizontal-right-spacer.ag-scroller-corner': {
      backgroundColor: theme.palette.background.default,
    },
    '.ag-body-viewport.ag-layout-normal': {
      backgroundColor: theme.palette.background.default,
    },
    '.ag-theme-material .ag-select .ag-picker-field-wrapper ': {
      backgroundColor: 'transparent',
    },
    '.ag-theme-material .ag-select-list': {
      backgroundColor: theme.palette.background.default,
      background: theme.palette.background.default,
    },
    '.ag-theme-material .ag-list-item.ag-active-item': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
    },
  };

  return obj;
};
