import {
  INIT_WORKSPACE,
  ACTIVATE_WORKSPACE,
  UPDATE_WORKSPACE_LAYOUTS,
  ADD_LAYOUT_WIDGET,
  REMOVE_LAYOUT_WIDGET,
  INIT_WORKSPACE_SUCCESS,
  INIT_WORKSPACE_ERROR,
  ACTIVATE_WORKSPACE_SUCCESS,
  ACTIVATE_WORKSPACE_ERROR,
  UPDATE_WORKSPACES_ERROR,
  UPDATE_WORKSPACES_SUCCESS,
  UPDATE_WORKSPACE_LAYOUTS_SUCCESS,
  UPDATE_WORKSPACE_LAYOUTS_ERROR,
  ADD_LAYOUT_WIDGET_SUCCESS,
  ADD_LAYOUT_WIDGET_ERROR,
  REMOVE_LAYOUT_WIDGET_SUCCESS,
  REMOVE_LAYOUT_WIDGET_ERROR,
  ADD_COMPONENT,
  ADD_COMPONENT_SUCCESS,
  ADD_COMPONENT_ERROR,
  RESET_WORKSPACE,
  RESET_WORKSPACE_SUCCESS,
  RESET_WORKSPACE_ERROR,
  CLEAR_WORKSPACE,
  CLEAR_WORKSPACE_SUCCESS,
  CLEAR_WORKSPACE_ERROR,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_ERROR,
  REMOVE_WIDGET,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET_ERROR,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_WIDGET_ERROR,
  UPDATE_WORKSPACES,
  EDIT_MANAGE_WORKSPACE_NAME,
  ADD_MANAGE_WORKSPACE,
  SET_MANAGE_ACTIVE_WORKSPACE,
  SAVE_WORKSPACES,
  COPY_MANAGE_WORKSPACE,
  DELETE_MANAGE_WORKSPACE,
  UPDATE_MANAGE_WORKSPACES,
  UPDATE_MANAGE_WORKSPACES_SUCCESS,
  UPDATE_MANAGE_WORKSPACES_ERROR,
} from '../constants';
import { blankWorkspace } from '../config';

import auth from '../../auth';

import { isBoolean } from '../../../commons/utils/functions';

const initialState = {
  default: null,
  items: [],
  manage: {
    open: false,
    items: [],
    status: null,
    error: null,
    receivedAt: null,
  },
  status: null,
  error: null,
  receivedAt: null,
};

const reducer = (state = initialState, action) => {
  let nextState = null;
  let nextManageWorkspaces = null;

  switch (action.type) {
    case INIT_WORKSPACE:
      return {
        ...state,
        status: 'pending',
      };
    case INIT_WORKSPACE_SUCCESS:
      return {
        ...state,
        default: action.payload.defaultItem,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case INIT_WORKSPACE_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case ACTIVATE_WORKSPACE:
      return {
        ...state,
        status: 'pending',
      };
    case ACTIVATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case ACTIVATE_WORKSPACE_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case RESET_WORKSPACE:
      return {
        ...state,
        status: 'pending',
      };
    case RESET_WORKSPACE_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case RESET_WORKSPACE_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case CLEAR_WORKSPACE:
      return {
        ...state,
        status: 'pending',
      };
    case CLEAR_WORKSPACE_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case CLEAR_WORKSPACE_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case ADD_COMPONENT:
      return {
        ...state,
        status: 'pending',
      };
    case ADD_COMPONENT_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case ADD_COMPONENT_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case UPDATE_WORKSPACE:
    case UPDATE_WIDGET:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_WORKSPACE_SUCCESS:
    case UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_WORKSPACE_ERROR:
    case UPDATE_WIDGET_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case REMOVE_LAYOUT_WIDGET:
    case REMOVE_WIDGET:
      return {
        ...state,
        status: 'pending',
      };
    case REMOVE_LAYOUT_WIDGET_SUCCESS:
    case REMOVE_WIDGET_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case REMOVE_LAYOUT_WIDGET_ERROR:
    case REMOVE_WIDGET_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case UPDATE_WORKSPACES:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_WORKSPACES_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_WORKSPACES_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case UPDATE_WORKSPACE_LAYOUTS:
      return {
        ...state,
        status: 'pending',
      };
    case UPDATE_WORKSPACE_LAYOUTS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case UPDATE_WORKSPACE_LAYOUTS_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case ADD_LAYOUT_WIDGET:
      return {
        ...state,
        status: 'pending',
      };
    case ADD_LAYOUT_WIDGET_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        status: 'success',
        receivedAt: action.meta.receivedAt,
      };
    case ADD_LAYOUT_WIDGET_ERROR:
      return {
        ...state,
        status: 'error',
      };
    case UPDATE_MANAGE_WORKSPACES:
      nextState = {
        ...state,
        manage: {
          ...state.manage,
          status: 'pending',
        },
      };
      return nextState;
    case UPDATE_MANAGE_WORKSPACES_SUCCESS:
      nextManageWorkspaces = {
        ...state.manage,
        status: 'success',
        error: null,
        receivedAt: action.meta.receivedAt,
      };

      if (isBoolean(action.payload.open)) {
        nextManageWorkspaces.open = action.payload.open;
      }

      if (Array.isArray(action.payload.items)) {
        nextManageWorkspaces.items = action.payload.items;
      }

      nextState = {
        ...state,
        manage: {
          ...nextManageWorkspaces,
        },
      };
      return nextState;
    case UPDATE_MANAGE_WORKSPACES_ERROR:
      nextState = {
        ...state,
        manage: {
          ...state.manage,
          status: 'error',
          error: action.payload,
        },
      };
      return nextState;
    case SAVE_WORKSPACES:
      return {
        ...state,
        items: [...action.payload],
        manage: {
          ...state.manage,
          visible: false,
        },
      };
    case ADD_MANAGE_WORKSPACE:
      return {
        ...state,
        manageItems: [...state.manageItems, blankWorkspace(state.manageItems.length + 1)],
      };
    case COPY_MANAGE_WORKSPACE:
      const origin = state.manageItems.find((item) => action.payload === item.id);
      return {
        ...state,
        manageItems: [...state.manageItems, { ...origin, name: `${origin.name} COPY` }],
      };
    case EDIT_MANAGE_WORKSPACE_NAME:
      return {
        ...state,
        manageItems: state.manageItems.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              name: action.payload.name,
            };
          }
          return item;
        }),
      };
    case SET_MANAGE_ACTIVE_WORKSPACE:
      return {
        ...state,
        manageItems: state.manageItems.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              active: true,
            };
          }
          return {
            ...item,
            active: false,
          };
        }),
      };
    case DELETE_MANAGE_WORKSPACE:
      const newItems = state.manageItems.filter((item) => item.id !== action.payload);
      if (!newItems.find((item) => item.active) && newItems.length) {
        newItems[0].active = true;
      }
      return {
        ...state,
        manageItems: newItems,
      };
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
