export const NAME = 'chat';

export const CHATS_UPDATE = 'chats/update';
export const CHATS_UPDATE_SUCCESS = 'chats/update/success';
export const CHATS_UPDATE_ERROR = 'chats/update/error';

export const CHATS_SELECT_CHAT = 'chats/select/chat';
export const CHATS_SELECT_CHAT_SUCCESS = 'chats/select/chat/success';
export const CHATS_SELECT_CHAT_ERROR = 'chats/select/chat/error';

export const CHATS_RECEIVE_MESSAGES_FROM_SOCKET = 'chats/receive/messages/from/socket';
export const CHATS_RECEIVE_MESSAGES_FROM_SOCKET_SUCCESS =
  'chats/receive/messages/from/socket/success';
export const CHATS_RECEIVE_MESSAGES_FROM_SOCKET_ERROR = 'chats/receive/messages/from/socket/error';

export const CHATS_INSERT_CHATS = 'chats/insert/chats';
export const CHATS_INSERT_CHATS_SUCCESS = 'chats/insert/chats/success';
export const CHATS_INSERT_CHATS_ERROR = 'chats/insert/chats/error';

export const MODAL_CHAT_OPEN = 'chats/modal/open';
export const MODAL_CHAT_OPEN_SUCCESS = 'chats/modal/open/success';
export const MODAL_CHAT_OPEN_ERROR = 'chats/modal/open/error';
