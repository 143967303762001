import { createSelector } from 'reselect';
import { NAME } from '../constants';

import { newCalculator } from '../config/calculators';

import * as fxratesSelectors from '../../fxrates/selectors';

const getCalculatorsFromSettingsProp = (state) => {
  const { items } = state[NAME].settings;
  return items;
};
export const getCalculatorsFromSettings = createSelector(
  getCalculatorsFromSettingsProp,
  (items) => {
    return items;
  },
);

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => {
  return items;
});
export const getAll = createSelector(getAllObject, (items) => {
  return Object.values(items);
});

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const emptyCalculator = {};
const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item || emptyCalculator;
};
export const getById = createSelector(getByIdProp, (item) => item);

export const getNewCalculator = (state) => {
  const calculator = newCalculator();
  return calculator;
};

const getLocalCalculatorsProp = (state) => {
  const { items } = state[NAME];
  return items;
};
export const getLocalCalculators = createSelector(getLocalCalculatorsProp, (items) => items);

const emptyLocalCalculator = {};
const getLocalCalculatorProp = (state, id) => {
  const item = state[NAME].items[id];
  return item || emptyLocalCalculator;
};
export const getLocalCalculator = createSelector(getLocalCalculatorProp, (item) => item);

const emptyManagerCalculator = {};
const getManagerCalculatorProp = (state) => {
  const { manager } = state[NAME];
  return manager || emptyManagerCalculator;
};
export const getManagerCalculator = createSelector(getManagerCalculatorProp, (item) => item);

const isOpenManagerCalculatorProp = (state) => {
  const { open } = state[NAME].manager;
  return open;
};
export const isOpenManagerCalculator = createSelector(isOpenManagerCalculatorProp, (open) => open);

const getAllLocalCalculatorsProp = (state) => {
  const { items } = state[NAME];
  return Object.values(items);
};
export const getAllLocalCalculators = createSelector(getAllLocalCalculatorsProp, (items) => items);

export const getFxratesObject = createSelector(fxratesSelectors.getAllObject, (itemsObject) => {
  return itemsObject;
});
