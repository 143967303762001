import { eventChannel } from 'redux-saga';
import { call, put, take, fork, cancelled } from 'redux-saga/effects';

import * as signalR from '@microsoft/signalr';

import { Auth } from 'aws-amplify';

import socketConfig from '../config';

import * as actionTypes from '../actionTypes';

import { socketAction } from '../../../commons/actions';

function createWebSocketConnection() {
  const { url } = socketConfig;

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: async () => {
        // TODO: review if socket can use select:getToken from auth module.
        const awsAuth = await Auth.currentAuthenticatedUser();

        if (awsAuth.signInUserSession) {
          return awsAuth.signInUserSession.accessToken.jwtToken || null;
        }

        return null;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => {
        return socketConfig.RECONNECT_TIMEOUT;
      },
    })
    .build();

  // connection.serverTimeoutInMilliseconds = socketConfig.SERVER_TIMEOUT;

  return connection;
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket
      .start() // { withCredentials: true }
      .then(() => {
        socket.invoke('SubscribeToUpdates', socketConfig.events);
      })
      .catch((error) => {
        console.log('SOCKET:OnServerUpdates:start:catch:error', error);
      });

    socket.on('OnServerUpdates', (receivedMessage) => {
      emit(receivedMessage);
    });

    socket.on('close', () => {
      socket.off('OnServerUpdates');
      emit(signalR.HubConnectionState.Disconnected);
      // emit(END);
    });

    socket.onreconnecting(() => {
      emit(signalR.HubConnectionState.Reconnecting);
    });

    socket.onreconnected(() => {
      socket.invoke('SubscribeToUpdates', socketConfig.events);
      emit('Reconnected');
      // emit(connectionId);
    });

    const unsubscribe = () => {
      socket.stop();
    };

    return unsubscribe;
  });
}

function* listenSocket() {
  let socket;
  let socketChannel;

  try {
    socket = yield call(createWebSocketConnection);
    socketChannel = yield call(createSocketChannel, socket);
    // yield put({ type: 'CONNECT_SUCCESS' });

    while (true) {
      const payload = yield take(socketChannel);
      yield call(processData, payload);
      // yield put({ type: 'SOCKET_EVENT' }); // payload;
    }
  } catch (error) {
    console.error(error);
    // yield put({ type: 'CONNECT_ERROR' }); // Error while connecting to the WebSocket';
  } finally {
    if (yield cancelled()) {
      socketChannel.close();
      // socket = null;
      // socketChannel = null;
      // socket.close();
    } else {
      // yield put({ type: 'CONNECT_ERROR' }); // Socket disconnect
    }
  }
}

function* processData(receivedMessage) {
  console.log('received Message', receivedMessage);
  console.log(
    'received Message.type',
    receivedMessage.payload ? receivedMessage.payload.type : 'no Type',
  );
  if (typeof receivedMessage === 'string') {
    const events = {
      [signalR.HubConnectionState.Connected]: actionTypes.SOCKET_CONNECTED,
      [signalR.HubConnectionState.Disconnected]: actionTypes.SOCKET_DISCONNECTED,
      [signalR.HubConnectionState.Reconnecting]: actionTypes.SOCKET_RECONNECTING,
      Reconnected: actionTypes.SOCKET_RECONNECTED,
    };

    if (events[receivedMessage]) {
      yield put(socketAction(events[receivedMessage]));
    }
  } else if (Object.prototype.toString.call(receivedMessage) === '[object Object]') {
    const { payload } = receivedMessage;

    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i += 1) {
        const { type, action, data } = payload[i];

        if (
          typeof type === 'string' &&
          typeof action === 'string' &&
          socketConfig.types[type.toLowerCase()] &&
          socketConfig.types[type.toLowerCase()][action.toLowerCase()]
        ) {
          yield put(
            socketConfig.types[type.toLowerCase()][action.toLowerCase()]({
              items: [JSON.parse(data)],
            }),
          );
        }
      }
    }
  }
}

export function* watchSocket() {
  /* const socketTask = */ yield fork(listenSocket);
  /* yield take('DISCONNECT');
  yield cancel(socketTask);
  yield put({ type: 'DISCONNECT_SUCCESS' }); */
}
