import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { MenuItem, TextField } from '@material-ui/core';

const styles = (theme) => ({});

const CustomSelect = (props) => {
  const options = props.enumOptions || [];
  const { accessor, className } = props;
  const value = props.value === null ? '' : props.value;
  return (
    <TextField
      id={props.cellData.id}
      fullWidth
      InputProps={{ disableUnderline: true, fullWidth: true, className }}
      select
      name={props.cellData.name}
      value={value}
      onChange={props.onChange(accessor)}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomSelect);
